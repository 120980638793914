import React, { Component } from "react";
// import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvRadio, AvRadioGroup, AvInput } from 'availity-reactstrap-validation';

// import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createFloorPlan, FloorPlanList, editFloorPlan, FloorPlanDetails, CityNameList, floorPlanImages, DistrictNameList, FilterOptionList, SpecificFilterOptionList, SpecificCityNameList, SpecificDistrictNameList, } from '../../store/FloorPlan/actions';
import { SubProjectNameListData, SpecificProjectNameList } from '../../store/ProjectName/actions';
// import {SpecificProjectNameList} from '../../store/ProjectName/actions';
import { clearSuccess } from '../../store/FilterValue/actions';

// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import 'react-image-picker-editor/dist/index.css'
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class FloorPlanEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            activeTabProgress: 1,
            supplier: {

            },
            status: 0,
            rows1: [],
            change_site_map_image: {},
            change_project_logo: {},
            change_project_cover_image_2: {},
            change_project_cover_image_1: {},
            project_cover_image_2: {},
            hasExtraValue: false,
            errorMessage: '',
            selectedGroup: null,
            districtId: null,
            bathroom_details: '',
            details_descrptions: '',
            short_descriptions: '',
            cityId: null,
            storiesId: null,
            bedroomId: null,
            garageId: null,
            bathroomId: null,
            sizeId: null,
            priceId: null,
            brand: {

            },


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlleProjectName = this.handlleProjectName.bind(this);
        this.handleCityId = this.handleCityId.bind(this);
        this.handledistrictId = this.handledistrictId.bind(this);
        this.handlebathroomId = this.handlebathroomId.bind(this);
        this.handlebedroomId = this.handlebedroomId.bind(this);
        this.handlesizeId = this.handlesizeId.bind(this);
        this.handlepriceId = this.handlepriceId.bind(this);
        this.handlegarageId = this.handlegarageId.bind(this);
        this.handlestoriesId = this.handlestoriesId.bind(this);
    }

    componentDidMount() {
        // console.log(this.props)
        if (this.props.location.state !== undefined) {
            // console.log(this.props.location.state.value.id)
            this.fetchFloorPlanDetails(this.props.location.state.value.id)
            this.fetchFloorPlanImages(this.props.location.state.value.id)

            this.fetchSpecificParentProjectNameList(this.props.location.state.value.id)
            this.fetchSpecificDistrictNameList(this.props.location.state.value.id)
            this.fetchSpecificCitynameList(this.props.location.state.value.id)
            this.fetchSpecificFilterOptionList(this.props.location.state.value.id)

            this.fetchProjectSliderList();
            this.fetchCitynameList();
            this.fetchDistrictNameList();
            this.fetchFilterOptionList();
        }
        else {
            let id = window.location.href.split('/').pop();
            this.fetchFloorPlanDetails(id)
            this.fetchFloorPlanImages(id)
            this.fetchSpecificParentProjectNameList(id)
            this.fetchSpecificDistrictNameList(id)
            this.fetchSpecificCitynameList(id)
            this.fetchSpecificFilterOptionList(id)
            this.fetchProjectSliderList();
            this.fetchCitynameList();
            this.fetchDistrictNameList();
            this.fetchFilterOptionList();
        }
    }
    fetchSpecificParentProjectNameList = (id) => {

        SpecificProjectNameList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ParentProjectNameList: response.data[0].options[0],
                });
                this.state.ParentProjectNameList = response.data[0].options[0];
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchSpecificCitynameList = (id) => {

        SpecificCityNameList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    SpecificCityNameList: response.data[0].options[0],
                });
                this.state.SpecificCityNameList = response.data[0].options[0];

            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchSpecificDistrictNameList = (id) => {

        SpecificDistrictNameList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    SpecificDistrictNameList: response.data[0].options[0],
                });
                this.state.SpecificDistrictNameList = response.data[0].options[0];

            } else {
                this.setState({
                    DistrictNameList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchSpecificFilterOptionList = (id) => {

        SpecificFilterOptionList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    SpecificFilterOptionList: response.data,
                    storiesId: response.data.Stories[0].options[0],
                    bedroomId: response.data.Bedroom[0].options[0],
                    garageId: response.data.Garage[0].options[0],
                    bathroomId: response.data.Bathrooms[0].options[0],
                    sizeId: response.data.Size[0].options[0],
                    priceId: response.data.Price[0].options[0],
                    bedRoom: response.data.Bedroom[0].options[0],
                });




                this.state.SpecificFilterOptionList = response.data;
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchFloorPlanDetails = (id) => {


        FloorPlanDetails(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    FloorPlanList: response.data[0],
                });
                this.state.FloorPlanList = response.data[0];

            } else {
                this.setState({
                    FloorPlanList: {

                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchFloorPlanImages = (id) => {


        floorPlanImages(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    FloorPlanImages: response.data,
                });
                this.state.FloorPlanImages = response.data;
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }


    fetchProjectSliderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        SubProjectNameListData(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data,
                });
                this.state.ProjectNameList = response.data;

            }
            else {
                this.setState({
                    ProjectNameList: {
                    },
                });
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchCitynameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        CityNameList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    CityNameList: response.data,
                });
                this.state.CityNameList = response.data;

            } else {
                this.setState({
                    CityNameList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchDistrictNameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        DistrictNameList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    DistrictNameList: response.data,
                });
                this.state.DistrictNameList = response.data;

            } else {
                this.setState({
                    DistrictNameList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchFilterOptionList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        FilterOptionList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    FilterOptionList: response.data,
                });
                this.state.FilterOptionList = response.data;

            } else {
                this.setState({
                    FilterOptionList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }


    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        console.log("values", values);
        this.props.editFloorPlan(values);

    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }


    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const formData = { file: image, name: "change_project_cover_image_1", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_cover_image_1["change_project_cover_image_1"] = formData;

        // console.log("this.setState.project_cover_image_1[0]", this.state.project_cover_image_1["project_cover_image_1"]);

    }

    handleLargeImageUpload = (image, id) => {

        const formData = { file: image, name: "change_project_cover_image_2", type: "image/jpeg", id: id };
        //console.log("formData", formData);

        this.state.change_project_cover_image_2["change_project_cover_image_2"] = formData;
        console.log(formData);
    }

    handleNewImageUpload = (index, image) => {

        const formData = { file: image, name: `project_cover_image_${index}`, type: "image/jpeg" };
        // //console.log("formData", formData);

        this.state.project_cover_image_2[index] = formData;
        console.log(formData);
    }

    handleMediumImageUpload = (image) => {

        const formData = { file: image, name: "change_site_map_image", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_site_map_image["change_site_map_image"] = formData;
    }

    handleProjectLogoUpload = (image) => {

        const formData = { file: image, name: "change_project_logo", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_logo["change_project_logo"] = formData;
    }

    toggleTab(tab) {
        if (tab === 2 && (document.getElementById('floore_plan_name').value == '' || document.getElementById('floore_plan_name').value == null
            || document.getElementById('floore_plan_name').value == undefined)) {
            this.setState({
                errorMessage: "Floor Plan name is required",
            })
        }
        else if (tab === 2 && (document.getElementById('floor_type').value == '' || document.getElementById('floor_type').value == null
            || document.getElementById('floor_type').value == undefined)) {
            this.setState({
                errorMessage: "Project Title is required",
            })
        }

        else if (tab === 2 && (document.getElementById('f_city_info_id').value == '' || document.getElementById('f_city_info_id').value == null
            || document.getElementById('f_city_info_id').value == undefined)) {
            this.setState({
                errorMessage: "City is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_district_id').value == '' || document.getElementById('f_district_id').value == null
            || document.getElementById('f_district_id').value == undefined)) {
            this.setState({
                errorMessage: "District is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_stories_id').value == '' || document.getElementById('f_stories_id').value == null
            || document.getElementById('f_stories_id').value == undefined)) {
            this.setState({
                errorMessage: "Stories is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_bedroom_id').value == '' || document.getElementById('f_bedroom_id').value == null
            || document.getElementById('f_bedroom_id').value == undefined)) {
            this.setState({
                errorMessage: "Bedroom is required",
            })
        }
        else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
            || document.getElementById('priority').value == undefined)) {
            this.setState({
                errorMessage: "Priority is required",
            })
        }
        else {
            if (this.state.activeTab !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

    }

    SuccessMessage() {
        localStorage.setItem('floorPlanCreate', 'true');
        this.props.history.push(
            {
                pathname: '/index/floorPlan',
            }
        )
    }

    handleAddRowNested = () => {
        const item1 = {
            name1: ""
        };
        this.setState({
            rows1: [...this.state.rows1, item1]
        });
    };

    handleRemoveRowNested = (e, idx) => {
        document.getElementById("nested" + idx).style.display = "none";
    };


    removeMessage() {
        this.setState({ errorMessage: '' });
    }
    handlleProjectNameFilter(option, inputValue) {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }

    handlleProjectName(selectedGroup) {

        this.setState({
            ParentProjectNameList: selectedGroup
        })
        console.log("out", this.state.selectedGroup);

        //   this.state.selectedGroup = option;
    }

    handleCityId(cityId) {

        this.setState({ SpecificCityNameList: cityId });
    }
    handledistrictId(districtId) {

        this.setState({ SpecificDistrictNameList: districtId });
    }
    handlepriceId(priceId) {

        this.setState({ priceId });
    }
    handlesizeId(sizeId) {

        this.setState({ sizeId });
    }
    handlestoriesId(storiesId) {

        this.setState({ storiesId });
    }
    handlebedroomId(bedroomId) {

        this.setState({ bedroomId });
    }
    handlegarageId(garageId) {

        this.setState({ garageId });
    }
    handlebathroomId(bathroomId) {
        this.setState({ bathroomId });
    }


    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/index/floorPlan',
            }
        )
    }
    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;

        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        const initialImage = '';
        const { ParentProjectNameList, SpecificDistrictNameList, SpecificCityNameList } = this.state;
        const defaultValues = {
            is_quick_move_in_homes: this.state.FloorPlanList && this.state.FloorPlanList.is_quick_move_in_homes,

            checkItOut: true,
        };

        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Floor Plan Create" />
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.toggleTab(1); }} >
                                                        <span className="step-number mr-2">01</span>
                                                        Floor Plan Setup
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => { this.toggleTab(2); }} >
                                                        <span className="step-number mr-2">02</span>
                                                        <span>Image upload</span>
                                                    </NavLink>
                                                </NavItem>

                                            </ul>
                                            {
                                                this.state.FloorPlanList &&
                                                <>
                                                    <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">

                                                        <TabPane tabId={1}>
                                                            <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)} model={defaultValues}>

                                                                <Row>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">Floor Plan name : </Label>
                                                                        <Select
                                                                            //isMulti
                                                                            onChange={this.handlleProjectName}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.ProjectNameList}
                                                                            value={ParentProjectNameList && ParentProjectNameList}
                                                                        />
                                                                        <AvField type="hidden" name="project_id_value" id="project_id_value" value={ParentProjectNameList && ParentProjectNameList.value} required />
                                                                        <AvField type="hidden" name="id" id="id" value={this.state.FloorPlanList && this.state.FloorPlanList.id} required />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Floor Plan Name')} id="floore_plan_name" name="floore_plan_name" value={this.state.FloorPlanList.floore_plans_names} required />
                                                                    </div>

                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Floor Type'} value={this.state.FloorPlanList && this.state.FloorPlanList.floor_type} id="floor_type" name="floor_type" />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Address')} id="address" name="address" value={this.state.FloorPlanList && this.state.FloorPlanList.address} required />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Video Link'} id="vedio_link" name="vedio_link" value={this.state.FloorPlanList && this.state.FloorPlanList.vedio_link} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <AvField className="form-control" type="hidden" label={reqLabel('Short Description')} id="short_description" name="short_descriptions" value={this.state.short_descriptions && this.state.short_descriptions} />

                                                                        <CKEditor
                                                                            editor={ClassicEditorDescription}
                                                                            data={this.state.short_descriptions && this.state.short_descriptions}
                                                                            onReady={editor => {
                                                                                this.setState({
                                                                                    short_descriptions: this.state.FloorPlanList.short_descriptions && this.state.FloorPlanList.short_descriptions,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.setState({
                                                                                    short_descriptions: data,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Blur.', editor );
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Focus.', editor );
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <AvField className="form-control" type="hidden" label={reqLabel('Details Descrptions')} id="details_descrptions" name="details_descrptions" value={this.state.details_descrptions} />

                                                                        <CKEditor
                                                                            editor={ClassicEditorDescription}
                                                                            data={this.state.details_descrptions && this.state.details_descrptions}
                                                                            onReady={editor => {
                                                                                this.setState({
                                                                                    details_descrptions: this.state.FloorPlanList.details_descrptions && this.state.FloorPlanList.details_descrptions,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // You can store the "editor" and use when it is needed.
                                                                                // console.log( 'Editor is ready to use!', editor );
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.setState({
                                                                                    details_descrptions: data,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Blur.', editor );
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Focus.', editor );
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">{reqLabel('City name : ')}</Label>

                                                                        <Select
                                                                            onChange={this.handleCityId}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.CityNameList}
                                                                            value={SpecificCityNameList && SpecificCityNameList}


                                                                        />

                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">{reqLabel('School District name : ')}</Label>
                                                                        <Select
                                                                            onChange={this.handledistrictId}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.DistrictNameList}
                                                                            value={SpecificDistrictNameList && SpecificDistrictNameList}
                                                                        />

                                                                    </div>
                                                                    <AvField type="hidden" name="f_city_info_id" id="f_city_info_id" value={SpecificCityNameList && SpecificCityNameList.value} required />
                                                                    <AvField type="hidden" name="f_district_id" id="f_district_id" value={SpecificDistrictNameList && SpecificDistrictNameList.value} required />

                                                                </Row>

                                                                <Row>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">{reqLabel('Stories name :')} </Label>
                                                                        <Select
                                                                            onChange={this.handlestoriesId}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Stories}
                                                                            value={this.state.storiesId && this.state.storiesId}


                                                                        />

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Stories Quantity')} value={this.state.FloorPlanList && this.state.FloorPlanList.stories_qty} id="stories_qty" name="stories_qty" required />
                                                                    </div>
                                                                    <AvField type="hidden" name="f_stories_id" id="f_stories_id" value={this.state.storiesId && this.state.storiesId.value} required />

                                                                </Row>
                                                                <Row>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">{reqLabel('Bedroom name : ')}</Label>

                                                                        <Select
                                                                            onChange={this.handlebedroomId}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Bedroom}
                                                                            value={this.state.bedroomId && this.state.bedroomId}

                                                                        />

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Beedroom Quantity')} value={this.state.FloorPlanList && this.state.FloorPlanList.bedroom_qty} id="bedroom_qty" name="bedroom_qty" required />
                                                                    </div>
                                                                    <AvField type="hidden" name="f_bedroom_id" id="f_bedroom_id" value={this.state.bedroomId && this.state.bedroomId.value} required />
                                                                </Row>
                                                                <Row>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">{reqLabel('Garage name : ')}</Label>
                                                                        <Select
                                                                            onChange={this.handlegarageId}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Garage}

                                                                            value={this.state.garageId && this.state.garageId}
                                                                        />

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Garage Quantity')} value={this.state.FloorPlanList && this.state.FloorPlanList.garage_qty} id="garage_qty" name="garage_qty" required />
                                                                    </div>
                                                                    <AvField type="hidden" name="f_garage_id" id="f_garage_id" value={this.state.garageId && this.state.garageId.value} required />
                                                                </Row>
                                                                {/* </TabPane>

                                                                    <TabPane tabId={2}> */}

                                                                <Row>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">{reqLabel('Bathroom name : ')}</Label>

                                                                        <Select
                                                                            onChange={this.handlebathroomId}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Bathrooms}
                                                                            value={this.state.bathroomId && this.state.bathroomId}

                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Bathroom Quantity')} value={this.state.FloorPlanList && this.state.FloorPlanList.bathroom_qty} id="bathroom_qty" name="bathroom_qty" required />
                                                                    </div>
                                                                    <AvField type="hidden" name="f_bathroom_id" id="f_bathroom_id" value={this.state.bathroomId && this.state.bathroomId.value} required />
                                                                </Row>

                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <AvField className="form-control" type="hidden" label={reqLabel('Bathroom Details')} id="bathroom_details" name="bathroom_details" value={this.state.bathroom_details && this.state.bathroom_details} />

                                                                        <CKEditor
                                                                            editor={ClassicEditorDescription}
                                                                            data={this.state.bathroom_details && this.state.bathroom_details}
                                                                            onReady={editor => {
                                                                                this.setState({
                                                                                    bathroom_details: this.state.FloorPlanList.bathroom_details && this.state.FloorPlanList.bathroom_details,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";

                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.setState({
                                                                                    bathroom_details: data,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Blur.', editor );
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Focus.', editor );
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">{reqLabel('Size : ')}</Label>


                                                                        <Select
                                                                            onChange={this.handlesizeId}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Size}
                                                                            value={this.state.sizeId && this.state.sizeId}

                                                                        />

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Size Details Sqrft')} value={this.state.FloorPlanList && this.state.FloorPlanList.size_details_sq_fit} id="size_details_sq_fit" name="size_details_sq_fit" required />
                                                                    </div>

                                                                    <AvField type="hidden" name="f_size_id" id="f_size_id" value={this.state.sizeId && this.state.sizeId.value} required />
                                                                </Row>

                                                                <hr />
                                                                <Row>
                                                                    <div className="form-group col-md-6">
                                                                        <Label htmlFor="formname">{reqLabel('Price Id : ')}</Label>
                                                                        <Select
                                                                            onChange={this.handlepriceId}
                                                                            filterOption={this.handlleProjectNameFilter}
                                                                            noOptionsMessage={() => "No more options"}
                                                                            options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Price}
                                                                            value={this.state.priceId && this.state.priceId}
                                                                        />

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Price')} value={this.state.FloorPlanList && this.state.FloorPlanList.price} id="price" name="price" required />
                                                                    </div>

                                                                    <AvField type="hidden" name="f_price_id" id="f_price_id" value={this.state.priceId && this.state.priceId.value} required />
                                                                </Row>

                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvRadioGroup inline name="is_quick_move_in_homes" id="is_quick_move_in_homes" label={reqLabel('Is Quick Move In Home')}>
                                                                            <AvRadio customInput label="Yes" value={1} />
                                                                            <AvRadio customInput label="No" value={0} />
                                                                        </AvRadioGroup>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField type="date" name="quick_move_in_homes_ready_date" id="quick_move_in_homes_ready_date" value={this.state.FloorPlanList && this.state.FloorPlanList.quick_move_in_homes_ready_date} label={'Quick Ready Home Date'} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" value={this.state.FloorPlanList && this.state.FloorPlanList.remarks} />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" required value={this.state.FloorPlanList && this.state.FloorPlanList.priority} />
                                                                    </div>
                                                                    {/* <AvField type="hidden" value={this.state.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" /> */}
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField type="select" label={reqLabel('Status')} className="custom-select" name="Status" value={this.state.FloorPlanList.status}   >
                                                                            {/* <option defaultValue>Select Status</option> */}
                                                                            <option value="0" >Inactive</option>
                                                                            <option value="1">Active</option>
                                                                        </AvField>
                                                                    </div>
                                                                </Row>
                                                                <AvField type="hidden" value={this.state.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" />
                                                                <AvField type="hidden" value={this.state.change_project_cover_image_2} name="change_project_cover_image_2" id="change_project_cover_image_2" />
                                                            </AvForm>

                                                        </TabPane>


                                                        <TabPane tabId={2}>
                                                            <div className="row">

                                                                {
                                                                    this.state.FloorPlanImages &&
                                                                    this.state.FloorPlanImages.map((data, index) =>

                                                                        <div className="col-md-6 mt-2">
                                                                            <Label>{reqLabel('Image ' + (index + 1))}</Label><br />

                                                                            <div>
                                                                                {
                                                                                    data.slider_large_image != null ?
                                                                                        (
                                                                                            <img src={imageLink + 'FloorPlan/' + data.f_project_names_id + '/Slider/large/' + data.slider_large_image} alt="" className="w-75 h-50 img-fluid rounded mt-1 mb-2" />
                                                                                        ) : null
                                                                                }
                                                                            </div>
                                                                            <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1080px * 600px</small>
                                                                            < ReactImagePickerEditor
                                                                                id="project_cover_image_2_0"
                                                                                name="project_cover_image_2_0"
                                                                                type="file"
                                                                                config={config2}
                                                                                imageSrcProp={initialImage}
                                                                                imageChanged={(image) => {
                                                                                    this.handleLargeImageUpload(image, data.id)
                                                                                }} />

                                                                        </div>

                                                                    )

                                                                }
                                                            </div>

                                                            <div className="inner-repeater mb-4">
                                                                <table style={{ width: "100%" }}>
                                                                    <tbody>
                                                                        <tr id="addrMain" key="">
                                                                            <td>
                                                                                <Row className="mb-2">
                                                                                    <div className="col-md-4">
                                                                                        <br />
                                                                                        <br />
                                                                                        <Label>{reqLabel('Slider Image ')}</Label>
                                                                                        <br />
                                                                                        <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1080px * 600px</small>

                                                                                        < ReactImagePickerEditor
                                                                                            id="project_cover_image_2_0"
                                                                                            name="project_cover_image_2_0"
                                                                                            type="file"
                                                                                            config={config2}
                                                                                            imageSrcProp={initialImage}
                                                                                            imageChanged={(image) => {
                                                                                                this.handleNewImageUpload(0, image)
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <Col md="2" >
                                                                                        <Button
                                                                                            disabled
                                                                                            color="danger"
                                                                                            className="btn-block inner mt-5 "
                                                                                            style={{ width: "100%" }}
                                                                                        > Delete
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </td>
                                                                        </tr>

                                                                        {this.state.rows1.map((item1, idx) => (
                                                                            <tr id={"nested" + idx} key={idx}>
                                                                                <td>
                                                                                    <Row className="mb-2">
                                                                                        <div className="col-md-4">
                                                                                            {/* <Label>{reqLabel('Slider Image')}</Label><br /> */}
                                                                                            <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1080px * 600px</small>

                                                                                            <br />
                                                                                            < ReactImagePickerEditor
                                                                                                id="project_cover_image_2_0"
                                                                                                name="project_cover_image_2_0"
                                                                                                type="file"
                                                                                                config={config2}
                                                                                                imageSrcProp={initialImage}
                                                                                                imageChanged={(image) => {
                                                                                                    this.handleNewImageUpload((idx + 1), image)
                                                                                                }}
                                                                                            />

                                                                                        </div>
                                                                                        <Col md="2">
                                                                                            <Button
                                                                                                onClick={e =>
                                                                                                    this.handleRemoveRowNested(e, idx)
                                                                                                }
                                                                                                color="danger"
                                                                                                className="btn-block inner mt-5 "
                                                                                                style={{ width: "100%" }}
                                                                                            > Delete
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                <Button
                                                                    onClick={this.handleAddRowNested}
                                                                    color="success"
                                                                    className="mt-1"
                                                                >
                                                                    Add Image
                                                                </Button>
                                                            </div>
                                                        </TabPane>

                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                            <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab - 1); }}>Previous</Link></li>
                                                            {
                                                                this.state.activeTab == 1 ?
                                                                    <>
                                                                        <li className="next">
                                                                            <Button type="submit" form='my-form' className="mr-2" color="success">Submit</Button>
                                                                        </li>
                                                                        <li className={this.state.activeTab === 2 ? "next disabled mr-2" : "next mr-2"} ><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab + 1); }}>Next</Link></li>

                                                                    </>
                                                                    :
                                                                    <li className="next">
                                                                        <Button type="submit" form='my-form' className="mr-2" color="success">Submit</Button>
                                                                    </li>

                                                            }
                                                        </ul>
                                                    </TabContent>
                                                </>
                                            }
                                        </div>

                                        {/* 
                                            <div className=" mt-4">
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>
                                             */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.FloorPlan;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createFloorPlan, editFloorPlan, clearSuccess })(FloorPlanEdit));