import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {CREATE_ProjectName,CREATE_SubProjectName,EDIT_ProjectName,EDIT_SubProjectName,SHOW_ProjectName_SUCCESS,SHOW_SHOW_ProjectName,SHOW_ProjectName_ERROR} from './actionTypes';

import { brandSuccess, AboutUsPageSliderError,brandShow, merchantError, AboutUsPageSliderSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* CREATE_createProjectNames({ payload: { ProjectName } }) {
    try {
    //    console.log("SHOW_ProjectName",ProjectName);
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'ProjectName/create',ProjectName);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
                if(response.success == true){
                    // setTimeout(() => {
                    //     this.props.history.push({
                    //         pathname: '/index/projectName',
                    //     });
                    // }, 2000)
                }
            } 
          }
    } catch (error) {
        yield put(AboutUsPageSliderError("Can not Create !"));
    }
}
function* CREATE_createSubProjectNames({ payload: { ProjectName } }) {
    try {
    //    console.log("SHOW_ProjectName",ProjectName);
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'ProjectName/sub/create',ProjectName);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
                if(response.success == true){
                    // setTimeout(() => {
                    //     this.props.history.push({
                    //         pathname: '/index/projectName',
                    //     });
                    // }, 2000)
                }
            } 
          }
    } catch (error) {
        yield put(AboutUsPageSliderError("Can not Create !"));
    }
}


function* EDIT_createProjectNames({ payload: { ProjectName } }) {
    // console.log("saga ---- ",ProjectName);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'ProjectName/'+ProjectName.id, ProjectName);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(AboutUsPageSliderError(error));
    }
}
function* EDIT_createSubProjectNames({ payload: { ProjectName } }) {
    // console.log("saga ---- ",ProjectName);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'ProjectName/sub/'+ProjectName.id, ProjectName);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(AboutUsPageSliderError(error));
    }
}

export function* watchProjectName() {
    yield takeEvery(EDIT_ProjectName, EDIT_createProjectNames)
    yield takeEvery(EDIT_SubProjectName, EDIT_createSubProjectNames)
    yield takeEvery(CREATE_SubProjectName, CREATE_createSubProjectNames)
    yield takeEvery(CREATE_ProjectName, CREATE_createProjectNames)
    // yield takeEvery(SHOW_MERCHANT, showMerchant)
}

function* ProjectName() {
    yield all([
        fork(watchProjectName),
    ]);
}

export default ProjectName;