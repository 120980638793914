import React, { Component } from "react";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createAboutUsPageSlider, AboutUsPageSliderList, logo_small_Upload, logo_medium_Upload, logo_large_Upload, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload } from '../../store/AboutUsPageSlider/actions';
// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import classnames from 'classnames';




const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class AboutUsPageSliderCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            supplier: {

            },
            Status: 0,

            slider_medium_image: {},
            slider_large_image: {},
            slider_thumbnail_image: {},

            imageSrc: '',
            site_map_image: {},

            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 25,

            long_descriptiom: '',
            short_description: '',


            brand: {

            },

            displayHide: false,



        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchAboutUsPageSliderList();
        // this.fetchCityList();
        // this.fetchMarchantList();
    }

    fetchAboutUsPageSliderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        AboutUsPageSliderList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    AboutUsPageSliderList: response.data.data,
                });
                this.state.AboutUsPageSliderList = response.data.data;

                // console.log("Setting companySettingListLists: " + response.data.data[0]);
            } else {
                this.setState({
                    AboutUsPageSliderList: {

                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        if (values.slider_large_image.slider_large_image == undefined) {
            this.setState({
                error: "select slider large image",
            });
        } else {
            this.setState({
                displayHide: true
            })
            this.props.createAboutUsPageSlider(values);

          
        }
    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handletHumbnailImageUpload = (image) => {
        const formData = { file: image, name: "slider_thumbnail_image", type: "image/jpeg" };

        this.state.slider_thumbnail_image["slider_thumbnail_image"] = formData;
    }


    handleLargeImageUpload = (image) => {
        const formData = { file: image, name: "slider_large_image", type: "image/jpeg" };
        console.log("formData", formData);

        this.state.slider_large_image["slider_large_image"] = formData;
    }

    handleMediumImageUpload = (image) => {
        const formData = { file: image, name: "slider_medium_image", type: "image/jpeg" };
        console.log("formData", formData);

        this.state.slider_medium_image["slider_medium_image"] = formData;
    }

    toggleTab(tab) {
        if (tab === 2 && (document.getElementById('slider_header_title').value == '' || document.getElementById('slider_header_title').value == null
            || document.getElementById('slider_header_title').value == undefined)) {
            this.setState({
                errorMessage: "Name is required",
            })
        }
        else if (tab === 2 && (document.getElementById('slider_title').value == '' || document.getElementById('slider_title').value == null
            || document.getElementById('slider_title').value == undefined)) {
            this.setState({
                errorMessage: "Position/Designation is required",
            })
        }
        else if (tab === 2 && (document.getElementById('Status').value == 'Select Status' || document.getElementById('Status').value == '' || document.getElementById('Status').value == null
            || document.getElementById('Status').value == undefined)) {
            this.setState({
                errorMessage: "Status is required",
            })
        }
        else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
            || document.getElementById('priority').value == undefined)) {
            this.setState({
                errorMessage: "Priority is required",
            })
        }
        else {
            if (this.state.activeTab !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTab: tab
                    });
                }
            }

        }

    }


    toggleTabProgress(tab) {


        if (this.state.activeTabProgress !== tab) {

            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTabProgress: tab
                });

                if (tab === 1) { this.setState({ progressValue: 25 }) }
                if (tab === 2) { this.setState({ progressValue: 50 }) }
                if (tab === 3) { this.setState({ progressValue: 75 }) }
                if (tab === 4) { this.setState({ progressValue: 100 }) }
            }
        }


    }

    removeMessage() {
        this.setState({ errorMessage: '' });
        this.setState({ error: '' });

    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };

        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;




        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        // console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }

        const clear = () => {
            localStorage.setItem('last_action', "create");

            this.props.history.push(
                {
                    pathname: '/management/info',
                }
            )

        }

        if(this.props.success != '') {
            this.state.displayHide = false;
        }

        return (
            <React.Fragment>

                {
                    this.state.displayHide == true ?
                        <div className="page-content" style={{position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}}>
                            <h6 align="center">Loding...</h6>
                            <img style={{position:'center'}} src="https://i.pinimg.com/originals/8e/44/36/8e44367589f9f262759be288459c7fdc.gif" />
                            {this.state.error && this.state.error ?
                                        <SweetAlert
                                            danger
                                            title={this.state.error}
                                            timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.success && this.state.success ?
                                        <SweetAlert
                                            title={this.state.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onConfirm={() => window.location.reload()
                                            }
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.error && this.props.error ?
                                        <SweetAlert
                                            danger
                                            title={this.props.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.success && this.props.success ?
                                        <SweetAlert
                                            title={this.props.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                        </div>
                        :
                        <>
                            <div className="page-content">
                                <Container fluid={true}>

                                    <Breadcrumbs title="Settings" breadcrumbItem="Management info Create" />
                                    {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                                    {this.props.error && this.props.error ?
                                        <SweetAlert
                                            danger
                                            title={this.props.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.success && this.props.success ?
                                        <SweetAlert
                                            title={this.props.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            success
                                            onCancel={this.hideAlert}
                                            onConfirm={() =>

                                                clear()

                                            }
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.errorMessage && this.state.errorMessage ?
                                        <SweetAlert
                                            danger
                                            title={this.state.errorMessage}
                                            timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => this.removeMessage()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.error && this.state.error ?
                                        <SweetAlert
                                            danger
                                            title={this.state.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => this.removeMessage()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.success && this.state.success ?
                                        <SweetAlert
                                            title={this.state.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            success
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    <Row>


                                        <Col lg="12" md="12">
                                            <Card>
                                                <CardBody>

                                                    {/* new */}
                                                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.toggleTab(1); }} >
                                                                    <span className="step-number mr-2">01</span>
                                                                    Management info Setup
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => { this.toggleTab(2); }} >
                                                                    <span className="step-number mr-2">02</span>
                                                                    <span>Image upload</span>
                                                                </NavLink>
                                                            </NavItem>

                                                        </ul>

                                                        <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">

                                                            <TabPane tabId={1}>
                                                                <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Name')} id="slider_header_title" name="slider_header_title" required />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Position/Designation')} id="slider_title" name="slider_title" required />
                                                                        </div>
                                                                    </Row>
                                                                    <Row>

                                                                        <div className="col-md-6">
                                                                            <FormGroup>
                                                                                <Label>Short Bio</Label>
                                                                                <CKEditor
                                                                                    editor={ClassicEditorDescription}
                                                                                    data={this.state.short_description}
                                                                                    onReady={editor => {
                                                                                        this.setState({
                                                                                            short_description: this.state.short_description,
                                                                                        });
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this.setState({
                                                                                            short_description: data,
                                                                                        });
                                                                                    }}
                                                                                    onBlur={(event, editor) => {
                                                                                        // console.log( 'Blur.', editor );
                                                                                    }}
                                                                                    onFocus={(event, editor) => {
                                                                                        // console.log( 'Focus.', editor );
                                                                                    }}


                                                                                />
                                                                                <AvField name="short_description" value={this.state.short_description} className="form-control" type="hidden" />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <FormGroup>
                                                                                <Label>Long Bio</Label>

                                                                                <CKEditor
                                                                                    editor={ClassicEditorDescription}
                                                                                    data={this.state.long_descriptiom}
                                                                                    onReady={editor => {
                                                                                        this.setState({
                                                                                            long_descriptiom: this.state.long_descriptiom,
                                                                                        });
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this.setState({
                                                                                            long_descriptiom: data,
                                                                                        });
                                                                                    }}
                                                                                    onBlur={(event, editor) => {
                                                                                        // console.log( 'Blur.', editor );
                                                                                    }}
                                                                                    onFocus={(event, editor) => {
                                                                                        // console.log( 'Focus.', editor );
                                                                                    }}


                                                                                />
                                                                                <AvField name="long_descriptiom" value={this.state.long_descriptiom} className="form-control" type="hidden" />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </Row>
                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" required />
                                                                        </div>
                                                                    </Row>
                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <Label>{reqLabel('Status')}</Label><br />
                                                                            <AvField type="select" className="form-control" id="Status" name="Status"   >
                                                                                <option value="1">Active</option>
                                                                                <option value="0">Inactive</option>

                                                                            </AvField>
                                                                        </div>
                                                                    </Row>
                                                                    <AvField type="hidden" value={this.state.slider_large_image} name="slider_large_image" id="slider_large_image" />
                                                                    <AvField type="hidden" value={this.state.slider_medium_image} name="slider_medium_image" id="slider_medium_image" />
                                                                    <AvField type="hidden" value={this.state.slider_thumbnail_image} name="slider_thumbnail_image" id="slider_thumbnail_image" />

                                                                </AvForm>
                                                            </TabPane>

                                                            <TabPane tabId={2}>

                                                                <Row>

                                                                    <div className="col-md-6">
                                                                        <Label>{reqLabel('Image')}</Label><br />
                                                                        <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 250px * 250px</small>
                                                                        < ReactImagePickerEditor
                                                                            id="slider_large_image_0"
                                                                            name="slider_large_image_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleLargeImageUpload(image)
                                                                            }} />

                                                                    </div>
                                                                </Row>


                                                            </TabPane>

                                                        </TabContent>
                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                            <li  className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab - 1); }}>Previous</Link></li>
                                                            {
                                                                this.state.activeTab == 2 ?
                                                                    <li className="next">
                                                                        <Button type="submit" form='my-form' color="success">Submit</Button>
                                                                    </li>
                                                                    :
                                                                    <li  className={this.state.activeTab === 2 ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab + 1); }}>Next</Link></li>

                                                            }
                                                        </ul>

                                                    </div>


                                                    {/* new end */}



                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </>
                }


            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createAboutUsPageSlider, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload })(AboutUsPageSliderCreate));