import React from 'react';
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

export class CustomPagination extends React.Component {

  render() {
    const {pagesCount, currentPage, from, to, total} = this.props.paginator;
    let startPage = 1, endPage = pagesCount;

    if (pagesCount > 7) {
      startPage = Math.max(1, currentPage - 3);
      endPage = Math.min(currentPage + 5, pagesCount);
    }
    let totalItem = (endPage - startPage) + 1;
    if(endPage == undefined){ totalItem = 1;}
    
    return (
      <Row>
        <Col xs="12" sm="6">
          <p>Showing <strong>{from} to {to} of {total} entries</strong>.</p>
        </Col>
        <Col xs="12" sm="6">
          <Pagination className="float-right">
            <PaginationItem disabled={currentPage <= 1}>
              <PaginationLink previous tag="button" onClick={e => this.props.handlePageClick(e, currentPage - 1)}/>
            </PaginationItem>
            {Array(totalItem).fill(null).map((value, index) => (
              <PaginationItem active={index + startPage === currentPage} key={index}>
                <PaginationLink tag="button" onClick={e => this.props.handlePageClick(e, index + startPage)}>
                  {index + startPage}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem disabled={currentPage >= pagesCount}>
              <PaginationLink next tag="button" onClick={e => this.props.handlePageClick(e, currentPage + 1)}/>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    );
  }
}

CustomPagination.propTypes = {
  paginator: PropTypes.object.isRequired,
  handlePageClick: PropTypes.func.isRequired,
};
