
import {CREATE_Lot,EDIT_Lot,AboutUsPageSlider_SUCCESS,SHOW_AboutUsPageSlider,AboutUsPageSlider_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createLot = (Lot) => {
    console.log("AboutUsPageSlider",Lot)
    return {
        type: CREATE_Lot,
        payload: { Lot }
    }
}
// export function deleteAboutUsPageSlider(AboutUsPageSliderId){
//     return getApi().delete('vouchar/destroy/'+AboutUsPageSliderId)
// }


export const editLot = (Lot) => {
    // console.log(Lot)
    return {
        type: EDIT_Lot,
        payload: { Lot }
    }
}


export function filterLotList(params){
    return getApi().get('Lot/list',{
        params: params
    })
}
export function editBulkLot(data){
    return getApi().put('Lot/bulk/edit',data)
}



export const AboutUsPageSliderSuccess = (msg) => {
    return {
        type: AboutUsPageSlider_SUCCESS,
        payload: msg
    }
}

export const AboutUsPageSliderError = (msg) => {
    return {
        type: AboutUsPageSlider_ERROR,
        payload: msg
    }
}

export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_AboutUsPageSlider,
        payload: { voucher_id }
    }
}

export function deleteLot(id){
    return getApi().delete('Lot/'+id)
}

export function createBlukTransaction(data) {
    return getApi().post('Lot/bulk/create', data);
}


export function orderShow(order_id){
    return getApi().get('vouchar/generates/show/'+order_id)
}

export function slider_large_image_Upload(params,id){
     if(id){
         return getApi().put('aboutUsPageSlider/large_image/edit/'+id,params)
     }
 }

 export function slider_medium_image_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('aboutUsPageSlider/medium_image/edit/'+id,params)
     }
 }

 export function slider_thumbnail_image_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('aboutUsPageSlider/thumbnail_image/edit/'+id,params)
     }
 }

 export function favicon_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/favicon/edit/'+id,params)
     }
 }

 export function our_vision_image_upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/our_vision_image/edit/'+id,params)
     }
 }

 export function about_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_1/edit/'+id,params)
     }
 }
 export function about_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_2/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_1/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_2/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_1/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_2/edit/'+id,params)
     }
 }




