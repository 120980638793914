
import {CREATE_Book,EDIT_Book,AboutUsPageSlider_SUCCESS,SHOW_AboutUsPageSlider,AboutUsPageSlider_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createBook = (Book) => {
    console.log("AboutUsPageSlider",Book)
    return {
        type: CREATE_Book,
        payload: { Book }
    }
}
// export function deleteAboutUsPageSlider(AboutUsPageSliderId){
//     return getApi().delete('vouchar/destroy/'+AboutUsPageSliderId)
// }


export const editBook = (Book) => {
    // console.log("BOOOOOKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK",Book)
    return {
        type: EDIT_Book,
        payload: { Book }
    }
}

export function filterBookList(params){
    return getApi().get('BookNow/list',{
        params: params
    })
}
export function searchBookList(params){
    return getApi().get('BookNow/searchList',{
        params: params
    })
}



export const AboutUsPageSliderSuccess = (msg) => {
    return {
        type: AboutUsPageSlider_SUCCESS,
        payload: msg
    }
}

export const AboutUsPageSliderError = (msg) => {
    return {
        type: AboutUsPageSlider_ERROR,
        payload: msg
    }
}

export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_AboutUsPageSlider,
        payload: { voucher_id }
    }
}

export function deleteBook(id){
    return getApi().delete('BookNow/'+id)
}
export function ShowBook(id){
    return getApi().get('BookNow/details/'+id)
}
export function createBlukTransaction(data) {
    return getApi().post('BookNow/bulk/create', data);
}






