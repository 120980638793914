import React, { Component } from 'react';
import PropTypes from "prop-types";
import {
    Container, Row, Col, Card, CardBody, CardTitle,
    Button, Input, FormText, FormGroup, Label, Form, CardHeader, CardFooter, InputGroup
} from "reactstrap";
import { Link } from "react-router-dom";
// import MetaTags from 'react-meta-tags';
// import { CSVLink, CSVDownload } from "react-csv";
// toaster
import toastr from "toastr";
import { createFloorPlan, CityNameList, DistrictNameList, createProjectSiteMap, FloorDataPlanList, FilterOptionList } from '../../store/FloorPlan/actions';
import { editSubProjectSlider, SubProjectList, SubListSpecific, ProjectSliderDetails } from '../../store/ProjectSlider/actions';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropzone from "react-dropzone"
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions

import { createCity, createBlukTransaction } from '../../store/CITY/actions';
import Select from "react-select";
import csvfile from "../../assets/sample-city.csv";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Fade from 'react-reveal/Fade';

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import 'react-image-picker-editor/dist/index.css'

class ProjectSiteMapCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientInfo: {
                // roles: [],
                // status: 1
                priority: '',
                remarks: '',
            },
            all_roles: [],
            selectedFiles: [],
            selectedFiles2: [],
            date: new Date(),
            custom_div2: false,
            change_project_cover_image_1:{}
        };

        this.handlleProjectName = this.handlleProjectName.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
        this.handleAcceptedFiles2 = this.handleAcceptedFiles2.bind(this);
    }

    handlleProjectNameFilter(option, inputValue) {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }
    handlleProjectName(selectedGroup) {

        this.setState({
            selectedGroup
        })
        console.log("out", this.state.selectedGroup);

        //   this.state.selectedGroup = option;
    }
    fetchProjectSliderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        FloorDataPlanList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data,
                });
                this.state.ProjectNameList = response.data;

            }
            else {
                this.setState({
                    ProjectNameList: {
                    },
                });
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles: files })
    }
    handleAcceptedFiles2 = files2 => {
        files2.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles2: files2 })
    }
    fetchSubProjectList = () => {
        SubProjectList().then((response) => {

            console.log("SubProjectList", response);
            if (response.data != []) {
                this.setState({
                    SubProjectList: response.data[0].options,
                });
                this.state.SubProjectList = response.data[0].options;

            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    componentDidMount() {
        this.fetchProjectSliderList();
        this.fetchSubProjectList();
    }

    handleInputChange = (event) => {
        // event.preventDefault();
        console.log("date", event.target.name);
        if (event.target.name == "f_group_id") {
            this.fetchClientInfoList(event.target.value);
        }
        if (event.target.name == "f_client_id") {
            this.fetchClientCustomerInfoList(event.target.value);
        }
        const { target: { name, value } } = event;

        this.updateUserObject(name, value);
    }

    updateUserObject = (name, value, callback = null) => {
        let transInfoCopy = Object.assign({}, this.state.transInfo);
        transInfoCopy[name] = value;

        this.setState({
            transInfo: transInfoCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }


    handlleremarks = (e) => {

        let value = e.target.value;
        let name = e.target.name;
        if (name == "remarks") {
            this.setState({ remarks: value });
        }
        if (name == "priority") {
            this.setState({ priority: value });

        }
        console.log("value", value);

        // setIsSelected(true);
    };
    
    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const data = { file: image, name: "change_project_cover_image_1", type: "image/jpeg" };
        // console.log(data);
        this.state.change_project_cover_image_1 = data;

    }
    changeHandler = (event) => {

        let targetFile = event.target.files[0];
        console.log("targetFile", targetFile);


        this.setState({
            uploadedFile: targetFile
        });

        // this.state.uploaded_file = event.target.files[0];
        console.log("uploaded_file", this.state.uploadedFile);
        // setIsSelected(true);
    };

    handleSubmission = () => {
        const formData = new FormData();
        formData.append('files', this.state.selectedFiles[0]);
        formData.append('files2', this.state.change_project_cover_image_1.file);
        formData.append('project_id_value', this.state.selectedGroup.value);
        console.log("uploaded_file", formData);
        createProjectSiteMap(formData)
            .then((response) => {
                if (response.data.status === true) {
                    this.setState({
                        message: response.data.message,
                        custom_div2: true
                    })
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/index/projectSiteMap',
                        });
                    }, 2000)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    render() {
        let clientGroup = [];
        let clientInfoList = [];
        let categoryList = [];
        let categoryCustomerList = [];
        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }

        if (this.state.hasOwnProperty('clientInfoData')) {
            clientInfoList = this.state.clientInfoData;
        }
        if (this.state.hasOwnProperty('clientCustomerInfoData')) {
            categoryCustomerList = this.state.clientCustomerInfoData;
        }

        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList.data;
        }
        const csvData = [
            ["Group Code", "Client Info Code", "Client Customer Code", "Category Code", "Transaction Date", "Transaction Amount", "remarks"]
        ];
        const { date, message } = this.state;
        console.log(message);
        return (
            <React.Fragment>

                <title>Upload Project SIte Map </title>


                <div className="page-content">
                    {message == "" || message == undefined || message == null ?
                        "" :
                        // this.state.custom_div1==true &&
                        this.state.custom_div2 ?
                            <SweetAlert
                                title={message}
                                timeout={2000}
                                showConfirm={false}
                                showCloseButton={false}
                                success
                                onConfirm={() => this.setState({
                                    custom_div2: false,
                                    message: undefined
                                })}
                            >
                                {/* I Will close in 2 Seconds */}
                            </SweetAlert> : null


                    }
                    <Container fluid={true}>
                        <Breadcrumbs title="Forms" breadcrumbItem="Upload Project SIte Map" />
                        <br />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">Project SIte Map Upload</CardTitle>
                                        <p className="card-title-desc">
                                            {" "}
                                            Select the file to Upload.
                                        </p>
                                        <Form encType="multipart/form-data">
                                            <Row>
                                                <div className="form-group col-md-6">
                                                    <Label htmlFor="formname">Project name : </Label>
                                                    <Select
                                                        //isMulti
                                                        onChange={this.handlleProjectName}
                                                        filterOption={this.handlleProjectNameFilter}
                                                        noOptionsMessage={() => "No more options"}
                                                        options={this.state.SubProjectList}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    <Input type="hidden" name="project_id_value" id="project_id_value" value={this.state.selectedGroup && this.state.selectedGroup.value} required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Label>Communities Site Map</Label><br/>
                                                    <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1920px * 1080px</small>

                                                    <Dropzone
                                                        onDrop={acceptedFiles =>
                                                            this.handleAcceptedFiles(acceptedFiles)
                                                        }
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className="dropzone">
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} name="uploaded_file" multiple />
                                                                    <div className="mb-3">
                                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                    </div>
                                                                    <h4>Drop files here or click to upload.</h4>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    <div
                                                        className="dropzone-previews mt-3"
                                                        id="file-previews"
                                                    >
                                                        {this.state.selectedFiles.map((f, i) => {
                                                            return (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={i + "-file"}
                                                                >
                                                                    <div className="p-2">
                                                                        <Row className="align-items-center">
                                                                            <Col className="col-auto">

                                                                                <img
                                                                                    src={f.preview}
                                                                                    frameBorder="0"
                                                                                    scrolling="none"
                                                                                    height="400px"
                                                                                    width="100%"
                                                                                />
                                                                            </Col>
                                                                            <Col>

                                                                                <p className="mb-0">
                                                                                    <strong>{f.name}</strong>
                                                                                </p>
                                                                                <p className="mb-0">
                                                                                    <strong>{f.formattedSize}</strong>
                                                                                </p>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            )
                                                        })}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                 
                                                    <Label>{'Booking Site map Image'}</Label><br />
                                                    <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1920px * 1080px</small>

                                                    <div className="col-md-6">
                                                        < ReactImagePickerEditor
                                                            id="project_cover_image_1_0"
                                                            name="project_cover_image_1_0"
                                                            type="file"
                                                            config={config2}
                                                            imageSrcProp={initialImage}
                                                            imageChanged={(image) => {
                                                                this.handletHumbnailImageUpload(image)
                                                            }} />
                                                    </div>

                                                </Col>
                                            </Row>


                                            <div className="text-center mt-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-success" onClick={this.handleSubmission}
                                                >
                                                    Send Files
                                                </button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>

        );
    }
}

ProjectSiteMapCreate.propTypes = {
    handleErrorResponse: PropTypes.any,
    match: PropTypes.any,
    history: PropTypes.any,
};
const mapStatetoProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}



export default withRouter(connect(mapStatetoProps, { createProjectSiteMap })(ProjectSiteMapCreate));

