import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import SimpleBar from "simplebar-react";


class SidebarContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.refDiv = React.createRef();

  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }




  initMenu() {
    var pathName = this.props.location.pathname;

    let menubar = JSON.parse(localStorage.getItem("menubar"));


    menubar = Object.keys(menubar).map(key => menubar[key]);

    var matchingMenuItem = null;

    menubar.map(function (menu, i) {

      new MetisMenu("#sideMenu" + i);
      var ul = document.getElementById("sideMenu" + i);

      var items = ul.getElementsByTagName("a");

      for (var i = 0; i < items.length; ++i) {

        if (pathName === items[i].pathname) {
         
          matchingMenuItem = items[i]

          let parent = Object.keys(menu.group).map(key => menu.group[key]);
          parent.forEach(childrens => {
            let nodes = Object.keys(childrens.children).map(key => childrens.children[key]);
            nodes.forEach(click => {
              let click_react_url = click.react_url;
              if (pathName == click_react_url) {
                localStorage.setItem("clicked_react_url", pathName)
                localStorage.setItem("clicked_group_id", click.group_id)

              }
            });
          });

          break;

        } else {
          // for inner click side bar on
          var clicked_react_url = localStorage.getItem("clicked_react_url");
          var clicked_group_id = localStorage.getItem("clicked_group_id");

          if(clicked_react_url != undefined){
            menubar.map(function (menu, i) {
                  var ul = document.getElementById("sideMenu" + i);

                  var items = ul.getElementsByTagName("a");

                  for (var i = 0; i < items.length; ++i) {
                      let parent = Object.keys(menu.group).map(key => menu.group[key]);
                      parent.forEach(childrens => { 
                      let nodes = Object.keys(childrens.children).map(key => childrens.children[key]);
                          nodes.forEach(click => {  
                            if((clicked_react_url  === items[i].pathname) && (clicked_group_id == click.group_id) ){
                              matchingMenuItem = items[i]; 
                            }
                          });
                      });

                  }      
            });
          }
        }

      }

    });


    


    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
   
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  render() {
    let menubar = JSON.parse(localStorage.getItem("menubar"));
    menubar = Object.keys(menubar).map(key => menubar[key]);
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            {menubar.map((menu, i) => (
              <ul className="metismenu list-unstyled " id={"sideMenu" + i} key={"sideMenu" + i}>
                <li className="menu-title color-white">
                  <span> {this.props.t(menu.module_name_en)}</span>
                </li>
                {(Object.keys(menu.group).map(key => menu.group[key])).map((group, j) => (
                  <li className="color-white" key={'group' + j} >
                    <Link to={(group.is_children == 0) ? group.children[0].react_url : '/#'} className={group.is_children != 1 ? "waves-effect" : "waves-effect has-arrow"}>
                      <i className={group.icon}></i>
                      <span>{this.props.t(group.main_menu_name)}</span>
                    </Link>
                    {group.is_children == 1 &&
                      <ul className="sub-menu " aria-expanded="false" style={{background:'#101820FF'}}>
                        {Object.keys(group.children).map(key => group.children[key]).map((children, k) => (
                          <li className="color-white" key={"sub-menu" + k}>
                            <Link to={children.react_url}><i className={children.icon == null ? '' : children.icon}></i>{this.props.t(children.menu_name)}</Link>
                          </li>
                        ))}
                      </ul>
                    }
                  </li>
                ))}
              </ul>
            ))}

          </div>
        </SimpleBar>
      </React.Fragment>
    );


  }
}

export default withRouter(withNamespaces()(SidebarContent));
