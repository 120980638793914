import {CREATE_ProjectName,CREATE_SubProjectName,EDIT_SubProjectName,EDIT_ProjectName,AboutUsPageSlider_SUCCESS,SHOW_ProjectName,AboutUsPageSlider_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createProjectName = (ProjectName) => {
    return {
        type: CREATE_ProjectName,
        payload: { ProjectName }
    }
}
export const createSubProjectName = (ProjectName) => {
    return {
        type: CREATE_SubProjectName,
        payload: { ProjectName }
    }
}


export const editProjectName = (ProjectName) => {
    return {
        type: EDIT_ProjectName,
        payload: { ProjectName }
    }
}
export const editSubProjectName = (ProjectName) => {
    return {
        type: EDIT_SubProjectName,
        payload: { ProjectName }
    }
}

export function ProjectNameList(params){
    return getApi().get('ProjectName/list',{
        params: params
    })
}
export function ParentProjectNameList(params){
    return getApi().get('ProjectName/parent/list',{
        params: params
    })
}

export function SpecificParentProjectNameList(id){
    return getApi().get('ProjectName/parent/specific/'+id)
}
export function SpecificProjectNameList(id){
    return getApi().get('ProjectName/specific/'+id)
}

export function SubProjectNameList(params){
    return getApi().get('ProjectName/subProject/list',{
        params: params
    })
}
export function SubProjectNameListData(params){
    return getApi().get('ProjectName/subProject/data/list',{
        params: params
    })
}

export function ProjectNameDetails(id){
    return getApi().get('ProjectName/Details/'+id)
}




export const AboutUsPageSliderSuccess = (msg) => {
    return {
        type: AboutUsPageSlider_SUCCESS,
        payload: msg
    }
}

export const AboutUsPageSliderError = (msg) => {
    return {
        type: AboutUsPageSlider_ERROR,
        payload: msg
    }
}
export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_ProjectName,
        payload: { voucher_id }
    }
}

export function deleteVoucher(voucherId){
    return getApi().delete('ProjectName/'+voucherId)
}


export function orderShow(order_id){
    return getApi().get('vouchar/generates/show/'+order_id)
}

export function slider_large_image_Upload(params,id){
     if(id){
         return getApi().put('ProjectName/large_image/edit/'+id,params)
     }
 }

 export function slider_medium_image_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('ProjectName/medium_image/edit/'+id,params)
     }
 }

 export function slider_thumbnail_image_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('ProjectName/thumbnail_image/edit/'+id,params)
     }
 }

 export function favicon_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/favicon/edit/'+id,params)
     }
 }

 export function our_vision_image_upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/our_vision_image/edit/'+id,params)
     }
 }

 export function about_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_1/edit/'+id,params)
     }
 }
 export function about_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_2/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_1/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_2/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_1/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_2/edit/'+id,params)
     }
 }




