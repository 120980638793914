import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, CustomInput, FormGroup } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteUpcoming, editFilterName, filterNameList, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload } from '../../store/FilterName/actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import { featureSettingsEdit, featuresSettings } from "../../store/FeatureSettings/actions";

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class FeatureSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            filterNameList_list: {},

            supplier: {

            },
            change_upcoming_project_image: {},
            upcoming_project_image: {},

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchFilterList();
        this.fetchFeaturesSettings();
        // this.fetchCityList();     
    }

    fetchFilterList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        filterNameList(options).then((response) => {
            //   console.log(response);
            this.setState({
                filterNameList: response.data,
            });
            this.state.filterNameList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchFeaturesSettings = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        featuresSettings(options).then((response) => {

            this.setState({
                featureSettings: response.data,
            });
            this.state.featureSettings = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }


    //modal
    tog_scroll = (filterNameList) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.change_slider_thumbnail_image = {};
        this.state.change_slider_large_image = {};
        this.state.filterNameList_list = filterNameList;
        this.removeBodyCss();
    }

    // Voucherdelete = (voucherId) => {

    //     this.state.voucherId = voucherId;
    //     deleteVoucher(voucherId);
    //     setTimeout(function () {
    //         window.location.reload();
    //     }, 3500)
    // }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchFilterList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }

    handleDeleteSubmit(values) {
        alert("Are You Want To Delete This?");
        this.fetchFilterList();
        deleteUpcoming(values)
            .then((response) => {
                if (response.success == true) {
                    //   console.log(response)
                    alert(response.data);
                    this.fetchFilterList();
                    window.location.reload();
                }
            })
            .catch((err) => {
                this.props.handleErrorResponse(err);
                //  console.log('err', this.props.handleErrorResponse(err));
            });
    }


    handleEditSubmit(event, values) {
        this.props.editFilterName(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchFilterList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        console.log(e.target.checked)

        if (e.target.checked === true) {

        } else {

        }
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchFilterList();
        });
    }


    handleImageUpload = (e) => {
        var id = e.target.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
            //   //console.log("formData", formData);

            this.state.change_upcoming_project_image[id] = formData;
            //  console.log("this.setState.voucharImage[0]",  this.state.voucharImage[id]);
        }
    }

    handleChange = (e,id) => {

        // console.log("this.setState.voucharImage",e.target.checked);
        let status= e.target.checked==true?1:0;
        const data = { status:status}
        // console.log("this.setState.voucharImage",e.target.id);
        featureSettingsEdit(id,data).then(response => {
            this.fetchFeaturesSettings();
        })

    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let filterNameListInfo = [];
        let featureSettings = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        // if (this.state.hasOwnProperty('filterNameList')) {
        //     filterNameListInfo = this.state.filterNameList.data;
        //     paginator = {
        //         'currentPage': this.state.filterNameList.current_page,
        //         'pagesCount': this.state.filterNameList.last_page,
        //         'total': this.state.filterNameList.total,
        //         'to': this.state.filterNameList.to,
        //         'from': this.state.filterNameList.from,
        //     }
        // }

        if (this.state.hasOwnProperty('featureSettings')) {
            featureSettings = this.state.featureSettings;
            console.log('featureSettings', featureSettings)
            // paginator = {
            //     'currentPage': this.state.filterNameList.current_page,
            //     'pagesCount': this.state.filterNameList.last_page,
            //     'total': this.state.filterNameList.total,
            //     'to': this.state.filterNameList.to,
            //     'from': this.state.filterNameList.from,
            // }
        }

        let singlevoucher = this.state.voucher;

        const { code, filter_name, id, remarks, priority, status } = this.state.filterNameList_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        //   console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Settings" breadcrumbItem="Feature Settings" />
                        {this.state.error && this.state.error ?
                            <SweetAlert
                                danger
                                title={this.state.error}
                                timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.success && this.state.success ?
                            <SweetAlert
                                title={this.state.success}
                                // timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                success
                                onConfirm={() => window.location.reload()
                                }
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>

                                        <CardTitle>Feature Settings List</CardTitle>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* <DataTabeSearch handleInputChange={this.handleInputChange} /> */}
                                                <Table responsive>

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Settings Name</th>
                                                            <th>Status</th>
                                                            <th colspan="2">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {featureSettings && featureSettings.map((settings, index) =>
                                                            <tr key={settings.id.toString()}>
                                                                <td scope="row">{index + 1}</td>
                                                                <td>{settings.settings_name}</td>
                                                                <td><Badge color={getBadge(settings.status)}>{getStatusValue(settings.status)}</Badge></td>
                                                                <td>
                                                                    <FormGroup check inline>
                                                                        <CustomInput type="switch" id={settings.id} name={settings.id}
                                                                           
                                                                            onChange={(e) => this.handleChange(e,settings.id)} checked={settings.status==1?true:false} />
                                                                    </FormGroup>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CardBody>
                                    {/* {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    } */}
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">{code}</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Filter Name Updated Successfully!
                                                </Alert>
                                            ) : null}
                                            <Row>
                                                <AvField className="form-control" type="hidden" id="id" name="id" value={id} />
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel(' Name')} id="filter_name" name="filter_name" value={filter_name} required />

                                                </div>
                                                <div className="col-md-6">

                                                    <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={priority} required />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Remarks')} id="remarks" name="remarks" value={remarks} required />

                                                </div>
                                                <div className="col-md-6">
                                                    <AvField type="select" label={reqLabel('Status')} className="custom-select" name="Status" value={status}   >
                                                        {/* <option defaultValue>Select Status</option> */}
                                                        <option value="0" >Inactive</option>
                                                        <option value="1">Active</option>
                                                    </AvField>
                                                </div>
                                            </Row>





                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>

                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps, { editFilterName, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload })(FeatureSettings));