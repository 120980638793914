import { Gallery_ERROR,Gallery_SUCCESS,EDIT_Gallery,CLEAR_MESSAGE } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const Gallery = (state = initialState, action) => {
    switch (action.type) {
     
        case EDIT_Gallery:
            state = { ...state }
            break;
        case Gallery_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case Gallery_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Gallery;