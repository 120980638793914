import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, Label, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, Form, Input, InputGroup } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteBook, filterBookList, editBook, searchBookList } from '../../store/Book/actions';
import { filterNameList } from '../../store/FilterName/actions';
import { clearSuccess } from '../../store/FilterValue/actions';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import DeleteModal from './DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import './Book.scss';
// import { error } from "jodit/types/core/helpers";
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class BookDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            filterBookList_list: {},
            deleteModal: false,
            startDate: new Date(),
            endDate: new Date(),

            supplier: {

            },
            project_cover_image_1: {},
            upcoming_project_image: {},

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        // this.fetchBookList();
        if (localStorage.getItem('last_action') == 'create') {
            localStorage.setItem('last_action', "");

            if (this.props.success != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);
            }
            if (this.props.error != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);

            }
        }

    }






    //modal

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchBookList();
    }

    toggleDeleteModal = (upcoming) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(upcoming);
    };

    onClickDelete = (project) => {
        console.log("Delete Modal1", project);
        this.setState({ deleteProject: project });
        this.setState({ deleteModal: true });

    };

    handleDeleteSubmit(values) {

        const { deleteProject } = this.state;
        console.log("Delete Modal22222222", deleteProject);

        if (deleteProject != undefined) {
            deleteBook(deleteProject.Id)
                .then((response) => {
                    if (response.success == true) {
                        this.fetchBookList();
                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }



    handleEditSubmit(event, values) {
        this.props.editBook(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchBookList();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchBookList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchBookList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchBookList();
        });
    }

    handleImageUpload = (image) => {

        console.log('Image Upload', image);
        const formData = { file: image, name: "project_cover_image_1", type: "image/jpeg" };
        this.state.project_cover_image_1["project_cover_image_1"] = formData;
    }
    ////
    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.state.success = null;
    }

    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
        this.state.error = null;
    }
    onHandleSubmit = (e) => {
        e.preventDefault();

        console.log("onHandleSubmit", document.getElementById('search').value)
        console.log("onHandleSubmit", document.getElementById('generate').value)
        // if (document.getElementById('search')=='search'
        const { startDate, endDate } = this.state;
        let data = {
            startDate: startDate,
            endDate: endDate,
            Generate: 0,
        }
        searchBookList(data)
            .then((response) => {
                if (response.success == true) {
                    this.setState({
                        filterBookList: response.data,
                    });
                }

            })
            .catch((err) => {
                console.log(err);
            });


    }

    onHandleSubmitGenerate = (e) => {
        e.preventDefault();

        // if (document.getElementById('search')=='search'
        const { startDate, endDate } = this.state;
        let data = {
            startDate: startDate,
            endDate: endDate,
            Generate: 1,
        }
        searchBookList(data)
            .then((response) => {
                if (response.success == true) {
                    this.setState({
                        filterBookList: response.data,
                        myfiles: response.data.files[2],
                    });
                }

            })
            .catch((err) => {
                console.log(err);
            });


    }

    onChangeSubmit = (e) => {

        const { name, value } = e.target

        this.setState({ [name]: value })
        console.log(name);
        console.log(value);

    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let filterBookListInfo = [];
        let BookDownload = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        if (this.state.hasOwnProperty('filterBookList')) {
            filterBookListInfo = this.state.filterBookList.data;
            paginator = {
                'currentPage': this.state.filterBookList.current_page,
                'pagesCount': this.state.filterBookList.last_page,
                'total': this.state.filterBookList.total,
                'to': this.state.filterBookList.to,
                'from': this.state.filterBookList.from,
            }
        }
        let filterNameListInfo = [];
        if (this.state.hasOwnProperty('filterNameList')) {
            filterNameListInfo = this.state.filterNameList.data;
        }

        let singlevoucher = this.state.voucher;

        const { id, code, Buyer_Serial_No_Contract, Buyer_Serial_No_LOI, f_floore_plans_id, f_project_names_id, Block, Book, Model, SQFT, status, Books_image_1 } = this.state.filterBookList_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        const { deleteModal, message } = this.state;

        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            compressInitial: null,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Settings" breadcrumbItem="Book List" />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h5 mb-4">Search For Data</CardTitle>

                                        <Form className="row gy-2 gx-3 align-items-center">
                                            <div className="col-sm-auto">
                                                <Label className="visually-hidden" htmlFor="autoSizingInput">Start Date</Label>
                                                <InputGroup>
                                                    <div className="input-group-text">&#x1F4C5;</div>
                                                    <input type="date" className="form-control" id="autoSizingInputGroup" placeholder="startDate" name="startDate" onChange={(e) => this.onChangeSubmit(e)} />
                                                </InputGroup>
                                            </div>
                                            <div className="col-sm-auto">
                                                <Label className="visually-hidden" htmlFor="autoSizingInputGroup">End Date</Label>
                                                <InputGroup>
                                                    <div className="input-group-text">&#x1F4C5;</div>
                                                    <input type="date" className="form-control" id="autoSizingInputGroup" placeholder="endDate" name="endDate" onChange={(e) => this.onChangeSubmit(e)} />
                                                </InputGroup>
                                            </div>

                                            <div className="col-sm-auto">
                                                <button type="submit" className="btn btn-primary w-md mt-4" id="search" value={1} onClick={this.onHandleSubmit}>Submit</button>
                                            </div>
                                            <div className="col-sm-auto">
                                                <button type="submit" className="btn btn-success w-md mt-4" id="generate" value={2} onClick={this.onHandleSubmitGenerate}>Generate Excel</button>

                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" md="12">
                                <Card>

                                    <CardBody>

                                        <CardTitle>Book List</CardTitle>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {
                                                    (this.state.myfiles != null && this.state.myfiles !="") &&
                                                    <a href={imageLink+"BookCsv/"+this.state.myfiles} download>
                                                    <button className="btn btn-primary mx-2"><i className="mdi mdi-excel-circle-outline me-1" />Excel Download</button>
                                                    </a>
                                                }
                                                
                                                <Table responsive>

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Buyer Name</th>
                                                            <th>Buyer Address</th>
                                                            <th>Buyer City</th>
                                                            <th>Buyer Email</th>
                                                            <th>Buyer Phone</th>
                                                            <th>Lot</th>
                                                            <th>Model</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filterBookListInfo && filterBookListInfo.map((filterBook, index) =>
                                                            <tr key={filterBook.Id.toString()}>
                                                                <td scope="row">{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                                <td>{filterBook.Buyer_First_Name}</td>
                                                                <td>{filterBook.Buyer_Address_1}</td>
                                                                <td>{filterBook.Buyer_City}</td>
                                                                <td>{filterBook.Buyer_Email}</td>
                                                                <td>{filterBook.Buyer_Phone}</td>
                                                                <td>{filterBook.Lot}</td>
                                                                <td>{filterBook.Model}</td>

                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CardBody>

                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { clearSuccess, editBook })(BookDownload));