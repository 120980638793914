export const CREATE_FloorPlan = 'CREATE_FloorPlan';
export const CREATE_SubFloorPlan = 'CREATE_SubFloorPlan';
export const CREATE_CreateBrochure = 'CREATE_createBrochure';
export const EDIT_FloorPlan = 'EDIT_FloorPlan';
export const EDIT_uploadImageFloorPlan = 'EDIT_uploadImageFloorPlan';
export const EDIT_SubFloorPlan = 'EDIT_SubFloorPlan';
export const SHOW_FloorPlan = 'SHOW_FloorPlan';
export const AboutUsPageSlider_SUCCESS = 'AboutUsPageSlider_SUCCESS';
export const AboutUsPageSlider_ERROR = 'AboutUsPageSlider_ERROR';

