import React, { Component } from "react";
// import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";

import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { editSubProjectSlider, SubProjectList, SubListSitemapSpecific } from '../../store/ProjectSlider/actions';
import { editProjectSiteMap } from '../../store/FloorPlan/actions';
import { ProjectNameDetails } from '../../store/ProjectName/actions';

import Dropzone from "react-dropzone"

// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import 'react-image-picker-editor/dist/index.css'
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class ProjectSiteMapEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            activeTabProgress: 1,
            selectedGroup: '',
            rows1: [],
            supplier: {

            },
            status: 0,
            SubListSpecificList: null,
            change_site_map_image: {},
            change_project_logo: {},
            project_cover_image_2: {},
            change_project_cover_image_2: {},
            change_project_cover_image_1: {},

            brand: {},


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
        this.state = { selectedFiles: [], selectedFiles2: [] }
    }

    componentDidMount() {
        console.log(this.props.location.state.value.id)
        if (this.props.location.state !== undefined) {
            console.log(this.props.location.state.value.id)
            this.fetchProjectSliderDetails(this.props.location.state.value.id)
            this.fetchSubListSpecific(this.props.location.state.value.id);
            this.fetchSubProjectList();
        }
        else {
            let id = window.location.href.split('/').pop();
            this.fetchProjectSliderDetails(id)
            this.fetchSubListSpecific(id);
            this.fetchSubProjectList();
        }
    }
    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles: files })
        console.log('selectedFiles', this.state.selectedFiles)
    }
    handleAcceptedFiles2 = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles2: files })
        console.log('selectedFiles', this.state.selectedFiles2)
    }
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    handleSubmission = () => {
        const formData = new FormData();

        if (this.state.selectedFiles[0] != null || this.state.selectedFiles[0] != undefined) {
            formData.append('files', this.state.selectedFiles[0]);
        }
        if (this.state.change_project_cover_image_1 != null || this.state.change_project_cover_image_1 != undefined) {
            formData.append('files2', this.state.change_project_cover_image_1.file);
        }
        formData.append('project_id_value', this.state.SubListSpecificList.value);

        console.log("uploaded_file", formData);
        editProjectSiteMap(formData)
            .then((response) => {
                if (response.data.status === true) {
                    this.setState({
                        message: response.data.message,
                        custom_div2: true
                    })
                }
                if (response.success == false) {
                    this.setState({
                        message: response.message,
                        custom_div2: true
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    fetchProjectSliderDetails = (id) => {
        ProjectNameDetails(id).then((response) => {
            console.log("ProjectNameDetails", response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data[0],
                });
                this.state.ProjectNameList = response.data[0];
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchSubListSpecific = (id) => {
        SubListSitemapSpecific(id).then((response) => {
            console.log("fetchSubListSpecific", response);

            console.log(response);
            if (response.data != []) {
                this.setState({
                    SubListSpecificList: response.data[0].options[0],
                });
                this.state.SubListSpecificList = response.data[0].options[0];
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchSubProjectList = () => {
        SubProjectList().then((response) => {

            console.log("SubProjectList", response);
            if (response.data != []) {
                this.setState({
                    SubProjectList: response.data[0].options,
                });
                this.state.SubProjectList = response.data[0].options;

            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    handleValidSubmit(event, values) {
        console.log("values", values);
        this.props.editSubProjectSlider(values);

    }
    handleAddRowNested = () => {
        const item1 = {
            name1: ""
        };
        this.setState({
            rows1: [...this.state.rows1, item1]
        });
    };

    handleRemoveRowNested = (e, idx) => {
        document.getElementById("nested" + idx).style.display = "none";
    };


    removeMessage() {
        this.setState({ errorMessage: '' });
    }

    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }


    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const data = { file: image, name: "change_project_cover_image_1", type: "image/jpeg" };
        // console.log(data);
        this.state.change_project_cover_image_1 = data;

    }
    handleNewImageUpload = (index, image) => {
        console.log("index", index);
        const formData = { file: image, name: `project_cover_image_${index}`, type: "image/jpeg" };
        // //console.log("formData", formData);

        this.state.project_cover_image_2[index] = formData;
        console.log(formData);
    }
    handleLargeImageUpload = (image) => {

        const formData = { file: image, name: "change_project_cover_image_2", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_cover_image_2["change_project_cover_image_2"] = formData;
    }

    handleMediumImageUpload = (image) => {

        const formData = { file: image, name: "change_site_map_image", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_site_map_image["change_site_map_image"] = formData;
    }

    handleProjectLogoUpload = (image) => {

        const formData = { file: image, name: "change_project_logo", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_logo["change_project_logo"] = formData;
    }

    toggleTab(tab) {
        if (tab === 2 && (document.getElementById('project_name').value == '' || document.getElementById('project_name').value == null
            || document.getElementById('project_name').value == undefined)) {
            this.setState({
                errorMessage: "Project name is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_title').value == '' || document.getElementById('project_title').value == null
            || document.getElementById('project_title').value == undefined)) {
            this.setState({
                errorMessage: "Project Title is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_short_description').value == '' || document.getElementById('project_short_description').value == null
            || document.getElementById('project_short_description').value == undefined)) {
            this.setState({
                errorMessage: "Project Short Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_details_description').value == '' || document.getElementById('project_details_description').value == null
            || document.getElementById('project_details_description').value == undefined)) {
            this.setState({
                errorMessage: "Project Details Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('contact_information_description').value == '' || document.getElementById('contact_information_description').value == null
            || document.getElementById('contact_information_description').value == undefined)) {
            this.setState({
                errorMessage: "Contact Information Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_address').value == '' || document.getElementById('project_address').value == null
            || document.getElementById('project_address').value == undefined)) {
            this.setState({
                errorMessage: "Project Address is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_phone_no').value == '' || document.getElementById('project_phone_no').value == null
            || document.getElementById('project_phone_no').value == undefined)) {
            this.setState({
                errorMessage: "Project Phone No is required",
            })
        }
        else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
            || document.getElementById('priority').value == undefined)) {
            this.setState({
                errorMessage: "Priority is required",
            })
        }
        else {
            if (this.state.activeTab !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

    }
    handleSelectGroup(selectedGroup) {

        console.log({ selectedGroup })
        this.setState({ SubListSpecificList: selectedGroup });
    }

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;

        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;


        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        const initialImage = '';

        console.log("this.state.SubListSpecificList.value", this.state.SubListSpecificList && this.state.SubListSpecificList)

        // const {SubListSpecificList}= this.state;
        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Project Slider" />
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() => this.props.history.push(
                                    {
                                        pathname: '/index/projectSiteMap',
                                    }
                                )}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.error && this.state.error ?
                            <SweetAlert
                                danger
                                title={this.state.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.message && this.state.message ?
                            <SweetAlert
                                title={this.state.message}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() => this.props.history.push(
                                    {
                                        pathname: '/index/projectSiteMap',
                                    }
                                )}
                            >
                            </SweetAlert> : ''
                        }
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        {
                                            this.state.ProjectNameList &&

                                            <div data-repeater-list="outer-group" className="outer">

                                                <div data-repeater-item className="outer">
                                                    <Form encType="multipart/form-data">
                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">Project name : </Label>
                                                                <Select
                                                                    value={this.state.SubListSpecificList && this.state.SubListSpecificList}
                                                                    onChange={this.handleSelectGroup}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.SubProjectList}
                                                                />
                                                                <Input type="hidden" name="project_id_value" id="project_id_value" value={this.state.SubListSpecificList && this.state.SubListSpecificList.value} required />
                                                            </div>
                                                        </Row>
                                                        <Row className="align-items-center">
                                                            <Col md={6}>
                                                                <Label>Previous Site Map File</Label>
                                                                <img
                                                                    src={imageLink + 'company_settings/aboutus/sub/site_map_image/' + this.state.ProjectNameList.site_map_image}
                                                                    frameBorder="0"
                                                                    scrolling="none"
                                                                    height="400px"
                                                                    width="100%"
                                                                    style={{ objectFit: 'contain' }}
                                                                />

                                                                {/* </img> */}
                                                            </Col>

                                                        </Row>

                                                        <Row>
                                                            <Col md="6">
                                                                <small className="mb-2 text-danger font-weight-bold mt-2" style={{ fontSize: '12px' }}>SIZE: 1920px * 1080px</small>

                                                                <Dropzone
                                                                    onDrop={acceptedFiles =>
                                                                        this.handleAcceptedFiles(acceptedFiles)
                                                                    }
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div className="dropzone">
                                                                            <div
                                                                                className="dz-message needsclick"
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input {...getInputProps()} name="uploaded_file" multiple />
                                                                                <div className="mb-3">
                                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                                </div>
                                                                                <h4>Drop files here or click to upload.</h4>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                                <div
                                                                    className="dropzone-previews mt-3"
                                                                    id="file-previews"
                                                                >
                                                                    {this.state.selectedFiles.map((f, i) => {
                                                                        return (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col className="col-auto">

                                                                                            <img
                                                                                                src={f.preview}
                                                                                                frameBorder="0"
                                                                                                scrolling="none"
                                                                                                height="400px"
                                                                                                width="100%"
                                                                                                style={{ objectFit: 'contain' }}

                                                                                            />
                                                                                            {/* </iframe> */}
                                                                                        </Col>
                                                                                        <Col>

                                                                                            <p className="mb-0">
                                                                                                <strong>{f.name}</strong>
                                                                                            </p>
                                                                                            <p className="mb-0">
                                                                                                <strong>{f.formattedSize}</strong>
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                        <Row className="align-items-center">
                                                            <Col md={6}>
                                                                <Label>Previous Booking Site Map File</Label>
                                                                <img
                                                                    src={imageLink + 'company_settings/aboutus/sub/booking_sitemap_image/' + this.state.ProjectNameList.booking_sitemap_image}
                                                                    frameBorder="0"
                                                                    scrolling="none"
                                                                    height="400px"
                                                                    width="100%"
                                                                    style={{ objectFit: 'contain' }}

                                                                />

                                                                {/* </img> */}
                                                            </Col>
                                                        </Row>
                                                    </Form>

                                                    <Col md="6">
                                                        <br />
                                                        <div className="col-md-6">
                                                            <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1920px * 1080px</small>

                                                            < ReactImagePickerEditor
                                                                id="project_cover_image_1_0"
                                                                name="project_cover_image_1_0"
                                                                type="file"
                                                                config={config2}
                                                                imageSrcProp={initialImage}
                                                                imageChanged={(image) => {
                                                                    this.handletHumbnailImageUpload(image)
                                                                }} />
                                                        </div>

                                                        <Input type="hidden" value={this.state.change_project_cover_image_1} name="change_project_cover_image_1" id="change_project_cover_image_1" />

                                                    </Col>

                                                    <div className="text-center mt-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success float-right" onClick={this.handleSubmission}
                                                        >
                                                            Edit File
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ProjectSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { editSubProjectSlider, editProjectSiteMap })(ProjectSiteMapEdit));