import { GENDER_TYPES} from "../constants/appConstants";
import {permissions} from "../constants/appConstants";



export function getGenderObject(gender_value) {

    let genderType = GENDER_TYPES
        .filter((type) => type.value === gender_value);

    return genderType.length ? genderType[0] : null;
}


export const getStatusBadge = (status) => {
    return status === 1 ? 'success' : 'secondary'
}

export const getStatusValue = (status) => {
    return status === 1 ? 'Active' : 'In-Active'
}

export function containsAny(source,target)  {
    if (source !== null && source.length) {
        let result = source.filter(function(item) {
            return target.indexOf(item) > -1
        });

        return (result.length > 0);
    }  else {
        return false;
    }
}

export function checkPermission(permission_name) {
    // return permissions.includes(permission_name);
    if(permissions != null){
        return permissions.includes(permission_name);
    }
    else{
        window.location.reload();
        return permissions.includes(permission_name);
    }

}

export const permissionEnum = {
    USER_CREATE : 'create user',
    USER_UPDATE : 'update user',
    USER_VIEW   : 'view user',
    USER_DELETE : 'delete user',

    // CUSTOMER_CREATE : 'create customer',
    // CUSTOMER_UPDATE : 'update customer',
    // CUSTOMER_VIEW   : 'view customer',
    // CUSTOMER_DELETE : 'delete customer',

    // VENDOR_CREATE : 'create vendor',
    // VENDOR_UPDATE : 'update vendor',
    // VENDOR_VIEW   : 'view vendor',
    // VENDOR_DELETE : 'delete vendor',

    // STUFF_CREATE : 'create stuff',
    // STUFF_UPDATE : 'update stuff',
    // STUFF_VIEW   : 'view stuff',
    // STUFF_DELETE : 'delete stuff',

    // EMPLOYEE_CREATE : 'create employee',
    // EMPLOYEE_UPDATE : 'update employee',
    // EMPLOYEE_VIEW   : 'view employee',
    // EMPLOYEE_DELETE : 'delete employee',

    // EXPENSE_TYPE_CREATE : 'create expense_type',
    // EXPENSE_TYPE_UPDATE : 'update expense_type',
    // EXPENSE_TYPE_VIEW   : 'view expense_type',

    // DEPOSIT_TYPE_CREATE : 'create deposit_type',
    // DEPOSIT_TYPE_UPDATE : 'update deposit_type',
    // DEPOSIT_TYPE_VIEW   : 'view deposit_type',

    // SHIFT_SETUP_CREATE : 'create shift_setup',
    // SHIFT_SETUP_UPDATE : 'update shift_setup',
    // SHIFT_SETUP_VIEW   : 'view shift_setup',
    // SHIFT_SETUP_DELETE   : 'delete shift_setup',

    // SHIFT_MAPPING_CREATE : 'create shift_mapping',
    // SHIFT_MAPPING_UPDATE : 'update shift_mapping',
    // SHIFT_MAPPING_VIEW   : 'view shift_mapping',
    // SHIFT_MAPPING_DELETE   : 'delete shift_mapping',

    // SISTER_WINGS_CREATE : 'create sister_wings',
    // SISTER_WINGS_UPDATE : 'update sister_wings',
    // SISTER_WINGS_VIEW   : 'view sister_wings',
    // SISTER_WINGS_DELETE   : 'delete sister_wings',

    // ACCOUNTFISCALYEAR_CREATE : 'create accountFiscalYear',
    // ACCOUNTFISCALYEAR_UPDATE : 'update accountFiscalYear',
    // ACCOUNTFISCALYEAR_VIEW   : 'view accountFiscalYear',
    // ACCOUNTFISCALYEAR_DELETE   : 'delete accountFiscalYear',

    // EXPENSE_TRANSACTION_CREATE : 'create expenseTransaction',
    // EXPENSE_TRANSACTION_UPDATE : 'update expenseTransaction',
    // EXPENSE_TRANSACTION_VIEW   : 'view expenseTransaction',
    // EXPENSE_TRANSACTION_DELETE   : 'delete expenseTransaction',
    // VENDOR_PAYMENT_VIEW   : 'view vendorPayment',
    // WINGS_LOAN_PERMISSION   : 'view wings_loan',


    // BANK_SETUP_CREATE : 'create bank_setup',
    // BANK_SETUP_UPDATE : 'update bank_setup',
    // BANK_SETUP_VIEW   : 'view bank_setup',
    // BANK_SETUP_DELETE   : 'delete bank_setup',

    // BANK_ACCOUNT_MAPPING_CREATE : 'create bank_account_mapping',
    // BANK_ACCOUNT_MAPPING_UPDATE : 'update bank_account_mapping',
    // BANK_ACCOUNT_MAPPING_VIEW   : 'view bank_account_mapping',
    // BANK_ACCOUNT_MAPPING_DELETE   : 'delete bank_account_mapping',

    // BANK_ACCOUNT_MAPPING_CREATE : 'create bank_account_mapping',
    // BANK_ACCOUNT_MAPPING_UPDATE : 'update bank_account_mapping',
    // BANK_ACCOUNT_MAPPING_VIEW   : 'view bank_account_mapping',
    // BANK_ACCOUNT_MAPPING_DELETE   : 'delete bank_account_mapping',

    // BANK_CASH_TRANSFER_CREATE : 'create bank_transfer',
    // BANK_CASH_TRANSFER_UPDATE : 'update bank_transfer',
    // BANK_CASH_TRANSFER_VIEW   : 'view bank_transfer',
    // BANK_CASH_TRANSFER_DELETE   : 'delete bank_transfer',


    // FEEDING_UNIT_SETUP_CREATE : 'create feeding_unit_setup',
    // FEEDING_UNIT_SETUP_UPDATE : 'update feeding_unit_setup',
    // FEEDING_UNIT_SETUP_VIEW   : 'view feeding_unit_setup',
    // FEEDING_UNIT_SETUP_DELETE   : 'delete feeding_unit_setup',

    // FEEDING_UNIT_SCALE_NAME_CREATE : 'create feeding_unit_scale_name',
    // FEEDING_UNIT_SCALE_NAME_UPDATE : 'update feeding_unit_scale_name',
    // FEEDING_UNIT_SCALE_NAME_VIEW   : 'view feeding_unit_scale_name',
    // FEEDING_UNIT_SCALE_NAME_DELETE   : 'delete feeding_unit_scale_name',

    // FUEL_MACHINE_SETUP_CREATE : 'create fuel_machine_setup',
    // FUEL_MACHINE_SETUP_UPDATE : 'update fuel_machine_setup',
    // FUEL_MACHINE_SETUP_VIEW   : 'view fuel_machine_setup',
    // FUEL_MACHINE_SETUP_DELETE   : 'delete fuel_machine_setup',

    // FUEL_NAME_SETUP_CREATE : 'create fuel_name_setup',
    // FUEL_NAME_SETUP_UPDATE : 'update fuel_name_setup',
    // FUEL_NAME_SETUP_VIEW   : 'view fuel_name_setup',
    // FUEL_NAME_SETUP_DELETE   : 'delete fuel_name_setup',

    // TANK_SETUP_CREATE : 'create tank_setup',
    // TANK_SETUP_UPDATE : 'update tank_setup',
    // TANK_SETUP_VIEW   : 'view tank_setup',
    // TANK_SETUP_DELETE   : 'delete tank_setup',

    // FUEL_MACHINE_AND_TANK_MAP_CREATE : 'create fuel_machine_and_tank_map',
    // FUEL_MACHINE_AND_TANK_MAP_UPDATE : 'update fuel_machine_and_tank_map',
    // FUEL_MACHINE_AND_TANK_MAP_VIEW   : 'view fuel_machine_and_tank_map',
    // FUEL_MACHINE_AND_TANK_MAP_DELETE   : 'delete fuel_machine_and_tank_map',

    // STOCK_IN_CREATE : 'create stock_in',
    // STOCK_IN_UPDATE : 'update stock_in',
    // STOCK_IN_VIEW   : 'view stock_in',
    // STOCK_IN_DELETE   : 'delete stock_in',

    // STOCK_OUT_CREATE : 'create stock_out' ,
    // STOCK_OUT_UPDATE : 'update stock_out' ,
    // STOCK_OUT_VIEW   : 'view stock_out' ,
    // STOCK_OUT_DELETE   : 'delete stock_out' ,


    // CNG_SALE_CREATE : 'create cng_sale' ,
    // CNG_SALE_UPDATE : 'update cng_sale' ,
    // CNG_SALE_VIEW   : 'view cng_sale' ,
    // CNG_SALE_DELETE   : 'delete cng_sale' ,

    // CORPORATE_SALE_CREATE : 'create corporate_sale' ,
    // CORPORATE_SALE_UPDATE : 'update corporate_sale' ,
    // CORPORATE_SALE_VIEW   : 'view corporate_sale' ,
    // CORPORATE_SALE_DELETE   : 'delete corporate_sale' ,


    ROLE_CREATE : 'create ROLE',
    ROLE_UPDATE : 'update ROLE',
    ROLE_VIEW   : 'view ROLE',
    ROLE_DELETE : 'delete ROLE',

    DASHBOARD_PERMISSION  : 'view dashboard',


    // COMMERCIAL_LOAN_MANAGE_CREATE : 'create commercial_loan_manage',
    // COMMERCIAL_LOAN_MANAGE_UPDATE : 'update commercial_loan_manage',
    // COMMERCIAL_LOAN_MANAGE_VIEW   : 'view commercial_loan_manage',
    // COMMERCIAL_LOAN_MANAGE_DELETE   : 'delete commercial_loan_manage',

    // Report Permission
    // SALES_REPORT_VIEW   : 'view sales_report' ,
    // STOCKIN_REPORT_VIEW : 'view stockin_report',
    // EXPENSE_REPORT_VIEW : 'view expense_report',
    // STOCKSUMMARY_REPORT_VIEW : 'view summary_report',
    // DAYBOOK_REPORT_VIEW : 'view daybook_report',

    //Products
    // PRODUCT_CREATE : 'create product' ,
    // PRODUCT_UPDATE : 'update product' ,
    // PRODUCT_VIEW   : 'view product' ,
    // PRODUCT_DELETE   : 'delete product' ,
    // PRODUCT_REPORT   : 'view product_report' ,
    // PRODUCT_DASHBOARD_PERMISSION  : 'view product_dashboard',

    // PRODUCT_MAIN_CATEGORY_CREATE : 'create product_main_category' ,
    // PRODUCT_MAIN_CATEGORY_UPDATE : 'update product_main_category' ,
    // PRODUCT_MAIN_CATEGORY_VIEW   : 'view product_main_category' ,
    // PRODUCT_MAIN_CATEGORY_DELETE   : 'delete product_main_category' ,

    // PRODUCT_SUB_CATEGORY_CREATE : 'create product_sub_category' ,
    // PRODUCT_SUB_CATEGORY_UPDATE : 'update product_sub_category' ,
    // PRODUCT_SUB_CATEGORY_VIEW   : 'view product_sub_category' ,
    // PRODUCT_SUB_CATEGORY_DELETE   : 'delete product_sub_category' ,

    // PRODUCT_BRAND_CREATE : 'create product_brand' ,
    // PRODUCT_BRAND_UPDATE : 'update product_brand' ,
    // PRODUCT_BRAND_VIEW   : 'view product_brand' ,
    // PRODUCT_BRAND_DELETE   : 'delete product_brand' ,

    // PRODUCT_UNIT_TYPE_CREATE : 'create product_unit_type' ,
    // PRODUCT_UNIT_TYPE_UPDATE : 'update product_unit_type' ,
    // PRODUCT_UNIT_TYPE_VIEW   : 'view product_unit_type' ,
    // PRODUCT_UNIT_TYPE_DELETE   : 'delete product_unit_type' ,

    // PRODUCT_STOCK_CREATE : 'create product_stock' ,
    // PRODUCT_STOCK_UPDATE : 'update product_stock' ,
    // PRODUCT_STOCK_VIEW   : 'view product_stock' ,
    // PRODUCT_STOCK_DELETE   : 'delete product_stock' ,

    // PRODUCT_SALE_CREATE : 'create product_sale' ,
    // PRODUCT_SALE_UPDATE : 'update product_sale' ,
    // PRODUCT_SALE_VIEW   : 'view product_sale' ,
    // PRODUCT_SALE_DELETE   : 'delete product_sale' ,

    // PRODUCT_PAYMENT_CREATE : 'create product_payment' ,
    // PRODUCT_PAYMENT_UPDATE : 'update product_payment' ,
    // PRODUCT_PAYMENT_VIEW   : 'view product_payment' ,
    // PRODUCT_PAYMENT_DELETE   : 'delete product_payment' ,

    // PRODUCT_RECEIVE_PAYMENT_CREATE : 'create product_receive_payment' ,
    // PRODUCT_RECEIVE_PAYMENT_UPDATE : 'update product_receive_payment' ,
    // PRODUCT_RECEIVE_PAYMENT_VIEW   : 'view product_receive_payment' ,
    // PRODUCT_RECEIVE_PAYMENT_DELETE   : 'delete product_receive_payment' ,


    // WAREHOUSE_CREATE : 'create warehouse_create' ,
    // WAREHOUSE_UPDATE : 'update warehouse_update' ,
    // WAREHOUSE_VIEW   : 'view warehouse_view' ,
    // WAREHOUSE_DELETE   : 'delete warehouse_delete' ,

    // GROUP MENU PERMISSION
    // PRODUCT_PERMISSION   : 'view product_permission' ,
    // CNG_PERMISSION   : 'view cng_permission' ,
    // LOAN_PERMISSION   : 'view loan_permission' ,
    // CORPORATE_SALE_PERMISSION   : 'view corporate_sale_permission' ,
}