import React, { Component } from "react";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvRadio, AvRadioGroup, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
import classnames from 'classnames';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
// SubFloorPlanListData
import { SubProjectNameListData } from '../../store/ProjectName/actions';
import { createFloorPlan, CityNameList, DistrictNameList, FilterOptionList } from '../../store/FloorPlan/actions';
// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import { clearSuccess } from '../../store/FilterValue/actions';

import 'react-image-picker-editor/dist/index.css'
import Select from "react-select";
import Spinner from "../../components/Common/Spinner";
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}



class FloorPlanCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 25,
            rows1: [],
            supplier: {

            },
            status: 0,
            imageSrc: '',
            site_map_image: {},
            project_logo: {},
            project_cover_image_2: {},
            project_cover_image_1: {},
            hasExtraValue: false,
            errorMessage: '',
            selectedGroup: null,
            districtId: null,
            bathroom_details: '',
            details_descrptions: '',
            short_descriptions: '',
            cityId: null,
            storiesId: null,
            bedroomId: null,
            garageId: null,
            bathroomId: null,
            sizeId: null,
            priceId: null,
            brand: {

            },

            loader: false,


        };
        // this.handleChange = this.handleChange.bind(this);
        this.handlleProjectName = this.handlleProjectName.bind(this);
        this.handleCityId = this.handleCityId.bind(this);
        this.handledistrictId = this.handledistrictId.bind(this);
        this.handlebathroomId = this.handlebathroomId.bind(this);
        this.handlebedroomId = this.handlebedroomId.bind(this);
        this.handlesizeId = this.handlesizeId.bind(this);
        this.handlepriceId = this.handlepriceId.bind(this);
        this.handlegarageId = this.handlegarageId.bind(this);
        this.handlestoriesId = this.handlestoriesId.bind(this);

    }


    componentDidMount() {
        this.fetchProjectSliderList();
        this.fetchCitynameList();
        this.fetchDistrictNameList();
        this.fetchFilterOptionList();

    }

    fetchProjectSliderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        SubProjectNameListData(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data,
                });
                this.state.ProjectNameList = response.data;

            }
            else {
                this.setState({
                    ProjectNameList: {
                    },
                });
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchCitynameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        CityNameList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    CityNameList: response.data,
                });
                this.state.CityNameList = response.data;

            } else {
                this.setState({
                    CityNameList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchDistrictNameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        DistrictNameList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    DistrictNameList: response.data,
                });
                this.state.DistrictNameList = response.data;

            } else {
                this.setState({
                    DistrictNameList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchFilterOptionList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        FilterOptionList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    FilterOptionList: response.data,
                });
                this.state.FilterOptionList = response.data;

            } else {
                this.setState({
                    FilterOptionList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    handleValidSubmit(event, values) {
        this.setState({ loader: true });
        this.props.createFloorPlan(values);
        this.state.loader = false;
    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
    }


    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    // handleChange(status) {
    //     this.setState({ status });
    // }

    handleLargeImageUpload = (index, image) => {

        const formData = { file: image, name: `project_cover_image_${index}`, type: "image/jpeg" };
        // //console.log("formData", formData);

        this.state.project_cover_image_2[index] = formData;
    }

    toggleTab(tab) {
        if (tab === 2 && (document.getElementById('project_id_value').value == '' || document.getElementById('project_id_value').value == null
            || document.getElementById('project_id_value').value == undefined)) {
            this.setState({
                errorMessage: "Project Name  is required",
            })
        }
        else if (tab === 2 && (document.getElementById('floore_plan_name').value == '' || document.getElementById('floore_plan_name').value == null
            || document.getElementById('floore_plan_name').value == undefined)) {
            this.setState({
                errorMessage: "Floor Plan Information is required",
            })
        }
        else if (tab === 2 && (document.getElementById('address').value == '' || document.getElementById('address').value == null
            || document.getElementById('address').value == undefined)) {
            this.setState({
                errorMessage: "Address Title is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_city_info_id').value == '' || document.getElementById('f_city_info_id').value == null
            || document.getElementById('f_city_info_id').value == undefined)) {
            this.setState({
                errorMessage: "City Information is required",
            })
        }

        else if (tab === 2 && (document.getElementById('f_district_id').value == '' || document.getElementById('f_district_id').value == null
            || document.getElementById('f_district_id').value == undefined)) {
            this.setState({
                errorMessage: "District Information Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_stories_id').value == '' || document.getElementById('f_stories_id').value == null
            || document.getElementById('f_stories_id').value == undefined)) {
            this.setState({
                errorMessage: "Stories is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_bedroom_id').value == '' || document.getElementById('f_bedroom_id').value == null
            || document.getElementById('f_bedroom_id').value == undefined)) {
            this.setState({
                errorMessage: "Bedroom is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_garage_id').value == '' || document.getElementById('f_garage_id').value == null
            || document.getElementById('f_garage_id').value == undefined)) {
            this.setState({
                errorMessage: "Garage is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_bathroom_id').value == '' || document.getElementById('f_bathroom_id').value == null
            || document.getElementById('f_bathroom_id').value == undefined)) {
            this.setState({
                errorMessage: "Bathroom is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_size_id').value == '' || document.getElementById('f_size_id').value == null
            || document.getElementById('f_size_id').value == undefined)) {
            this.setState({
                errorMessage: "Size is required",
            })
        }
        else if (tab === 2 && (document.getElementById('f_price_id').value == '' || document.getElementById('f_price_id').value == null
            || document.getElementById('f_price_id').value == undefined)) {
            this.setState({
                errorMessage: "Price No is required",
            })
        }
        else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
            || document.getElementById('priority').value == undefined)) {
            this.setState({
                errorMessage: "Priority is required",
            })
        }
        else {
            if (this.state.activeTab !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

    }
    handleAddRowNested = () => {
        const item1 = {
            name1: ""
        };
        this.setState({
            rows1: [...this.state.rows1, item1]
        });
    };

    handleRemoveRowNested = (e, idx) => {
        document.getElementById("nested" + idx).style.display = "none";
    };


    removeMessage() {
        this.setState({ errorMessage: '' });
    }

    handlleProjectNameFilter(option, inputValue) {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }

    handlleProjectName(selectedGroup) {

        this.setState({
            selectedGroup
        })
        console.log("out", this.state.selectedGroup);

        //   this.state.selectedGroup = option;
    }

    handleCityId(cityId) {

        this.setState({ cityId });
    }
    handledistrictId(districtId) {

        this.setState({ districtId });
    }
    handlepriceId(priceId) {

        this.setState({ priceId });
    }
    handlesizeId(sizeId) {

        this.setState({ sizeId });
    }
    handlestoriesId(storiesId) {

        this.setState({ storiesId });
    }
    handlebedroomId(bedroomId) {

        this.setState({ bedroomId });
    }
    handlegarageId(garageId) {

        this.setState({ garageId });
    }
    handlebathroomId(bathroomId) {

        this.setState({ bathroomId });
    }


    clear = () => {
        // localStorage.setItem('last_action',"create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/index/floorPlan',
            }
        )
    }
    clearErr = () => {
        // localStorage.setItem('last_action',"create");
        this.props.clearSuccess(this.props.error);
    }


    render() {

        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        const initialImage = '';
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        const { selectedGroup } = this.state;
        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>
                        {this.state.loader == true ?
                            <Spinner /> : ''
                        }
                        <Breadcrumbs title="Settings" breadcrumbItem=" Floor Plan Create" />
                        {this.state.errorMessage && this.state.errorMessage ?
                            <SweetAlert
                                danger
                                title={this.state.errorMessage}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.removeMessage()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }


                        <Row>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.toggleTab(1); }} >
                                                        <span className="step-number mr-2">01</span>
                                                        Floor Plan Setup
                                                    </NavLink>
                                                </NavItem>

                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => { this.toggleTab(2); }} >
                                                        <span className="step-number mr-2">02</span>
                                                        <span>Image upload</span>
                                                    </NavLink>
                                                </NavItem>

                                            </ul>
                                            <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">

                                                <TabPane tabId={1}>
                                                    <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">Project name : </Label>
                                                                <Select
                                                                    //isMulti
                                                                    onChange={this.handlleProjectName}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.ProjectNameList}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                <AvField type="hidden" name="project_id_value" id="project_id_value" value={this.state.selectedGroup && this.state.selectedGroup.value} required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Floor Plan Name')} id="floore_plan_name" name="floore_plan_name" required />
                                                            </div>

                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Floor Type'} id="floor_type" name="floor_type" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Address')} id="address" name="address" required />
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Video Link'} id="vedio_link" name="vedio_link" />
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-12">
                                                                <AvField className="form-control" type="hidden" label={reqLabel('Short Description')} id="short_description" name="short_descriptions" value={this.state.short_descriptions} />

                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={''}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            short_descriptions: this.state.short_descriptions && this.state.short_descriptions,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            short_descriptions: data,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Focus.', editor );
                                                                    }}

                                                                />
                                                            </div>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <div className="col-md-12">
                                                                <AvField className="form-control" type="hidden" label={reqLabel('Details Descrptions')} id="details_descrptions" name="details_descrptions" value={this.state.details_descrptions} />

                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={''}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            details_descrptions: this.state.details_descrptions && this.state.details_descrptions,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // You can store the "editor" and use when it is needed.
                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            details_descrptions: data,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Focus.', editor );
                                                                    }}

                                                                />
                                                            </div>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname" >{reqLabel("City name : ")}</Label>

                                                                <Select
                                                                    onChange={this.handleCityId}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.CityNameList}
                                                                    classNamePrefix="select2-selection"

                                                                />

                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">{reqLabel("School District name : ")}</Label>
                                                                <Select
                                                                    onChange={this.handledistrictId}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.DistrictNameList}
                                                                    classNamePrefix="select2-selection"

                                                                />

                                                            </div>
                                                            <AvField type="hidden" name="f_city_info_id" id="f_city_info_id" value={this.state.cityId && this.state.cityId.value} required />
                                                            <AvField type="hidden" name="f_district_id" id="f_district_id" value={this.state.districtId && this.state.districtId.value} required />

                                                        </Row>

                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">{reqLabel("Stories name : ")}</Label>
                                                                <Select
                                                                    onChange={this.handlestoriesId}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Stories}
                                                                    classNamePrefix="select2-selection"

                                                                />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Stories Quantity')} id="stories_qty" name="stories_qty" required />
                                                            </div>
                                                            <AvField type="hidden" name="f_stories_id" id="f_stories_id" value={this.state.storiesId && this.state.storiesId.value} required />

                                                        </Row>
                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">{reqLabel("Bedroom name : ")}</Label>

                                                                <Select
                                                                    onChange={this.handlebedroomId}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Bedroom}
                                                                    classNamePrefix="select2-selection"

                                                                />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Beedroom Quantity')} id="bedroom_qty" name="bedroom_qty" required />
                                                            </div>
                                                            <AvField type="hidden" name="f_bedroom_id" id="f_bedroom_id" value={this.state.bedroomId && this.state.bedroomId.value} required />
                                                        </Row>
                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">{reqLabel("Garage name : ")}</Label>
                                                                <Select
                                                                    onChange={this.handlegarageId}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Garage}
                                                                    classNamePrefix="select2-selection"

                                                                />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Garage Quantity')} id="garage_qty" name="garage_qty" required />
                                                            </div>
                                                            <AvField type="hidden" name="f_garage_id" id="f_garage_id" value={this.state.garageId && this.state.garageId.value} required />
                                                        </Row>
                                                        {/* </TabPane>

                                                        <TabPane tabId={2}> */}

                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">{reqLabel("Bathroom name :")} </Label>

                                                                <Select
                                                                    onChange={this.handlebathroomId}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Bathrooms}
                                                                    classNamePrefix="select2-selection"

                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Bathroom Quantity')} id="bathroom_qty" name="bathroom_qty" required />
                                                            </div>
                                                            <AvField type="hidden" name="f_bathroom_id" id="f_bathroom_id" value={this.state.bathroomId && this.state.bathroomId.value} required />
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-12">
                                                                <AvField className="form-control" type="hidden" label={'Bathroom Details'} id="bathroom_details" name="bathroom_details" value={this.state.bathroom_details} />

                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={''}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            bathroom_details: this.state.bathroom_details && this.state.bathroom_details,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";

                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            bathroom_details: data,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Focus.', editor );
                                                                    }}

                                                                />
                                                            </div>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">{reqLabel("Size : ")}</Label>


                                                                <Select
                                                                    onChange={this.handlesizeId}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Size}
                                                                    classNamePrefix="select2-selection"

                                                                />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Size Details Sqrft')} id="size_details_sq_fit" name="size_details_sq_fit" required />
                                                            </div>

                                                            <AvField type="hidden" name="f_size_id" id="f_size_id" value={this.state.sizeId && this.state.sizeId.value} required />
                                                        </Row>


                                                        <Row>
                                                            <div className="form-group col-md-6">
                                                                <Label htmlFor="formname">{reqLabel("Price Id : ")}</Label>
                                                                <Select
                                                                    onChange={this.handlepriceId}
                                                                    filterOption={this.handlleProjectNameFilter}
                                                                    noOptionsMessage={() => "No more options"}
                                                                    options={this.state.FilterOptionList != undefined && this.state.FilterOptionList.Price}
                                                                    classNamePrefix="select2-selection"

                                                                />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Price')} id="price" name="price" required />
                                                            </div>

                                                            <AvField type="hidden" name="f_price_id" id="f_price_id" value={this.state.priceId && this.state.priceId.value} required />
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvRadioGroup inline name="is_quick_move_in_homes" id="is_quick_move_in_homes" label={reqLabel('Is Quick Move In Home')} required>
                                                                    <AvRadio customInput label="Yes" value="0" />
                                                                    <AvRadio customInput label="No" value="1" />
                                                                </AvRadioGroup>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField type="date" name="quick_move_in_homes_ready_date" id="quick_move_in_homes_ready_date" label={'Quick Ready Home Date'} />
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" required />
                                                            </div>
                                                            <AvField type="hidden" value={this.state.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" required />
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <Label>{reqLabel('Status')}</Label><br />
                                                                <AvField type="select" className="form-control" id="Status" name="Status"   >
                                                                    <option value="0">Active</option>
                                                                    <option value="1">Inactive</option>

                                                                </AvField>
                                                            </div>
                                                        </Row>
                                                    </AvForm>

                                                </TabPane>


                                                <TabPane tabId={2}>
                                                    <div className="inner-repeater mb-4">
                                                        <table style={{ width: "100%" }}>
                                                            <tbody>
                                                                <tr id="addrMain" key="">
                                                                    <td>
                                                                        <Row className="mb-2">
                                                                            <div className="col-md-4">
                                                                                <Label>{reqLabel('Slider Image ')}</Label><br />
                                                                                <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1080px * 600px</small>
                                                                                <br />
                                                                                < ReactImagePickerEditor
                                                                                    id="project_cover_image_2_0"
                                                                                    name="project_cover_image_2_0"
                                                                                    type="file"
                                                                                    config={config2}
                                                                                    imageSrcProp={initialImage}
                                                                                    imageChanged={(image) => {
                                                                                        this.handleLargeImageUpload(0, image)
                                                                                    }}
                                                                                />

                                                                            </div>

                                                                            <Col md="2" >
                                                                                <Button
                                                                                    disabled
                                                                                    color="danger"
                                                                                    className="btn-block inner mt-5 "
                                                                                    style={{ width: "100%" }}
                                                                                > Delete
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>


                                                                {this.state.rows1.map((item1, idx) => (
                                                                    <tr id={"nested" + idx} key={idx}>
                                                                        <td>
                                                                            <Row className="mb-2">
                                                                                <div className="col-md-4">
                                                                                    {/* <Label>{reqLabel('Slider Image')}</Label><br /> */}
                                                                                    <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1080px * 600px</small>

                                                                                    <br />
                                                                                    < ReactImagePickerEditor
                                                                                        id="project_cover_image_2_0"
                                                                                        name="project_cover_image_2_0"
                                                                                        type="file"
                                                                                        config={config2}
                                                                                        imageSrcProp={initialImage}
                                                                                        imageChanged={(image) => {
                                                                                            this.handleLargeImageUpload((idx + 1), image)
                                                                                        }}
                                                                                    />

                                                                                </div>
                                                                                <Col md="2">
                                                                                    <Button
                                                                                        onClick={e =>
                                                                                            this.handleRemoveRowNested(e, idx)
                                                                                        }
                                                                                        color="danger"
                                                                                        className="btn-block inner mt-5 "
                                                                                        style={{ width: "100%" }}
                                                                                    > Delete
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        <Button
                                                            onClick={this.handleAddRowNested}
                                                            color="success"
                                                            className="mt-1"
                                                        >
                                                            Add Image
                                                        </Button>
                                                    </div>
                                                </TabPane>

                                                <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                    <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab - 1); }}>Previous</Link></li>
                                                    {
                                                        this.state.activeTab == 2 ?
                                                            <li className="next">
                                                                <Button type="submit" form='my-form' color="success">Submit</Button>
                                                            </li>
                                                            :
                                                            <li className={this.state.activeTab === 2 ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab + 1); }}>Next</Link></li>

                                                    }
                                                </ul>
                                            </TabContent>
                                            {/* 
                                            <Button type="submit" form='my-form' color="primary">
                                                Submit
                                            </Button> */}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.FloorPlan;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createFloorPlan, clearSuccess })(FloorPlanCreate));