import { AboutUsPageSlider_ERROR,AboutUsPageSlider_SUCCESS,EDIT_District } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const District = (state = initialState, action) => {
    switch (action.type) {
     
        case EDIT_District:
            state = { ...state }
            break;
        case AboutUsPageSlider_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case AboutUsPageSlider_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default District;