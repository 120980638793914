import React, { Component } from "react";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
// import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
import classnames from 'classnames';
import { AvForm, AvField, AvRadio, AvRadioGroup, AvInput } from 'availity-reactstrap-validation';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
// SubProjectNameListData
import { SubProjectNameListData } from '../../store/ProjectName/actions';
import { FloorDataPlanList } from '../../store/FloorPlan/actions';
import { createGallery, clearSuccess } from '../../store/Gallery/actions';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import 'react-image-picker-editor/dist/index.css'
import Select from "react-select";
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}



class GalleryCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 25,
            rows1: [],
            supplier: {
            },
            status: 0,
            imageSrc: '',
            site_map_image: {},
            project_logo: {},
            FloorPlanList: '',
            project_cover_image_2: {},
            project_cover_image_1: {},
            errorMessage: '',
            selectedGroup: null,
            brand: {
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        this.handleFloorPlan = this.handleFloorPlan.bind(this);

    }


    componentDidMount() {
        this.fetchProjectNameList();
        this.fetchFloorPlanList();

    }

    fetchProjectNameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        SubProjectNameListData(options).then((response) => {
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data,
                });
                this.state.ProjectNameList = response.data;

            } else {
                this.setState({
                    ProjectNameList: {

                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchFloorPlanList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        FloorDataPlanList(options).then((response) => {
            if (response.data != []) {
                this.setState({
                    FloorPlanList: response.data,
                });
                this.state.FloorPlanList = response.data;
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createGallery(values);

    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
    }


    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handleLargeImageUpload = (index, image) => {

        const formData = { file: image, name: `project_cover_image_${index}`, type: "image/jpeg" };
        console.log("formData", formData);

        this.state.project_cover_image_2[index] = formData;
    }

    toggleTab(tab) {
        if (tab === 2 && (document.getElementById('project_name').value == '' || document.getElementById('project_name').value == null
            || document.getElementById('project_name').value == undefined)) {
            this.setState({
                errorMessage: "gallery  is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_title').value == '' || document.getElementById('project_title').value == null
            || document.getElementById('project_title').value == undefined)) {
            this.setState({
                errorMessage: "Project Title is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_short_description').value == '' || document.getElementById('project_short_description').value == null
            || document.getElementById('project_short_description').value == undefined)) {
            this.setState({
                errorMessage: "Project Short Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_details_description').value == '' || document.getElementById('project_details_description').value == null
            || document.getElementById('project_details_description').value == undefined)) {
            this.setState({
                errorMessage: "Project Details Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('contact_information_description').value == '' || document.getElementById('contact_information_description').value == null
            || document.getElementById('contact_information_description').value == undefined)) {
            this.setState({
                errorMessage: "Contact Information Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_address').value == '' || document.getElementById('project_address').value == null
            || document.getElementById('project_address').value == undefined)) {
            this.setState({
                errorMessage: "Project Address is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_phone_no').value == '' || document.getElementById('project_phone_no').value == null
            || document.getElementById('project_phone_no').value == undefined)) {
            this.setState({
                errorMessage: "Project Phone No is required",
            })
        }
        else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
            || document.getElementById('priority').value == undefined)) {
            this.setState({
                errorMessage: "Priority is required",
            })
        }
        else {
            if (this.state.activeTab !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

    }
    handleAddRowNested = () => {
        const item1 = {
            name1: ""
        };
        this.setState({
            rows1: [...this.state.rows1, item1]
        });
    };

    handleRemoveRowNested = (e, idx) => {
        document.getElementById("nested" + idx).style.display = "none";
    };


    removeMessage() {
        this.setState({ errorMessage: '' });
    }
    handleSelectGroup(selectedGroup) {

        this.setState({ selectedGroup });
    }
    handleFloorPlan(floorPlan) {

        this.setState({ floorPlan });
    }
    SuccessMessage() {
        localStorage.setItem('floorPlanCreate', 'true');
        this.props.history.push(
            {
                pathname: '/index/floorPlan',
            }
        )
    }

    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/index/gallery',
            }
        )
    }
    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;

        const initialImage = '';
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem=" gallery Create" />
                        {this.state.errorMessage && this.state.errorMessage ?
                            <SweetAlert
                                danger
                                title={this.state.errorMessage}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>
                                    this.clear()
                                }
                            >
                            </SweetAlert> : ''
                        }

                        <Row>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <div data-repeater-list="outer-group" className="outer">

                                            <div data-repeater-item className="outer">
                                                <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                                    <Row>
                                                        <div className="col-md-6">
                                                            <AvRadioGroup inline name="is_article" id="is_article" label={reqLabel('Selecet One')} required>
                                                                <AvRadio customInput label="Article" value="1" name="is_article" />
                                                                <AvRadio customInput label="Gallery" value="0" name="is_article" />
                                                            </AvRadioGroup>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField type="text" label={'Author Name'} name="author_name" id="author_name" />
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Project name : </Label>
                                                            <Select
                                                                id="parent_project_id"
                                                                name="parent_project_id"
                                                                value={this.state.selectedGroup}
                                                                onChange={this.handleSelectGroup}
                                                                options={this.state.ProjectNameList}
                                                            />
                                                        </div>
                                                        <AvField type="hidden" name="project_id_value" id="project_id_value" value={this.state.selectedGroup && this.state.selectedGroup.value} />
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Floor Plan name : </Label>
                                                            <Select
                                                                id="parent_project_id"
                                                                name="parent_project_id"
                                                                value={this.state.floorPlan}
                                                                onChange={this.handleFloorPlan}
                                                                options={this.state.FloorPlanList}
                                                            />
                                                        </div>
                                                        <AvField type="hidden" name="f_floore_plans_id" id="f_floore_plans_id" value={this.state.floorPlan && this.state.floorPlan.value} />
                                                    </Row>
                                                    {/* <Row>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={'Short Description'} id="Short_description" name="Short_description" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={'Long Descriptiom'} id="Long_description" name="Long_description" />
                                                        </div>
                                                    </Row> */}
                                                    <Row>
                                                        <div className="col-md-12">
                                                            <AvField className="form-control" type="hidden" label={reqLabel('Short Description')} id="Short_description" name="Short_description" value={this.state.Short_description && this.state.Short_description} />

                                                            <CKEditor
                                                                editor={ClassicEditorDescription}
                                                                data={this.state.Short_description && this.state.Short_description}
                                                                onReady={editor => {
                                                                    this.setState({
                                                                        Short_description: this.state.FloorPlanList.Short_description && this.state.FloorPlanList.Short_description,
                                                                    });
                                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    this.setState({
                                                                        Short_description: data,
                                                                    });
                                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                                }}
                                                                onBlur={(event, editor) => {
                                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    // console.log( 'Blur.', editor );
                                                                }}
                                                                onFocus={(event, editor) => {
                                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    // console.log( 'Focus.', editor );
                                                                }}

                                                            />
                                                        </div>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <div className="col-md-12">
                                                            <AvField className="form-control" type="hidden" label={reqLabel('Details Descrptions')} id="Long_description" name="Long_description" value={this.state.Long_description} />

                                                            <CKEditor
                                                                editor={ClassicEditorDescription}
                                                                data={this.state.Long_description && this.state.Long_description}
                                                                onReady={editor => {
                                                                    this.setState({
                                                                        Long_description: this.state.FloorPlanList.Long_description && this.state.FloorPlanList.Long_description,
                                                                    });
                                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    this.setState({
                                                                        Long_description: data,
                                                                    });
                                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                                }}
                                                                onBlur={(event, editor) => {
                                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    // console.log( 'Blur.', editor );
                                                                }}
                                                                onFocus={(event, editor) => {
                                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    // console.log( 'Focus.', editor );
                                                                }}

                                                            />
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <AvField type="hidden" value={this.state.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" required />

                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" required />
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className="col-md-6">
                                                            <Label>{reqLabel('Status')}</Label><br />
                                                            <AvField type="select" className="form-control" id="Status" name="Status"   >
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>

                                                            </AvField>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label='Title' id="title" name="title" required />
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </AvForm>
                                                <div className="inner-repeater mb-4">
                                                    <table style={{ width: "100%" }}>
                                                        <tbody>
                                                            <tr id="addrMain" key="">
                                                                <td>
                                                                    <Row className="mb-2">
                                                                        <div className="col-md-4">
                                                                            <Label>{reqLabel('Gallery Image ')}</Label><br />
                                                                            <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 1080px</small>

                                                                            <br />
                                                                            < ReactImagePickerEditor
                                                                                id="project_cover_image_2_0"
                                                                                name="project_cover_image_2_0"
                                                                                type="file"
                                                                                config={config2}
                                                                                imageSrcProp={initialImage}
                                                                                imageChanged={(image) => {
                                                                                    this.handleLargeImageUpload(0, image)
                                                                                }}
                                                                            />

                                                                        </div>

                                                                        <Col md="2" >
                                                                            <Button
                                                                                disabled
                                                                                color="danger"
                                                                                className="btn-block inner mt-5 "
                                                                                style={{ width: "100%" }}
                                                                            > Delete
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                            </tr>


                                                            {this.state.rows1.map((item1, idx) => (
                                                                <tr id={"nested" + idx} key={idx}>
                                                                    <td>
                                                                        <Row className="mb-2">
                                                                            <div className="col-md-4">
                                                                                {/* <Label>{reqLabel('Gallery Image')}</Label><br /> */}
                                                                                <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 1080px</small>
                                                                                <br />
                                                                                < ReactImagePickerEditor
                                                                                    id="project_cover_image_2_0"
                                                                                    name="project_cover_image_2_0"
                                                                                    type="file"
                                                                                    config={config2}
                                                                                    imageSrcProp={initialImage}
                                                                                    imageChanged={(image) => {
                                                                                        this.handleLargeImageUpload((idx + 1), image)
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <Col md="2">
                                                                                <Button
                                                                                    onClick={e =>
                                                                                        this.handleRemoveRowNested(e, idx)
                                                                                    }
                                                                                    color="danger"
                                                                                    className="btn-block inner mt-5 "
                                                                                    style={{ width: "100%" }}
                                                                                > Delete
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <Button
                                                        onClick={this.handleAddRowNested}
                                                        color="success"
                                                        className="mt-1"
                                                    >
                                                        Add Image
                                                    </Button>
                                                </div>
                                                <Button type="submit" form='my-form' color="success" style={{ float: "right" }}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.Gallery;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createGallery, clearSuccess })(GalleryCreate));