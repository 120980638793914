import {CREATE_Gallery,CREATE_SubGallery,EDIT_SubGallery,EDIT_Gallery,Gallery_SUCCESS,SHOW_Gallery,Gallery_ERROR,CLEAR_MESSAGE } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createGallery = (Gallery) => {
    return {
        type: CREATE_Gallery,
        payload: { Gallery }
    }
}
export const createSubGallery = (Gallery) => {
    return {
        type: CREATE_SubGallery,
        payload: { Gallery }
    }
}
export const clearSuccess = (valueSuccess) => {
    let message = {valueSuccess};

    return {
        type: CLEAR_MESSAGE,
        payload: { message }
    }
}

export const editGallery = (Gallery) => {
    return {
        type: EDIT_Gallery,
        payload: { Gallery }
    }
}
export const editSubGallery = (Gallery) => {
    return {
        type: EDIT_SubGallery,
        payload: { Gallery }
    }
}

export function GalleryList(params){
    return getApi().get('Gallery/list',{
        params: params
    })
}
export function ParentGalleryList(params){
    return getApi().get('Gallery/parent/list',{
        params: params
    })
}

export function SpecificParentGalleryList(id){
    return getApi().get('Gallery/parent/specific/'+id)
}


export function SubGalleryList(params){
    return getApi().get('Gallery/subProject/list',{
        params: params
    })
}

export function SubProjectList(params){
    return getApi().get('Gallery/subProject/list',{
        params: params
    })
}
export function SubListSpecific(id){
    return getApi().get('Gallery/subProject/list/'+id)
}

export function GalleryDetails(id){
    return getApi().get('Gallery/Details/'+id)
}



export const GallerySuccess = (msg) => {
    return {
        type: Gallery_SUCCESS,
        payload: msg
    }
}

export const GalleryError = (msg) => {
    return {
        type: Gallery_ERROR,
        payload: msg
    }
}

export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_Gallery,
        payload: { voucher_id }
    }
}

export function deleteVoucher(voucherId){
    return getApi().delete('Gallery/'+voucherId)
}


export function orderShow(order_id){
    return getApi().get('vouchar/generates/show/'+order_id)
}






