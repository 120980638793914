import React, { Component } from "react";
import { TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, CardSubtitle } from "reactstrap";
import classnames from "classnames";

import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteVoucher, editProjectName, ProjectNameList, SubProjectNameList, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload } from '../../store/ProjectName/actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';

import DeleteModal from './DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class ProjectName extends Component {
    constructor(props) {
        var Tabvalue = localStorage.getItem('projectName');
        // console.log(Tabvalue);
        if (Tabvalue == undefined || Tabvalue == null) {
            localStorage.setItem('projectName', "project");
        }
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            activeTab1: Tabvalue,
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            ProjectName_list: {},
            deleteModal: false,
            supplier: {

            },

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchProjectNameList();
        this.fetchSubProjectNameList();
        var Tabvalue = localStorage.getItem('projectName');
        if (Tabvalue == undefined || Tabvalue == null) {
            localStorage.setItem('projectName', "project");
        }
        // this.fetchCityList();     
    }

    fetchProjectNameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        ProjectNameList(options).then((response) => {
            //   console.log(response);
            this.setState({
                ProjectNameList: response.data,
            });
            this.state.ProjectNameList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchSubProjectNameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        SubProjectNameList(options).then((response) => {
            //   console.log(response);
            this.setState({
                SubProjectNameList: response.data,
            });
            this.state.SubProjectNameList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (ProjectName) => {
        // this.setState(prevState => ({
        //     modal_scroll: !prevState.modal_scroll,
        // }));
        // this.state.ProjectName_list = ProjectName;
        // this.removeBodyCss();
        this.props.history.push({
            pathname: '/edit/projectName/' + ProjectName.id,
            state: { value: ProjectName, }
        });
    }
    //modal
    tog_scroll2 = (ProjectName) => {
        // this.setState(prevState => ({
        //     modal_scroll: !prevState.modal_scroll,
        // }));
        // this.state.ProjectName_list = ProjectName;
        // this.removeBodyCss();
        this.props.history.push({
            pathname: '/edit/sub/projectName/' + ProjectName.id,
            state: { value: ProjectName, }
        });
    }
    // Voucherdelete = (voucherId) => {

    //     this.state.voucherId = voucherId;
    //     deleteVoucher(voucherId);
    //     setTimeout(function () {
    //         window.location.reload();
    //     }, 3500)
    // }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchProjectNameList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }
    toggleDeleteModal = (conmpany) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(conmpany);
    };

    onClickDelete = (customer) => {
        // console.log("Delete Modal", customer);
        this.setState({ deleteCustomer: customer });
        this.setState({ deleteModal: true });

    };
    handleDeleteSubmit() {
        // alert("Are You Want To Delete This?");
        // this.fetchProjectNameList(); 
        const { deleteCustomer } = this.state;
        // console.log("Delete Modal22222222", deleteCustomer);

        if (deleteCustomer != undefined) {
            deleteVoucher(deleteCustomer.id)
                .then((response) => {
                    if (response.success == true) {
                        //   console.log(response)
                        // alert(response.data);
                        this.fetchProjectNameList();
                        // window.location.reload();
                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,

                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }


    handleEditSubmit(event, values) {
        this.props.editProjectName(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchProjectNameList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchProjectNameList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchProjectNameList();
        });
    }



    handleImageUpload_slider_large_image = (e) => {
        var id = e.target.id;
        var id = this.state.ProjectName_list.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type, id: id };
            slider_large_image_Upload(formData, formData.id);
        }
    }

    handleImageUpload_slider_medium_image = (e) => {
        var id = e.target.id;
        var id = this.state.ProjectName_list.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type, id: id };
            slider_medium_image_Upload(formData, formData.id);
        }
    }

    handleImageUpload = (e) => {
        var id = e.target.id;
        var id = this.state.ProjectName_list.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type, id: id };
            slider_thumbnail_image_Upload(formData, formData.id);
        }
    }
    toggle1(tab) {
        localStorage.setItem('projectName', tab)
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab
            });

        }
    }

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let ProjectNameListtMap = [];
        let SubProjectNameListtMap = [];
        const { deleteModal, message } = this.state;

        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        let paginator2;
        if (this.state.hasOwnProperty('ProjectNameList')) {
            ProjectNameListtMap = this.state.ProjectNameList.data;
            paginator = {
                'currentPage': this.state.ProjectNameList.current_page,
                'pagesCount': this.state.ProjectNameList.last_page,
                'total': this.state.ProjectNameList.total,
                'to': this.state.ProjectNameList.to,
                'from': this.state.ProjectNameList.from,
            }
        }
        if (this.state.hasOwnProperty('SubProjectNameList')) {
            SubProjectNameListtMap = this.state.SubProjectNameList.data;
            paginator2 = {
                'currentPage': this.state.SubProjectNameList.current_page,
                'pagesCount': this.state.SubProjectNameList.last_page,
                'total': this.state.SubProjectNameList.total,
                'to': this.state.SubProjectNameList.to,
                'from': this.state.SubProjectNameList.from,
            }
        }

        let singlevoucher = this.state.voucher;

        const { code, slider_header_title, id, slider_title, short_description, long_descriptiom, slider_thumbnail_image, slider_large_image, slider_medium_image, remarks, priority,status } = this.state.ProjectName_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Settings" breadcrumbItem="Communities Name " />
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                        {this.state.error && this.state.error ?
                            <SweetAlert
                                danger
                                title={this.state.error}
                                timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.success && this.state.success ?
                            <SweetAlert
                                title={this.state.success}
                                timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                success
                                onConfirm={() => window.location.reload()
                                }
                            >
                            </SweetAlert> : ''
                        }
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={() => this.handleDeleteSubmit()}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />
                        <Row>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                            <Nav pills className="navtab-bg justify-content-center ">
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer", border: "2px solid #CFB53B" }}
                                                        className={classnames({
                                                            active: this.state.activeTab1 === "project"
                                                        })}
                                                        onClick={() => {
                                                            this.toggle1("project");
                                                        }}
                                                    >
                                                        Communities
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" ,border: "2px solid #CFB53B" }}
                                                        className={classnames({
                                                            active: this.state.activeTab1 === "sub_project"
                                                        })}
                                                        onClick={() => {
                                                            this.toggle1("sub_project");
                                                        }}
                                                    >
                                                        Sub Communities
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>


                                        <TabContent activeTab={this.state.activeTab1}>
                                            <TabPane tabId="project" className="p-3">
                                                <Row>
                                                    <Col lg="12" md="12">
                                                        <Card>

                                                            <CardBody>

                                                                <CardTitle>
                                                                    <div className="row justify-content-between">
                                                                        <p>Communities Name  List </p>
                                                                        <div>
                                                                            <Link to={'/create/projectName'}><Button className="btn btn-success pull-left m-1">Communities Name </Button></Link>
                                                                            {/* <Link to={'/index/projectSlider'}><Button className="btn btn-success pull-left m-1">Add Communities Slider </Button></Link> */}
                                                                        </div>


                                                                    </div>

                                                                </CardTitle>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                                        <Table responsive>

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Code</th>
                                                                                    <th>Communities Name</th>
                                                                                    <th>Communities Title</th>
                                                                                    <th>Communities Image One</th>
                                                                                    <th>Communities Image Two</th>
                                                                                    <th>Site map Image</th>
                                                                                    {/* <th>Remark</th> */}
                                                                                    <th>Priority</th>
                                                                                    <th>Status</th>
 
                                                                                    <th colspan="2">Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {ProjectNameListtMap.map((companySetting, index) =>
                                                                                    <tr key={companySetting.id.toString()}>
                                                                                        <td scope="row">{index + 1}</td>
                                                                                        <td>{companySetting.code}</td>
                                                                                        <td>{companySetting.project_name}</td>
                                                                                        <td>{companySetting.project_title}</td>

                                                                                        <td>
                                                                                            {
                                                                                                companySetting.project_cover_image_1 != null ?
                                                                                                    (
                                                                                                        <img src={imageLink + 'company_settings/aboutus/project_cover_image_1/' + companySetting.project_cover_image_1} alt="" height="50" />
                                                                                                    ) : null
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                companySetting.project_cover_image_2 != null ?
                                                                                                    (
                                                                                                        <img src={imageLink + 'company_settings/aboutus/project_cover_image_2/' + companySetting.project_cover_image_2} alt="" height="50" />
                                                                                                    ) : null
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                companySetting.site_map_image != null ?
                                                                                                    (
                                                                                                        <img src={imageLink + 'company_settings/aboutus/site_map_image/' + companySetting.site_map_image} alt="" height="50" />
                                                                                                    ) : null
                                                                                            }
                                                                                        </td>
                                                                                        {/* <td>{companySetting.remarks}</td> */}
                                                                                        <td>{companySetting.priority}</td>
                                                                                        <td><Badge color={getBadge(companySetting.status)}>{getStatusValue(companySetting.status)}</Badge></td>
 
                                                                                        <td>
                                                                                            <i type="button"
                                                                                                className="fa fa-edit  text-warning fa-2x my-2 ml-2"
                                                                                                onClick={() => { this.tog_scroll(companySetting) }}

                                                                                            ></i>
                                                                                            <i
                                                                                                className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                                                onClick={() => { this.toggleDeleteModal(companySetting) }}
                                                                                            ></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                            {paginator &&
                                                                <CardFooter className="p-6">
                                                                    <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                                                </CardFooter>
                                                            }
                                                        </Card>
                                                        <Modal
                                                            isOpen={this.state.modal_scroll}
                                                            toggle={this.tog_scroll}
                                                            scrollable={true}
                                                        >
                                                            <div className="modal-header">
                                                                <h5 className="modal-title mt-0">{code}</h5>
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        this.setState({ modal_scroll: false })
                                                                    }
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">

                                                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                                                    {this.props.msg && this.props.msg ? (
                                                                        <Alert color="success">
                                                                            Communities Name  Updated Successfully!
                                                                        </Alert>
                                                                    ) : null}
                                                                    <Row>
                                                                        <AvField className="form-control" type="hidden" id="id" name="id" value={id} />
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Slider Header Title')} id="slider_header_title" value={slider_header_title} name="slider_header_title" required />
                                                                            <AvField className="form-control" type="text" label={reqLabel('Slider Title')} id="slider_title" name="slider_title" value={slider_title} required />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Long Descriptiom')} id="long_descriptiom" name="long_descriptiom" value={long_descriptiom} required />
                                                                            <AvField className="form-control" type="text" label={reqLabel('Short Description')} id="short_description" name="short_description" value={short_description} required />
                                                                        </div>
                                                                    </Row>

                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={priority} required />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Remarks')} id="remarks" name="remarks" value={remarks} required />
                                                                        </div>
                                                                    </Row>
                                                                    

                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField type="hidden" value={slider_thumbnail_image} name="slider_thumbnail_image" id="slider_thumbnail_image" />
                                                                            {
                                                                                slider_thumbnail_image != null ?
                                                                                    (
                                                                                        <img src={imageLink + 'company_settings/aboutus/slider_thumbnail_image/' + slider_thumbnail_image} alt="" height="50" />
                                                                                    ) : null
                                                                            }
                                                                            <AvField label={reqLabel('Slider Thumbnail Image')} type="file" className="form-control" name="slider_thumbnail_image_0" onChange={(e, v) => this.handleImageUpload(e, v)} id="slider_thumbnail_image" />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField type="hidden" value={slider_large_image} name="slider_large_image" id="slider_large_image" />
                                                                            {
                                                                                slider_large_image != null ?
                                                                                    (
                                                                                        <img src={imageLink + 'company_settings/aboutus/slider_large_image/' + slider_large_image} alt="" height="50" />
                                                                                    ) : null
                                                                            }

                                                                            <AvField label={reqLabel('large Image')} type="file" className="form-control" name="slider_large_image_0" onChange={(e, v) => this.handleImageUpload_slider_large_image(e, v)} id="slider_large_image" />
                                                                        </div>
                                                                    </Row>
                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField type="hidden" value={slider_medium_image} name="slider_medium_image" id="slider_medium_image" />
                                                                            {
                                                                                slider_medium_image != null ?
                                                                                    (
                                                                                        <img src={imageLink + 'company_settings/aboutus/slider_medium_image/' + slider_medium_image} alt="" height="50" />
                                                                                    ) : null
                                                                            }

                                                                            <AvField label={reqLabel('Medium Image')} type="file" className="form-control" name="slider_medium_image_0" onChange={(e, v) => this.handleImageUpload_slider_medium_image(e, v)} id="slider_medium_image" />
                                                                        </div>
                                                                    </Row>





                                                                    <div className="text-center mt-4">
                                                                        <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                                        <button type="submit" className="btn btn-success save-event">Save</button>
                                                                    </div>
                                                                </AvForm>

                                                            </div>
                                                        </Modal>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="sub_project" className="p-3">
                                                <Row>
                                                    <Col lg="12" md="12">
                                                        <Card>

                                                            <CardBody>

                                                                <CardTitle>
                                                                    <div className="row justify-content-between">
                                                                        <p>Sub Communities Name  List </p>
                                                                        <div>
                                                                            <Link to={'/create/sub/projectName'}>
                                                                                <Button className="btn btn-success pull-left">Sub Communities Name </Button>
                                                                            </Link>
                                                                            <Link to={'/index/projectSlider'}><Button className="btn btn-primary pull-left m-1">Communities Slider </Button></Link>
                                                                            <Link to={'/index/projectBrochure'}><Button className="btn btn-success pull-left m-1">Communities Boucher </Button></Link>
                                                                            <Link to={'/index/projectSiteMap'}><Button className="btn btn-warning pull-left m-1">Communities Site Map </Button></Link>

                                                                        </div>
                                                                    </div>
                                                                </CardTitle>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                                        <Table responsive>

                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Code</th>
                                                                                    <th>Communities Name</th>
                                                                                    <th>Communities Title</th>
                                                                                    <th>Communities Image One</th>
                                                                                    <th>Communities Image Two</th>
                                                                                    <th>Site map Image</th>
                                                                                    {/* <th>Remark</th> */}
                                                                                    <th>Priority</th>
                                                                                    <th>Status</th>

                                                                                    <th colspan="2">Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {SubProjectNameListtMap.map((companySetting, index) =>
                                                                                    <tr key={companySetting.id.toString()}>
                                                                                        <td scope="row">{index + 1}</td>
                                                                                        <td>{companySetting.code}</td>
                                                                                        <td>{companySetting.project_name}</td>
                                                                                        <td>{companySetting.project_title}</td>

                                                                                        <td>
                                                                                            {
                                                                                                companySetting.project_cover_image_1 != null ?
                                                                                                    (
                                                                                                        <img src={imageLink + 'company_settings/aboutus/sub/project_cover_image_1/' + companySetting.project_cover_image_1} alt="" height="50" />
                                                                                                    ) : null
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                companySetting.project_cover_image_2 != null ?
                                                                                                    (
                                                                                                        <img src={imageLink + 'company_settings/aboutus/sub/project_cover_image_2/' + companySetting.project_cover_image_2} alt="" height="50" />
                                                                                                    ) : null
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                companySetting.site_map_image != null ?
                                                                                                    (
                                                                                                        <img src={imageLink + 'company_settings/aboutus/sub/site_map_image/' + companySetting.site_map_image} alt="" height="50" />
                                                                                                    ) : null
                                                                                            }
                                                                                        </td>
                                                                                        {/* <td>{companySetting.remarks}</td> */}
                                                                                        <td>{companySetting.priority}</td>
                                                                                        <td><Badge color={getBadge(companySetting.status)}>{getStatusValue(companySetting.status)}</Badge></td>

                                                                                        <td>
                                                                                            <i type="button"
                                                                                                className="fa fa-edit  text-warning fa-2x my-2 ml-2"
                                                                                                onClick={() => { this.tog_scroll2(companySetting) }}

                                                                                            ></i>
                                                                                            <i
                                                                                                className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                                                onClick={() => { this.toggleDeleteModal(companySetting) }}
                                                                                            ></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                            {paginator2 &&
                                                                <CardFooter className="p-6">
                                                                    <CustomPagination paginator={paginator2} handlePageClick={this.handlePageClick} />
                                                                </CardFooter>
                                                            }
                                                        </Card>
                                                        <Modal
                                                            isOpen={this.state.modal_scroll}
                                                            toggle={this.tog_scroll}
                                                            scrollable={true}
                                                        >
                                                            <div className="modal-header">
                                                                <h5 className="modal-title mt-0">{code}</h5>
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        this.setState({ modal_scroll: false })
                                                                    }
                                                                    className="close"
                                                                    data-dismiss="modal"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">

                                                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                                                    {this.props.msg && this.props.msg ? (
                                                                        <Alert color="success">
                                                                            Communities Name  Updated Successfully!
                                                                        </Alert>
                                                                    ) : null}
                                                                    <Row>
                                                                        <AvField className="form-control" type="hidden" id="id" name="id" value={id} />
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Slider Header Title')} id="slider_header_title" value={slider_header_title} name="slider_header_title" required />
                                                                            <AvField className="form-control" type="text" label={reqLabel('Slider Title')} id="slider_title" name="slider_title" value={slider_title} required />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Long Descriptiom')} id="long_descriptiom" name="long_descriptiom" value={long_descriptiom} required />
                                                                            <AvField className="form-control" type="text" label={reqLabel('Short Description')} id="short_description" name="short_description" value={short_description} required />
                                                                        </div>
                                                                    </Row>

                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={priority} required />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Remarks')} id="remarks" name="remarks" value={remarks} required />
                                                                        </div>
                                                                    </Row>

                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField type="hidden" value={slider_thumbnail_image} name="slider_thumbnail_image" id="slider_thumbnail_image" />
                                                                            {
                                                                                slider_thumbnail_image != null ?
                                                                                    (
                                                                                        <img src={imageLink + 'company_settings/aboutus/slider_thumbnail_image/' + slider_thumbnail_image} alt="" height="50" />
                                                                                    ) : null
                                                                            }
                                                                            <AvField label={reqLabel('Slider Thumbnail Image')} type="file" className="form-control" name="slider_thumbnail_image_0" onChange={(e, v) => this.handleImageUpload(e, v)} id="slider_thumbnail_image" />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField type="hidden" value={slider_large_image} name="slider_large_image" id="slider_large_image" />
                                                                            {
                                                                                slider_large_image != null ?
                                                                                    (
                                                                                        <img src={imageLink + 'company_settings/aboutus/slider_large_image/' + slider_large_image} alt="" height="50" />
                                                                                    ) : null
                                                                            }

                                                                            <AvField label={reqLabel('large Image')} type="file" className="form-control" name="slider_large_image_0" onChange={(e, v) => this.handleImageUpload_slider_large_image(e, v)} id="slider_large_image" />
                                                                        </div>
                                                                    </Row>
                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField type="hidden" value={slider_medium_image} name="slider_medium_image" id="slider_medium_image" />
                                                                            {
                                                                                slider_medium_image != null ?
                                                                                    (
                                                                                        <img src={imageLink + 'company_settings/aboutus/slider_medium_image/' + slider_medium_image} alt="" height="50" />
                                                                                    ) : null
                                                                            }

                                                                            <AvField label={reqLabel('Medium Image')} type="file" className="form-control" name="slider_medium_image_0" onChange={(e, v) => this.handleImageUpload_slider_medium_image(e, v)} id="slider_medium_image" />
                                                                        </div>
                                                                    </Row>
                                                                    <div className="text-center mt-4">
                                                                        <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                                        <button type="submit" className="btn btn-success save-event">Save</button>
                                                                    </div>
                                                                </AvForm>

                                                            </div>
                                                        </Modal>
                                                    </Col>
                                                </Row>
                                            </TabPane>



                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ProjectName;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps, { editProjectName, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload })(ProjectName));