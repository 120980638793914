import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row,Label, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteLot, editLot, filterLotList } from '../../store/Lot/actions';
import { filterNameList } from '../../store/FilterName/actions';
import { clearSuccess } from '../../store/FilterValue/actions';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import DeleteModal from './DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class LotList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            filterLotList_list: {},
            deleteModal: false,


            supplier: {

            },
            project_cover_image_1: {},
            upcoming_project_image: {},

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchLotList();
        if (localStorage.getItem('last_action') == 'create') {
            localStorage.setItem('last_action', "");

            if (this.props.success != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);
            }
            if (this.props.error != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);

            }
        }

    }

    fetchLotList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        filterLotList(options).then((response) => {
            // console.log("response", response);
            this.setState({
                filterLotList: response.data,
            });
            this.state.filterLotList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }




    //modal
    tog_scroll = (filterLotList) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.filterLotList_list = filterLotList;
        this.removeBodyCss();
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchLotList();
    }

    toggleDeleteModal = (upcoming) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(upcoming);
    };

    onClickDelete = (project) => {
        // console.log("Delete Modal1", project);
        this.setState({ deleteProject: project });
        this.setState({ deleteModal: true });

    };

    handleDeleteSubmit(values) {

        const { deleteProject } = this.state;
        // console.log("Delete Modal22222222", deleteProject);

        if (deleteProject != undefined) {
            deleteLot(deleteProject.id)
                .then((response) => {
                    if (response.success == true) {
                        this.fetchLotList();

                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }



    handleEditSubmit(event, values) {
        this.props.editLot(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchLotList();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchLotList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchLotList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchLotList();
        });
    }

    handleImageUpload = (image) => {

        // console.log('Image Upload', image);
        const formData = { file: image, name: "project_cover_image_1", type: "image/jpeg" };
        this.state.project_cover_image_1["project_cover_image_1"] = formData;
    }
    ////
    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        window.location.reload();
    }

    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
        window.location.reload();
    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let filterLotListInfo = [];
        let LotList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        if (this.state.hasOwnProperty('filterLotList')) {
            filterLotListInfo = this.state.filterLotList.data;
            paginator = {
                'currentPage': this.state.filterLotList.current_page,
                'pagesCount': this.state.filterLotList.last_page,
                'total': this.state.filterLotList.total,
                'to': this.state.filterLotList.to,
                'from': this.state.filterLotList.from,
            }
        }
        let filterNameListInfo = [];
        if (this.state.hasOwnProperty('filterNameList')) {
            filterNameListInfo = this.state.filterNameList.data;
        }

        let singlevoucher = this.state.voucher;

        const { id, code, Buyer_Serial_No_Contract, Buyer_Serial_No_LOI, f_floore_plans_id, f_project_names_id, Block, Lot, Model, SQFT, status,Lots_image_1 ,active_status} = this.state.filterLotList_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        const { deleteModal, message } = this.state;

        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            compressInitial: null,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Settings" breadcrumbItem="Lot List" />
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}

                                onConfirm={() =>
                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }

                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={() => this.handleDeleteSubmit()}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader className="px-0">
                                        {/* <Link to={'/create/Lot'}><Button className="btn btn-success pull-right">Add Lot Value</Button></Link> */}
                                        <Link to="/multiple/create/Lot">
                                            <button className="btn btn-success mr-2"><i className="mdi mdi-plus-circle-outline me-1" />Lot Bulk Insert</button>
                                        </Link>
                                        <Link to="/lot/edit/list">
                                            <button className="btn btn-success mr-2"><i className="mdi mdi-plus-circle-outline me-1" />Lot Bulk Edit</button>
                                        </Link>
                                    </CardHeader>
                                    <CardBody>

                                        <CardTitle>Lot List</CardTitle>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                <Table responsive>

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Code</th>
                                                            <th>Block</th>
                                                            <th>Lot</th>
                                                            <th>Model</th>
                                                            <th>SQFT</th>
                                                            <th>Status</th>
                                                            <th>Active Status</th>
                                                            <th>Phase</th>
                                                            <th colspan="2">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filterLotListInfo && filterLotListInfo.map((filterLot, index) =>
                                                            <tr key={filterLot.id.toString()}>
                                                                <td scope="row">{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                                <td>{filterLot.Code}</td>
                                                                <td>{filterLot.Block}</td>
                                                                <td>{filterLot.Lot}</td>
                                                                <td>{filterLot.Model}</td>
                                                                <td>{filterLot.SQFT}</td>
                                                                <td>{filterLot.Status}</td>
                                                                <td><Badge color={getBadge(filterLot.active_status)}>{getStatusValue(filterLot.active_status)}</Badge></td>

                                                                <td>{filterLot.Phase}</td>
                                                                <td>
                                                                    
                                                                    <i type="button"
                                                                        className="fa fa-edit  text-warning fa-2x my-2 ml-2"
                                                                        onClick={() => { this.tog_scroll(filterLot) }}
                                                                    ></i>
                                                                    <i
                                                                        className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                        onClick={() => { this.toggleDeleteModal(filterLot) }}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                    size="xl"
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">{code}</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} ref={c => (this.form = c)}>
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Lot Updated Successfully!
                                                </Alert>
                                            ) : null}
                                            <Row>
                                                <AvField className="form-control" type="hidden" id="id" name="id" value={id} />
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Buyer Serial No Contract')} id="Buyer_Serial_No_Contract" name="Buyer_Serial_No_Contract" value={Buyer_Serial_No_Contract}  />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Buyer Serial No LOI')} id="Buyer_Serial_No_LOI" name="Buyer_Serial_No_LOI" value={Buyer_Serial_No_LOI}  />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Floor Plan Id')} id="f_floore_plans_id" name="f_floore_plans_id" value={f_floore_plans_id}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Communities Id')} id="f_project_names_id" name="f_project_names_id" value={f_project_names_id} />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Block')} id="Block" name="Block" value={Block} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Lot')} id="Lot" name="Lot" value={Lot} required />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Model')} id="Model" name="Model" value={Model} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('SQFT')} id="SQFT" name="SQFT" value={SQFT} required />
                                                </div>
                                                <AvField className="form-control" type="hidden" id="project_cover_image_1" name="project_cover_image_1" value={this.state.project_cover_image_1 && this.state.project_cover_image_1} />
                                            </Row>
                                            <Row>
                                                            <div className="col-md-6">
                                                                <AvField type="select" label={reqLabel('Active Status')} className="custom-select" name="active_status" value={active_status}   >
                                                                    {/* <option defaultValue>Select Status</option> */}
                                                                    <option value="0" >Inactive</option>
                                                                    <option value="1">Active</option>
                                                                </AvField>
                                                            </div>
                                                        </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <Label>{reqLabel('Image')}</Label><br />
                                                    {
                                                        Lots_image_1 != null ?
                                                        
                                                            (
                                                                <img src={imageLink + 'Lot/Image/Lots_image_1/' + Lots_image_1} alt="" className="w-50 h-50 rounded mt-2 mb-2" style={{objectFit:'contain'}} />
                                                            ) : null
                                                    }
                                                </div>
                                                <div className="col-md-6">
                                                    <Label>Want to change?</Label>
                                                    < ReactImagePickerEditor
                                                        id="slider_large_image_0"
                                                        name="slider_large_image_0"
                                                        type="file"
                                                        config={config2}
                                                        imageSrcProp={initialImage}
                                                        imageChanged={(image) => {
                                                            this.handleImageUpload(image)
                                                        }} />
                                                </div>
                                            </Row>
                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>

                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps, { editLot, clearSuccess })(LotList));