import React, { Component } from "react";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";

// import { CardImg, Card, CardBody, Col, Row,Input, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label, TabContent } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createProjectName, ProjectNameList, ParentProjectNameList, SpecificParentProjectNameList, editProjectName, editSubProjectName, ProjectNameDetails, logo_small_Upload, logo_medium_Upload, logo_large_Upload, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload } from '../../store/ProjectName/actions';
// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";
import { clearSuccess } from '../../store/FilterValue/actions';

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import 'react-image-picker-editor/dist/index.css'
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class SubProjectNameEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            supplier: {

            },
            status: 0,
            SubParentProjectNameList: null,
            change_site_map_image: {},
            change_project_logo: {},
            change_project_cover_image_2: {},
            change_project_cover_image_1: {},

            brand: {

            },


        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state != undefined) {
            this.fetchProjectNameDetails(this.props.location.state.value.id)
            this.fetchParentProjectNameList();

        }

        else {
            let id = window.location.href.split('/').pop();
            this.fetchProjectNameDetails(id);
            this.fetchParentProjectNameList();
        }

    }





    fetchProjectNameDetails = (id) => {


        ProjectNameDetails(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data[0],
                });
                this.state.ProjectNameList = response.data[0];
                // console.log("response.data[0].parent_id",response.data[0].parent_id);
                this.fetchSpecificParentProjectNameList(response.data[0].parent_id);

            } else {
                this.setState({
                    ProjectNameList: {

                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchParentProjectNameList = () => {

        ParentProjectNameList().then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ParentProjectNameList: response.data,
                });
                this.state.ParentProjectNameList = response.data;

            } else {
                this.setState({
                    ParentProjectNameList: {
                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchSpecificParentProjectNameList = (id) => {

        SpecificParentProjectNameList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    SubParentProjectNameList: response.data[0].options[0],
                });
                this.state.SubParentProjectNameList = response.data[0].options[0];
            } else {
                this.setState({
                    SubParentProjectNameList: {
                    },

                });
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        if (values.project_cover_image_1 == undefined) {
            this.setState({
                errorMessage: "Communities Cover Image 1 is required",
            })
        }
        else if (values.project_cover_image_2 == undefined) {
            this.setState({
                errorMessage: "Communities Cover Image 2 is required",
            })
        }

        else if (values.project_logo == undefined) {
            this.setState({
                errorMessage: "Communities Logo is required",
            })
        } else {
            this.props.editSubProjectName(values);
        }


    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const formData = { file: image, name: "change_project_cover_image_1", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_cover_image_1["change_project_cover_image_1"] = formData;

        // console.log("this.setState.project_cover_image_1[0]", this.state.project_cover_image_1["project_cover_image_1"]);

    }

    handleLargeImageUpload = (image) => {

        const formData = { file: image, name: "change_project_cover_image_2", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_cover_image_2["change_project_cover_image_2"] = formData;
    }

    handleMediumImageUpload = (image) => {

        const formData = { file: image, name: "change_site_map_image", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_site_map_image["change_site_map_image"] = formData;
    }

    handleProjectLogoUpload = (image) => {

        const formData = { file: image, name: "change_project_logo", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_logo["change_project_logo"] = formData;
    }

    handleSelectGroup = (SubParentProject) => {
        console.log('SubParentProjectNameList', SubParentProject)

        this.setState({
            SubParentProjectNameList: SubParentProject
        });
        this.state.SubParentProjectNameList = SubParentProject
    }

    toggleTab(tab) {
        if (tab === 2 && (document.getElementById('parent_project_id_value').value == '' || document.getElementById('parent_project_id_value').value == null
            || document.getElementById('parent_project_id_value').value == undefined)) {
            this.setState({
                errorMessage: "Parent Communities Name is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_name').value == '' || document.getElementById('project_name').value == null
            || document.getElementById('project_name').value == undefined)) {
            this.setState({
                errorMessage: "Sub Communities name is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_title').value == '' || document.getElementById('project_title').value == null
            || document.getElementById('project_title').value == undefined)) {
            this.setState({
                errorMessage: "Sub Communities Title is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_short_description').value == '' || document.getElementById('project_short_description').value == null
            || document.getElementById('project_short_description').value == undefined)) {
            this.setState({
                errorMessage: "Sub Communities Short Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_details_description').value == '' || document.getElementById('project_details_description').value == null
            || document.getElementById('project_details_description').value == undefined)) {
            this.setState({
                errorMessage: "Sub Communities Details Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_address').value == '' || document.getElementById('project_address').value == null
            || document.getElementById('project_address').value == undefined)) {
            this.setState({
                errorMessage: "Sub Communities Address is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_phone_no').value == '' || document.getElementById('project_phone_no').value == null
            || document.getElementById('project_phone_no').value == undefined)) {
            this.setState({
                errorMessage: "Sub Communities Phone No is required",
            })
        }
        else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
            || document.getElementById('priority').value == undefined)) {
            this.setState({
                errorMessage: "Priority is required",
            })
        }
        else {
            if (this.state.activeTab !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

    }

    removeMessage() {
        this.setState({ errorMessage: '' });
    }


    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/index/projectName',
            }
        )
    }
    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const { SubParentProjectNameList } = this.state;
        console.log('SubParentProjectNameList2222', SubParentProjectNameList)
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };

        const initialImage = '';


        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Sub Communities Edit" />
                        {this.state.errorMessage && this.state.errorMessage ?
                            <SweetAlert
                                danger
                                title={this.state.errorMessage}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.removeMessage()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.toggleTab(1); }} >
                                                        <span className="step-number mr-2">01</span>
                                                        Sub Communities Name Setup
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => { this.toggleTab(2); }} >
                                                        <span className="step-number mr-2">02</span>
                                                        <span>Image upload</span>
                                                    </NavLink>
                                                </NavItem>

                                            </ul>
                                            {
                                                this.state.ProjectNameList &&
                                                <>
                                                    <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">

                                                        <TabPane tabId={1}>
                                                            <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <AvField className="form-control" type="hidden" id="id" value={this.state.ProjectNameList.id} name="id" required />

                                                                        <AvField type="hidden" name="parent_project_id_value" id="parent_project_id_value" value={SubParentProjectNameList && SubParentProjectNameList.value} required />
                                                                        {/* <Input type="text" /> */}
                                                                        <Label>Parent Communities Name</Label>
                                                                        <Select
                                                                            id="parent_project_id"
                                                                            name="parent_project_id"
                                                                            value={SubParentProjectNameList && SubParentProjectNameList}
                                                                            onChange={this.handleSelectGroup}
                                                                            options={this.state.ParentProjectNameList && this.state.ParentProjectNameList}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                    </div>
                                                                    <br />

                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Communities Name')} id="project_name" value={this.state.ProjectNameList.project_name} name="project_name" required />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Communities Title')} id="project_title" name="project_title" value={this.state.ProjectNameList.project_title} required />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Video Link'} id="vedio_link" name="vedio_link" value={this.state.ProjectNameList.vedio_link} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <AvField className="form-control" type="hidden" label={reqLabel('Short Description')} id="project_short_description" name="project_short_description" value={this.state.project_short_description} />

                                                                        <CKEditor
                                                                            editor={ClassicEditorDescription}
                                                                            data={this.state.ProjectNameList.project_short_description && this.state.ProjectNameList.project_short_description}
                                                                            onReady={editor => {
                                                                                this.setState({
                                                                                    project_short_description: this.state.ProjectNameList.project_short_description && this.state.ProjectNameList.project_short_description,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.setState({
                                                                                    project_short_description: data,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Blur.', editor );
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Focus.', editor );
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <div className="col-md-12">
                                                                        <AvField className="form-control" type="hidden" label={reqLabel('Details Descrptions')} id="project_details_description" name="project_details_description" value={this.state.project_details_description} />

                                                                        <CKEditor
                                                                            editor={ClassicEditorDescription}
                                                                            data={this.state.ProjectNameList.project_details_description && this.state.ProjectNameList.project_details_description}
                                                                            onReady={editor => {
                                                                                this.setState({
                                                                                    project_details_description: this.state.ProjectNameList.project_details_description && this.state.ProjectNameList.project_details_description,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // You can store the "editor" and use when it is needed.
                                                                                // console.log( 'Editor is ready to use!', editor );
                                                                            }}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                this.setState({
                                                                                    project_details_description: data,
                                                                                });
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                            }}
                                                                            onBlur={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Blur.', editor );
                                                                            }}
                                                                            onFocus={(event, editor) => {
                                                                                editor.ui.view.editable.element.style.minHeight = "200px";
                                                                                // console.log( 'Focus.', editor );
                                                                            }}

                                                                        />
                                                                    </div>
                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Contact Information Description')} id="contact_information_description" name="contact_information_description" value={this.state.ProjectNameList.contact_information_description} required />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Area Map Url'} id="area_map_url" name="area_map_url" value={this.state.ProjectNameList.area_map_url} />
                                                                    </div>
                                                                </Row>

                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Communities Address')} id="project_address" value={this.state.ProjectNameList.project_address} name="project_address" required />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Communities Phone No')} id="project_phone_no" value={this.state.ProjectNameList.project_phone_no} name="project_phone_no" required />
                                                                    </div>
                                                                </Row>

                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" value={this.state.ProjectNameList.remarks} />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={this.state.ProjectNameList.priority} required />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                            <div className="col-md-6">
                                                                                <AvField type="select" label={reqLabel('Status')} className="custom-select" name="Status"   value={this.state.ProjectNameList.status}   >
                                                                                    {/* <option defaultValue>Select Status</option> */}
                                                                                    <option value="0" >Inactive</option>
                                                                                    <option value="1">Active</option>
                                                                                </AvField>
                                                                            </div>
                                                                </Row>

                                                                <AvField type="hidden" value={this.state.ProjectNameList.project_cover_image_1} name="project_cover_image_1" id="project_cover_image_1" required />
                                                                <AvField type="hidden" value={this.state.change_project_cover_image_1} name="change_project_cover_image_1" id="change_project_cover_image_1" />

                                                                <AvField type="hidden" value={this.state.ProjectNameList.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" required />
                                                                <AvField type="hidden" value={this.state.change_project_cover_image_2} name="change_project_cover_image_2" id="change_project_cover_image_2" />

                                                                <AvField type="hidden" value={this.state.ProjectNameList.site_map_image} name="site_map_image" id="site_map_image" required />
                                                                <AvField type="hidden" value={this.state.change_site_map_image} name="change_site_map_image" id="change_site_map_image" />

                                                                <AvField type="hidden" value={this.state.ProjectNameList.project_logo} name="project_logo" id="project_logo" required />
                                                                <AvField type="hidden" value={this.state.change_project_logo} name="change_project_logo" id="change_project_logo" />
                                                            </AvForm>

                                                        </TabPane>
                                                        <TabPane tabId={2}>

                                                            <Row>
                                                                <div className="col-md-6">
                                                                    <Label>{reqLabel('Cover Image First')}</Label><br />

                                                                    <div>
                                                                        {
                                                                            this.state.ProjectNameList.project_cover_image_1 != null ?
                                                                                (
                                                                                    <img src={imageLink + 'company_settings/aboutus/sub/project_cover_image_1/' + this.state.ProjectNameList.project_cover_image_1} alt="" className="w-75 h-50 rounded mt-5 mb-2" />
                                                                                ) : null
                                                                        }
                                                                    </div>
                                                                    <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1920px * 1080px</small>

                                                                    < ReactImagePickerEditor
                                                                        id="project_cover_image_1_0"
                                                                        name="project_cover_image_1_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handletHumbnailImageUpload(image)
                                                                        }} />

                                                                </div>
                                                                <div className="col-md-6">
                                                                    <Label>{reqLabel('Cover Image Secound')}</Label><br />

                                                                    <div>
                                                                        {
                                                                            this.state.ProjectNameList.project_cover_image_2 != null ?
                                                                                (
                                                                                    <img src={imageLink + 'company_settings/aboutus/sub/project_cover_image_2/' + this.state.ProjectNameList.project_cover_image_2} alt="" className="w-75 h-50 rounded mt-5 mb-2" />
                                                                                ) : null
                                                                        }
                                                                    </div>
                                                                    <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1920px * 380px</small>

                                                                    < ReactImagePickerEditor
                                                                        id="project_cover_image_2_0"
                                                                        name="project_cover_image_2_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleLargeImageUpload(image)
                                                                        }} />

                                                                </div>
                                                            </Row>

                                                            <Row>
                                                                {/* <div className="col-md-6">
                                                                    <Label>{reqLabel('Site Map Image')}</Label><br />

                                                                    <div>
                                                                        {
                                                                            this.state.ProjectNameList.site_map_image != null ?
                                                                                (
                                                                                    <img src={imageLink + 'company_settings/aboutus/sub/site_map_image/' + this.state.ProjectNameList.site_map_image} alt="" className="w-75 h-50 rounded mt-5 mb-2" />
                                                                                ) : null
                                                                        }
                                                                    </div>
                                                                    < ReactImagePickerEditor
                                                                        id="site_map_image_0"
                                                                        name="site_map_image_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleMediumImageUpload(image)
                                                                        }} />

                                                                </div> */}
                                                                <div className="col-md-6">
                                                                    <Label>{reqLabel('Logo Image')}</Label><br />

                                                                    <div>
                                                                        {
                                                                            this.state.ProjectNameList.project_logo != null ?
                                                                                (
                                                                                    <img src={imageLink + 'company_settings/aboutus/sub/project_logo/' + this.state.ProjectNameList.project_logo} alt="" className="w-75 h-50 rounded mt-5 mb-2" />
                                                                                ) : null
                                                                        }
                                                                    </div>
                                                                    <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 370px * 226px</small>

                                                                    < ReactImagePickerEditor
                                                                        id="project_logo_0"
                                                                        name="project_logo_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleProjectLogoUpload(image)
                                                                        }} />

                                                                </div>
                                                            </Row>
                                                        </TabPane>
                                                        <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                            <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab - 1); }}>Previous</Link></li>
                                                            {
                                                                this.state.activeTab == 2 ?
                                                                    <li className="next">
                                                                        <Button type="submit" form='my-form' color="success">Submit</Button>
                                                                    </li>
                                                                    :
                                                                    <li className={this.state.activeTab === 2 ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab + 1); }}>Next</Link></li>
                                                            }
                                                        </ul>
                                                    </TabContent>
                                                </>
                                            }
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ProjectName;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createProjectName, editProjectName, editSubProjectName, clearSuccess })(SubProjectNameEdit));