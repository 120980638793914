import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createFilterValue, clearSuccess} from '../../store/FilterValue/actions';
import {  filterNameList  } from '../../store/FilterName/actions';
import SweetAlert from 'react-bootstrap-sweetalert';


// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class FilterValueCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            supplier: {

            },
            status: 0,

            upcoming_project_image: {},




            brand: {

            },


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // this.fetchDashboardListList();
        this.fetchFilterList();

        // this.fetchMarchantList();
    }

    fetchFilterList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        filterNameList(options).then((response) => {
            //   console.log(response);
            this.setState({
                filterNameList: response.data,
            });
            this.state.filterNameList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }
    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {


        this.props.createFilterValue(values);

       
        // setTimeout(this.props.history.push('/value/filter/list'), 3000);
        //this.form && this.form.reset();
        // this.fetchsupplierList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handletHumbnailImageUpload = (e) => {
        var id = e.target.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
              console.log("formData", formData);

            this.state.upcoming_project_image[id] = formData;
            //  console.log("this.setState.voucharImage[0]",  this.state.voucharImage[id]);
        }
    }

     clear = () =>{
        localStorage.setItem('last_action',"create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                 pathname: '/value/filter/list',
            }
        )
    }

    clearErr = () =>{
        localStorage.setItem('last_action',"create");
        this.props.clearSuccess(this.props.error);
    }

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        let filterNameListInfo = [];
        if (this.state.hasOwnProperty('filterNameList')) {
            filterNameListInfo = this.state.filterNameList.data;
        }

        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;




        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        // console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }

            console.log( this.props.error )
        

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Filter Value Create" />
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                          {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}

                                onConfirm={() => 
                                    this.clear()
                                
                            }
                            >
                            </SweetAlert> : ''
                        }
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                          
                                           <Row>
                                                <div className="col-md-6">
                                                    <Label>{reqLabel('Filter Name')}</Label><br/>
                                                    <AvField type="select" className="form-control" name="f_filte_list_name_id"  required >
                                                        <option defaultValue>Select Filter Name </option>
                                                        { filterNameListInfo && filterNameListInfo.map((upcoming, index) =>
                                                             <option value={upcoming.id}>{upcoming.filter_name}</option>
                                                          
                                                        )}
                                                    </AvField>
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel(' Value String')} id="filter_value_string" name="filter_value_string" required />
                                                </div>
                                            </Row> 
                                            <Row>
                                               <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel(' Value Number')} id="filter_value_number" name="filter_value_number" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label='Remarks' id="remarks" name="remarks"   />
                                                </div>
                                            </Row>
                                            <Row> 
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority"  required />
                                                </div>
                                                <div className="col-md-6">
                                                    <Label>{reqLabel('Status')}</Label><br/>
                                                    <AvField type="select" className="form-control" name="Status"   >
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                       
                                                    </AvField>
                                                </div>
                                            </Row> 
                                            <div className=" mt-4">
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>


                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createFilterValue , clearSuccess})(FilterValueCreate));