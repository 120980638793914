import React, { Component } from "react";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createBasicCompanySettings, companySettingListList, logo_small_Upload, logo_medium_Upload, logo_large_Upload, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload } from '../../store/BasicCompanySettings/actions';
// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import { clearSuccess } from '../../store/FilterValue/actions';


const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class BasicCompanySettingsCreate extends Component {
    constructor(props) {
        var Tabvalue = localStorage.getItem('basicCompanysetting');
        // console.log(Tabvalue);
        if (Tabvalue == undefined || Tabvalue == null) {
            localStorage.setItem('basicCompanysetting', 1);
        }
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            supplier: {

            },
            activeTab: Tabvalue,
            activeTabProgress: 1,
            progressValue: 20,
            status: 0,

            logo_small: {},
            logo_medium: {},
            logo_large: {},
            about_us_cover_image_1: {},
            our_vision_image: {},
            favicon: {},

            about_us_cover_image_2: {},
            privacy_policy_cover_image_1: {},
            privacy_policy_cover_image_2: {},

            contact_us_cover_image_2: {},
            contact_us_cover_image_1: {},

            companySettingListLists: {
                phone_1: '', id: '', phone_2: '', phone_3: '', email_1: '', email_2: '', email_3: '', logo_small: '', logo_medium: '', logo_large: '', favicon: '', address_1: '', address_2: '', address_3: '', our_vision_title: '', our_vision_short_description: '', our_vision_details_description: '', our_vision_image: '', about_us: '', about_us_cover_image_1: '', about_us_cover_image_2: '', privacy_policy: '', privacy_policy_cover_image_1: '', privacy_policy_cover_image_2: '', contact_us_map_link: '', contact_us_cover_image_1: '', contact_us_cover_image_2: '', f_about_us_info: '', copy_right_info_name: '', copy_right_year: '', dashboard_youtube_link_1: '', dashboard_youtube_link_2: '', dashboard_youtube_link_3: '', dashboard_youtube_link_4: '', dashboard_our_foot_print_map: '', slider_transection_time: '', privacy_policy: '',
            },

            change_logo_small: {},
            change_logo_medium: {},
            change_logo_large: {},
            change_about_us_cover_image_1: {},
            change_our_vision_image: {},
            change_favicon: {},

            change_about_us_cover_image_2: {},
            change_privacy_policy_cover_image_1: {},
            change_privacy_policy_cover_image_2: {},

            change_contact_us_cover_image_2: {},
            change_contact_us_cover_image_1: {},

            imageSrc: '',
            site_map_image: {},

            brand: {

            },


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleTab.bind(this);
        // this.toggleTabProgress.bind(this);
    }

    componentDidMount() {
        this.fetchCompanySettingList();
        var Tabvalue = localStorage.getItem('basicCompanysetting');
        if (Tabvalue == undefined || Tabvalue == null) {
            localStorage.setItem('basicCompanysetting', 1);
        } else {
            this.state.activeTab = Tabvalue * 1;
        }

        this.toggleTab(localStorage.getItem('basicCompanysetting'));
        console.log('Toggling basicCompanysetting', localStorage.getItem('basicCompanysetting'));

    }

    fetchCompanySettingList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        companySettingListList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    companySettingListLists: response.data.data[0],
                });
                this.state.companySettingListLists = response.data.data[0];

                // console.log("Setting companySettingListLists: " + response.data.data[0]);
            } else {
                this.setState({
                    companySettingListLists: {

                    },

                });
                this.state.companySettingListLists['phone_1'] = '';
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        console.log("handleValid", values);

        let onConfirm = 0;

        if (values.logo_large != undefined && values.logo_medium != undefined && values.logo_small != undefined && values.favicon != undefined) {
            if (values.logo_large.logo_large == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.logo_small == null)) {
                this.setState({
                    error: "select logo large image",
                });
            } else if (values.logo_medium.logo_medium == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.logo_medium == null)) {
                this.setState({
                    error: "select logo medium image",
                });
            } else if (values.logo_small.logo_small == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.logo_small == null)) {
                this.setState({
                    error: "select logo small image",
                });
            } else if (values.favicon.favicon == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.favicon == null)) {
                this.setState({
                    error: "select favicon image",
                });
            } else {
                onConfirm = 1;
            }
        }

        if (values.contact_us_cover_image_1 != undefined && values.contact_us_cover_image_2 != undefined) {
            if (values.contact_us_cover_image_1.contact_us_cover_image_1 == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.contact_us_cover_image_1 == null)) {
                this.setState({
                    error: "select contact us cover image 1",
                });
            } else if (values.contact_us_cover_image_2.contact_us_cover_image_2 == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.contact_us_cover_image_2 == null)) {
                this.setState({
                    error: "select contact us cover image 2",
                });
            } else {
                onConfirm = 1;
            }
        }

        if (values.about_us_cover_image_1 != undefined && values.about_us_cover_image_2 != undefined) {
            if (values.about_us_cover_image_1.about_us_cover_image_1 == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.about_us_cover_image_1 == null)) {
                this.setState({
                    error: "select about us cover image 1",
                });
            } else if (values.about_us_cover_image_2.about_us_cover_image_2 == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.about_us_cover_image_2 == null)) {
                this.setState({
                    error: "select about us cover image 2",
                });
            } else {
                onConfirm = 1;
            }
        }

        if (values.our_vision_image != undefined) {
            if (values.our_vision_image.our_vision_image == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.our_vision_image == null)) {
                this.setState({
                    error: "select our vision image",
                });
            } else {
                onConfirm = 1;
            }
        }

        if (values.privacy_policy_cover_image_1 != undefined && values.privacy_policy_cover_image_2 != undefined) {
            if (values.privacy_policy_cover_image_1.privacy_policy_cover_image_1 == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.privacy_policy_cover_image_1 == null)) {
                this.setState({
                    error: "select privacy policy cover image 1",
                });
            } else if (values.privacy_policy_cover_image_2.privacy_policy_cover_image_2 == undefined && (this.state.companySettingListLists && this.state.companySettingListLists.privacy_policy_cover_image_2 == null)) {
                this.setState({
                    error: "select privacy policy cover image  2",
                });
            } else {
                onConfirm = 1;
            }
        }

        if (values.dashboard_our_foot_print_map != undefined) {
            onConfirm = 1;
        }

        if (onConfirm == 1) {
            this.props.createBasicCompanySettings(values);
        }

        // this.fetchCompanySettingList();
    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handleImageUpload = (image) => {
        console.log('Image Upload', image);
        const formData = { file: image, name: "logo_small" };


        this.state.logo_small["logo_small"] = formData;
    }

    handleImageUpload_edit = (image) => {
        const formData = { file: image, name: "logo_small", type: "image/jpeg" };
        this.state.logo_small["logo_small"] = formData;
    }



    handleImageUpload_logo_medium = (image) => {
        const formData = { file: image, name: "logo_medium", type: "image/jpeg" };

        this.state.logo_medium["logo_medium"] = formData;

    }

    handleImageUpload_logo_medium_edit = (image) => {
        const formData = { file: image, name: "logo_medium", type: "image/jpeg" };

        this.state.logo_medium["logo_medium"] = formData;
    }


    handleImageUpload_logo_large = (image) => {
        const formData = { file: image, name: "logo_large", type: "image/jpeg" };

        this.state.logo_large["logo_large"] = formData;
    }

    handleImageUpload_logo_large_edit = (image) => {
        const formData = { file: image, name: "logo_large", type: "image/jpeg" };

        this.state.logo_large["logo_large"] = formData;
    }

    handleImageUpload_favicon = (image) => {
        const formData = { file: image, name: "favicon", type: "image/jpeg" };

        this.state.favicon["favicon"] = formData;

    }

    handleImageUpload_favicon_edit = (image) => {
        const formData = { file: image, name: "favicon", type: "image/jpeg" };

        this.state.favicon["favicon"] = formData;
    }

    handleImageUpload_our_vision_image = (image) => {
        const formData = { file: image, name: "our_vision_image", type: "image/jpeg" };

        this.state.our_vision_image["our_vision_image"] = formData;

    }

    handleImageUpload_our_vision_image_edit = (image) => {
        const formData = { file: image, name: "our_vision_image", type: "image/jpeg" };

        this.state.our_vision_image["our_vision_image"] = formData;
    }


    handleImageUpload_about_us_cover_image_1 = (image) => {
        const formData = { file: image, name: "about_us_cover_image_1", type: "image/jpeg" };


        this.state.about_us_cover_image_1["about_us_cover_image_1"] = formData;
    }

    handleImageUpload_about_us_cover_image_1_edit = (image) => {
        const formData = { file: image, name: "about_us_cover_image_1", type: "image/jpeg" };


        this.state.about_us_cover_image_1["about_us_cover_image_1"] = formData;
    }



    handleImageUpload_about_us_cover_image_2 = (image) => {
        const formData = { file: image, name: "about_us_cover_image_2", type: "image/jpeg" };


        this.state.about_us_cover_image_2["about_us_cover_image_2"] = formData;
    }

    handleImageUpload_about_us_cover_image_2_edit = (image) => {
        const formData = { file: image, name: "about_us_cover_image_2", type: "image/jpeg" };


        this.state.about_us_cover_image_2["about_us_cover_image_2"] = formData;
    }


    handleImageUpload_privacy_policy_cover_image_1 = (image) => {
        const formData = { file: image, name: "privacy_policy_cover_image_1", type: "image/jpeg" };


        this.state.privacy_policy_cover_image_1["privacy_policy_cover_image_1"] = formData;
    }

    handleImageUpload_privacy_policy_cover_image_1_edit = (image) => {
        const formData = { file: image, name: "privacy_policy_cover_image_1", type: "image/jpeg" };


        this.state.privacy_policy_cover_image_1["privacy_policy_cover_image_1"] = formData;
    }




    handleImageUpload_privacy_policy_cover_image_2 = (image) => {
        const formData = { file: image, name: "privacy_policy_cover_image_2", type: "image/jpeg" };


        this.state.privacy_policy_cover_image_2["privacy_policy_cover_image_2"] = formData;

    }

    handleImageUpload_privacy_policy_cover_image_2_edit = (image) => {
        const formData = { file: image, name: "privacy_policy_cover_image_2", type: "image/jpeg" };


        this.state.privacy_policy_cover_image_2["privacy_policy_cover_image_2"] = formData;
    }




    handleImageUpload_contact_us_cover_image_1 = (image) => {
        const formData = { file: image, name: "contact_us_cover_image_1", type: "image/jpeg" };


        this.state.contact_us_cover_image_1["contact_us_cover_image_1"] = formData;

    }

    handleImageUpload_contact_us_cover_image_1_edit = (image) => {
        const formData = { file: image, name: "contact_us_cover_image_1", type: "image/jpeg" };


        this.state.contact_us_cover_image_1["contact_us_cover_image_1"] = formData;
    }


    handleImageUpload_contact_us_cover_image_2 = (image) => {
        const formData = { file: image, name: "contact_us_cover_image_2", type: "image/jpeg" };


        this.state.contact_us_cover_image_2["contact_us_cover_image_2"] = formData;
    }

    handleImageUpload_contact_us_cover_image_2_edit = (image) => {
        const formData = { file: image, name: "contact_us_cover_image_2", type: "image/jpeg" };


        this.state.contact_us_cover_image_2["contact_us_cover_image_2"] = formData;
    }

    clear = () => {
        console.log(this.props.success);
        this.props.clearSuccess(this.props.success);
        window.location.reload();

    }
    clearErr = () => {
        this.props.clearSuccess(this.props.error);
        window.location.reload();
    }
    toggleTab(tab) {
        console.log('tab', tab);
        localStorage.setItem('basicCompanysetting', tab * 1)
        if (this.state.activeTab !== tab * 1) {
            if (tab >= 1 && tab <= 6) {
                this.setState({
                    activeTab: tab * 1
                });
                this.state.activeTab = tab * 1;
            }
        }
    }

    // toggleTabProgress(tab) {
    //     if (this.state.activeTabProgress !== tab) {
    //         if (tab >= 1 && tab <= 6) {
    //             this.setState({
    //                 activeTabProgress: tab
    //             });

    //             if (tab === 1) { this.setState({ progressValue: 20 }) }
    //             if (tab === 2) { this.setState({ progressValue: 45 }) }
    //             if (tab === 3) { this.setState({ progressValue: 65 }) }
    //             if (tab === 4) { this.setState({ progressValue: 50 }) }
    //             if (tab === 5) { this.setState({ progressValue: 85 }) }
    //             if (tab === 6) { this.setState({ progressValue: 100 }) }
    //         }
    //     }
    // }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '50%',
            height: '50%',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;




        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        // console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Basic Company Settings Create" />
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.error && this.state.error ?
                            <SweetAlert
                                danger
                                title={this.state.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.success && this.state.success ?
                            <SweetAlert
                                title={this.state.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {/* <h4 className="card-title mb-4">Basic pills Wizard</h4> */}

                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab == 1 })} onClick={() => { this.toggleTab(1); }} >
                                                        <span className="step-number mr-2">01</span>
                                                        Logo Setup
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab == 2 })} onClick={() => { this.toggleTab(2); }} >
                                                        <span className="step-number mr-2">02</span>
                                                        <span>Home Page Links</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab == 3 })} onClick={() => { this.toggleTab(3); }} >
                                                        <span className="step-number mr-2">03</span>
                                                        Contact
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab == 4 })} onClick={() => { this.toggleTab(4); }} >
                                                        <span className="step-number mr-2">04</span>
                                                        About Us
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab == 5 })} onClick={() => { this.toggleTab(5); }} >
                                                        <span className="step-number mr-2">05</span>
                                                        Home Our Vision
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab == 6 })} onClick={() => { this.toggleTab(6); }} >
                                                        <span className="step-number mr-2">06</span>
                                                        Privacy Policy
                                                    </NavLink>
                                                </NavItem>
                                            </ul>
                                            <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">

                                                <TabPane tabId={1}>
                                                    <AvForm className="form-horizontal" id='my-form-1' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                                        <AvField type="hidden" value={this.state.logo_small != null ? this.state.logo_small : ''} name="logo_small" id="logo_small" />
                                                        <AvField type="hidden" value={this.state.change_logo_small} name="change_logo_small" id="change_logo_small" />
                                                        <AvField type="hidden" value={this.state.logo_medium != null ? this.state.logo_medium : ''} name="logo_medium" id="logo_medium" />
                                                        <AvField type="hidden" value={this.state.change_logo_medium} name="change_logo_medium" id="change_logo_medium" />
                                                        <AvField type="hidden" value={this.state.logo_large != null ? this.state.logo_large : ''} name="logo_large" id="logo_large" />
                                                        <AvField type="hidden" value={this.state.change_logo_large} name="change_logo_large" id="change_logo_large" />
                                                        <AvField type="hidden" value={this.state.favicon != null ? this.state.favicon : ''} name="favicon" id="favicon" />
                                                        <AvField type="hidden" value={this.state.change_favicon} name="change_favicon" id="change_favicon" />
                                                    </AvForm>


                                                    <Row>
                                                        <div className="col-md-3">
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('Small Logo')}</Label><br />
                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.logo_small != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/logo_small/' + this.state.companySettingListLists.logo_small} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>
                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.logo_small != null ?
                                                                    // <AvField  type="file" className="form-control" name="logo_small_0" onChange={(e) => this.handleImageUpload_edit(e)} id="logo_small" />
                                                                    <>
                                                                        <br />
                                                                        <Label>Want to change?</Label>
                                                                      
                                                                      

                                                                        < ReactImagePickerEditor
                                                                            id="logo_small_0"
                                                                            name="logo_small_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_edit(image)
                                                                            }}
                                                                        />
                                                                        <br />
                                                                    </>
                                                                    :
                                                                    // <AvField type="file" className="form-control" name="logo_small_0" onChange={(e) => this.handleImageUpload(e)} id="logo_small"  required />
                                                                    < ReactImagePickerEditor
                                                                        id="logo_small_0"
                                                                        name="logo_small_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload(image)
                                                                        }}
                                                                    />

                                                            }

                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="col-md-12">

                                                                <Label>{reqLabel('Medium Logo')}</Label><br />
                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.logo_medium != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/logo_medium/' + this.state.companySettingListLists.logo_medium} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>
                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.logo_medium != null ?
                                                                    // <AvField  type="file" className="form-control" name="logo_medium_0" onChange={(e) => this.handleImageUpload_logo_medium_edit(e)} id="logo_medium" />
                                                                    <>
                                                                        <br /><Label>Want to change?</Label>

                                                                        < ReactImagePickerEditor
                                                                            id="logo_medium_0"
                                                                            name="logo_medium_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_logo_medium_edit(image)
                                                                            }}
                                                                        />
                                                                        <br />

                                                                    </>
                                                                    :
                                                                    // <AvField  type="file" className="form-control" name="logo_medium_0" onChange={(e) => this.handleImageUpload_logo_medium(e)} id="logo_medium"  required />
                                                                    < ReactImagePickerEditor
                                                                        id="logo_medium_0"
                                                                        name="logo_medium_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload_logo_medium(image)
                                                                        }}
                                                                    />
                                                            }

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('Large Logo')}</Label><br />

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.logo_large != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/logo_large/' + this.state.companySettingListLists.logo_large} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>

                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.logo_large != null ?
                                                                    // <AvField  type="file" className="form-control" name="logo_large_0" onChange={(e) => this.handleImageUpload_logo_large_edit(e)} id="logo_large" />
                                                                    <>
                                                                        <br /><Label>Want to change?</Label>

                                                                        < ReactImagePickerEditor
                                                                            id="logo_large_0"
                                                                            name="logo_large_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_logo_large_edit(image)
                                                                            }}
                                                                        />
                                                                        <br />

                                                                    </>
                                                                    :
                                                                    // <AvField  type="file" className="form-control" name="logo_large_0" onChange={(e) => this.handleImageUpload_logo_large(e)} id="logo_large" required />
                                                                    < ReactImagePickerEditor
                                                                        id="logo_large_0"
                                                                        name="logo_large_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload_logo_large(image)
                                                                        }}
                                                                    />
                                                            }

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('Favicon')}</Label><br />

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.favicon != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/favicon/' + this.state.companySettingListLists.favicon} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>

                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.favicon != null ?
                                                                    // <AvField  type="file" className="form-control" name="favicon_0" onChange={(e) => this.handleImageUpload_favicon_edit(e)} id="favicon" />
                                                                    <>
                                                                        <br /><Label>Want to change?</Label>

                                                                        < ReactImagePickerEditor
                                                                            id="favicon_0"
                                                                            name="favicon_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_favicon_edit(image)
                                                                            }}
                                                                        />
                                                                        <br />

                                                                    </>
                                                                    :
                                                                    // <AvField  type="file" className="form-control" name="favicon_0" onChange={(e) => this.handleImageUpload_favicon(e)} id="favicon" required />
                                                                    < ReactImagePickerEditor
                                                                        id="favicon_0"
                                                                        name="favicon_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload_favicon(image)
                                                                        }}
                                                                    />
                                                            }

                                                        </div>
                                                    </Row>


                                                    <div className="col-md-12" align="right">
                                                        <Button type="submit" form='my-form-1' color="success" align="right">Save Data</Button>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <div>
                                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                            <Row>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label="Youtube Link " type="text" name="dashboard_youtube_link_1" id="dashboard_youtube_link_1" value={this.state.companySettingListLists && this.state.companySettingListLists.dashboard_youtube_link_1} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label=" Facebook Link " type="text" name="dashboard_youtube_link_2" id="dashboard_youtube_link_2" value={this.state.companySettingListLists && this.state.companySettingListLists.dashboard_youtube_link_2} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label="Instagram Link" type="text" name="dashboard_youtube_link_3" id="dashboard_youtube_link_3" value={this.state.companySettingListLists && this.state.companySettingListLists.dashboard_youtube_link_3} />
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label="Twitter Link" type="text" name="dashboard_youtube_link_4" id="dashboard_youtube_link_4" value={this.state.companySettingListLists && this.state.companySettingListLists.dashboard_youtube_link_4} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label={reqLabel("Slider Transection Time (ms)")} type="number" name="slider_transection_time" id="slider_transection_time" value={this.state.companySettingListLists && this.state.companySettingListLists.slider_transection_time != null ? this.state.companySettingListLists.slider_transection_time : 7000} required />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label={reqLabel("Our Foot Print Map")} type="text" name="dashboard_our_foot_print_map" id="our_foot_print_map" value={this.state.companySettingListLists && this.state.companySettingListLists.dashboard_our_foot_print_map} required />
                                                                </div>
                                                                <div className="col-md-12" align="right">
                                                                    <Button type="submit" color="success" align="right">Save Data</Button>
                                                                </div>
                                                            </Row>
                                                        </AvForm>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <div>
                                                        <AvForm className="form-horizontal" id='my-form-3' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                                            <Row>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" type="text" label={reqLabel('Phone 1')} id="phone_1" name="phone_1" value={this.state.companySettingListLists && this.state.companySettingListLists.phone_1} required />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" value={this.state.companySettingListLists && this.state.companySettingListLists.phone_2} label="Phone 2" type="text" name="phone_2" id="phone_2" />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" value={this.state.companySettingListLists && this.state.companySettingListLists.phone_3} label="Phone 3" type="text" name="phone_3" id="phone_3" />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" type="text" label={reqLabel('Email 1')} id="email_1" name="email_1" value={this.state.companySettingListLists && this.state.companySettingListLists.email_1} required />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label="Email 2" type="text" name="email_2" id="email_2" value={this.state.companySettingListLists && this.state.companySettingListLists.email_2} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label="Email 3" type="text" name="email_3" id="email_3" value={this.state.companySettingListLists && this.state.companySettingListLists.email_3} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" type="text" label={reqLabel('Address 1')} id="address_1" name="address_1" value={this.state.companySettingListLists && this.state.companySettingListLists.address_1} required />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label="Address 2" type="text" name="address_2" id="address_2" value={this.state.companySettingListLists && this.state.companySettingListLists.address_2} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <AvField className="form-control" label="Address 3" type="text" name="address_3" id="address_3" value={this.state.companySettingListLists && this.state.companySettingListLists.address_3} />
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <AvField className="form-control" label={reqLabel('Contact Us Map Link')} type="text" name="contact_us_map_link" id="contact_us_map_link" value={this.state.companySettingListLists && this.state.companySettingListLists.contact_us_map_link} required />
                                                                </div>
                                                            </Row>

                                                            <AvField type="hidden" value={this.state.contact_us_cover_image_2 != null ? this.state.contact_us_cover_image_2 : ''} name="contact_us_cover_image_2" id="contact_us_cover_image_1" />
                                                            <AvField type="hidden" value={this.state.change_contact_us_cover_image_2} name="change_contact_us_cover_image_2" id="change_contact_us_cover_image_2" />
                                                            <AvField type="hidden" value={this.state.contact_us_cover_image_1 != null ? this.state.contact_us_cover_image_1 : ''} name="contact_us_cover_image_1" id="contact_us_cover_image_1" />
                                                            <AvField type="hidden" value={this.state.change_contact_us_cover_image_1} name="change_contact_us_cover_image_1" id="change_contact_us_cover_image_1" />

                                                        </AvForm>

                                                        <Row>

                                                            <div className="col-md-6">
                                                                <div className="col-md-12">
                                                                    <Label>{reqLabel('Contact Us Cover Image 1')}</Label><br />

                                                                    {
                                                                        this.state.companySettingListLists && this.state.companySettingListLists.contact_us_cover_image_1 != null ?
                                                                            (
                                                                                <img src={imageLink + 'company_settings/contact_us_cover_image_1/' + this.state.companySettingListLists.contact_us_cover_image_1} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                            ) : null
                                                                    }
                                                                </div>

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.contact_us_cover_image_1 != null ?
                                                                        <>
                                                                            <br /><Label>Want to change?</Label>
                                                                            <br/>
                                                                            <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 1080px</small>

                                                                            < ReactImagePickerEditor
                                                                                id="contact_us_cover_image_1_0"
                                                                                name="contact_us_cover_image_1_0"
                                                                                type="file"
                                                                                config={config2}
                                                                                imageSrcProp={initialImage}
                                                                                imageChanged={(image) => {
                                                                                    this.handleImageUpload_contact_us_cover_image_1_edit(image)
                                                                                }}
                                                                            />
                                                                        </>
                                                                        :
                                                                        < ReactImagePickerEditor
                                                                            id="contact_us_cover_image_1_0"
                                                                            name="contact_us_cover_image_1_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_contact_us_cover_image_1(image)
                                                                            }}
                                                                        />
                                                                }
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="col-md-12">
                                                                    <Label>{reqLabel('Contact Us Cover Image 2')}</Label><br />

                                                                    {
                                                                        this.state.companySettingListLists && this.state.companySettingListLists.contact_us_cover_image_2 != null ?
                                                                            (
                                                                                <img src={imageLink + 'company_settings/contact_us_cover_image_2/' + this.state.companySettingListLists.contact_us_cover_image_2} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                            ) : null
                                                                    }
                                                                </div>

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.contact_us_cover_image_2 != null ?
                                                                        //  <AvField  type="file" className="form-control" name="contact_us_cover_image_2_0" onChange={(e) => this.handleImageUpload_contact_us_cover_image_2_edit(e)} id="contact_us_cover_image_2" />
                                                                        <>
                                                                            <br /><Label>Want to change?</Label>
                                                                            <br/>
                                                                            <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 380px</small>

                                                                            < ReactImagePickerEditor
                                                                                id="contact_us_cover_image_2_0"
                                                                                name="contact_us_cover_image_2_0"
                                                                                type="file"
                                                                                config={config2}
                                                                                imageSrcProp={initialImage}
                                                                                imageChanged={(image) => {
                                                                                    this.handleImageUpload_contact_us_cover_image_2_edit(image)
                                                                                }}
                                                                            />
                                                                        </>
                                                                        :
                                                                        // <AvField  type="file" className="form-control" name="contact_us_cover_image_2_0" onChange={(e) => this.handleImageUpload_contact_us_cover_image_2(e)} id="contact_us_cover_image_2" required />
                                                                        < ReactImagePickerEditor
                                                                            id="contact_us_cover_image_2_0"
                                                                            name="contact_us_cover_image_2_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_contact_us_cover_image_2(image)
                                                                            }}
                                                                        />
                                                                }
                                                            </div>

                                                            <div className="col-md-12" align="right">
                                                                <Button type="submit" form='my-form-3' color="success" align="right">Save Data</Button>
                                                            </div>
                                                        </Row>


                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <AvForm className="form-horizontal" id='my-form-4' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                        <Row>
                                                            <div className="col-md-12">
                                                                <h4>About Section</h4>
                                                                <hr></hr>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" label={reqLabel('About Us Info')} type="text" name="f_about_us_info" id="f_about_us_info" value={this.state.companySettingListLists && this.state.companySettingListLists.f_about_us_info} required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Label>{reqLabel('About Us')}</Label>

                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={this.state.companySettingListLists && this.state.companySettingListLists.about_us != null ? this.state.companySettingListLists.about_us : ''}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            about_us: this.state.companySettingListLists && this.state.companySettingListLists.about_us,
                                                                        });
                                                                        // You can store the "editor" and use when it is needed.
                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            about_us: data,
                                                                        });
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        // console.log( 'Focus.', editor );
                                                                    }}

                                                                />
                                                                <AvField name="about_us" value={this.state.about_us} className="form-control" type="hidden" />
                                                            </div>
                                                            <AvField type="hidden" value={this.state.about_us_cover_image_2 != null ? this.state.about_us_cover_image_2 : ''} name="about_us_cover_image_2" id="about_us_cover_image_2" />
                                                            <AvField type="hidden" value={this.state.change_about_us_cover_image_2} name="change_about_us_cover_image_2" id="change_about_us_cover_image_2" />

                                                            <AvField type="hidden" value={this.state.about_us_cover_image_1 != null ? this.state.about_us_cover_image_1 : ''} name="about_us_cover_image_1" id="about_us_cover_image_1" />
                                                            <AvField type="hidden" value={this.state.change_about_us_cover_image_1} name="change_about_us_cover_image_1" id="change_about_us_cover_image_1" />

                                                        </Row>
                                                    </AvForm>
                                                    <Row>
                                                        <div className="col-md-6">
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('About Us Cover Image 1')}</Label><br />

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.about_us_cover_image_1 != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/about_us_cover_image_1/' + this.state.companySettingListLists.about_us_cover_image_1} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>

                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.about_us_cover_image_1 != null ?
                                                                    //  <AvField type="file" className="form-control" name="about_us_cover_image_1_0" onChange={(e) => this.handleImageUpload_about_us_cover_image_1_edit(e)} id="about_us_cover_image_1" />
                                                                    <>
                                                                        <br /><Label>Want to change?</Label>
                                                                        <br/>
                                                                        <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 1080px</small>

                                                                        < ReactImagePickerEditor
                                                                            id="about_us_cover_image_1_0"
                                                                            name="about_us_cover_image_1_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_about_us_cover_image_1_edit(image)
                                                                            }}
                                                                        />
                                                                    </>
                                                                    :
                                                                    // <AvField  type="file" className="form-control" name="about_us_cover_image_1_0" onChange={(e) => this.handleImageUpload_about_us_cover_image_1(e)} id="about_us_cover_image_1" required />
                                                                    < ReactImagePickerEditor
                                                                        id="about_us_cover_image_1_0"
                                                                        name="about_us_cover_image_1_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload_about_us_cover_image_1(image)
                                                                        }}
                                                                    />
                                                            }


                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('About Us Cover Image 2')}</Label><br />

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.about_us_cover_image_2 != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/about_us_cover_image_2/' + this.state.companySettingListLists.about_us_cover_image_2} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>

                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.about_us_cover_image_2 != null ?
                                                                    // <AvField  type="file" className="form-control" name="about_us_cover_image_2_0" onChange={(e) => this.handleImageUpload_about_us_cover_image_2_edit(e)} id="about_us_cover_image_2" />
                                                                    <>
                                                                        <br /><Label>Want to change?</Label>
                                                                        <br/>
                                                                        <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 380px</small>

                                                                        < ReactImagePickerEditor
                                                                            id="about_us_cover_image_2_0"
                                                                            name="about_us_cover_image_2_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_about_us_cover_image_2_edit(image)
                                                                            }}
                                                                        />
                                                                    </>
                                                                    :
                                                                    // <AvField  type="file" className="form-control" name="about_us_cover_image_2_0" onChange={(e) => this.handleImageUpload_about_us_cover_image_2(e)} id="about_us_cover_image_2" required />
                                                                    < ReactImagePickerEditor
                                                                        id="about_us_cover_image_2_0"
                                                                        name="about_us_cover_image_2_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload_about_us_cover_image_2(image)
                                                                        }}
                                                                    />
                                                            }

                                                        </div>





                                                        <div className="col-md-12" align="right">
                                                            <Button type="submit" form='my-form-4' color="success" align="right">Save Data</Button>
                                                        </div>
                                                    </Row>

                                                </TabPane>
                                                <TabPane tabId={5}>
                                                    <AvForm className="form-horizontal" id='my-form-5' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>


                                                        <Row>
                                                            <div className="col-md-12">
                                                                <h4>Our Vision</h4>
                                                                <hr></hr>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <AvField className="form-control" type="text" label={reqLabel('Our Vision Title')} id="our_vision_title" name="our_vision_title" value={this.state.companySettingListLists && this.state.companySettingListLists.our_vision_title} required />
                                                            </div>


                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <label>{reqLabel('Our Vision Short Description')}</label>
                                                                    <AvField name="our_vision_short_description" value={this.state.our_vision_short_description} className="form-control" type="hidden" />
                                                                    <CKEditor
                                                                        editor={ClassicEditorDescription}
                                                                        data={this.state.companySettingListLists && this.state.companySettingListLists.our_vision_short_description != null ? this.state.companySettingListLists.our_vision_short_description : ""}
                                                                        onReady={editor => {
                                                                            this.setState({
                                                                                our_vision_short_description: this.state.companySettingListLists && this.state.companySettingListLists.our_vision_short_description,
                                                                            });
                                                                            // You can store the "editor" and use when it is needed.
                                                                            // console.log( 'Editor is ready to use!', editor );
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            this.setState({
                                                                                our_vision_short_description: data,
                                                                            });
                                                                        }}
                                                                        onBlur={(event, editor) => {
                                                                            // console.log( 'Blur.', editor );
                                                                        }}
                                                                        onFocus={(event, editor) => {
                                                                            // console.log( 'Focus.', editor );
                                                                        }}

                                                                    />
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label>{reqLabel('Our Vision Details Description')}</Label>
                                                                    <AvField name="our_vision_details_description" value={this.state.our_vision_details_description} className="form-control" type="hidden" />
                                                                    <CKEditor
                                                                        editor={ClassicEditorDescription}
                                                                        data={this.state.companySettingListLists && this.state.companySettingListLists.our_vision_details_description != null ? this.state.companySettingListLists.our_vision_details_description : ""}
                                                                        onReady={editor => {
                                                                            this.setState({
                                                                                our_vision_details_description: this.state.companySettingListLists && this.state.companySettingListLists.our_vision_details_description,
                                                                            });
                                                                            // You can store the "editor" and use when it is needed.
                                                                            // console.log( 'Editor is ready to use!', editor );
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            this.setState({
                                                                                our_vision_details_description: data,
                                                                            });
                                                                        }}
                                                                        onBlur={(event, editor) => {
                                                                            // console.log( 'Blur.', editor );
                                                                        }}
                                                                        onFocus={(event, editor) => {
                                                                            // console.log( 'Focus.', editor );
                                                                        }}


                                                                    />
                                                                </FormGroup>
                                                            </div>
                                                        </Row>
                                                        <AvField type="hidden" value={this.state.our_vision_image != null ? this.state.our_vision_image : ''} name="our_vision_image" id="our_vision_image" />
                                                        <AvField type="hidden" value={this.state.change_our_vision_image} name="change_our_vision_image" id="change_our_vision_image" />


                                                    </AvForm>

                                                    <Row>
                                                        <div className="col-md-6">
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('Our Vision Image')}</Label><br />

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.our_vision_image != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/our_vision_image/' + this.state.companySettingListLists.our_vision_image} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>

                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.our_vision_image != null ?
                                                                    //  <AvField  type="file" className="form-control" name="lour_vision_image_0" onChange={(e) => this.handleImageUpload_our_vision_image_edit(e)} id="our_vision_image" />
                                                                    <>
                                                                        <br /><Label>Want to change?</Label>
                                                                        <br/>
                                                                        <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 1080px</small>

                                                                        < ReactImagePickerEditor
                                                                            id="lour_vision_image_0"
                                                                            name="lour_vision_image_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_our_vision_image_edit(image)
                                                                            }}
                                                                        />
                                                                    </>
                                                                    :
                                                                    // <AvField  type="file" className="form-control" name="our_vision_image_0" onChange={(e) => this.handleImageUpload_our_vision_image(e)} id="our_vision_image" required />
                                                                    < ReactImagePickerEditor
                                                                        id="our_vision_image_0"
                                                                        name="our_vision_image_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload_our_vision_image(image)
                                                                        }}
                                                                    />
                                                            }

                                                        </div>

                                                        <div className="col-md-12" align="right">
                                                            <Button type="submit" form='my-form-5' color="success" align="right">Save Data</Button>
                                                        </div>
                                                    </Row>


                                                </TabPane>
                                                <TabPane tabId={6}>
                                                    <AvForm className="form-horizontal" id='my-form-6' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                        <Row>
                                                            <div className="col-md-12">
                                                                <h4>Privacy Policy</h4>
                                                                <hr></hr>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('Privacy Policy')}</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={this.state.companySettingListLists && this.state.companySettingListLists.privacy_policy != null ? this.state.companySettingListLists.privacy_policy : ""}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            privacy_policy: this.state.companySettingListLists && this.state.companySettingListLists.privacy_policy,
                                                                        });
                                                                        // You can store the "editor" and use when it is needed.
                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            privacy_policy: data,
                                                                        });
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        // console.log( 'Focus.', editor );
                                                                    }}


                                                                />
                                                                <AvField className="form-control" type="hidden" name="privacy_policy" id="privacy_policy" value={this.state.privacy_policy} required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" label={reqLabel("Copy Right Year")} type="text" name="copy_right_year" id="copy_right_year" value={this.state.companySettingListLists && this.state.companySettingListLists.copy_right_year} required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" label={reqLabel("Copy Right Info Name")} type="text" name="copy_right_info_name" id="copy_right_info_name" value={this.state.companySettingListLists && this.state.companySettingListLists.copy_right_info_name} required />
                                                            </div>
                                                        </Row>
                                                        <AvField type="hidden" value={this.state.privacy_policy_cover_image_2 != null ? this.state.privacy_policy_cover_image_2 : ''} name="privacy_policy_cover_image_2" id="privacy_policy_cover_image_2" />
                                                        <AvField type="hidden" value={this.state.change_privacy_policy_cover_image_2} name="change_privacy_policy_cover_image_2" id="change_privacy_policy_cover_image_2" />
                                                        <AvField type="hidden" value={this.state.privacy_policy_cover_image_1 != null ? this.state.privacy_policy_cover_image_1 : ''} name="privacy_policy_cover_image_1" id="privacy_policy_cover_image_1" />
                                                        <AvField type="hidden" value={this.state.change_privacy_policy_cover_image_1} name="change_privacy_policy_cover_image_1" id="change_privacy_policy_cover_image_1" />


                                                    </AvForm>

                                                    <Row>
                                                        <div className="col-md-6">
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('Privacy Policy Cover Image 1')}</Label><br />

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.privacy_policy_cover_image_1 != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/privacy_policy_cover_image_1/' + this.state.companySettingListLists.privacy_policy_cover_image_1} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>

                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.privacy_policy_cover_image_1 != null ?
                                                                    // <AvField type="file" className="form-control" name="privacy_policy_cover_image_1_0" onChange={(e) => this.handleImageUpload_privacy_policy_cover_image_1_edit(e)} id="privacy_policy_cover_image_1" />
                                                                    <>
                                                                        <br /><Label>Want to change?</Label>
                                                                        <br/>
                                                                        <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 1080px</small>
                                                               
                                                                        < ReactImagePickerEditor
                                                                            id="privacy_policy_cover_image_1_0"
                                                                            name="privacy_policy_cover_image_1_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_privacy_policy_cover_image_1_edit(image)
                                                                            }}
                                                                        />
                                                                    </>
                                                                    :
                                                                    //  <AvField  type="file" className="form-control" name="privacy_policy_cover_image_1_0" onChange={(e) => this.handleImageUpload_privacy_policy_cover_image_1(e)} id="privacy_policy_cover_image_1" required />
                                                                    < ReactImagePickerEditor
                                                                        id="privacy_policy_cover_image_1_0"
                                                                        name="privacy_policy_cover_image_1_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload_privacy_policy_cover_image_1(image)
                                                                        }}
                                                                    />
                                                            }
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="col-md-12">
                                                                <Label>{reqLabel('Privacy Policy Cover Image 2')}</Label><br />

                                                                {
                                                                    this.state.companySettingListLists && this.state.companySettingListLists.privacy_policy_cover_image_2 != null ?
                                                                        (
                                                                            <img src={imageLink + 'company_settings/privacy_policy_cover_image_2/' + this.state.companySettingListLists.privacy_policy_cover_image_2} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                        ) : null
                                                                }
                                                            </div>

                                                            {
                                                                this.state.companySettingListLists && this.state.companySettingListLists.privacy_policy_cover_image_2 != null ?
                                                                    // <AvField  type="file" className="form-control" name="privacy_policy_cover_image_2_0" onChange={(e) => this.handleImageUpload_privacy_policy_cover_image_2_edit(e)} id="privacy_policy_cover_image_2" />
                                                                    <>
                                                                        <br /><Label>Want to change?</Label>
                                                                        <br/>
                                                                        <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 380px</small>
                                                               
                                                                        < ReactImagePickerEditor
                                                                            id="privacy_policy_cover_image_2_0"
                                                                            name="privacy_policy_cover_image_2_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleImageUpload_privacy_policy_cover_image_2_edit(image)
                                                                            }}
                                                                        />
                                                                    </>
                                                                    :
                                                                    // <AvField  type="file" className="form-control" name="privacy_policy_cover_image_2_0" onChange={(e) => this.handleImageUpload_privacy_policy_cover_image_2(e)} id="privacy_policy_cover_image_2" required />
                                                                    < ReactImagePickerEditor
                                                                        id="privacy_policy_cover_image_2_0"
                                                                        name="privacy_policy_cover_image_2_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleImageUpload_privacy_policy_cover_image_2(image)
                                                                        }}
                                                                    />
                                                            }
                                                        </div>

                                                    </Row>
                                                    <div className="col-md-12" align="right">
                                                        <Button type="submit" form='my-form-6' color="success" align="right">Save Data</Button>
                                                    </div>


                                                </TabPane>
                                            </TabContent>


                                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                {
                                                    this.state.activeTab == 1 ?
                                                        ""
                                                        :
                                                        <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab * 1 - 1); }}>Previous</Link></li>

                                                }

                                                {
                                                    this.state.activeTab == 6 ?
                                                        ""
                                                        :
                                                        <li className={this.state.activeTab === 6 ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab * 1 + 1); }}>Next</Link></li>

                                                }
                                            </ul>
                                            {/* <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab*1 - 1); }}>Previous</Link></li>
                                                <li className={this.state.activeTab === 6 ? "disabled" : "next"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab*1 + 1); }}>Next</Link></li>
                                            </ul> */}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.BasicCompanySettings;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createBasicCompanySettings, clearSuccess, logo_small_Upload, logo_medium_Upload, logo_large_Upload, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload })(BasicCompanySettingsCreate));