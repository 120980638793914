import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CREATE_FilterValue, EDIT_FilterValue, CLEAR_MESSAGE } from './actionTypes';

import { brandSuccess, AboutUsPageSliderError, brandShow, merchantError, AboutUsPageSliderSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData, editJwtData } from '../../helpers/self_accounts_settings_helper';

function* CREATE_FilterValues({ payload: { FilterValue } }) {
    try {
        console.log("CREATE_FilterValues", FilterValue);
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(postJwtData, 'filterValue/create', FilterValue);
            if (response.success == false) {
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            } else if (response.success == true) {
                yield put(AboutUsPageSliderSuccess(response.data.message));
            }
        }
    } catch (error) {
        yield put(AboutUsPageSliderError("Can not Create !"));
    }
}

function* EDIT_FilterClearMessage({ payload: { message } }) {
    try {
        if (message.valueSuccess) {
            console.log(message.valueSuccess);
            yield put(AboutUsPageSliderSuccess(""));
            yield put(AboutUsPageSliderError(""));
        } else {
            yield put(AboutUsPageSliderSuccess(""));
            yield put(AboutUsPageSliderError(""));
        }
    } catch (error) {
            yield put(AboutUsPageSliderSuccess(""));
            yield put(AboutUsPageSliderError(""));
    }
}



function* EDIT_FilterValues({ payload: { editFilterValue } }) {
    //  console.log("saga ---- ",SHOW_AboutUsPageSlider);
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            const response = yield call(editJwtData, 'filterValue/' + editFilterValue.id, editFilterValue);
            if (response.success == false) {
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            } else if (response.success == true) {
                yield put(AboutUsPageSliderSuccess(response.data.message));
            }
        }

    } catch (error) {
        yield put(AboutUsPageSliderError(error));
    }
}
export function* watchFilterValue() {
    yield takeEvery(EDIT_FilterValue, EDIT_FilterValues)
    yield takeEvery(CREATE_FilterValue, CREATE_FilterValues)
    yield takeEvery(CLEAR_MESSAGE, EDIT_FilterClearMessage)
}

function* FilterValue() {
    yield all([
        fork(watchFilterValue),
    ]);
}

export default FilterValue;