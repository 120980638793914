import React, { Component } from "react";
import { CardImg,Card, CardBody, Col, Row, CardTitle, Alert,Container,Button,Table,CardFooter,Modal,Input,Label } from "reactstrap";
import { AvForm, AvField} from 'availity-reactstrap-validation';
import { Link } from "react-router-dom";
// import {CustomPagination} from "../../Settings/Common/CustomPagination";
// import {DataTabeSearch} from '../../Settings/Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {homefeaturesList,createHomeFeaturesMap} from '../../store/homefeatures/actions';
// import {productList} from '../../store/product/actions';
// import {brandList} from '../../store/brand/actions';
// import {categoryList} from '../../store/category/actions'
const reqLabel = label => {
    return <>{label}<sup style={{color:'red'}}>(*)</sup></>;
  }

class HomeFeaturesMap  extends Component {
    constructor(props) {
        super(props);
        this.state = {
                page: 1,
                per_page: 10,
                search: '',
                customchk: true,
                toggleSwitch: false,
                edittoggleSwitch: false,  
                modal_scroll: false,
                checked1: false,
                checked2: false,
                checked3: false,
                featuresMap: {
                    
                },
                status_name:''
            };
    }

    componentDidMount()
    {
            this.fetchhomefeaturesList();
            // this.fetchproductList(); 
            // this.fetchCategoryList(); 
            // this.fetchbrandList();  
    }

    fetchhomefeaturesList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        homefeaturesList(options).then((response) => {
            this.setState({
                homefeaturesList :response.data, 
            });
            this.state.homefeaturesList = response.data;
        })
        .catch((err) => {
            console.log(err);
        })
    }


    // fetchproductList = () => {
    //     let options = {
    //         page: this.state.page,
    //         per_page: this.state.per_page,
    //         search: this.state.search
    //     }
    //     productList(options).then((response) => {
    //         this.setState({
    //             productList :response.data.data, 
    //         });
    //         this.state.productList = response.data.data;

    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
    // }

    // fetchCategoryList = () => {
    //     let options = {
    //         page: this.state.page,
    //         per_page: 50,
    //         search: this.state.search
    //     }
    //     categoryList(options).then((response) => {
    //         this.setState({
    //             categoryList :response.data.data, 
    //         });
    //         this.state.categoryList = response.data.data;
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
    // }

    // fetchbrandList = () => {
    //     let options = {
    //         page: this.state.page,
    //         per_page: this.state.per_page,
    //         search: this.state.search
    //     }
    //     brandList(options).then((response) => {
    //         this.setState({
    //             brandList :response.data.data, 
    //         });
    //         this.state.brandList = response.data.data;
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     })
    // }

    //modal
    tog_scroll = (features) => {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }));
        this.state.features = features;
        this.removeBodyCss();
      }

      removeBodyCss() {
        document.body.classList.add("no_padding");
      }

      // handleValidSubmit
        handleValidSubmit(event, values) {
           
            this.props.createHomeFeaturesMap(values);
            // this.fetchproductList();
            this.fetchhomefeaturesList();
            // this.fetchCategoryList();   
            // this.fetchbrandList();  
            this.form && this.form.reset();
        }
     

       handleInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value},() => {
            this.fetchhomefeaturesList();
            // this.fetchproductList();
            // this.fetchCategoryList();
            // this.fetchbrandList();       
        });
       }

       handlePageClick = (e, index) => {   
            e.preventDefault()
            this.setState({
                page: index
            },() => {
                // this.fetchCategoryList();    
                // this.fetchproductList();
                // this.fetchCategoryList();   
                // this.fetchbrandList();       
            });
        }

operation1=e=>{
    const { name, value } = e.target;
    if(name=='show_hide_value' && value=='is_product'){
        this.setState({
            checked1 : true,
            checked2 : false,
            checked3 : false,
            status_name:value
        })
    }
    if(name=='show_hide_value' && value=='is_category'){
        this.setState({
            checked1 : false,
            checked2 : true,
            checked3 : false,
            status_name:value

        })
    }
    if(name=='show_hide_value' && value=='is_brand'){
        this.setState({
            checked1 : false,
            checked2 : false,
            checked3 : true,
            status_name:value

        })
    }
}

    render() {
        // let productListMap = [];
        let homefeaturesListMap = [];
        let paginator;
        // if (this.state.hasOwnProperty('productList')) { productListMap = this.state.productList; }
        if (this.state.hasOwnProperty('homefeaturesList')) {
            homefeaturesListMap = this.state.homefeaturesList.data;
        }
        // let brandListMap = [];
        // let categoryListMap = [];
        // if (this.state.hasOwnProperty('brandList')) { brandListMap = this.state.brandList;
        //     console.log('hhhssds',brandListMap)}
        // if (this.state.hasOwnProperty('categoryList')) { categoryListMap = this.state.categoryList; }
        const {home_features_name_en,f_product_id,f_features_id,f_category_id,f_brand_id,is_product,is_category,is_brand} = this.state.featuresMap;
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
          }
          
          const getStatusValue = (status) => {
              return status == 1 ? 'Active' : 'In-active'
          }
          console.log("this.props.success",this.props.success)
          if(this.props.success != ''){
            setTimeout(function() {
                window.location.reload();
            }, 1000);
             
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Settings" breadcrumbItem="Home and Features Map" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg='12'>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Create Home and Features Map</CardTitle>
                                        <Link to={'/featuresCreate'}><button type="button" className="btn btn-sm btn-primary waves-effect waves-light">Features Map List</button></Link>
                                        <AvForm className="form-horizontal" onValidSubmit={(e,v) => { this.handleValidSubmit(e,v) }} ref={c => (this.form = c)}>
                                        {this.props.msg && this.props.msg ? (
                                           <Alert color="success">
                                               Features Map Created Successfully!
                                           </Alert>
                                           ) : null}
                                        <div className="col-md-8">
                                                    <AvField type="select"className="custom-select" label='Home And Features' name={"f_features_id"} id={"f_features_id"} required >
                                                        <option defaultValue>Select Home and Features List</option>
                                                            {homefeaturesListMap.map((features,index)=>
                                                        <option value={features.home_features_id}>{features.home_features_name_en}</option>
                                                            )}
                                                    </AvField>
                                        </div> 
                                            <div className="col-md-8">
                                                { this.state.checked1 ?
                                                    <div>
                                                        <AvField name="f_product_id" label='Product' value={f_product_id} type="select"className="custom-select">
                                                          <option defaultValue>Select Product List</option>
                                                          {/* {productListMap != undefined && productListMap!=null && productListMap.map((product,index)=>
                                                              <option value={product.product_id}>{product.product_name_en}</option>
                                                          )} */}
                                                        </AvField>
                                                    </div> : null
                                                      
                                                }

                                           </div> 
                                           <div className="col-md-8">
                                           { this.state.checked2 ?
                                               <div>
                                                    <AvField name="f_category_id" label='Category' value={f_category_id} type="select"className="custom-select" required>
                                                        <option defaultValue>Select Category List</option>
                                                        {/* {categoryListMap != undefined && categoryListMap!=null && categoryListMap.map((category,index)=>
                                                            <option value={category.category_id}>{category.category_name_en}</option>
                                                        )} */}
                                                    </AvField>
                                                </div> : null
                                                }
                
                                           </div>
                                           <div className="col-md-8">
                                           { this.state.checked3 ?
                                           <div>
                                               <AvField name="f_brand_id" label='Brand' value={f_brand_id} type="select"className="custom-select" required>
                                                        <option defaultValue>Select Brand List</option>
                                                        {/* {brandListMap != undefined && brandListMap!=null && brandListMap.map((brand,index)=>
                                                            <option value={brand.brand_id}>{brand.brand_name_en}</option>
                                                        )} */}
                                                </AvField>
                                            </div> : null
                                                }
                                                <div className="radio" >
                                                    <AvField type="radio" className="" value='is_product' name={"show_hide_value"} onChange={this.operation1}/>
                                                    <label className="" htmlFor="customControlInline">Is Product</label>
                                                </div>
                                                <div className="radio">
                                                    <AvField type="radio" className="" value='is_category' id={"is_category"} name={"show_hide_value"} onChange={this.operation1}/>
                                                    <label className="" htmlFor="customControlInline">Is Category</label>
                                                </div>
                                                <div className="radio">
                                                    <AvField type="radio" className="" value='is_brand' id={"is_brand"} name={"show_hide_value"} onChange={this.operation1}/>
                                                    <label className="" htmlFor="customControlInline">Is Brand</label>
                                                </div>
                                                <AvField name="check_status"  value={this.state.status_name}  type="hidden"  />


                                           </div>
                                             <div className=" mt-4">
                                                 <Button type="submit" color="success">Submit</Button>
                                             </div>
                                         </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg,error,success } = state.HomeFeatures;
    return { msg,error,success };
}

export default withRouter(connect(mapStatetoProps, { homefeaturesList,createHomeFeaturesMap})(HomeFeaturesMap ));
