import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteFilterValue, editFilterValue,clearSuccess, filterNameValueList, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload  } from '../../store/FilterValue/actions';
import {  filterNameList  } from '../../store/FilterName/actions';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import DeleteModal from './DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class FilterValueList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            filterNameValueList_list: {},
            deleteModal: false,


            supplier: {

            },
            change_upcoming_project_image: {},
            upcoming_project_image: {},

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchFilterValueList();
        this.fetchFilterList();
        clearSuccess(this.props.success);
        if(localStorage.getItem('last_action') == 'create') {
            localStorage.setItem('last_action',"");
        } 
    
    }

    fetchFilterValueList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        filterNameValueList(options).then((response) => {
            //   console.log(response);
            this.setState({
                filterNameValueList: response.data,
            });
            this.state.filterNameValueList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchFilterList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        filterNameList(options).then((response) => {
            //   console.log(response);
            this.setState({
                filterNameList: response.data,
            });
            this.state.filterNameList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }



    //modal
    tog_scroll = (filterNameValueList) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.change_slider_thumbnail_image = {};
        this.state.change_slider_large_image = {};
        this.state.filterNameValueList_list = filterNameValueList;
        this.removeBodyCss();
    }

    // Voucherdelete = (voucherId) => {

    //     this.state.voucherId = voucherId;
    //     deleteVoucher(voucherId);
    //     setTimeout(function () {
    //         window.location.reload();
    //     }, 3500)
    // }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchFilterValueList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }

    // handleDeleteSubmit( values) {
    //     alert("Are You Want To Delete This?");
    //     this.fetchFilterValueList();  
    //     deleteFilterValue(values)
    //     .then((response) => {
    //       if (response.success == true) {
    //        //   console.log(response)
    //         alert(response.data);
    //         this.fetchFilterValueList();  
    //         window.location.reload();
    //       }
    //     })
    //     .catch((err) => {
    //       this.props.handleErrorResponse(err);
    //     //  console.log('err', this.props.handleErrorResponse(err));
    //     });
    // }

    toggleDeleteModal = (upcoming) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(upcoming);
    };

    onClickDelete = (project) => {
        console.log("Delete Modal1", project);
        this.setState({ deleteProject: project });
        this.setState({ deleteModal: true });

    };

    handleDeleteSubmit( values) {
       

        const {deleteProject} = this.state;
        console.log("Delete Modal22222222", deleteProject);

        if(deleteProject!=undefined) {
            deleteFilterValue(deleteProject.id)
            .then((response) => {
              if (response.success == true) {
               //   console.log(response)
                // alert(response.data);
                this.fetchFilterValueList();  
                // window.location.reload();
                this.setState({
                    deleteModal: false,
                    success: response.data.message,
                });
              }
              else{
                this.setState({
                    deleteModal: false,
                    error: response.data.error,
    
                })
              }
            })
            .catch((err) => {
             console.log(err);
            });
        }
    }


    handleEditSubmit(event, values) {
        this.props.editFilterValue(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchFilterValueList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchFilterValueList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchFilterValueList();
        });
    }

  



    
  
////
    // handleImageUpload = (e) => {
    //     var id = e.target.id;
    //     var id = this.state.filterNameValueList_list.id;
    //     let files = e.target.files;

    //     let reader = new FileReader();
    //     reader.readAsDataURL(files[0]);
    //     reader.onload = (e) => {
    //         const formData = { file: e.target.result, name: files[0].name, type: files[0].type, id: id };
    //         slider_thumbnail_image_Upload(formData, formData.id);
    //     }
    // }

    handleImageUpload = (e) => {
        var id = e.target.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
              console.log("formData", formData);

            this.state.change_upcoming_project_image[id] = formData;
            //  console.log("this.setState.voucharImage[0]",  this.state.voucharImage[id]);
        }
    }
    ////

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let filterNameValueListInfo = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        if (this.state.hasOwnProperty('filterNameValueList')) {
            filterNameValueListInfo = this.state.filterNameValueList.data;
            paginator = {
                'currentPage': this.state.filterNameValueList.current_page,
                'pagesCount': this.state.filterNameValueList.last_page,
                'total': this.state.filterNameValueList.total,
                'to': this.state.filterNameValueList.to,
                'from': this.state.filterNameValueList.from,
            }
        }
        let filterNameListInfo = [];
        if (this.state.hasOwnProperty('filterNameList')) {
            filterNameListInfo = this.state.filterNameList.data;
        }
        const { deleteModal, message } = this.state;

        let singlevoucher = this.state.voucher;

        const { code, filter_value_string,f_filte_list_name_id,filter_value_number, id, remarks, priority ,status} = this.state.filterNameValueList_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        //   console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }

        console.log('this.props.success', this.props.success);

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Settings" breadcrumbItem="Filter Value List" />
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                         {this.state.error && this.state.error ?
                            <SweetAlert
                                danger
                                title={this.state.error}
                                timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onConfirm={() => window.location.reload()} 
                            >
                            </SweetAlert>:''
                            }
                            {this.state.success && this.state.success ? 
                            <SweetAlert
                            title={this.state.success}
                            // timeout={2000}
                            showConfirm={true}
                            // showCloseButton={true}
                            success
                            onConfirm={() => window.location.reload()
                            }
                        >
                        </SweetAlert>:''
                        }
                         {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        

                            <DeleteModal
                                show={deleteModal}
                                onDeleteClick={()=>this.handleDeleteSubmit()}
                                onCloseClick={() => this.setState({ deleteModal: false })}
                            />
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader className="px-0">
                                        <Link to={'/create/filter/value'}><Button className="btn btn-success pull-right text-uppercase">Add Filter Value</Button></Link>
                                    </CardHeader>
                                    <CardBody>

                                        <CardTitle>Filter Value List</CardTitle>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                <Table responsive>

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Code</th>
                                                            <th> Name</th>
                                                            <th> Value String</th>
                                                            <th> Value Number</th>
                                                            {/* <th>Remark</th> */}
                                                            <th>Priority</th>
                                                            <th>Status</th>

                                                            <th colspan="2">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { filterNameValueListInfo && filterNameValueListInfo.map((filterNameValue, index) =>
                                                            <tr key={filterNameValue.id.toString()}>
                                                                <td scope="row">{index + 1}</td>
                                                                <td>{filterNameValue.code}</td>
                                                                <td>{filterNameValue.filter_list_name}</td>
                                                                <td>{filterNameValue.filter_value_string}</td>
                                                                <td>{filterNameValue.filter_value_number}</td>


                                                                {/* <td>{filterNameValue.remarks}</td> */}
                                                                <td>{filterNameValue.priority}</td>
                                                                <td><Badge color={getBadge(filterNameValue.status)}>{getStatusValue(filterNameValue.status)}</Badge></td>
                                                                <td>

                                                                   <i type="button"
                                                                        className="fa fa-edit  text-warning fa-2x my-2 ml-2"
                                                                        onClick={() => { this.tog_scroll(filterNameValue) }}  
                                                                    ></i>
                                                                    <i 
                                                                        className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                        onClick={() => { this.toggleDeleteModal( filterNameValue) }}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">{code}</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Filter Values Updated Successfully!
                                                </Alert>
                                            ) : null}
                                            <Row>
                                                <AvField className="form-control" type="hidden" id="id" name="id" value={id} />
                                                <div className="col-md-6">
                                                    <AvField type="select" label={reqLabel(' Filter Name')} className="form-control" name="f_filte_list_name_id" value={f_filte_list_name_id}  required >
                                                        <option defaultValue>Select Filter Name </option>
                                                        { filterNameListInfo && filterNameListInfo.map((upcoming, index) =>
                                                             <option value={upcoming.id}>{upcoming.filter_name}</option>
                                                          
                                                        )}
                                                    </AvField>
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel(' Value String')} id="filter_value_string" name="filter_value_string" value={filter_value_string} required />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel(' Value Number')} id="filter_value_number" name="filter_value_number" value={filter_value_number} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label='Remarks' id="remarks" name="remarks" value={remarks}  />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={priority}  required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField type="select" label={reqLabel('Status')} className="form-control" name="Status" value={status}  >
                                                        
                                                        <option value="0">Inactive</option>
                                                        <option value="1">Active</option>
                                                    </AvField>
                                                </div>
                                            </Row>





                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>

                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps, { editFilterValue,clearSuccess, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload })(FilterValueList));