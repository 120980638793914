import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {CREATE_createBasicCompanySettings,EDIT_createBasicCompanySettings,VOUCHER_SUCCESS,SHOW_VOUCHER,VOUCHER_ERROR} from './actionTypes';

import { brandSuccess, AboutUsPageSliderError,brandShow, merchantError, AboutUsPageSliderSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* CREATE_createBasicCompanySetting({ payload: { voucher } }) {
    try {
      //  console.log("voucher",voucher);
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'basicCompanySettings/create',voucher);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
    } catch (error) {
        yield put(AboutUsPageSliderError("Can not Create !"));
    }
}


function* EDIT_createBasicCompanySetting({ payload: { voucher } }) {
   //  console.log("saga ---- ",voucher);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'basicCompanySettings/'+voucher.id, voucher);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(AboutUsPageSliderError(error));
    }
}


// function* showMerchant({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtData, 'users/id/'+user_id);
//              yield put(brandShow(response));
//           }
          
          
//     } catch (error) {
//         yield put(brandError(error));
//     }
// }

export function* watchVoucher() {
    yield takeEvery(EDIT_createBasicCompanySettings, EDIT_createBasicCompanySetting)
    yield takeEvery(CREATE_createBasicCompanySettings, CREATE_createBasicCompanySetting)
    // yield takeEvery(SHOW_MERCHANT, showMerchant)
}

function* BasicCompanySettingsSaga() {
    yield all([
        fork(watchVoucher),
    ]);
}

export default BasicCompanySettingsSaga;