import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteDistrict, editDistrict, filterDistrictList } from '../../store/District/actions';
import { filterNameList } from '../../store/FilterName/actions';
import { clearSuccess } from '../../store/FilterValue/actions';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import DeleteModal from './DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class DistrictList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            deleteModal: false,
            filterDistrictList_list: {},

            supplier: {

            },
            change_upcoming_project_image: {},
            upcoming_project_image: {},

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchDistrictList();
        if (localStorage.getItem('last_action') == 'create') {
            localStorage.setItem('last_action', "");
        }

    }

    fetchDistrictList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        filterDistrictList(options).then((response) => {
            //   console.log(response);
            this.setState({
                filterDistrictList: response.data,
            });
            this.state.filterDistrictList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }




    //modal
    tog_scroll = (filterDistrictList) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.filterDistrictList_list = filterDistrictList;
        this.removeBodyCss();
    }

    // Voucherdelete = (voucherId) => {

    //     this.state.voucherId = voucherId;
    //     deleteVoucher(voucherId);
    //     setTimeout(function () {
    //         window.location.reload();
    //     }, 3500)
    // }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchDistrictList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }

    // handleDeleteSubmit( values) {
    //     alert("Are You Want To Delete This?");
    //     this.fetchDistrictList();  
    //     deleteDistrict(values)
    //     .then((response) => {
    //       if (response.success == true) {
    //        //   console.log(response)
    //         alert(response.data);
    //         this.fetchDistrictList();  
    //         window.location.reload();
    //       }
    //     })
    //     .catch((err) => {
    //       this.props.handleErrorResponse(err);
    //     //  console.log('err', this.props.handleErrorResponse(err));
    //     });
    // }

    toggleDeleteModal = (upcoming) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(upcoming);
    };

    onClickDelete = (project) => {
        console.log("Delete Modal1", project);
        this.setState({ deleteProject: project });
        this.setState({ deleteModal: true });

    };

    handleDeleteSubmit(values) {


        const { deleteProject } = this.state;
        console.log("Delete Modal22222222", deleteProject);

        if (deleteProject != undefined) {
            deleteDistrict(deleteProject.district_id)
                .then((response) => {
                    if (response.success == true) {
                        //   console.log(response)
                        // alert(response.data);
                        this.fetchDistrictList();

                        // window.location.reload();
                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,

                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }


    handleEditSubmit(event, values) {
        this.props.editDistrict(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchDistrictList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchDistrictList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchDistrictList();
        });
    }







    ////
    // handleImageUpload = (e) => {
    //     var id = e.target.id;
    //     var id = this.state.filterDistrictList_list.id;
    //     let files = e.target.files;

    //     let reader = new FileReader();
    //     reader.readAsDataURL(files[0]);
    //     reader.onload = (e) => {
    //         const formData = { file: e.target.result, name: files[0].name, type: files[0].type, id: id };
    //         slider_thumbnail_image_Upload(formData, formData.id);
    //     }
    // }

    handleImageUpload = (e) => {
        var id = e.target.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
            console.log("formData", formData);

            this.state.change_upcoming_project_image[id] = formData;
            //  console.log("this.setState.voucharImage[0]",  this.state.voucharImage[id]);
        }
    }
    ////

    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.fetchDistrictList();

    }

    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
        this.fetchDistrictList();

    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let filterDistrictListInfo = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        if (this.state.hasOwnProperty('filterDistrictList')) {
            filterDistrictListInfo = this.state.filterDistrictList.data;
            paginator = {
                'currentPage': this.state.filterDistrictList.current_page,
                'pagesCount': this.state.filterDistrictList.last_page,
                'total': this.state.filterDistrictList.total,
                'to': this.state.filterDistrictList.to,
                'from': this.state.filterDistrictList.from,
            }
        }
        let filterNameListInfo = [];
        if (this.state.hasOwnProperty('filterNameList')) {
            filterNameListInfo = this.state.filterNameList.data;
        }

        let singlevoucher = this.state.voucher;

        const { code, district_lon, district_lat, district_name, district_id, remarks, priority, school_district_rating, status } = this.state.filterDistrictList_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        //   console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }
        const { deleteModal, message } = this.state;


        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Settings" breadcrumbItem="District List" />
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}

                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clear()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }


                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={() => this.handleDeleteSubmit()}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader className="px-0">
                                        <Link to={'/create/school/district'}><Button className="btn btn-success pull-right">Add School District Value</Button></Link>
                                        <Link to="/multiple/create/district">
                                            <button className="btn btn-success mx-2"><i className="mdi mdi-plus-circle-outline me-1" />Upload CSV File</button>
                                        </Link>
                                    </CardHeader>
                                    <CardBody>

                                        <CardTitle>School District List</CardTitle>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                <Table responsive>

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Code</th>
                                                            <th>Name</th>
                                                            <th>Latitude</th>
                                                            <th>Longitude</th>
                                                            <th>School District Rating</th>
                                                            <th>Priority</th>
                                                            <th>Status</th>

                                                            <th colspan="2">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filterDistrictListInfo && filterDistrictListInfo.map((filterDistrict, index) =>
                                                            <tr key={filterDistrict.district_id.toString()}>
                                                                <td scope="row">{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                                <td>{filterDistrict.code}</td>
                                                                <td>{filterDistrict.district_name}</td>
                                                                <td>{filterDistrict.district_lat}</td>
                                                                <td>{filterDistrict.district_lon}</td>

                                                                <td>{filterDistrict.school_district_rating}</td>
                                                                <td>{filterDistrict.priority}</td>
                                                                <td><Badge color={getBadge(filterDistrict.status)}>{getStatusValue(filterDistrict.status)}</Badge></td>
                                                                <td>
                                                                    {/* <button type="button"
                                                                    className="btn btn-sm btn-primary waves-effect waves-light"
                                                                    onClick={() => { this.tog_scroll(filterDistrict) }}
                                                                    data-toggle="modal"
                                                                     >Edit</button>
                                                                    <button type="button"
                                                                    className="btn btn-sm btn-danger waves-effect waves-light"
                                                                    onClick={() => { this.handleDeleteSubmit( filterDistrict.district_id) }}
                                                                    >Delete</button> */}
                                                                    <i type="button"
                                                                        className="fa fa-edit  text-warning fa-2x my-2 ml-2"
                                                                        onClick={() => { this.tog_scroll(filterDistrict) }}
                                                                    ></i>
                                                                    <i
                                                                        className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                        onClick={() => { this.toggleDeleteModal(filterDistrict) }}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">{code}</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    District Updated Successfully!
                                                </Alert>
                                            ) : null}
                                            <Row>
                                                <AvField className="form-control" type="hidden" id="id" name="id" value={district_id} />
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('District')} id="district_name" name="district_name" value={district_name} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Latitude')} id="district_lat" name="district_lat" value={district_lat} required />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Longitude')} id="district_lon" name="district_lon" value={district_lon} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label='Remarks' id="remarks" name="remarks" value={remarks} />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={priority} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField type="select" label={reqLabel('Status')} className="form-control" name="Status" value={status}   >

                                                        <option value="0">Inactive</option>
                                                        <option value="1">Active</option>
                                                    </AvField>
                                                </div>
                                            </Row>
                                            <div className="col-md-6">
                                                <AvField className="form-control" type="number" label={reqLabel('School DIstrict Rating')} id="school_district_rating" name="school_district_rating" value={school_district_rating} />
                                            </div>





                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>

                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps, { editDistrict, clearSuccess })(DistrictList));