import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {CREATE_AboutUsPageSlider,EDIT_AboutUsPageSlider,SHOW_AboutUsPageSlider_SUCCESS,SHOW_SHOW_AboutUsPageSlider,SHOW_AboutUsPageSlider_ERROR} from './actionTypes';

import { brandSuccess, AboutUsPageSliderError,brandShow, merchantError, AboutUsPageSliderSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* CREATE_createAboutUsPageSliders({ payload: { AboutUsPageSlider } }) {
    try {
      //  console.log("SHOW_AboutUsPageSlider",SHOW_AboutUsPageSlider);
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'aboutUsPageSlider/create',AboutUsPageSlider);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
    } catch (error) {
        yield put(AboutUsPageSliderError("Can not Create !"));
    }
}


function* EDIT_createAboutUsPageSliders({ payload: { AboutUsPageSlider } }) {
   //  console.log("saga ---- ",SHOW_AboutUsPageSlider);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'aboutUsPageSlider/'+AboutUsPageSlider.id, AboutUsPageSlider);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(AboutUsPageSliderError(error));
    }
}


// function* showMerchant({ payload: { user_id } }) {
//     try {
//           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//           {  
//              const response = yield call(getJwtData, 'users/id/'+user_id);
//              yield put(brandShow(response));
//           }
          
          
//     } catch (error) {
//         yield put(brandError(error));
//     }
// }

export function* watchAboutUsPageSlider() {
    yield takeEvery(EDIT_AboutUsPageSlider, EDIT_createAboutUsPageSliders)
    yield takeEvery(CREATE_AboutUsPageSlider, CREATE_createAboutUsPageSliders)
    // yield takeEvery(SHOW_MERCHANT, showMerchant)
}

function* AboutUsPageSlider() {
    yield all([
        fork(watchAboutUsPageSlider),
    ]);
}

export default AboutUsPageSlider;