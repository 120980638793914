import React, { Component } from 'react';
import PropTypes from "prop-types";
import {
    Container, Row, Col, Card, CardBody, CardTitle,
    Button, Input, FormText, FormGroup, Label, Form, CardHeader, CardFooter, InputGroup
} from "reactstrap";
import { Link } from "react-router-dom";
// import MetaTags from 'react-meta-tags';
// import { CSVLink, CSVDownload } from "react-csv";
// toaster
import toastr from "toastr";
import { createFloorPlan, CityNameList, DistrictNameList, createSliderBrochure, FloorDataPlanList, FilterOptionList } from '../../store/FloorPlan/actions';
import {editSubProjectSlider , SubProjectList, SubListSpecific, ProjectSliderDetails} from '../../store/ProjectSlider/actions';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropzone from "react-dropzone"
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions

import { createCity, createBlukTransaction } from '../../store/CITY/actions';
import Select from "react-select";
import csvfile from "../../assets/sample-city.csv";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Fade from 'react-reveal/Fade';

class ProjectSliderBrochure extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientInfo: {
                // roles: [],
                // status: 1
                priority:'',
                remarks: '',
            },
            all_roles: [],
            date: new Date(),
            custom_div2: false
        };
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
        this.handlleProjectName = this.handlleProjectName.bind(this);

        this.state = { selectedFiles: [] }
    }

    handlleProjectNameFilter(option, inputValue) {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }
    handlleProjectName(selectedGroup) {

        this.setState({
            selectedGroup
        })
        console.log("out", this.state.selectedGroup);

        //   this.state.selectedGroup = option;
    }
    fetchProjectSliderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        FloorDataPlanList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data,
                });
                this.state.ProjectNameList = response.data;

            }
            else {
                this.setState({
                    ProjectNameList: {
                    },
                });
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles: files })
        console.log('selectedFiles', this.state.selectedFiles)
    }
    fetchSubProjectList = () => {
        SubProjectList().then((response) => {

            console.log("SubProjectList", response);
            if (response.data != []) {
                this.setState({
                    SubProjectList: response.data[0].options,
                });
                this.state.SubProjectList = response.data[0].options;

            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    componentDidMount() {
        this.fetchProjectSliderList();
        this.fetchSubProjectList();
    }

    handleInputChange = (event) => {
        // event.preventDefault();
        console.log("date", event.target.name);
        if (event.target.name == "f_group_id") {
            this.fetchClientInfoList(event.target.value);
        }
        if (event.target.name == "f_client_id") {
            this.fetchClientCustomerInfoList(event.target.value);
        }
        const { target: { name, value } } = event;

        this.updateUserObject(name, value);
    }

    updateUserObject = (name, value, callback = null) => {
        let transInfoCopy = Object.assign({}, this.state.transInfo);
        transInfoCopy[name] = value;

        this.setState({
            transInfo: transInfoCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }

    
    handlleremarks = (e) => {

        let value = e.target.value;
        let name = e.target.name;
        if (name=="remarks") {
            this.setState({ remarks: value });
        }
        if (name=="priority") {
            this.setState({ priority: value });
            
        }
        console.log("value", value);

        // setIsSelected(true);
    };
    changeHandler = (event) => {

        let targetFile = event.target.files[0];
        console.log("targetFile", targetFile);


        this.setState({
            uploadedFile: targetFile
        });

        // this.state.uploaded_file = event.target.files[0];
        console.log("uploaded_file", this.state.uploadedFile);
        // setIsSelected(true);
    };

    handleSubmission = () => {
        const formData = new FormData();
        console.log("uploaded_file", formData);

        formData.append('files', this.state.selectedFiles[0]);
        formData.append('project_id_value', this.state.selectedGroup.value);
        formData.append('short_descriptions', this.state.short_descriptions);
        formData.append('details_descrptions', this.state.details_descrptions);
        formData.append('remarks', this.state.remarks);
        formData.append('priority', this.state.priority);
        
        console.log("uploaded_file", formData);

        // createSliderBrochure(formData);

        createSliderBrochure(formData)
            .then((response) => {
                // console.log("formData--response", response)
                if (response.data.status === true) {

                    this.setState({
                        message: response.data.message,
                        custom_div2: true
                    })
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/index/projectBrochure',
                        });
                    }, 2000)
                }
            })
            .catch((err) => {
                console.log(err);
                // this.props.handleErrorResponse(err);
            });
    };


    render() {
        let clientGroup = [];
        let clientInfoList = [];
        let categoryList = [];
        let categoryCustomerList = [];


        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }

        if (this.state.hasOwnProperty('clientInfoData')) {
            clientInfoList = this.state.clientInfoData;
        }
        if (this.state.hasOwnProperty('clientCustomerInfoData')) {
            categoryCustomerList = this.state.clientCustomerInfoData;
        }

        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList.data;
        }
        const csvData = [
            ["Group Code", "Client Info Code", "Client Customer Code", "Category Code", "Transaction Date", "Transaction Amount", "remarks"]
        ];
        const { date, message } = this.state;
        console.log(message);
        return (
            <React.Fragment>

                <title>Upload Brochure </title>


                <div className="page-content">
                    {message == "" || message == undefined || message == null ?
                        "" :
                        // this.state.custom_div1==true &&
                        this.state.custom_div2 ?
                            <SweetAlert
                                title={message}
                                // timeout={2000}
                                showConfirm={false}
                                showCloseButton={false}
                                success
                                onConfirm={() => this.setState({
                                    custom_div2: false,
                                    message: undefined
                                })}
                            >
                                {/* I Will close in 2 Seconds */}
                            </SweetAlert> : null


                    }
                    <Container fluid={true}>
                        <Breadcrumbs title="Forms" breadcrumbItem="Upload Brochure" />
                        {/* <CSVLink data={csvfile} className="btn btn-success mb-3">Excel Sample Download</CSVLink> */}
                        {/* <a href={csvfile} download="sample-transection.csv" className="btn btn-success pull-right"><i className="fas fa-download"></i> Download sample csv file 1</a> */}

                        <br />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">Brochure Upload</CardTitle>
                                        <p className="card-title-desc">
                                            {" "}
                                            Select the file to Upload.
                                        </p>
                                        <Form encType="multipart/form-data">
                                            <Row>
                                                <div className="form-group col-md-6">
                                                    <Label htmlFor="formname">Project name : </Label>
                                                    <Select
                                                        //isMulti
                                                        onChange={this.handlleProjectName}
                                                        filterOption={this.handlleProjectNameFilter}
                                                        noOptionsMessage={() => "No more options"}
                                                        options={this.state.SubProjectList}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                    <Input type="hidden" name="project_id_value" id="project_id_value" value={this.state.selectedGroup && this.state.selectedGroup.value} required />
                                                </div>

                                            </Row>
                                            <Row>
                                                <div className="col-md-12">
                                                    <Label>Short Discription</Label>

                                                    <Input className="form-control" type="hidden" id="short_description" name="short_descriptions" value={this.state.short_descriptions} />

                                                    <CKEditor
                                                        editor={ClassicEditorDescription}
                                                        data={''}
                                                        onReady={editor => {
                                                            this.setState({
                                                                short_descriptions: this.state.short_descriptions && this.state.short_descriptions,
                                                            });
                                                            editor.ui.view.editable.element.style.minHeight = "200px";
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.setState({
                                                                short_descriptions: data,
                                                            });
                                                            editor.ui.view.editable.element.style.minHeight = "200px";
                                                        }}
                                                        onBlur={(event, editor) => {
                                                            editor.ui.view.editable.element.style.minHeight = "200px";
                                                            // console.log( 'Blur.', editor );
                                                        }}
                                                        onFocus={(event, editor) => {
                                                            editor.ui.view.editable.element.style.minHeight = "200px";
                                                            // console.log( 'Focus.', editor );
                                                        }}

                                                    />
                                                </div>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <div className="col-md-12">
                                                    <Label>Details Discription</Label>
                                                    <Input className="form-control" type="hidden" id="details_descrptions" name="details_descrptions" value={this.state.details_descrptions} />

                                                    <CKEditor
                                                        editor={ClassicEditorDescription}
                                                        data={''}
                                                        onReady={editor => {
                                                            this.setState({
                                                                details_descrptions: this.state.details_descrptions && this.state.details_descrptions,
                                                            });
                                                            editor.ui.view.editable.element.style.minHeight = "200px";
                                                            // You can store the "editor" and use when it is needed.
                                                            // console.log( 'Editor is ready to use!', editor );
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.setState({
                                                                details_descrptions: data,
                                                            });
                                                            editor.ui.view.editable.element.style.minHeight = "200px";
                                                        }}
                                                        onBlur={(event, editor) => {
                                                            editor.ui.view.editable.element.style.minHeight = "200px";
                                                            // console.log( 'Blur.', editor );
                                                        }}
                                                        onFocus={(event, editor) => {
                                                            editor.ui.view.editable.element.style.minHeight = "200px";
                                                            // console.log( 'Focus.', editor );
                                                        }}

                                                    />
                                                </div>
                                            </Row>
                                            <hr />
                                            <Dropzone
                                                onDrop={acceptedFiles =>
                                                    this.handleAcceptedFiles(acceptedFiles)
                                                }
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} name="uploaded_file" multiple />
                                                            <div className="mb-3">
                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                            </div>
                                                            <h4>Drop files here or click to upload.</h4>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div
                                                className="dropzone-previews mt-3"
                                                id="file-previews"
                                            >
                                                {this.state.selectedFiles.map((f, i) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    )
                                                })}
                                            </div>
                                            <Row>

                                                <div className="col-md-6">
                                                    <Label>Remarks</Label>
                                                    <Input className="form-control" type="text" id="remarks" name="remarks" onChange={(e)=>this.handlleremarks(e)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <Label>Priority</Label>

                                                    <Input className="form-control" type="number" id="priority" name="priority"  onChange={(e)=>this.handlleremarks(e)} required />
                                                </div>
                                            </Row>
                                            <div className="text-center mt-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-success" onClick={this.handleSubmission}
                                                >
                                                    Send Files
                                                </button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>

        );
    }
}

ProjectSliderBrochure.propTypes = {
    handleErrorResponse: PropTypes.any,
    match: PropTypes.any,
    history: PropTypes.any,
};
const mapStatetoProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}



export default withRouter(connect(mapStatetoProps, { createSliderBrochure })(ProjectSliderBrochure));

