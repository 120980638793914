
import {CREATE_createBasicCompanySettings,EDIT_createBasicCompanySettings,AboutUsPageSlider_SUCCESS,SHOW_VOUCHER,AboutUsPageSlider_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createBasicCompanySettings = (voucher) => {
    // console.log("Voucher",voucher)
    return {
        type: CREATE_createBasicCompanySettings,
        payload: { voucher }
    }
}
export function deleteVoucher(voucherId){
    return getApi().delete('vouchar/destroy/'+voucherId)
}
export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_VOUCHER,
        payload: { voucher_id }
    }
}

export const editCompanySetting = (voucher) => {
    return {
        type: EDIT_createBasicCompanySettings,
        payload: { voucher }
    }
}
export function companySettingListList(params){
    return getApi().get('basicCompanySettings/list',{
        params: params
    })
}

export function voucharGenerateList(params){
    //console.log("ok action")
    return getApi().get('vouchar/generates/list',{
        params: params
    })
}




export const AboutUsPageSliderSuccess = (msg) => {
    return {
        type: AboutUsPageSlider_SUCCESS,
        payload: msg
    }
}

export const AboutUsPageSliderError = (msg) => {
    return {
        type: AboutUsPageSlider_ERROR,
        payload: msg
    }
}

export function orderShow(order_id){
    return getApi().get('vouchar/generates/show/'+order_id)
}

export function logo_small_Upload(params,id){
    //  console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/logo_small/edit/'+id,params)
     }
 }

 export function logo_medium_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/logo_medium/edit/'+id,params)
     }
 }

 export function logo_large_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/logo_large/edit/'+id,params)
     }
 }

 export function favicon_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/favicon/edit/'+id,params)
     }
 }

 export function our_vision_image_upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/our_vision_image/edit/'+id,params)
     }
 }

 export function about_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_1/edit/'+id,params)
     }
 }
 export function about_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_2/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_1/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_2/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_1/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_2/edit/'+id,params)
     }
 }



