import React, { Component } from "react";
import './Spinner.scss';

class Spinner extends Component {

    render() {
        return (
            <div className="spinner-container">
                <div class=" spinner-btn" type="button" disabled>
                    <>
                    <span class="spinner-border text-primary spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="spinner-text">Submitting...</span>
                    </>
                </div>
            </div>
        );
    }

}

export default Spinner;