import React, { Component } from 'react';
import PropTypes from "prop-types";
import {
    Container, Row, Col, Card, CardBody, CardTitle,
    Button, Input, FormText, FormGroup, Label, Form, CardHeader, CardFooter, InputGroup
} from "reactstrap";
import { Link } from "react-router-dom";
// import MetaTags from 'react-meta-tags';
// import { CSVLink, CSVDownload } from "react-csv";
// toaster
import toastr from "toastr";
// import "flatpickr/dist/themes/material_blue.css"
// import Flatpickr from "react-flatpickr"

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Dropzone from "react-dropzone"
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// actions
// import { getUserDetails, createOrUpdateUser, getRoleList } from '../../store/auth/profile/actions';
// import { createClient } from 'store/clients/actions';
// import { createClientInfo, getClientInfoDrop, getClientCustomerInfoDrop } from 'store/clientInfo/actions';
// import { getClientList, editClient } from '../../store/clients/actions';
// import { createTransaction, createBlukTransaction } from 'store/transaction/actions';
// import { categoryList } from 'store/actions';
import { createBlukTransaction} from '../../store/District/actions';

import csvfile from "../../assets/sample-district.csv";

import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Fade from 'react-reveal/Fade';

class DistrictMultipleCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientInfo: {
                // roles: [],
                // status: 1
            },
            all_roles: [],
            date: new Date(),
            custom_div2: false
        };
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
        this.state = { selectedFiles: [] }
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles: files })
        console.log('selectedFiles', this.state.selectedFiles)
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    componentDidMount() {
    }

    handleInputChange = (event) => {
        // event.preventDefault();
        console.log("date", event.target.name);
        if (event.target.name == "f_group_id") {
            this.fetchClientInfoList(event.target.value);
        }
        if (event.target.name == "f_client_id") {
            this.fetchClientCustomerInfoList(event.target.value);
        }
        const { target: { name, value } } = event;

        this.updateUserObject(name, value);
    }

    updateUserObject = (name, value, callback = null) => {
        let transInfoCopy = Object.assign({}, this.state.transInfo);
        transInfoCopy[name] = value;

        this.setState({
            transInfo: transInfoCopy
        }, () => {
            if (typeof callback == "function")
                callback()
        });
    }


    changeHandler = (event) => {

        let targetFile = event.target.files[0];
        console.log("targetFile", targetFile);


        this.setState({
            uploadedFile: targetFile
        });

        // this.state.uploaded_file = event.target.files[0];
        console.log("uploaded_file", this.state.uploadedFile);
        // setIsSelected(true);
    };

    handleSubmission = () => {
        const formData = new FormData();

        formData.append('uploaded_file', this.state.selectedFiles[0]);

        console.log("uploaded_file", formData)

        createBlukTransaction(formData)
            .then((response) => {
                // console.log("formData--response", response)
                if (response.status === true) {

                    this.setState({
                        message: response.message,
                        custom_div2: true
                    })
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/school/district/list',
                        });
                    }, 2000)
                }
            })
            .catch((err) => {
                console.log(err);
                // this.props.handleErrorResponse(err);
            });
    };


    render() {
        let clientGroup = [];
        let clientInfoList = [];
        let categoryList = [];
        let categoryCustomerList = [];


        if (this.state.hasOwnProperty('clientData')) {
            clientGroup = this.state.clientData.data;
        }

        if (this.state.hasOwnProperty('clientInfoData')) {
            clientInfoList = this.state.clientInfoData;
        }
        if (this.state.hasOwnProperty('clientCustomerInfoData')) {
            categoryCustomerList = this.state.clientCustomerInfoData;
        }

        if (this.state.hasOwnProperty('categoryList')) {
            categoryList = this.state.categoryList.data;
        }
        const csvData = [
            ["Group Code", "Client Info Code", "Client Customer Code", "Category Code", "Transaction Date", "Transaction Amount", "remarks"]
        ];
        const { date, message } = this.state;
        return (
            <React.Fragment>
               
                    <title>District Bulk Create</title>
                

                    <div className="page-content">
                        {message == "" || message == undefined || message == null ?
                            "" :
                            // this.state.custom_div1==true &&
                            this.state.custom_div2 ?
                                <SweetAlert
                                    title={message}
                                    // timeout={2000}
                                    showConfirm={false}
                                    showCloseButton={false}
                                    success
                                    onConfirm={() => this.setState({
                                        custom_div2: false,
                                        message: undefined
                                    })}
                                >
                                    {/* I Will close in 2 Seconds */}
                                </SweetAlert> : null


                        }
                        <Container fluid={true}>
                            <Breadcrumbs title="Forms" breadcrumbItem="District Bulk Create" />
                            {/* <CSVLink data={csvfile} className="btn btn-success mb-3">Excel Sample Download</CSVLink> */}
                            <a href={csvfile} download="sample-district.csv" className="btn btn-success pull-right"><i className="fas fa-download"></i> Download sample csv file </a>

                            <br />
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="h4">Excel Upload</CardTitle>
                                            <p className="card-title-desc">
                                                {" "}
                                                Select the file to Upload.
                                            </p>
                                            <Form encType="multipart/form-data">
                                                <Dropzone
                                                    onDrop={acceptedFiles =>
                                                        this.handleAcceptedFiles(acceptedFiles)
                                                    }
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} name="uploaded_file" multiple />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                </div>
                                                                <h4>Drop files here or click to upload.</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div
                                                    className="dropzone-previews mt-3"
                                                    id="file-previews"
                                                >
                                                    {this.state.selectedFiles.map((f, i) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                alt={f.name}
                                                                                src={f.preview}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {f.name}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <strong>{f.formattedSize}</strong>
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        )
                                                    })}
                                                </div>
                                                <div className="text-center mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-success" onClick={this.handleSubmission}
                                                    >
                                                        Send Files
                                                    </button>
                                                </div>
                                            </Form>


                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                
            </React.Fragment>

        );
    }
}
DistrictMultipleCreate.propTypes = {
    handleErrorResponse: PropTypes.any,
    match: PropTypes.any,
    history: PropTypes.any,
};

const mapStateToProps = state => {
    const { msg, error, success } = state.Profile;
    return { msg, error, success };
}




export default withRouter(connect(mapStateToProps)(DistrictMultipleCreate));

