
// import {CREATE_District,EDIT_District,AboutUsPageSlider_SUCCESS,SHOW_AboutUsPageSlider,AboutUsPageSlider_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

// export const createDistrict = (District) => {
//     return {
//         type: CREATE_District,
//         payload: { District }
//     }
// }
// export function deleteAboutUsPageSlider(AboutUsPageSliderId){
//     return getApi().delete('vouchar/destroy/'+AboutUsPageSliderId)
// }

export function featuresSettings(params) {
    return getApi().get('settings/status',{
        params: params
    });
}

// export const editDistrict = (District) => {
//     return {
//         type: EDIT_District,
//         payload: { District }
//     }
// }
export function filterDistrictList(params){
    return getApi().get('district/list',{
        params: params
    })
}

export function featureSettingsEdit(id,params){
    return getApi().put('basicCompanySettings/feature/settings/edit/'+id,params)
}


// export const AboutUsPageSliderSuccess = (msg) => {
//     return {
//         type: AboutUsPageSlider_SUCCESS,
//         payload: msg
//     }
// }

// export const AboutUsPageSliderError = (msg) => {
//     return {
//         type: AboutUsPageSlider_ERROR,
//         payload: msg
//     }
// }

// export const showVoucher = (voucher_id) => {
//     return {
//         type: SHOW_AboutUsPageSlider,
//         payload: { voucher_id }
//     }
// }










