import { ProjectSlider_ERROR,ProjectSlider_SUCCESS,EDIT_ProjectSlider } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ProjectSlider = (state = initialState, action) => {
    switch (action.type) {
     
        case EDIT_ProjectSlider:
            state = { ...state }
            break;
        case ProjectSlider_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case ProjectSlider_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ProjectSlider;