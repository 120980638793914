 import {CREATE_FloorPlan,CREATE_SubFloorPlan,CREATE_CreateBrochure,EDIT_SubFloorPlan,EDIT_uploadImageFloorPlan,EDIT_FloorPlan,AboutUsPageSlider_SUCCESS,SHOW_FloorPlan,AboutUsPageSlider_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createFloorPlan = (FloorPlan) => {
    return {
        type: CREATE_FloorPlan,
        payload: { FloorPlan }
    }
}
export const createSubFloorPlan = (FloorPlan) => {
    return {
        type: CREATE_SubFloorPlan,
        payload: { FloorPlan }
    }
}

export function createBrochure(data) {
    return getApi().post('FloorPlan/upload/brochure', data);
}

export function createSliderBrochure(data) {
    return getApi().post('ProjectSlider/upload/brochure', data);
}

export function createProjectSiteMap(data) {
    return getApi().post('ProjectSlider/upload/projectSiteMap', data);
}

export function editProjectSiteMap(data) {
    // console.log('Edit Project Site Map',data);
    return getApi().post('ProjectSlider/upload/projectSiteMap/edit/'+data.project_id_value,data);
}
export const editFloorPlan = (FloorPlan) => {
    return {
        type: EDIT_FloorPlan,
        payload: { FloorPlan }
    }
}
export const uploadImageFloorPlan = (FloorPlan) => {
    return {
        type: EDIT_uploadImageFloorPlan,
        payload: { FloorPlan }
    }
}

export const editSubFloorPlan = (FloorPlan) => {
    return {
        type: EDIT_SubFloorPlan,
        payload: { FloorPlan }
    }
}

export function FloorPlanList(params){
    return getApi().get('FloorPlan/list',{
        params: params
    })
}
export function FloorPlanBrochureList(params){
    return getApi().get('FloorPlan/Brochure/list',{
        params: params
    })
}
export function FloorDataPlanList(params){
    return getApi().get('FloorPlan/data/list',{
        params: params
    })
}
export function CityNameList(params){
    return getApi().get('city/option/list',{
        params: params
    })
}
export function SpecificCityNameList(id){
    return getApi().get('city/option/specific/list/'+id)
}
export function floorPlanImages(id){
    return getApi().get('FloorPlan/Details/Images/'+id)
}
export function floorPlanBrochure(id){
    return getApi().get('FloorPlan/Details/Brochure/'+id)
}

export function DistrictNameList(params){
    return getApi().get('district/option/list',{
        params: params
    })
}
export function SpecificDistrictNameList(id){
    return getApi().get('district/option/specific/list/'+id)
}
export function FilterOptionList(params){
    return getApi().get('filter/option/list',{
        params: params
    })
}
export function SpecificFilterOptionList(id){
    return getApi().get('filter/option/specific/list/'+id)
}

export function ParentFloorPlanList(params){
    return getApi().get('FloorPlan/parent/list',{
        params: params
    })
}

export function SpecificParentFloorPlanList(id){
    return getApi().get('FloorPlan/parent/specific/'+id)
}


export function SubFloorPlanList(params){
    return getApi().get('FloorPlan/subProject/list',{
        params: params
    })
}
export function SubFloorPlanListData(params){
    return getApi().get('FloorPlan/subProject/data/list',{
        params: params
    })
}

export function FloorPlanDetails(id){
    return getApi().get('FloorPlan/Details/'+id)
}



export const AboutUsPageSliderSuccess = (msg) => {
    return {
        type: AboutUsPageSlider_SUCCESS,
        payload: msg
    }
}

export const AboutUsPageSliderError = (msg) => {
    return {
        type: AboutUsPageSlider_ERROR,
        payload: msg
    }
}

export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_FloorPlan,
        payload: { voucher_id }
    }
}

export function deleteVoucher(voucherId){
    return getApi().delete('FloorPlan/'+voucherId)
}

export function deleteBrochure(voucherId){
    return getApi().delete('FloorPlan/brochure/'+voucherId)
}

export function deleteFloorPlanImage(voucherId){
    return getApi().delete('FloorPlan/image/'+voucherId)
}


export function orderShow(order_id){
    return getApi().get('vouchar/generates/show/'+order_id)
}




