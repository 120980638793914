import React, { Component } from "react";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
import classnames from 'classnames';

// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createSubProjectName, ProjectNameList, ParentProjectNameList, logo_small_Upload, logo_medium_Upload, logo_large_Upload, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload } from '../../store/ProjectName/actions';
// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";
import { clearSuccess } from '../../store/FilterValue/actions';
import Dropzone from "react-dropzone"

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import 'react-image-picker-editor/dist/index.css'

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

const optionGroup = [
    {
        label: "Picnic",
        options: [
            { label: "Mustard", value: "Mustard" },
            { label: "Ketchup", value: "Ketchup" },
            { label: "Relish", value: "Relish" }
        ]
    },
    {
        label: "Camping",
        options: [
            { label: "Tent", value: "Tent" },
            { label: "Flashlight", value: "Flashlight" },
            { label: "Toilet Paper", value: "Toilet Paper" }
        ]
    }
];

class SubProjectNameCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 25,
            supplier: {

            },
            status: 0,
            imageSrc: '',
            site_map_image: {},
            project_logo: {},
            project_cover_image_2: {},
            project_cover_image_1: {},
            project_details_description: '',
            project_short_description: '',
            selectedGroup: null,


            brand: {

            },
            selectedFiles: []

        };

        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);

    }


    componentDidMount() {
        this.fetchProjectNameList();
        this.fetchParentProjectNameList();
        // this.fetchCityList();
        // this.fetchMarchantList();
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles: files })

        this.handleMediumImageUpload(files);
        console.log('selectedFiles', this.state.selectedFiles)
    }
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    fetchProjectNameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        ProjectNameList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data.data,
                });
                this.state.ProjectNameList = response.data.data;

                // console.log("Setting companySettingListLists: " + response.data.data[0]);
            } else {
                this.setState({
                    ProjectNameList: {

                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchParentProjectNameList = () => {

        ParentProjectNameList().then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ParentProjectNameList: response.data,
                });
                this.state.ParentProjectNameList = response.data;
            } else {
                this.setState({
                    ParentProjectNameList: {
                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }
    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        // console.log("values",values.project_cover_image_1.project_cover_image_1);

        if (values.project_cover_image_1.project_cover_image_1 == undefined) {
            this.setState({
                errorMessage: "Project Cover Image 1 is required",
            })
        }
        else if (values.project_cover_image_2.project_cover_image_2 == undefined) {
            this.setState({
                errorMessage: "Project Cover Image 2 is required",
            })
        }
        // else if (values.site_map_image.site_map_image == undefined) {
        //     this.setState({
        //         errorMessage: "Site Map Image  is required",
        //     })
        // }
        else if (values.project_logo.project_logo == undefined) {
            this.setState({
                errorMessage: "Project Logo is required",
            })
        } else {
            this.props.createSubProjectName(values);
            console.log("Project Logo is required", values);
        }
    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const formData = { file: image, name: "project_cover_image_1", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.project_cover_image_1["project_cover_image_1"] = formData;

        // console.log("this.setState.project_cover_image_1[0]", this.state.project_cover_image_1["project_cover_image_1"]);

    }

    handleLargeImageUpload = (image) => {

        const formData = { file: image, name: "project_cover_image_2", type: "image/jpeg" };
        console.log("formData", formData);

        this.state.project_cover_image_2["project_cover_image_2"] = formData;
    }

    handleMediumImageUpload = (image) => {


        const formData = { image };
        console.log("formData", image[0]);

        this.state.site_map_image["site_map_image"] = image[0];
    }

    handleProjectLogoUpload = (image) => {

        const formData = { file: image, name: "project_logo", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.project_logo["project_logo"] = formData;
    }

    toggleTab(tab) {
        if (tab === 2 && (document.getElementById('parent_project_id_value').value == '' || document.getElementById('parent_project_id_value').value == null
            || document.getElementById('parent_project_id_value').value == undefined)) {
            this.setState({
                errorMessage: "Parent Project Name is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_name').value == '' || document.getElementById('project_name').value == null
            || document.getElementById('project_name').value == undefined)) {
            this.setState({
                errorMessage: "Sub Project name is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_title').value == '' || document.getElementById('project_title').value == null
            || document.getElementById('project_title').value == undefined)) {
            this.setState({
                errorMessage: "Sub Project Title is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_short_description').value == '' || document.getElementById('project_short_description').value == null
            || document.getElementById('project_short_description').value == undefined)) {
            this.setState({
                errorMessage: "Sub Project Short Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_details_description').value == '' || document.getElementById('project_details_description').value == null
            || document.getElementById('project_details_description').value == undefined)) {
            this.setState({
                errorMessage: "Sub Project Details Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_address').value == '' || document.getElementById('project_address').value == null
            || document.getElementById('project_address').value == undefined)) {
            this.setState({
                errorMessage: "Sub Project Address is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_phone_no').value == '' || document.getElementById('project_phone_no').value == null
            || document.getElementById('project_phone_no').value == undefined)) {
            this.setState({
                errorMessage: "Sub Project Phone No is required",
            })
        }
        else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
            || document.getElementById('priority').value == undefined)) {
            this.setState({
                errorMessage: "Priority is required",
            })
        }
        else {
            if (this.state.activeTab !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

    }


    toggleTabProgress(tab) {
        if (this.state.activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTabProgress: tab
                });

                if (tab === 1) { this.setState({ progressValue: 25 }) }
                if (tab === 2) { this.setState({ progressValue: 50 }) }
                if (tab === 3) { this.setState({ progressValue: 75 }) }
                if (tab === 4) { this.setState({ progressValue: 100 }) }
            }
        }
    }

    removeMessage() {
        this.setState({ errorMessage: '' });
    }

    handleSelectGroup(selectedGroup) {

        this.setState({ selectedGroup });
    }


    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/index/projectName',
            }
        )
    }
    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;

        const { selectedGroup } = this.state;

        const initialImage = '';
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Sub Communities Create" />
                        {this.state.errorMessage && this.state.errorMessage ?
                            <SweetAlert
                                danger
                                title={this.state.errorMessage}
                                timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.removeMessage()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }

                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>

                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.toggleTab(1); }} >
                                                        <span className="step-number mr-2">01</span>
                                                        Sub Communities Name Setup
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => { this.toggleTab(2); }} >
                                                        <span className="step-number mr-2">02</span>
                                                        <span>Image upload</span>
                                                    </NavLink>
                                                </NavItem>

                                            </ul>
                                            <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">

                                                <TabPane tabId={1}>
                                                    <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                        <Row>
                                                            <div className="col-md-12">
                                                                {/* <AvField  type="hidden" label={reqLabel('Parent Communities Name')} id="parent_project_id" name="parent_project_id" required /> */}
                                                                <AvField type="hidden" name="parent_project_id_value" id="parent_project_id_value" value={this.state.selectedGroup && this.state.selectedGroup.value} required />
                                                                <Label>Parent Communities Name</Label>
                                                                <Select
                                                                    id="parent_project_id"
                                                                    name="parent_project_id"
                                                                    value={this.state.selectedGroup}
                                                                    onChange={this.handleSelectGroup}
                                                                    options={this.state.ParentProjectNameList}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                            <br />
                                                            {/* <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Sub Communities Title')} id="project_title" name="project_title" required />
                                                </div> */}
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Sub Communities Name')} id="project_name" name="project_name" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Sub Communities Title')} id="project_title" name="project_title" required />
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Video Link'} id="vedio_link" name="vedio_link" />
                                                            </div>
                                                        </Row>
                                                        {/* <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Sub Project Short Description')} id="project_short_description" name="project_short_description" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Sub Project Long Descriptiom')} id="project_details_description" name="project_details_description" required />
                                                            </div>
                                                        </Row> */}
                                                        <Row>
                                                            <div className="col-md-12">
                                                                <AvField className="form-control" type="hidden" label={reqLabel('Short Description')} id="project_short_description" name="project_short_description" value={this.state.project_short_description} />

                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={''}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            project_short_description: this.state.project_short_description && this.state.project_short_description,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            project_short_description: data,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Focus.', editor );
                                                                    }}

                                                                />
                                                            </div>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <div className="col-md-12">
                                                                <AvField className="form-control" type="hidden" label={reqLabel('Details Descrptions')} id="project_details_description" name="project_details_description" value={this.state.project_details_description} />

                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={''}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            project_details_description: this.state.project_details_description && this.state.project_details_description,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // You can store the "editor" and use when it is needed.
                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            project_details_description: data,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Focus.', editor );
                                                                    }}

                                                                />
                                                            </div>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Contact Information Description')} id="contact_information_description" name="contact_information_description" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Area Map Url')} id="area_map_url" name="area_map_url" required />
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Sub Communities Address')} id="project_address" name="project_address" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Sub Communities Phone No')} id="project_phone_no" name="project_phone_no" required />
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" required />
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <Label>{reqLabel('Status')}</Label><br />
                                                                <AvField type="select" className="form-control" id="Status" name="Status"   >
                                                                    <option value="1">Active</option>
                                                                    <option value="0">Inactive</option>

                                                                </AvField>
                                                            </div>
                                                        </Row>

                                                        <AvField type="hidden" value={this.state.project_cover_image_1} name="project_cover_image_1" id="project_cover_image_1" required />
                                                        <AvField type="hidden" value={this.state.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" required />
                                                        <AvField type="hidden" value={this.state.site_map_image} name="site_map_image" id="site_map_image" required />
                                                        <AvField type="hidden" value={this.state.project_logo} name="project_logo" id="project_logo" required />
                                                    </AvForm>

                                                </TabPane>
                                                <TabPane tabId={2}>

                                                    <Row>
                                                        <div className="col-md-6">
                                                            <Label>{reqLabel('Cover Image Top')}</Label><br />
                                                            <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1920px * 1080px</small>

                                                            {/* <AvField type="file" className="form-control" name="project_cover_image_1_0" onChange={(e) => this.handletHumbnailImageUpload(e)} id="project_cover_image_1"  required /> */}
                                                            < ReactImagePickerEditor
                                                                id="project_cover_image_1_0"
                                                                name="project_cover_image_1_0"
                                                                type="file"
                                                                config={config2}
                                                                imageSrcProp={initialImage}
                                                                imageChanged={(image) => {
                                                                    this.handletHumbnailImageUpload(image)
                                                                }} />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Label>{reqLabel('Cover Image Bottom')}</Label><br />
                                                            <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1920px * 380px</small>

                                                            < ReactImagePickerEditor
                                                                id="project_cover_image_2_0"
                                                                name="project_cover_image_2_0"
                                                                type="file"
                                                                config={config2}
                                                                imageSrcProp={initialImage}
                                                                imageChanged={(image) => {
                                                                    this.handleLargeImageUpload(image)
                                                                }} />
                                                            {/* <AvField type="file" className="form-control" name="project_cover_image_2_0" onChange={(e) => this.handleLargeImageUpload(e)} id="project_cover_image_2" required /> */}

                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className="col-md-6">
                                                            <Label>{reqLabel('Logo Image')}</Label><br />
                                                            <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 370px * 226px</small>

                                                            < ReactImagePickerEditor
                                                                id="project_logo_0"
                                                                name="project_logo_0"
                                                                type="file"
                                                                config={config2}
                                                                imageSrcProp={initialImage}
                                                                imageChanged={(image) => {
                                                                    this.handleProjectLogoUpload(image)
                                                                }} />
                                                            {/* <AvField type="file" className="form-control" name="project_logo_0" onChange={(e) => this.handleProjectLogoUpload(e)} id="project_logo" required /> */}

                                                        </div>
                                                        {/*<div className="col-md-6">
                                                             <Label>{reqLabel('Site Map Image')}</Label><br />
                                                            < ReactImagePickerEditor
                                                                id="site_map_image_0"
                                                                name="site_map_image_0"
                                                                type="file"
                                                                config={config2}
                                                                imageSrcProp={initialImage}
                                                                imageChanged={(image) => {
                                                                    this.handleMediumImageUpload(image)
                                                                }} /> */}
                                                        {/* <AvField type="file" className="form-control" name="site_map_image_0" onChange={(e) => this.handleMediumImageUpload(e)} id="site_map_image" required /> 

                                                            <Dropzone
                                                                onDrop={acceptedFiles =>
                                                                    this.handleAcceptedFiles(acceptedFiles)
                                                                }
                                                            >
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div className="dropzone">
                                                                        <div
                                                                            className="dz-message needsclick"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input {...getInputProps()} name="uploaded_file" multiple />
                                                                            <div className="mb-3">
                                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                            </div>
                                                                            <h4>Drop files here or click to upload.</h4>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                            <div
                                                                className="dropzone-previews mt-3"
                                                                id="file-previews"
                                                            >
                                                                {this.state.selectedFiles.map((f, i) => {
                                                                    return (
                                                                        <Card
                                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                            key={i + "-file"}
                                                                        >
                                                                            <div className="p-2">
                                                                                <Row className="align-items-center">
                                                                                    <Col className="col-auto">
                                                                                        <iframe
                                                                                            src={f.preview}
                                                                                            frameBorder="0"
                                                                                            scrolling="none"
                                                                                            height="350px"
                                                                                            width="100%"
                                                                                        ></iframe>

                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Link
                                                                                            to="#"
                                                                                            className="text-muted font-weight-bold"
                                                                                        >
                                                                                            {f.name}
                                                                                        </Link>
                                                                                        <p className="mb-0">
                                                                                            <strong>{f.formattedSize}</strong>
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Card>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>*/}


                                                    </Row>

                                                </TabPane>
                                                <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                    <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab - 1); }}>Previous</Link></li>
                                                    {
                                                        this.state.activeTab == 2 ?
                                                            <li className="next">
                                                                <Button type="submit" form='my-form' color="success">Submit</Button>
                                                            </li>
                                                            :
                                                            <li className={this.state.activeTab === 2 ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab + 1); }}>Next</Link></li>

                                                    }
                                                </ul>
                                            </TabContent>

                                        </div>

                                        {/* </AvForm> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment >
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ProjectName;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createSubProjectName, clearSuccess, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload })(SubProjectNameCreate));