import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createDashboardSlider, dashboardList, logo_small_Upload, logo_medium_Upload, logo_large_Upload, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload } from '../../store/DashboardSlider/actions';
// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import { clearSuccess } from '../../store/FilterValue/actions';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class DashboardSliderCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            supplier: {

            },
            status: 0,

            slider_medium_image: {},
            slider_large_image: {},
            slider_thumbnail_image: {},
            imageSrc: '',
            site_map_image: {},
            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 25,

            displayHide: false,
            short_description: '',
            slider_details_description: '',


            brand: {

            },


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchDashboardListList();
        // this.fetchCityList();
        // this.fetchMarchantList();
    }

    fetchDashboardListList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        dashboardList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    DashboardList: response.data.data,
                });
                this.state.DashboardList = response.data.data;

                // console.log("Setting companySettingListLists: " + response.data.data[0]);
            } else {
                this.setState({
                    DashboardList: {

                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        if (values.slider_large_image.slider_large_image == undefined) {
            this.setState({
                error: "select slider large image",
            });
        } else {
            this.setState({
                displayHide: true
            })
            this.props.createDashboardSlider(values);
        }
        // this.props.createDashboardSlider(values);

    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));

    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handletHumbnailImageUpload = (image) => {

        const formData = { file: image, name: "slider_thumbnail_image", type: "image/jpeg" };


        this.state.slider_thumbnail_image["slider_thumbnail_image"] = formData;

    }


    handleLargeImageUpload = (image) => {

        const formData = { file: image, name: "slider_large_image", type: "image/jpeg" };

        this.state.slider_large_image["slider_large_image"] = formData;
    }

    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/slider/landingPage',
            }
        )
    }
    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        if (this.props.success != '') {
            this.state.displayHide = false;
        }

        return (
            <React.Fragment>
                {
                    this.state.displayHide == true ?
                        <div className="page-content" style={{ position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }}>
                            <h6 align="center">Loding...</h6>
                            <img style={{ position: 'center' }} src="https://i.pinimg.com/originals/8e/44/36/8e44367589f9f262759be288459c7fdc.gif" />
                            {this.state.error && this.state.error ?
                                        <SweetAlert
                                            danger
                                            title={this.state.error}
                                            timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.success && this.state.success ?
                                        <SweetAlert
                                            title={this.state.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onConfirm={() => window.location.reload()
                                            }
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.error && this.props.error ?
                                        <SweetAlert
                                            danger
                                            title={this.props.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.success && this.props.success ?
                                        <SweetAlert
                                            title={this.props.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                        </div>
                        :
                        <>
                            <div className="page-content">
                                <Container fluid={true}>

                                    <Breadcrumbs title="Settings" breadcrumbItem="Landing Page Slider Create" />
                                    {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                    {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                                    {this.props.error && this.props.error ?
                                        <SweetAlert
                                            danger
                                            title={this.props.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => this.clearErr()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.success && this.props.success ?
                                        <SweetAlert
                                            title={this.props.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            success
                                            onCancel={this.hideAlert}
                                            onConfirm={() => this.clear()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.error && this.state.error ?
                                        <SweetAlert
                                            danger
                                            title={this.state.error}
                                            timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onConfirm={() => this.clearErr()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.success && this.state.success ?
                                        <SweetAlert
                                            title={this.state.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onConfirm={() => this.clear()
                                            }
                                        >
                                        </SweetAlert> : ''
                                    }
                                    <Row>


                                        <Col lg="12" md="12">
                                            <Card>
                                                <CardBody>

                                                    <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Slider Title')} id="slider_title" name="slider_title" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" />
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label>Short Description</Label>
                                                                    <CKEditor
                                                                        editor={ClassicEditorDescription}
                                                                        data={this.state.short_description}
                                                                        onReady={editor => {
                                                                            this.setState({
                                                                                short_description: this.state.short_description,
                                                                            });
                                                                            // You can store the "editor" and use when it is needed.
                                                                            // console.log( 'Editor is ready to use!', editor );
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            this.setState({
                                                                                short_description: data,
                                                                            });
                                                                        }}
                                                                        onBlur={(event, editor) => {
                                                                            // console.log( 'Blur.', editor );
                                                                        }}
                                                                        onFocus={(event, editor) => {
                                                                            // console.log( 'Focus.', editor );
                                                                        }}


                                                                    />
                                                                    <AvField name="short_description" value={this.state.short_description} className="form-control" type="hidden" />
                                                                </FormGroup>

                                                            </div>
                                                            <div className="col-md-6">
                                                                <FormGroup>
                                                                    <Label>Details Description</Label>

                                                                    <CKEditor
                                                                        editor={ClassicEditorDescription}
                                                                        data={this.state.slider_details_description}
                                                                        onReady={editor => {
                                                                            this.setState({
                                                                                slider_details_description: this.state.slider_details_description,
                                                                            });
                                                                            // You can store the "editor" and use when it is needed.
                                                                            // console.log( 'Editor is ready to use!', editor );
                                                                        }}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            this.setState({
                                                                                slider_details_description: data,
                                                                            });
                                                                        }}
                                                                        onBlur={(event, editor) => {
                                                                            // console.log( 'Blur.', editor );
                                                                        }}
                                                                        onFocus={(event, editor) => {
                                                                            // console.log( 'Focus.', editor );
                                                                        }}


                                                                    />
                                                                    <AvField name="slider_details_description" value={this.state.slider_details_description} className="form-control" type="hidden" />
                                                                </FormGroup>

                                                            </div>

                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <Label>{reqLabel('Status')}</Label><br />
                                                                <AvField type="select" className="form-control" id="Status" name="Status"   >
                                                                    <option value="1">Active</option>
                                                                    <option value="0">Inactive</option>
                                                                </AvField>
                                                            </div>

                                                        </Row>
                                                        <AvField type="hidden" value={this.state.slider_large_image} name="slider_large_image" id="slider_large_image" />

                                                    </AvForm>




                                                    <Row>
                                                        <div className="col-md-6">
                                                            <Label>{reqLabel(' Image')}</Label><br />
                                                            <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 2048px * 1080px</small>
                                                            < ReactImagePickerEditor
                                                                id="slider_large_image_0"
                                                                name="slider_large_image_0"
                                                                type="file"
                                                                config={config2}
                                                                imageSrcProp={initialImage}
                                                                imageChanged={(image) => {
                                                                    this.handleLargeImageUpload(image)
                                                                }} />

                                                        </div>
                                                    </Row>




                                                    <div className=" mt-4">
                                                        <Button type="submit" form='my-form' color="success" style={{float: 'right'}}>Submit</Button>
                                                    </div>


                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </>
                }

            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createDashboardSlider, clearSuccess, favicon_Upload, our_vision_image_upload, about_us_cover_image_1_Upload, about_us_cover_image_2_Upload, privacy_policy_cover_image_1_Upload, privacy_policy_cover_image_2_Upload, contact_us_cover_image_1_Upload, contact_us_cover_image_2_Upload })(DashboardSliderCreate));