import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Role from "./auth/role/reducer";
import BasicCompanySettings from "./BasicCompanySettings/reducer";
import AboutUsPageSlider from "./AboutUsPageSlider/reducer";
import DashboardSlider from "./DashboardSlider/reducer";
import Gallery from "./Gallery/reducer";
import Upcoming from "./Upcoming/reducer";
import FilterName from "./FilterName/reducer";
import FilterValue from "./FilterValue/reducer";
import District from "./District/reducer";

import City from "./CITY/reducer";
import Book from "./Book/reducer";

import HomeFeatures from "./homefeatures/reducer"
import PrivacyPolicy from "./privacyPolicy/reducer"
import ProjectName from "./ProjectName/reducer"
import FloorPlan from "./FloorPlan/reducer"
import Lot from "./Lot/reducer"
import ProjectSlider from "./ProjectSlider/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  BasicCompanySettings,
  AboutUsPageSlider,
  ProjectName,
  FloorPlan,
  DashboardSlider,
  Upcoming,
  FilterName,
  Lot,
  Gallery,
  ProjectSlider,

  FilterValue,
  District,
  City,
  Book,

  // Banner,
  // Product,
  // Supplier,
  // Warehouse,
  // WarehouseSupplierMap,
  // ProductStock,

  Profile,
  Role,

  HomeFeatures,
  PrivacyPolicy,

});

export default rootReducer;
