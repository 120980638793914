import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {CREATE_ProjectSlider,CREATE_SubProjectSlider,EDIT_ProjectSlider,EDIT_SubProjectSlider,SHOW_ProjectSlider_SUCCESS,SHOW_SHOW_ProjectSlider,SHOW_ProjectSlider_ERROR,CLEAR_MESSAGE} from './actionTypes';

import { brandSuccess, ProjectSliderError,brandShow, merchantError, ProjectSliderSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* CREATE_createProjectSliders({ payload: { ProjectSlider } }) {
    console.log("SHOW_ProjectSlider",ProjectSlider);

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'ProjectSlider/create',ProjectSlider);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(ProjectSliderError(response.message.message));
            }else if(response.success == true){
                yield put(ProjectSliderSuccess(response.data.message));
                if(response.success == true){
                    // setTimeout(() => {
                    //     this.props.history.push({
                    //         pathname: '/index/ProjectSlider',
                    //     });
                    // }, 2000)
                }
            } 
          }
    } catch (error) {
        yield put(ProjectSliderError("Can not Create !"));
    }
}
function* CREATE_createSubProjectSliders({ payload: { ProjectSlider } }) {
    try {
    //    console.log("SHOW_ProjectSlider",ProjectSlider);
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'ProjectSlider/sub/create',ProjectSlider);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(ProjectSliderError(response.message.message));
            }else if(response.success == true){
                yield put(ProjectSliderSuccess(response.data.message));
                if(response.success == true){
                    // setTimeout(() => {
                    //     this.props.history.push({
                    //         pathname: '/index/ProjectSlider',
                    //     });
                    // }, 2000)
                }
            } 
          }
    } catch (error) {
        yield put(ProjectSliderError("Can not Create !"));
    }
}


function* EDIT_createProjectSliders({ payload: { ProjectSlider } }) {
    // console.log("saga ---- ",ProjectSlider);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'ProjectSlider/'+ProjectSlider.id, ProjectSlider);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(ProjectSliderError(response.message.message));
            }else if(response.success == true){
                yield put(ProjectSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(ProjectSliderError(error));
    }
}
function* EDIT_createSubProjectSliders({ payload: { ProjectSlider } }) {
    // console.log("saga ---- ",ProjectSlider);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'ProjectSlider/sub/'+ProjectSlider.id, ProjectSlider);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(ProjectSliderError(response.data.message));
            }else if(response.success == true){
                yield put(ProjectSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(ProjectSliderError(error));
    }
}

function* EDIT_FilterClearMessage({ payload: { message } }) {
    try {
        if (message.valueSuccess) {
            console.log(message.valueSuccess);
            yield put(ProjectSliderSuccess(""));
            yield put(ProjectSliderError(""));
        } else {
            yield put(ProjectSliderSuccess(""));
            yield put(ProjectSliderError(""));
        }
    } catch (error) {
            yield put(ProjectSliderSuccess(""));
            yield put(ProjectSliderError(""));
    }
}

export function* watchProjectSlider() {
    yield takeEvery(EDIT_ProjectSlider, EDIT_createProjectSliders)
    yield takeEvery(EDIT_SubProjectSlider, EDIT_createSubProjectSliders)
    yield takeEvery(CREATE_SubProjectSlider, CREATE_createSubProjectSliders)
    yield takeEvery(CREATE_ProjectSlider, CREATE_createProjectSliders)
    yield takeEvery(CLEAR_MESSAGE, EDIT_FilterClearMessage)
}

function* ProjectSlider() {
    yield all([
        fork(watchProjectSlider),
    ]);
}

export default ProjectSlider;