import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteVoucher, editAboutUsPageSlider, AboutUsPageSliderList, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload } from '../../store/AboutUsPageSlider/actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import DeleteModal from './DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import classnames from 'classnames';
import renderHTML from 'react-render-html';




const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class AboutUsPageSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            AboutUsPageSlider_list: {},
            deleteModal: false,


            supplier: {

            },
            change_slider_medium_image: {},
            change_slider_large_image: {},
            change_slider_thumbnail_image: {},

            displayHide: false,


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchAboutUsPageSliderList();
        // this.fetchCityList();
        if (localStorage.getItem('last_action') == 'create') {
            localStorage.setItem('last_action', "");

            if (this.props.success != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);
            }
            if (this.props.error != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);

            }
        }
    }

    fetchAboutUsPageSliderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        AboutUsPageSliderList(options).then((response) => {
            //   console.log(response);
            this.setState({
                AboutUsPageSliderList: response.data,
            });
            this.state.AboutUsPageSliderList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }



    //modal
    tog_scroll = (AboutUsPageSlider) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.AboutUsPageSlider_list = AboutUsPageSlider;

        this.state.change_slider_thumbnail_image = {};
        this.state.change_slider_large_image = {};
        this.state.change_slider_medium_image = {};


        this.removeBodyCss();
    }


    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchAboutUsPageSliderList();
    }



    toggleDeleteModal = (upcoming) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(upcoming);
    };

    onClickDelete = (project) => {
        console.log("Delete Modal1", project);
        this.setState({ deleteProject: project });
        this.setState({ deleteModal: true });

    };

    handleDeleteSubmit(values) {

        const { deleteProject } = this.state;

        if (deleteProject != undefined) {
            deleteVoucher(deleteProject.id)
                .then((response) => {
                    if (response.success == true) {
                        //   console.log(response)
                        // alert(response.data);
                        this.fetchAboutUsPageSliderList();


                        // window.location.reload();
                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,

                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }


    handleEditSubmit(event, values) {
        this.props.editAboutUsPageSlider(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.setState({
            displayHide: true
        })
        this.fetchAboutUsPageSliderList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchAboutUsPageSliderList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchAboutUsPageSliderList();
        });
    }



    handleImageUpload_slider_large_image = (image) => {
        const formData = { file: image, name: "change_slider_large_image", type: "image/jpeg" };

        this.state.change_slider_large_image["change_slider_large_image"] = formData;
    }

    handleImageUpload_slider_medium_image = (image) => {
        const formData = { file: image, name: "change_slider_medium_image", type: "image/jpeg" };

        this.state.change_slider_medium_image["change_slider_medium_image"] = formData;
    }

    handleImageUpload = (image) => {
        const formData = { file: image, name: "change_slider_thumbnail_image", type: "image/jpeg" };

        this.state.change_slider_thumbnail_image["change_slider_thumbnail_image"] = formData;
    }



    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let AboutUsPageSliderListtMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        if (this.state.hasOwnProperty('AboutUsPageSliderList')) {
            AboutUsPageSliderListtMap = this.state.AboutUsPageSliderList.data;
            paginator = {
                'currentPage': this.state.AboutUsPageSliderList.current_page,
                'pagesCount': this.state.AboutUsPageSliderList.last_page,
                'total': this.state.AboutUsPageSliderList.total,
                'to': this.state.AboutUsPageSliderList.to,
                'from': this.state.AboutUsPageSliderList.from,
            }
        }
        const { deleteModal, message } = this.state;

        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };


        let singlevoucher = this.state.voucher;

        const { code, slider_header_title, id, slider_title, short_description, long_descriptiom, status, slider_thumbnail_image, slider_large_image, slider_medium_image, remarks, priority } = this.state.AboutUsPageSlider_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        //   console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }
        if (this.props.success != '') {
            this.state.displayHide = false;
        }

        return (
            <React.Fragment>
                {
                    this.state.displayHide == true ?
                        <div className="page-content" style={{ position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }}>
                            <h6 align="center">Loding...</h6>
                            <img style={{ position: 'center' }} src="https://i.pinimg.com/originals/8e/44/36/8e44367589f9f262759be288459c7fdc.gif" />
                            {this.state.error && this.state.error ?
                                        <SweetAlert
                                            danger
                                            title={this.state.error}
                                            timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.success && this.state.success ?
                                        <SweetAlert
                                            title={this.state.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onConfirm={() => window.location.reload()
                                            }
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.error && this.props.error ?
                                        <SweetAlert
                                            danger
                                            title={this.props.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.success && this.props.success ?
                                        <SweetAlert
                                            title={this.props.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                        </div>
                        :
                        <>
                            <div className="page-content">
                                <Container fluid>

                                    <Breadcrumbs title="Settings" breadcrumbItem="Management Info" />
                                    {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                                    {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                                    {this.state.error && this.state.error ?
                                        <SweetAlert
                                            danger
                                            title={this.state.error}
                                            timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.success && this.state.success ?
                                        <SweetAlert
                                            title={this.state.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onConfirm={() => window.location.reload()
                                            }
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.error && this.props.error ?
                                        <SweetAlert
                                            danger
                                            title={this.props.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.success && this.props.success ?
                                        <SweetAlert
                                            title={this.props.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }


                                    <DeleteModal
                                        show={deleteModal}
                                        onDeleteClick={() => this.handleDeleteSubmit()}
                                        onCloseClick={() => this.setState({ deleteModal: false })}
                                    />
                                    <Row>
                                        <Col lg="12" md="12">
                                            <Card>
                                                <CardHeader className="px-0">
                                                    <Link to={'/create/management/info'}><Button className="btn btn-success pull-right text-uppercase">Add Management Info</Button></Link>
                                                </CardHeader>
                                                <CardBody>

                                                    <CardTitle>Management Info List</CardTitle>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                            <Table responsive>

                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Code</th>
                                                                        <th>Name</th>
                                                                        <th>Short Bio</th>
                                                                        <th>Image</th>
                                                                        {/* <th>Remark</th> */}
                                                                        <th>Priority</th>
                                                                        <th>Status</th>
                                                                        <th colSpan="2">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {AboutUsPageSliderListtMap.map((companySetting, index) =>
                                                                        <tr key={companySetting.id.toString()}>
                                                                            <td scope="row">{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                                            <td>{companySetting.code}</td>
                                                                            <td>{companySetting.slider_header_title}</td>
                                                                            {/* <td>{companySetting.short_description}</td> */}
                                                                            <td>{companySetting.short_description ? renderHTML(companySetting.short_description) : ''}</td>

                                                                            <td>
                                                                                {
                                                                                    companySetting.slider_large_image != null ?
                                                                                        (
                                                                                            <img src={imageLink + 'company_settings/aboutus/slider_large_image/' + companySetting.slider_large_image} alt="" height="50"  style={{objectFit:'contain'}} />
                                                                                        ) : null
                                                                                }
                                                                            </td>

                                                                            {/* <td>{companySetting.remarks}</td> */}
                                                                            <td>{companySetting.priority}</td>
                                                                            <td><Badge color={getBadge(companySetting.status)}>{getStatusValue(companySetting.status)}</Badge></td>

                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <i type="button"
                                                                                        className="fa fa-edit  text-warning fa-2x my-2 ml-2"
                                                                                        onClick={() => { this.tog_scroll(companySetting) }}
                                                                                    ></i>
                                                                                    <i
                                                                                        className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                                        onClick={() => { this.toggleDeleteModal(companySetting) }}
                                                                                    ></i>
                                                                                </div>

                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                                {paginator &&
                                                    <CardFooter className="p-6">
                                                        <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                                    </CardFooter>
                                                }
                                            </Card>


                                            <Modal
                                                size="xl"
                                                isOpen={this.state.modal_scroll}
                                                toggle={this.tog_scroll}
                                                scrollable={true}
                                            >
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0">Management Info Updated</h5>
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            this.setState({ modal_scroll: false })
                                                        }
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">

                                                    <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                                        {this.props.msg && this.props.msg ? (
                                                            <Alert color="success">
                                                                Management Info Updated Successfully!
                                                            </Alert>
                                                        ) : null}
                                                        <Row>
                                                            <AvField className="form-control" type="hidden" id="id" name="id" value={id} />

                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Position/Designation')} id="slider_title" name="slider_title" value={slider_title} required />

                                                                <Label>Short Bio</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={short_description && short_description != null ? short_description : ""}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            short_description: short_description,
                                                                        });
                                                                        // You can store the "editor" and use when it is needed.
                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            short_description: data,
                                                                        });
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        // console.log( 'Focus.', editor );
                                                                    }}


                                                                />
                                                                <AvField name="short_description" value={this.state.short_description} className="form-control" type="hidden" />

                                                            </div>

                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Name')} id="slider_header_title" value={slider_header_title} name="slider_header_title" required />

                                                                <Label>Long Bio</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={long_descriptiom && long_descriptiom != null ? long_descriptiom : ""}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            long_descriptiom: long_descriptiom,
                                                                        });
                                                                        // You can store the "editor" and use when it is needed.
                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            long_descriptiom: data,
                                                                        });
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        // console.log( 'Focus.', editor );
                                                                    }}


                                                                />
                                                                <AvField name="long_descriptiom" value={this.state.long_descriptiom} className="form-control" type="hidden" />
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={priority} required />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" value={remarks} />
                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField type="select" label={reqLabel('Status')} className="custom-select" name="Status" value={status}   >
                                                                    {/* <option defaultValue>Select Status</option> */}
                                                                    <option value="0" >Inactive</option>
                                                                    <option value="1">Active</option>
                                                                </AvField>
                                                            </div>
                                                        </Row>
                                                        <AvField type="hidden" value={this.state.change_slider_large_image} name="change_slider_large_image" id="change_slider_large_image" />
                                                        <AvField type="hidden" value={slider_large_image} name="slider_large_image" id="slider_large_image" />
                                                        <AvField type="hidden" value={this.state.change_slider_medium_image} name="change_slider_medium_image" id="change_slider_medium_image" />
                                                        <AvField type="hidden" value={slider_medium_image} name="slider_medium_image" id="slider_medium_image" />
                                                        <AvField type="hidden" value={this.state.change_slider_thumbnail_image} name="change_slider_thumbnail_image" id="change_slider_thumbnail_image" />
                                                        <AvField type="hidden" value={slider_thumbnail_image} name="slider_thumbnail_image" id="slider_thumbnail_image" />

                                                    </AvForm>

                                                    <Row>

                                                        <div className="col-md-6">
                                                            <Label>{reqLabel('Image')}</Label><br />

                                                            {
                                                                slider_large_image != null ?
                                                                    (
                                                                        <img src={imageLink + 'company_settings/aboutus/slider_large_image/' + slider_large_image} alt="" className="w-100 h-50 rounded mt-2 mb-2"  style={{objectFit:'contain'}}
                                                                        />
                                                                    ) : null
                                                            }

                                                        </div>
                                                        <div className="col-md-6">

                                                            <Label>Want to change?</Label>
                                                            <br />
                                                            <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 250px * 250px</small>

                                                            < ReactImagePickerEditor
                                                                id="slider_large_image_0"
                                                                name="slider_large_image_0"
                                                                type="file"
                                                                config={config2}
                                                                imageSrcProp={initialImage}
                                                                imageChanged={(image) => {
                                                                    this.handleImageUpload_slider_large_image(image)
                                                                }} />

                                                        </div>


                                                    </Row>





                                                    <div className="text-center mt-4">
                                                        <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                        <button type="submit" form='my-form' className="btn btn-success save-event">Save</button>
                                                    </div>


                                                </div>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </>

                }

            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps, { editAboutUsPageSlider, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload })(AboutUsPageSlider));
