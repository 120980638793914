import {CREATE_ProjectSlider,CREATE_SubProjectSlider,EDIT_SubProjectSlider,CLEAR_MESSAGE,EDIT_ProjectSlider,ProjectSlider_ERROR,SHOW_ProjectSlider,ProjectSlider_SUCCESS } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createProjectSlider = (ProjectSlider) => {
    return {
        type: CREATE_ProjectSlider,
        payload: { ProjectSlider }
    }
}
export const createSubProjectSlider = (ProjectSlider) => {
    return {
        type: CREATE_SubProjectSlider,
        payload: { ProjectSlider }
    }
}


export const editProjectSlider = (ProjectSlider) => {
    return {
        type: EDIT_ProjectSlider,
        payload: { ProjectSlider }
    }
}
export const editSubProjectSlider = (ProjectSlider) => {
    return {
        type: EDIT_SubProjectSlider,
        payload: { ProjectSlider }
    }
}
export const clearSuccess = (valueSuccess) => {
    let message = {valueSuccess};

    return {
        type: CLEAR_MESSAGE,
        payload: { message }
    }
}
export function ProjectSliderList(params){
    return getApi().get('ProjectSlider/list',{
        params: params
    })
}

export function ProjectBrochureList(params){
    return getApi().get('ProjectSlider/brochure/list',{
        params: params
    })
}
export function ParentProjectSliderList(params){
    return getApi().get('ProjectSlider/parent/list',{
        params: params
    })
}

export function SpecificParentProjectSliderList(id){
    return getApi().get('ProjectSlider/parent/specific/'+id)
}


export function SubProjectSliderList(params){
    return getApi().get('ProjectSlider/subProject/list',{
        params: params
    })
}

export function SubProjectList(params){
    return getApi().get('ProjectSlider/subProject/list',{
        params: params
    })
}
export function SubListSpecific(id){
    return getApi().get('ProjectSlider/subProject/list/'+id)
}
export function SubListSitemapSpecific(id){
    return getApi().get('ProjectSlider/subProject/sitemap/list/'+id)
}

export function ProjectSliderDetails(id){
    return getApi().get('ProjectSlider/Details/'+id)
}



export const ProjectSliderSuccess = (msg) => {
    return {
        type: ProjectSlider_SUCCESS,
        payload: msg
    }
}

export const ProjectSliderError = (msg) => {
    return {
        type: ProjectSlider_ERROR,
        payload: msg
    }
}

export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_ProjectSlider,
        payload: { voucher_id }
    }
}

export function deleteVoucher(voucherId){
    return getApi().delete('ProjectSlider/'+voucherId)
}

export function deleteBrochure(voucherId){
    return getApi().delete('ProjectSlider/brochure/'+voucherId)
}
export function orderShow(order_id){
    return getApi().get('vouchar/generates/show/'+order_id)
}

export function slider_large_image_Upload(params,id){
     if(id){
         return getApi().put('ProjectSlider/large_image/edit/'+id,params)
     }
 }

 export function slider_medium_image_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('ProjectSlider/medium_image/edit/'+id,params)
     }
 }

 export function slider_thumbnail_image_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('ProjectSlider/thumbnail_image/edit/'+id,params)
     }
 }

 export function favicon_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/favicon/edit/'+id,params)
     }
 }

 export function our_vision_image_upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/our_vision_image/edit/'+id,params)
     }
 }

 export function about_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_1/edit/'+id,params)
     }
 }
 export function about_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_2/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_1/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_2/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_1/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_2/edit/'+id,params)
     }
 }




