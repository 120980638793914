import { AboutUsPageSlider_ERROR,AboutUsPageSlider_SUCCESS,EDIT_ProjectName } from './actionTypes';

const initialState = {
    error: "",
    success : ""
}

const ProjectName = (state = initialState, action) => {
    switch (action.type) {
     
        case EDIT_ProjectName:
            state = { ...state }
            break;
        case AboutUsPageSlider_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case AboutUsPageSlider_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ProjectName;