import { all } from 'redux-saga/effects';

//public
import AccountSaga from './auth/register/saga';
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forgetpwd/saga';
import ProfileSaga from './auth/profile/saga';
// import TransactionSaga from './transaction/saga';
import BasicCompanySettingsSaga from './BasicCompanySettings/saga';
import AboutUsPageSlider from './AboutUsPageSlider/saga';

import DashboardSlider from './DashboardSlider/saga';
import Gallery from './Gallery/saga';
import Upcoming from './Upcoming/saga';

import FilterName from './FilterName/saga';
import FilterValue from './FilterValue/saga';
import District from './District/saga';
import City from './CITY/saga';
import Book from './Book/saga';
// import FuelPumpMapSaga from './fuelPumpMap/saga';
// import FuelSaga from './fuel/saga';

// import BannerSaga from './banner/saga';
// import ProductSaga from './product/saga';
import LayoutSaga from './layout/saga';
// import SupplierSaga from './productSupplier/saga';
// import WarehouseSaga from "./warehouse/saga";
// import WarehouseSupplierMapSaga from "./warehouseSupplierMap/saga";
// import productStockSaga from "./productStock/saga";
import HomeFeaturesSaga from "./homefeatures/saga";
import PrivacyPolicySaga from "./privacyPolicy/saga";
// import CourierSaga from "./courier/saga";
import RoleSaga from './auth/role/saga';
import ProjectName from './ProjectName/saga';
import FloorPlan from './FloorPlan/saga';
import Lot from './Lot/saga';
import ProjectSlider from './ProjectSlider/saga';
// import MerchantSaga from './merchant/saga';
// import MerchantBranchSaga from './merchantBranch/saga';
// import VoucherSaga from './voucher/saga';

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        AuthSaga(),
        ForgetSaga(),
        BasicCompanySettingsSaga(),
        AboutUsPageSlider(),
        Lot(),
        DashboardSlider(),
        Upcoming(),
        Gallery(),
        ProjectName(),
        FloorPlan(),
        ProjectSlider(),
        FilterName(),
        FilterValue(),
        District(),
        ProfileSaga(),
        RoleSaga(),
        City(),
        Book(),
        // PetrolPumpSaga(),
        // FuelPumpMapSaga(),
        // FuelSaga(),
        // BrandSaga(),
        // BannerSaga(),
        // ProductSaga(),
        // SupplierSaga(),
        // WarehouseSaga(),
        // WarehouseSupplierMapSaga(),
        // productStockSaga(),
        LayoutSaga(),
        HomeFeaturesSaga(),
        PrivacyPolicySaga(),
        // CourierSaga(),
        // MerchantSaga(),
        // MerchantBranchSaga(),
        // VoucherSaga(),
    ])
}