import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, Label, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteBook, filterBookList, editBook } from '../../store/Book/actions';
import { filterNameList } from '../../store/FilterName/actions';
import { clearSuccess } from '../../store/FilterValue/actions';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import DeleteModal from './DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class BookList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            filterBookList_list: {},
            deleteModal: false,


            supplier: {

            },
            project_cover_image_1: {},
            upcoming_project_image: {},

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchBookList();
        if (localStorage.getItem('last_action') == 'create') {
            localStorage.setItem('last_action', "");

            if (this.props.success != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);
            }
            if (this.props.error != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);

            }
        }

    }

    fetchBookList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        filterBookList(options).then((response) => {
            console.log("response", response);
            this.setState({
                filterBookList: response.data,
            });
            this.state.filterBookList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }




    //modal
    tog_scroll = (filterBookList) => {
        this.props.history.push({
            pathname: '/book/edit/' + filterBookList.Id,
            state: { value: filterBookList, }
        });
    }

    tog_scroll_book = (filterBookList) => {
        this.props.editBook(filterBookList);
        this.fetchBookList();
    }
    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchBookList();
    }

    toggleDeleteModal = (upcoming) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(upcoming);
    };

    onClickDelete = (project) => {
        console.log("Delete Modal1", project);
        this.setState({ deleteProject: project });
        this.setState({ deleteModal: true });

    };

    handleDeleteSubmit(values) {

        const { deleteProject } = this.state;
        console.log("Delete Modal22222222", deleteProject);

        if (deleteProject != undefined) {
            deleteBook(deleteProject.Id)
                .then((response) => {
                    if (response.success == true) {
                        this.fetchBookList();
                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,
                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }



    handleEditSubmit(event, values) {
        this.props.editBook(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchBookList();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchBookList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchBookList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchBookList();
        });
    }

    handleImageUpload = (image) => {

        console.log('Image Upload', image);
        const formData = { file: image, name: "project_cover_image_1", type: "image/jpeg" };
        this.state.project_cover_image_1["project_cover_image_1"] = formData;
    }
    ////
    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.state.success = null;
    }

    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
        this.state.error = null;
    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let filterBookListInfo = [];
        let BookList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        if (this.state.hasOwnProperty('filterBookList')) {
            filterBookListInfo = this.state.filterBookList.data;
            paginator = {
                'currentPage': this.state.filterBookList.current_page,
                'pagesCount': this.state.filterBookList.last_page,
                'total': this.state.filterBookList.total,
                'to': this.state.filterBookList.to,
                'from': this.state.filterBookList.from,
            }
        }
        let filterNameListInfo = [];
        if (this.state.hasOwnProperty('filterNameList')) {
            filterNameListInfo = this.state.filterNameList.data;
        }

        let singlevoucher = this.state.voucher;

        const { id, code, Buyer_Serial_No_Contract, Buyer_Serial_No_LOI, f_floore_plans_id, f_project_names_id, Block, Book, Model, SQFT, status, Books_image_1 } = this.state.filterBookList_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }

        const { deleteModal, message } = this.state;

        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            compressInitial: null,
        };
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Settings" breadcrumbItem="Book List" />
                        {this.state.error && this.state.error ?
                            <SweetAlert
                                danger
                                title={this.state.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.success && this.state.success ?
                            <SweetAlert
                                title={this.state.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}

                                onConfirm={() =>
                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}

                                onConfirm={() =>
                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }

                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={() => this.handleDeleteSubmit()}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader className="px-0">
                                        {/* <Link to="/multiple/create/Book">
                                            <button className="btn btn-primary mx-2"><i className="mdi mdi-plus-circle-outline me-1" />Book Bulk Insert</button>
                                        </Link> */}
                                        <Link to="/book/excel/download">
                                            <button className="btn btn-primary mr-2"><i className="mdi mdi-plus-circle-outline me-1" />Excel Download</button>
                                        </Link>
                                    </CardHeader>
                                    <CardBody>

                                        <CardTitle>Book List</CardTitle>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                <Table responsive>

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Buyer Name</th>
                                                            <th>Buyer Address</th>
                                                            <th>Buyer City</th>
                                                            <th>Buyer Email</th>
                                                            <th>Buyer Phone</th>
                                                            <th>Lot</th>
                                                            <th>Model</th>
                                                           
                                                            <th >Action</th>
                                                            <th >Book Now</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filterBookListInfo && filterBookListInfo.map((filterBook, index) =>
                                                            <tr key={filterBook.Id.toString()}>
                                                                <td scope="row">{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                                <td>{filterBook.Buyer_First_Name}</td>
                                                                <td>{filterBook.Buyer_Address_1}</td>
                                                                <td>{filterBook.Buyer_City}</td>
                                                                <td>{filterBook.Buyer_Email}</td>
                                                                <td>{filterBook.Buyer_Phone}</td>
                                                                <td>{filterBook.Lot}</td>
                                                                <td>{filterBook.Model}</td>
                                                               
                                                                <td>

                                                                    <i type="button"
                                                                        className="fa fa-edit  text-warning fa-2x my-2 ml-2"
                                                                        onClick={() => { this.tog_scroll(filterBook) }}
                                                                    ></i>
                                                                    <i
                                                                        className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                        onClick={() => { this.toggleDeleteModal(filterBook) }}
                                                                    ></i>
                                                                </td>
                                                                <td>
                                                                    {filterBook.Is_booked == 0 ?
                                                                        <i type="button"
                                                                            className="fa fa-book  text-warning fa-2x my-2 ml-2"
                                                                            onClick={() => { this.tog_scroll_book(filterBook) }}
                                                                        ></i>
                                                                        :
                                                                        <Badge className="bg-success">Booked</Badge>
                                                                    }

                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CardBody>
                                    {paginator &&
                                        <CardFooter className="p-6">
                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                        </CardFooter>
                                    }
                                </Card>
                                <Modal
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.tog_scroll}
                                    scrollable={true}
                                    size="xl"
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">{code}</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} ref={c => (this.form = c)}>
                                            {this.props.msg && this.props.msg ? (
                                                <Alert color="success">
                                                    Book Updated Successfully!
                                                </Alert>
                                            ) : null}
                                            <Row>
                                                <AvField className="form-control" type="hidden" id="id" name="id" value={id} />
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Buyer Serial No Contract')} id="Buyer_Serial_No_Contract" name="Buyer_Serial_No_Contract" value={Buyer_Serial_No_Contract} />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Buyer Serial No LOI')} id="Buyer_Serial_No_LOI" name="Buyer_Serial_No_LOI" value={Buyer_Serial_No_LOI} />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Floor Plan Id')} id="f_floore_plans_id" name="f_floore_plans_id" value={f_floore_plans_id} />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Communities Id')} id="f_project_names_id" name="f_project_names_id" value={f_project_names_id} />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Block')} id="Block" name="Block" value={Block} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Book')} id="Book" name="Book" value={Book} required />
                                                </div>
                                            </Row>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Model')} id="Model" name="Model" value={Model} required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('SQFT')} id="SQFT" name="SQFT" value={SQFT} required />
                                                </div>
                                                <AvField className="form-control" type="hidden" id="project_cover_image_1" name="project_cover_image_1" value={this.state.project_cover_image_1 && this.state.project_cover_image_1} />
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <Label>{reqLabel('Image')}</Label><br />
                                                    {
                                                        Books_image_1 != null ?

                                                            (
                                                                <img src={imageLink + 'Book/Image/Books_image_1/' + Books_image_1} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                            ) : null
                                                    }
                                                </div>
                                                <div className="col-md-6">
                                                    <Label>Want to change?</Label>
                                                    < ReactImagePickerEditor
                                                        id="slider_large_image_0"
                                                        name="slider_large_image_0"
                                                        type="file"
                                                        config={config2}
                                                        imageSrcProp={initialImage}
                                                        imageChanged={(image) => {
                                                            this.handleImageUpload(image)
                                                        }} />
                                                </div>
                                            </Row>
                                            <div className="text-center mt-4">
                                                <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                <button type="submit" className="btn btn-success save-event">Save</button>
                                            </div>
                                        </AvForm>

                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { clearSuccess, editBook })(BookList));