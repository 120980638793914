import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createUpcoming, upcomingList,logo_small_Upload,logo_medium_Upload,logo_large_Upload ,favicon_Upload,our_vision_image_upload,about_us_cover_image_1_Upload,about_us_cover_image_2_Upload,privacy_policy_cover_image_1_Upload,privacy_policy_cover_image_2_Upload,contact_us_cover_image_1_Upload,contact_us_cover_image_2_Upload} from '../../store/Upcoming/actions';
// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';



const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class UpcomingCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            supplier: {

            },
            status: 0,

            upcoming_project_image: {},

            displayHide: false,

            project_short_description : '',

            brand: {

            },


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchDashboardListList();
        // this.fetchCityList();
        // this.fetchMarchantList();
    }

    fetchDashboardListList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        upcomingList(options).then((response) => {
            //   console.log(response);
            if(response.data != []){
                this.setState({
                    DashboardList: response.data.data,
                });
                this.state.DashboardList = response.data.data;
    
                // console.log("Setting companySettingListLists: " + response.data.data[0]);
            }else{
                this.setState({
                    DashboardList: {
                    
                    },

                });
            }
            

        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        if(values.upcoming_project_image.upcoming_project_image == undefined){
            this.setState({
                error:"select slider upcoming project image",
                displayHide: true
            });
        }else{ 
            this.setState({
                displayHide: true
            })
             this.props.createUpcoming(values);
        }

    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    // handletHumbnailImageUpload = (e) => {
    //     var id = e.target.id;
    //     let files = e.target.files;

    //     let reader = new FileReader();
    //     reader.readAsDataURL(files[0]);
    //     reader.onload = (e) => {
    //         const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
    //           console.log("formData", formData);

    //         this.state.upcoming_project_image[id] = formData;
    //         //  console.log("this.setState.voucharImage[0]",  this.state.voucharImage[id]);
    //     }
    // }

    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const formData = { file: image, name: "upcoming_project_image", type: "image/jpeg" };
        console.log("formData", formData);

        this.state.upcoming_project_image["upcoming_project_image"] = formData;

        // console.log("this.setState.project_cover_image_1[0]", this.state.project_cover_image_1["project_cover_image_1"]);


    }



   

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        const initialImage = '';


        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_project_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;


        if(this.props.success != '') {
            this.state.displayHide = false;
        }


        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        // console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }

        const clear = () =>{
            localStorage.setItem('last_action',"create");

            this.props.history.push(
                {
                    pathname: '/upcoming',
                }
            )

        }

        return (
            <React.Fragment>
                  {
                    this.state.displayHide == true ?
                        <div className="page-content" style={{position:'fixed',width:"100%",height:"100%",background:"rgba(255, 255, 255, 0.7)",zIndex:50000}}>
                            <h6 align="center">Loding...</h6>
                            <img style={{position:'center'}} src="https://i.pinimg.com/originals/8e/44/36/8e44367589f9f262759be288459c7fdc.gif" />
                            {this.props.error && this.props.error ?
                                                    <SweetAlert
                                                        danger
                                                        title={this.props.error}
                                                        // timeout={2000}
                                                        showConfirm={true}
                                                        showCloseButton={true}
                                                        onCancel={this.hideAlert}
                                                        onConfirm={() => window.location.reload()}
                                                    >
                                                    </SweetAlert> : ''
                                                }
                                                {this.props.success && this.props.success ?
                                                    <SweetAlert
                                                        title={this.props.success}
                                                        // timeout={2000}
                                                        showConfirm={true}
                                                        showCloseButton={true}
                                                        success
                                                        onCancel={this.hideAlert}
                                                        onConfirm={() => 
                                                            
                                                            clear()
                                                        
                                                        }
                                                    >
                                                    </SweetAlert> : ''
                                                }
                                                {this.state.error && this.state.error ?
                                                    <SweetAlert
                                                        danger
                                                        title={this.state.error}
                                                        // timeout={2000}
                                                        showConfirm={true}
                                                        showCloseButton={true}
                                                        onCancel={this.hideAlert}
                                                        onConfirm={() => window.location.reload()}
                                                    >
                                                    </SweetAlert> : ''
                                                }
                                                {this.state.success && this.state.success ?
                                                    <SweetAlert
                                                        title={this.state.success}
                                                        // timeout={2000}
                                                        showConfirm={true}
                                                        showCloseButton={true}
                                                        success
                                                        onCancel={this.hideAlert}
                                                        onConfirm={() => window.location.reload()}
                                                    >
                                                    </SweetAlert> : ''
                                                }
                        </div>
                        :
                        <>
                        <div className="page-content">
                                            <Container fluid={true}>

                                                <Breadcrumbs title="Settings" breadcrumbItem="Upcoming Projects Create" />
                                                {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                                {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                                                {this.props.error && this.props.error ?
                                                    <SweetAlert
                                                        danger
                                                        title={this.props.error}
                                                        // timeout={2000}
                                                        showConfirm={true}
                                                        showCloseButton={true}
                                                        onCancel={this.hideAlert}
                                                        onConfirm={() => window.location.reload()}
                                                    >
                                                    </SweetAlert> : ''
                                                }
                                                {this.props.success && this.props.success ?
                                                    <SweetAlert
                                                        title={this.props.success}
                                                        // timeout={2000}
                                                        showConfirm={true}
                                                        showCloseButton={true}
                                                        success
                                                        onCancel={this.hideAlert}
                                                        onConfirm={() => 
                                                            
                                                            clear()
                                                        
                                                        }
                                                    >
                                                    </SweetAlert> : ''
                                                }
                                                {this.state.error && this.state.error ?
                                                    <SweetAlert
                                                        danger
                                                        title={this.state.error}
                                                        // timeout={2000}
                                                        showConfirm={true}
                                                        showCloseButton={true}
                                                        onCancel={this.hideAlert}
                                                        onConfirm={() => window.location.reload()}
                                                    >
                                                    </SweetAlert> : ''
                                                }
                                                {this.state.success && this.state.success ?
                                                    <SweetAlert
                                                        title={this.state.success}
                                                        // timeout={2000}
                                                        showConfirm={true}
                                                        showCloseButton={true}
                                                        success
                                                        onCancel={this.hideAlert}
                                                        onConfirm={() => window.location.reload()}
                                                    >
                                                    </SweetAlert> : ''
                                                }
                                                <Row>


                                                    <Col lg="12" md="12">
                                                        <Card>
                                                            <CardBody>

                                                                <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>
                                                                
                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={reqLabel('Project Name')} id="upcoming_project_name" name="upcoming_project_name" required />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority"  required />
                                                                        </div>

                                                                    </Row>
                                                                    <Row>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="datetime-local" label={reqLabel('Start Date & Time')} id="project_starting_date_time" name="project_starting_date_time" required />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="datetime-local" label={reqLabel('End Date & Time')} id="project_ending_date_time" name="project_ending_date_time" required />
                                                                        </div>
                                                                    </Row>
                                                                    <Row> 
                                                                        <div className="col-md-6">
                                                                            <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks"  />
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <Label>{reqLabel('Status')}</Label><br/>
                                                                            <AvField type="select" className="form-control" name="Status"   >
                                                                                {/* <option defaultValue>Select Status </option> */}
                                                                                <option value="1">Active</option>
                                                                                <option value="0">Inactive</option>
                                                                            
                                                                            </AvField>
                                                                        </div>
                                                                    </Row> 
                                                                    <Row> 
                                                                    <div className="col-md-6">
                                                                            <FormGroup>
                                                                                <Label>Short Description</Label>
                                                                                <CKEditor
                                                                                    editor={ClassicEditorDescription}
                                                                                    data={this.state.project_short_description}
                                                                                    onReady={editor => {
                                                                                        this.setState({
                                                                                            project_short_description: this.state.project_short_description,
                                                                                        });
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                                    }}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        this.setState({
                                                                                            project_short_description: data,
                                                                                        });
                                                                                    }}
                                                                                    onBlur={(event, editor) => {
                                                                                        // console.log( 'Blur.', editor );
                                                                                    }}
                                                                                    onFocus={(event, editor) => {
                                                                                        // console.log( 'Focus.', editor );
                                                                                    }}


                                                                                />
                                                                                <AvField name="project_short_description" value={this.state.project_short_description} className="form-control" type="hidden" />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </Row>  
                                                                    <AvField type="hidden" value={ this.state.upcoming_project_image} name="upcoming_project_image" id="upcoming_project_image" />

                                                                    
                                                                </AvForm>

                                                                    <Row> 
                                                                        <div className="col-md-6">
                                                                            <Label>{reqLabel('Project Image')}</Label><br/>
                                                                            <small className="mb-2 text-danger font-weight-bold" style={{fontSize:'12px'}}>SIZE: 1920px * 1080px</small>

                                                                            {/* <AvField type="file" className="form-control" name="upcoming_project_image_0" onChange={(e) => this.handletHumbnailImageUpload(e)} id="upcoming_project_image"  required /> */}
                                                                            {/* <AvField type="hidden" value={ this.state.upcoming_project_image} name="upcoming_project_image" id="upcoming_project_image" /> */}
                                                                            < ReactImagePickerEditor
                                                                                id="upcoming_project_image_0"
                                                                                name="upcoming_project_image_0"
                                                                                type="file"
                                                                                config={config2}
                                                                                imageSrcProp={initialImage}
                                                                                imageChanged={(image) => {
                                                                                    this.handletHumbnailImageUpload(image)
                                                                            }} />
                                                                        
                                                                        </div>
                                                                        
                                                                    </Row>   
                                                                    <div className=" mt-4">
                                                                        <Button type="submit" form='my-form' color="success">Submit</Button>
                                                                    </div>

                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                        </>
                 }    
                
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createUpcoming ,favicon_Upload,our_vision_image_upload,about_us_cover_image_1_Upload,about_us_cover_image_2_Upload,privacy_policy_cover_image_1_Upload,privacy_policy_cover_image_2_Upload,contact_us_cover_image_1_Upload,contact_us_cover_image_2_Upload})(UpcomingCreate));