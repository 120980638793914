import React, { Component } from "react";
// import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";

import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { editSubProjectSlider, SubProjectList, SubListSpecific, ProjectSliderDetails, clearSuccess } from '../../store/ProjectSlider/actions';

// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";


import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import 'react-image-picker-editor/dist/index.css'
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class ProjectSliderEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            activeTabProgress: 1,
            selectedGroup: '',
            rows1: [],
            supplier: {

            },
            status: 0,

            change_site_map_image: {},
            change_project_logo: {},
            project_cover_image_2: {},
            change_project_cover_image_2: {},
            change_project_cover_image_1: {},

            brand: {

            },


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);

    }

    componentDidMount() {
        // console.log(this.props)
        if (this.props.location.state !== undefined) {
            console.log(this.props.location.state.value.id)
            this.fetchProjectSliderDetails(this.props.location.state.value.id)
            this.fetchSubListSpecific(this.props.location.state.value.id);
            this.fetchSubProjectList();
        }
        else {
            let id = window.location.href.split('/').pop();
            this.fetchProjectSliderDetails(id)
            this.fetchSubListSpecific(id);
            this.fetchSubProjectList();
        }
    }

    fetchProjectSliderDetails = (id) => {
        ProjectSliderDetails(id).then((response) => {
            console.log("ProjectSliderDetails", response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data[0],
                });
                this.state.ProjectNameList = response.data[0];
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchSubListSpecific = (id) => {
        SubListSpecific(id).then((response) => {
            console.log("SubListSpecific", response);

            console.log(response);
            if (response.data != []) {
                this.setState({
                    SubListSpecific: response.data[0].options[0],
                });
                this.state.SubListSpecific = response.data[0].options[0];

            } else {
                this.setState({
                    SubListSpecific: {

                    },

                });
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchSubProjectList = () => {
        SubProjectList().then((response) => {

            console.log("SubProjectList", response);
            if (response.data != []) {
                this.setState({
                    SubProjectList: response.data[0].options,
                });
                this.state.SubProjectList = response.data[0].options;

            }
        })
            .catch((err) => {
                console.log(err);
            })
    }
    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    handleValidSubmit(event, values) {
        console.log("values", values);
        // if (values.project_cover_image_1 == undefined) {
        //     this.setState({
        //         errorMessage: "Project Cover Image 1 is required",
        //     })
        // }
        // else {
        this.props.editSubProjectSlider(values);
        // }
    }
    handleAddRowNested = () => {
        const item1 = {
            name1: ""
        };
        this.setState({
            rows1: [...this.state.rows1, item1]
        });
    };

    handleRemoveRowNested = (e, idx) => {
        document.getElementById("nested" + idx).style.display = "none";
    };


    removeMessage() {
        this.setState({ errorMessage: '' });
    }

    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }


    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const formData = { file: image, name: "change_project_cover_image_1", type: "image/jpeg" };
        this.state.change_project_cover_image_1["change_project_cover_image_1"] = formData;

    }
    handleNewImageUpload = (index, image) => {
        console.log("index", index);
        const formData = { file: image, name: `project_cover_image_${index}`, type: "image/jpeg" };
        // //console.log("formData", formData);

        this.state.project_cover_image_2[index] = formData;
        console.log(formData);
    }
    handleLargeImageUpload = (image) => {

        const formData = { file: image, name: "change_project_cover_image_2", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_cover_image_2["change_project_cover_image_2"] = formData;
    }

    handleMediumImageUpload = (image) => {

        const formData = { file: image, name: "change_site_map_image", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_site_map_image["change_site_map_image"] = formData;
    }

    handleProjectLogoUpload = (image) => {

        const formData = { file: image, name: "change_project_logo", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_logo["change_project_logo"] = formData;
    }

    toggleTab(tab) {
        if (tab === 2 && (document.getElementById('project_name').value == '' || document.getElementById('project_name').value == null
            || document.getElementById('project_name').value == undefined)) {
            this.setState({
                errorMessage: "Project name is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_title').value == '' || document.getElementById('project_title').value == null
            || document.getElementById('project_title').value == undefined)) {
            this.setState({
                errorMessage: "Project Title is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_short_description').value == '' || document.getElementById('project_short_description').value == null
            || document.getElementById('project_short_description').value == undefined)) {
            this.setState({
                errorMessage: "Project Short Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_details_description').value == '' || document.getElementById('project_details_description').value == null
            || document.getElementById('project_details_description').value == undefined)) {
            this.setState({
                errorMessage: "Project Details Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('contact_information_description').value == '' || document.getElementById('contact_information_description').value == null
            || document.getElementById('contact_information_description').value == undefined)) {
            this.setState({
                errorMessage: "Contact Information Description is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_address').value == '' || document.getElementById('project_address').value == null
            || document.getElementById('project_address').value == undefined)) {
            this.setState({
                errorMessage: "Project Address is required",
            })
        }
        else if (tab === 2 && (document.getElementById('project_phone_no').value == '' || document.getElementById('project_phone_no').value == null
            || document.getElementById('project_phone_no').value == undefined)) {
            this.setState({
                errorMessage: "Project Phone No is required",
            })
        }
        else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
            || document.getElementById('priority').value == undefined)) {
            this.setState({
                errorMessage: "Priority is required",
            })
        }
        else {
            if (this.state.activeTab !== tab) {
                if (tab >= 1 && tab <= 4) {
                    this.setState({
                        activeTab: tab
                    });
                }
            }
        }

    }
    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/index/projectSlider',
            }
        )
    }
    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
    }
    handleSelectGroup(selectedGroup) {

        this.setState({ SubListSpecific: selectedGroup });
    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;

        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;


        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        const initialImage = '';

        console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }
        console.log("ProjectNameList", this.state.projectNameList)
        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Project Slider" />
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() => this.props.history.push(
                                    {
                                        pathname: '/index/projectSlider',
                                    }
                                )}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        {
                                            this.state.ProjectNameList &&

                                            <div data-repeater-list="outer-group" className="outer">

                                                <div data-repeater-item className="outer">
                                                    <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                        <div className="form-group">
                                                            <Label htmlFor="formname">Project name : </Label>
                                                            <Select
                                                                id="parent_project_id"
                                                                name="parent_project_id"
                                                                value={this.state.SubListSpecific}
                                                                onChange={this.handleSelectGroup}
                                                                options={this.state.SubProjectList}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                        <AvField type="hidden" name="project_id_value" id="project_id_value" value={this.state.SubListSpecific && this.state.SubListSpecific.value} required />
                                                        <AvField type="hidden" name="id" id="id" value={this.state.ProjectNameList && this.state.ProjectNameList.id} required />

                                                        <Row>
                                                            {/* <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Project Short Description')} id="project_short_description" name="project_short_description" value={this.state.ProjectNameList && this.state.ProjectNameList.short_description} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Project Long Descriptiom')} id="project_details_description" name="project_details_description" value={this.state.ProjectNameList && this.state.ProjectNameList.long_description} />
                                                            </div> */}
                                                            <AvField type="hidden" value={this.state.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" />

                                                            <AvField type="hidden" value={this.state.change_project_cover_image_1} name="change_project_cover_image_1" id="change_project_cover_image_1" />
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-12">
                                                                <AvField className="form-control" type="hidden" label={reqLabel('Short Description')} id="project_short_description" name="project_short_description" value={this.state.project_short_description } />

                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={this.state.ProjectNameList.short_description && this.state.ProjectNameList.short_description}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            project_short_description: this.state.ProjectNameList.short_description && this.state.ProjectNameList.short_description,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            project_short_description: data,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Focus.', editor );
                                                                    }}

                                                                />
                                                            </div>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <div className="col-md-12">
                                                                <AvField className="form-control" type="hidden" label={reqLabel('Details Descrptions')} id="project_details_description" name="project_details_description" value={this.state.project_details_description} />

                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={this.state.ProjectNameList.long_description && this.state.ProjectNameList.long_description}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            project_details_description: this.state.ProjectNameList.long_description && this.state.ProjectNameList.long_description,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // You can store the "editor" and use when it is needed.
                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            project_details_description: data,
                                                                        });
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        editor.ui.view.editable.element.style.minHeight = "200px";
                                                                        // console.log( 'Focus.', editor );
                                                                    }}

                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" value={this.state.ProjectNameList && this.state.ProjectNameList.remarks} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={this.state.ProjectNameList && this.state.ProjectNameList.priority} required />
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField type="select" label={reqLabel('Active Status')} className="custom-select" name="active_status" value={this.state.ProjectNameList && this.state.ProjectNameList.active_status}   >
                                                                    {/* <option defaultValue>Select Status</option> */}
                                                                    <option value="0" >Inactive</option>
                                                                    <option value="1">Active</option>
                                                                </AvField>
                                                            </div>
                                                        </Row>
                                                    </AvForm>

                                                    <div className="col-md-6 mb-2">
                                                        <Label>{'Slider Image First'}</Label><br />

                                                        <div>
                                                            {
                                                                this.state.ProjectNameList.slider_large_image != null ?
                                                                    (
                                                                        <img src={imageLink + '/Subproject/' + this.state.ProjectNameList.f_project_names_id + '/Slider/large/' + this.state.ProjectNameList.slider_large_image} alt="" className="w-75 h-50 rounded mt-5 mb-2" />
                                                                    ) : null
                                                            }
                                                        </div>
                                                        <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1080px * 600px</small>
                                                        < ReactImagePickerEditor
                                                            id="project_cover_image_1_0"
                                                            name="project_cover_image_1_0"
                                                            type="file"
                                                            config={config2}
                                                            imageSrcProp={initialImage}
                                                            imageChanged={(image) => {
                                                                this.handletHumbnailImageUpload(image)
                                                            }} />

                                                    </div>

                                                    <Button type="submit" form='my-form' color="success" className="float-right">
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ProjectSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { editSubProjectSlider, clearSuccess })(ProjectSliderEdit));