
import {CREATE_District,EDIT_District,AboutUsPageSlider_SUCCESS,SHOW_AboutUsPageSlider,AboutUsPageSlider_ERROR } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createDistrict = (District) => {
    console.log("AboutUsPageSlider",District)
    return {
        type: CREATE_District,
        payload: { District }
    }
}
// export function deleteAboutUsPageSlider(AboutUsPageSliderId){
//     return getApi().delete('vouchar/destroy/'+AboutUsPageSliderId)
// }

export function createBlukTransaction(data) {
    return getApi().post('district/bulk/create', data);
}

export const editDistrict = (District) => {
    return {
        type: EDIT_District,
        payload: { District }
    }
}
export function filterDistrictList(params){
    return getApi().get('district/list',{
        params: params
    })
}




export const AboutUsPageSliderSuccess = (msg) => {
    return {
        type: AboutUsPageSlider_SUCCESS,
        payload: msg
    }
}

export const AboutUsPageSliderError = (msg) => {
    return {
        type: AboutUsPageSlider_ERROR,
        payload: msg
    }
}

export const showVoucher = (voucher_id) => {
    return {
        type: SHOW_AboutUsPageSlider,
        payload: { voucher_id }
    }
}

export function deleteDistrict(id){
    return getApi().delete('district/'+id)
}


export function orderShow(order_id){
    return getApi().get('vouchar/generates/show/'+order_id)
}

export function slider_large_image_Upload(params,id){
     if(id){
         return getApi().put('aboutUsPageSlider/large_image/edit/'+id,params)
     }
 }

 export function slider_medium_image_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('aboutUsPageSlider/medium_image/edit/'+id,params)
     }
 }

 export function slider_thumbnail_image_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('aboutUsPageSlider/thumbnail_image/edit/'+id,params)
     }
 }

 export function favicon_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/favicon/edit/'+id,params)
     }
 }

 export function our_vision_image_upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/our_vision_image/edit/'+id,params)
     }
 }

 export function about_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_1/edit/'+id,params)
     }
 }
 export function about_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/about_us_cover_image_2/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_1/edit/'+id,params)
     }
 }

 export function privacy_policy_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/privacy_policy_cover_image_2/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_1_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_1/edit/'+id,params)
     }
 }

 export function contact_us_cover_image_2_Upload(params,id){
    // console.log("image_id",id)
     if(id){
         return getApi().put('basicCompanySettings/contact_us_cover_image_2/edit/'+id,params)
     }
 }




