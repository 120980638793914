import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteUpcoming, editUpcomming, upcomingList, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload } from '../../store/Upcoming/actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import DeleteModal from './DeleteModal';
import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';



const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class Upcoming extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            upcomingList_list: {},
            deleteModal: false,


            supplier: {

            },
            change_upcoming_project_image: {},
            upcoming_project_image: {},

            displayHide: false,


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchUpcomingList();
        // this.fetchCityList();  
        if (localStorage.getItem('last_action') == 'create') {
            localStorage.setItem('last_action', "");

            if (this.props.success != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);
            }
            if (this.props.error != '') {
                setTimeout(function () {
                    window.location.reload();
                }, 1);

            }
        }
    }

    fetchUpcomingList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        upcomingList(options).then((response) => {
            //   console.log(response);
            this.setState({
                upcomingList: response.data,
            });
            this.state.upcomingList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }



    //modal
    tog_scroll = (upcomingList) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.change_slider_thumbnail_image = {};
        this.state.change_slider_large_image = {};
        this.state.upcomingList_list = upcomingList;
        this.removeBodyCss();
    }

    // Voucherdelete = (voucherId) => {

    //     this.state.voucherId = voucherId;
    //     deleteVoucher(voucherId);
    //     setTimeout(function () {
    //         window.location.reload();
    //     }, 3500)
    // }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.props.createSupplier(values);
        this.form && this.form.reset();
        this.fetchUpcomingList();
        // alert("Supplier Create Successfully");
        // window.location.reload();
    }

    handleEditSubmit(event, values) {
        this.props.editUpcomming(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.setState({
            displayHide: true
        })
        this.fetchUpcomingList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchUpcomingList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchUpcomingList();
        });
    }

    toggleDeleteModal = (upcoming) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(upcoming);
    };

    onClickDelete = (project) => {
        console.log("Delete Modal1", project);
        this.setState({ deleteProject: project });
        this.setState({ deleteModal: true });

    };

    handleDeleteSubmit(values) {
        // alert("Are You Want To Delete This?");
        // this.fetchUpcomingList();  
        // deleteUpcoming(values)
        // .then((response) => {
        //   if (response.success == true) {
        //    //   console.log(response)
        //     alert(response.data);
        //     this.fetchUpcomingList();  
        //     window.location.reload();
        //   }
        // })
        // .catch((err) => {
        //   this.props.handleErrorResponse(err);
        // //  console.log('err', this.props.handleErrorResponse(err));
        // });

        const { deleteProject } = this.state;
        console.log("Delete Modal22222222", deleteProject);

        if (deleteProject != undefined) {
            deleteUpcoming(deleteProject.id)
                .then((response) => {
                    if (response.success == true) {
                        //   console.log(response)
                        // alert(response.data);
                        this.fetchUpcomingList();

                        // window.location.reload();
                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,

                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }





    ////
    // handleImageUpload = (e) => {
    //     var id = e.target.id;
    //     var id = this.state.upcomingList_list.id;
    //     let files = e.target.files;

    //     let reader = new FileReader();
    //     reader.readAsDataURL(files[0]);
    //     reader.onload = (e) => {
    //         const formData = { file: e.target.result, name: files[0].name, type: files[0].type, id: id };
    //         slider_thumbnail_image_Upload(formData, formData.id);
    //     }
    // }

    handleImageUpload = (image) => {
        // var id = e.target.id;
        // let files = e.target.files;

        // let reader = new FileReader();
        // reader.readAsDataURL(files[0]);
        // reader.onload = (e) => {
        //     const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
        //       //console.log("formData", formData);

        //     this.state.change_upcoming_project_image[id] = formData;
        //     //  console.log("this.setState.voucharImage[0]",  this.state.voucharImage[id]);
        // }

        const formData = { file: image, name: "change_upcoming_project_image", type: "image/jpeg" };

        this.state.change_upcoming_project_image["change_upcoming_project_image"] = formData;
    }
    ////

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let upcomingListInfo = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        if (this.state.hasOwnProperty('upcomingList')) {
            upcomingListInfo = this.state.upcomingList.data;
            paginator = {
                'currentPage': this.state.upcomingList.current_page,
                'pagesCount': this.state.upcomingList.last_page,
                'total': this.state.upcomingList.total,
                'to': this.state.upcomingList.to,
                'from': this.state.upcomingList.from,
            }
        }
        const { deleteModal, message } = this.state;

        const initialImage = '';
        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };

        if (this.props.success != '') {
            this.state.displayHide = false;
        }

        let singlevoucher = this.state.voucher;

        const { code, upcoming_project_name, id, project_short_description, project_starting_date_time, project_ending_date_time, remarks, priority, status, upcoming_project_image } = this.state.upcomingList_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        //   console.log("this.props.success", this.props.success)
        if (this.props.success != '') {
            setTimeout(function () {
                window.location.reload();
            }, 1000);

        }

        return (
            <React.Fragment>
                {
                    this.state.displayHide == true ?
                        <div className="page-content" style={{ position: 'fixed', width: "100%", height: "100%", background: "rgba(255, 255, 255, 0.7)", zIndex: 50000 }}>
                            <h6 align="center">Loding...</h6>
                            <img style={{ position: 'center' }} src="https://i.pinimg.com/originals/8e/44/36/8e44367589f9f262759be288459c7fdc.gif" />
                            {this.state.error && this.state.error ?
                                <SweetAlert
                                    danger
                                    title={this.state.error}
                                    // timeout={2000}
                                    showConfirm={true}
                                    // showCloseButton={true}
                                    onConfirm={() => window.location.reload()}
                                >
                                </SweetAlert> : ''
                            }
                            {this.state.success && this.state.success ?
                                <SweetAlert
                                    title={this.state.success}
                                    // timeout={2000}
                                    showConfirm={true}
                                    // showCloseButton={true}
                                    success
                                    onConfirm={() => window.location.reload()
                                    }
                                >
                                </SweetAlert> : ''
                            }
                            {this.props.error && this.props.error ?
                                <SweetAlert
                                    danger
                                    title={this.props.error}
                                    // timeout={2000}
                                    showConfirm={true}
                                    // showCloseButton={true}
                                    onCancel={this.hideAlert}
                                    onConfirm={() => window.location.reload()}
                                >
                                </SweetAlert> : ''
                            }
                            {this.props.success && this.props.success ?
                                <SweetAlert
                                    title={this.props.success}
                                    // timeout={2000}
                                    showConfirm={true}
                                    // showCloseButton={true}
                                    success
                                    onCancel={this.hideAlert}
                                    onConfirm={() => window.location.reload()}
                                >
                                </SweetAlert> : ''
                            }
                        </div>
                        :
                        <>
                            <div className="page-content">
                                <Container fluid>

                                    <Breadcrumbs title="Settings" breadcrumbItem="Upcoming Projects" />
                                    {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                    {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                                    {/* {this.state.errorMessage && this.state.errorMessage ?
                                        <SweetAlert
                                            danger
                                            title={this.state.errorMessage}
                                            timeout={2000}
                                            showConfirm={true}
                                            showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => this.removeMessage()}
                                        >
                                        </SweetAlert> : ''
                                    } */}
                                    {this.state.error && this.state.error ?
                                        <SweetAlert
                                            danger
                                            title={this.state.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.state.success && this.state.success ?
                                        <SweetAlert
                                            title={this.state.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onConfirm={() => window.location.reload()
                                            }
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.error && this.props.error ?
                                        <SweetAlert
                                            danger
                                            title={this.props.error}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }
                                    {this.props.success && this.props.success ?
                                        <SweetAlert
                                            title={this.props.success}
                                            // timeout={2000}
                                            showConfirm={true}
                                            // showCloseButton={true}
                                            success
                                            onCancel={this.hideAlert}
                                            onConfirm={() => window.location.reload()}
                                        >
                                        </SweetAlert> : ''
                                    }

                                    <DeleteModal
                                        show={deleteModal}
                                        onDeleteClick={() => this.handleDeleteSubmit()}
                                        onCloseClick={() => this.setState({ deleteModal: false })}
                                    />

                                    <Row>
                                        <Col lg="12" md="12">
                                            <Card>
                                                <CardHeader className="px-0">
                                                    <Link to={'/create/upcoming'}><Button className="btn btn-success pull-right">Add Upcoming Project</Button></Link>
                                                </CardHeader>
                                                <CardBody>

                                                    <CardTitle>Upcoming Projects List</CardTitle>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                            <Table responsive>

                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Code</th>
                                                                        <th>Project Name</th>
                                                                        <th>Starting Date Time</th>
                                                                        <th>Ending Date Time</th>
                                                                        <th>Project Image</th>
                                                                        {/* <th>Remark</th> */}
                                                                        <th>Priority</th>
                                                                        <th>Status</th>

                                                                        <th colspan="2">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {upcomingListInfo && upcomingListInfo.map((upcoming, index) =>
                                                                        <tr key={upcoming.id.toString()}>
                                                                            <td scope="row">{index + 1 + ((this.state.page - 1) * this.state.per_page)}</td>
                                                                            <td>{upcoming.code}</td>
                                                                            <td>{upcoming.upcoming_project_name}</td>
                                                                            <td>{upcoming.project_starting_date_time}</td>
                                                                            <td>{upcoming.project_ending_date_time}</td>
                                                                            <td>
                                                                                {
                                                                                    upcoming.upcoming_project_image != null ?
                                                                                        (
                                                                                            <img src={imageLink + '/upcoming/upcoming_project_name/' + upcoming.upcoming_project_image} alt="" height="50" />
                                                                                        ) : null
                                                                                }
                                                                            </td>

                                                                            {/* <td>{upcoming.remarks}</td> */}
                                                                            <td>{upcoming.priority}</td>
                                                                            <td><Badge color={getBadge(upcoming.status)}>{getStatusValue(upcoming.status)}</Badge></td>
                                                                            <td>
                                                                                {/* <button type="button"
                                                                                className="btn btn-sm btn-primary waves-effect waves-light"
                                                                                onClick={() => { this.tog_scroll(upcoming) }}
                                                                                data-toggle="modal"
                                                                                >Edit</button>
                                                                                <button type="button"
                                                                                className="btn btn-sm btn-danger waves-effect waves-light"
                                                                                onClick={() => { this.handleDeleteSubmit( upcoming.id) }}
                                                                                >Delete</button> */}

                                                                                <i type="button"
                                                                                    className="fa fa-edit  text-warning fa-2x my-2 ml-2"
                                                                                    onClick={() => { this.tog_scroll(upcoming) }}
                                                                                ></i>
                                                                                <i
                                                                                    className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                                    onClick={() => { this.toggleDeleteModal(upcoming) }}
                                                                                ></i>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                                {paginator &&
                                                    <CardFooter className="p-6">
                                                        <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                                    </CardFooter>
                                                }
                                            </Card>
                                            <Modal
                                                isOpen={this.state.modal_scroll}
                                                toggle={this.tog_scroll}
                                                scrollable={true}
                                                size="xl"
                                            >
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0">{code}</h5>
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            this.setState({ modal_scroll: false })
                                                        }
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">

                                                    <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} >
                                                        {this.props.msg && this.props.msg ? (
                                                            <Alert color="success">
                                                                Upcoming Project Updated Successfully!
                                                            </Alert>
                                                        ) : null}
                                                        <Row>
                                                            <AvField className="form-control" id='my-form' type="hidden" name="id" value={id} />
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={reqLabel('Project Name')} id="upcoming_project_name" name="upcoming_project_name" value={upcoming_project_name} required />
                                                                <AvField className="form-control" type="datetime-local" label={reqLabel('Start Date & Time')} id="project_starting_date_time" name="project_starting_date_time" value={project_starting_date_time} required />

                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" value={priority} required />
                                                                <AvField className="form-control" type="datetime-local" label={reqLabel('End Date & Time')} id="project_ending_date_time" name="project_ending_date_time" value={project_ending_date_time} required />

                                                            </div>
                                                        </Row>

                                                        <Row>
                                                            <div className="col-md-6">
                                                                <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" value={remarks} />


                                                            </div>
                                                            <div className="col-md-6">
                                                                <AvField label={reqLabel('Status')} type="select" className="form-control" name="Status" value={status}  >

                                                                    <option value="0" >Inactive</option>
                                                                    <option value="1">Active</option>
                                                                </AvField>
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className="col-md-6">
                                                                <Label>Short Description</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditorDescription}
                                                                    data={project_short_description && project_short_description != null ? project_short_description : ""}
                                                                    onReady={editor => {
                                                                        this.setState({
                                                                            project_short_description: project_short_description,
                                                                        });
                                                                        // You can store the "editor" and use when it is needed.
                                                                        // console.log( 'Editor is ready to use!', editor );
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        this.setState({
                                                                            project_short_description: data,
                                                                        });
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                        // console.log( 'Blur.', editor );
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                        // console.log( 'Focus.', editor );
                                                                    }}


                                                                />
                                                                <AvField name="project_short_description" value={this.state.project_short_description} className="form-control" type="hidden" />
                                                            </div>
                                                        </Row>
                                                        <AvField type="hidden" value={upcoming_project_image} name="upcoming_project_image" id="upcoming_project_image" />
                                                        <AvField type="hidden" value={this.state.change_upcoming_project_image} name="change_upcoming_project_image" id="change_upcoming_project_image" />
                                                    </AvForm>

                                                    <Row>
                                                        <div className="col-md-6">

                                                            <Label>{reqLabel('Project Image')}</Label><br />

                                                            {
                                                                upcoming_project_image != null ?
                                                                    (
                                                                        <img src={imageLink + 'upcoming/upcoming_project_name/' + upcoming_project_image} alt="" className="w-50 h-50 rounded mt-2 mb-2" />
                                                                    ) : null
                                                            }
                                                        </div>
                                                        <div className="col-md-6">

                                                            <Label>Want to change?</Label>  <br />
                                                            <small className="mb-2 text-danger font-weight-bold" style={{ fontSize: '12px' }}>SIZE: 1920px * 1080px</small>

                                                            < ReactImagePickerEditor
                                                                id="upcoming_project_image_0"
                                                                name="upcoming_project_image_0"
                                                                type="file"
                                                                config={config2}
                                                                imageSrcProp={initialImage}
                                                                imageChanged={(image) => {
                                                                    this.handleImageUpload(image)
                                                                }} />
                                                        </div>
                                                    </Row>





                                                    <div className="text-center mt-4">
                                                        <button type="button" className="btn btn-light mr-2" onClick={this.tog_scroll}>Close</button>
                                                        <button type="submit" form='my-form' className="btn btn-success save-event">Save</button>
                                                    </div>


                                                </div>
                                            </Modal>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </>
                }

            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps, { editUpcomming, slider_thumbnail_image_Upload, slider_large_image_Upload, slider_medium_image_Upload, favicon_Upload })(Upcoming));