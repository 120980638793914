import React, { Component } from "react";
// import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvRadio, AvRadioGroup, AvInput } from 'availity-reactstrap-validation';

// import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createFloorPlan, FloorPlanList, editFloorPlan,uploadImageFloorPlan, FloorPlanDetails, CityNameList, deleteFloorPlanImage, floorPlanImages, floorPlanBrochure, DistrictNameList, FilterOptionList, SpecificFilterOptionList, SpecificCityNameList, SpecificDistrictNameList, } from '../../store/FloorPlan/actions';
import { SubProjectNameListData, SpecificProjectNameList } from '../../store/ProjectName/actions';
// import {SpecificProjectNameList} from '../../store/ProjectName/actions';

// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import DeleteModal from './DeleteModal';

import 'react-image-picker-editor/dist/index.css'
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class FloorPlanView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            modal_scroll2: false,
            activeTab: 1,
            activeTabProgress: 1,
            supplier: {

            },
            status: 0,
            rows1: [],
            change_site_map_image: {},
            change_project_logo: {},
            change_project_cover_image_2: {},
            change_project_cover_image_1: {},
            project_cover_image_2: {},
            hasExtraValue: false,
            errorMessage: '',
            selectedGroup: null,
            districtId: null,
            bathroom_details: '',
            details_descrptions: '',
            short_descriptions: '',
            cityId: null,
            storiesId: null,
            deleteModal: false,
            bedroomId: null,
            garageId: null,
            bathroomId: null,
            sizeId: null,
            priceId: null,
            brand: {

            },


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlleProjectName = this.handlleProjectName.bind(this);
        this.handleCityId = this.handleCityId.bind(this);
        this.handledistrictId = this.handledistrictId.bind(this);
        this.handlebathroomId = this.handlebathroomId.bind(this);
        this.handlebedroomId = this.handlebedroomId.bind(this);
        this.handlesizeId = this.handlesizeId.bind(this);
        this.handlepriceId = this.handlepriceId.bind(this);
        this.handlegarageId = this.handlegarageId.bind(this);
        this.handlestoriesId = this.handlestoriesId.bind(this);
    }

    componentDidMount() {
        // console.log(this.props)
        if (this.props.location.state !== undefined) {
            // console.log(this.props.location.state.label.id)
            this.fetchFloorPlanDetails(this.props.location.state.value.id)
            this.fetchFloorPlanImages(this.props.location.state.value.id)

            this.fetchSpecificParentProjectNameList(this.props.location.state.value.id)
            this.fetchSpecificDistrictNameList(this.props.location.state.value.id)
            this.fetchSpecificCitynameList(this.props.location.state.value.id)
            this.fetchSpecificFilterOptionList(this.props.location.state.value.id)
            this.fetchFloorPlanBrochure(this.props.location.state.value.id)
            this.fetchProjectSliderList();
            this.fetchCitynameList();
            this.fetchDistrictNameList();
            this.fetchFilterOptionList();
            if (localStorage.getItem('floorPlanCreate') == "true") {
                localStorage.setItem('floorPlanCreate', "false");
                window.location.reload();
            }
        }
        else {
            let id = window.location.href.split('/').pop();
            this.fetchFloorPlanDetails(id)
            this.fetchFloorPlanImages(id)
            this.fetchSpecificParentProjectNameList(id)
            this.fetchSpecificDistrictNameList(id)
            this.fetchSpecificCitynameList(id)
            this.fetchSpecificFilterOptionList(id)
            this.fetchFloorPlanBrochure(id)
            this.fetchProjectSliderList();
            this.fetchCitynameList();
            this.fetchDistrictNameList();
            this.fetchFilterOptionList();
            if (localStorage.getItem('floorPlanCreate') == "true") {
                localStorage.setItem('floorPlanCreate', "false");
                window.location.reload();
            }
        }
    }
    fetchSpecificParentProjectNameList = (id) => {

        SpecificProjectNameList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ParentProjectNameList: response.data[0].options[0],
                });
                this.state.ParentProjectNameList = response.data[0].options[0];
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchSpecificCitynameList = (id) => {

        SpecificCityNameList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    SpecificCityNameList: response.data[0].options[0],
                });
                this.state.SpecificCityNameList = response.data[0].options[0];

            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchSpecificDistrictNameList = (id) => {

        SpecificDistrictNameList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    SpecificDistrictNameList: response.data[0].options[0],
                });
                this.state.SpecificDistrictNameList = response.data[0].options[0];

            } else {
                this.setState({
                    DistrictNameList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchSpecificFilterOptionList = (id) => {

        SpecificFilterOptionList(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    SpecificFilterOptionList: response.data,
                    storiesId: response.data.Stories[0].options[0],
                    bedroomId: response.data.Bedroom[0].options[0],
                    garageId: response.data.Garage[0].options[0],
                    bathroomId: response.data.Bathrooms[0].options[0],
                    sizeId: response.data.Size[0].options[0],
                    priceId: response.data.Price[0].options[0],
                    bedRoom: response.data.Bedroom[0].options[0],
                });




                this.state.SpecificFilterOptionList = response.data;
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchFloorPlanDetails = (id) => {


        FloorPlanDetails(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    FloorPlanList: response.data[0],
                });
                this.state.FloorPlanList = response.data[0];

            } else {
                this.setState({
                    FloorPlanList: {

                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchFloorPlanImages = (id) => {


        floorPlanImages(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    FloorPlanImages: response.data,
                });
                this.state.FloorPlanImages = response.data;
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchFloorPlanBrochure = (id) => {
        floorPlanBrochure(id).then((response) => {
            if (response.data != []) {
                this.setState({
                    FloorPlanBrochure: response.data,
                });
                this.state.FloorPlanBrochure = response.data;
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchProjectSliderList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        SubProjectNameListData(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    ProjectNameList: response.data,
                });
                this.state.ProjectNameList = response.data;

            }
            else {
                this.setState({
                    ProjectNameList: {
                    },
                });
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchCitynameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        CityNameList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    CityNameList: response.data,
                });
                this.state.CityNameList = response.data;

            } else {
                this.setState({
                    CityNameList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }
    fetchDistrictNameList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        DistrictNameList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    DistrictNameList: response.data,
                });
                this.state.DistrictNameList = response.data;

            } else {
                this.setState({
                    DistrictNameList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchFilterOptionList = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        FilterOptionList(options).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    FilterOptionList: response.data,
                });
                this.state.FilterOptionList = response.data;

            } else {
                this.setState({
                    FilterOptionList: {
                    },
                });
            }

        })
            .catch((err) => {
                console.log(err);
            })
    }


    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.editFloorPlan(values);
    }


    handleEditSubmit(event, values) {
        this.props.uploadImageFloorPlan(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }
    handleBrochureEditSubmit(event, values) {
        this.props.uploadImageFloorPlan(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }


    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const formData = { file: image, name: "change_project_cover_image_1", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_cover_image_1["change_project_cover_image_1"] = formData;

        // console.log("this.setState.project_cover_image_1[0]", this.state.project_cover_image_1["project_cover_image_1"]);

    }

    handleLargeImageUpload = (image, id) => {

        const formData = { file: id, name: "project_cover_image_2", type: "image/jpeg", id: image };
        //console.log("formData", formData);

        this.state.project_cover_image_2["project_cover_image_2"] = formData;
        console.log(formData);
    }

    handleNewImageUpload = (index, image) => {

        const formData = { file: image, name: `project_cover_image_${index}`, type: "image/jpeg" };
        // //console.log("formData", formData);

        this.state.project_cover_image_2[index] = formData;
        console.log(formData);
    }

    handleMediumImageUpload = (image) => {

        const formData = { file: image, name: "change_site_map_image", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_site_map_image["change_site_map_image"] = formData;
    }

    handleProjectLogoUpload = (image) => {

        const formData = { file: image, name: "change_project_logo", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_logo["change_project_logo"] = formData;
    }

    toggleTab(tab) {
        // if (tab === 2 && (document.getElementById('project_name').value == '' || document.getElementById('project_name').value == null
        // || document.getElementById('project_name').value == undefined)) {
        // this.setState({
        // errorMessage: "Project name is required", 
        // })
        // }
        // else if (tab === 2 && (document.getElementById('project_title').value == '' || document.getElementById('project_title').value == null
        // || document.getElementById('project_title').value == undefined)) {
        // this.setState({
        // errorMessage: "Project Title is required", 
        // })
        // }
        // else if (tab === 2 && (document.getElementById('project_short_description').value == '' || document.getElementById('project_short_description').value == null
        // || document.getElementById('project_short_description').value == undefined)) {
        // this.setState({
        // errorMessage: "Project Short Description is required", 
        // })
        // }
        // else if (tab === 2 && (document.getElementById('project_details_description').value == '' || document.getElementById('project_details_description').value == null
        // || document.getElementById('project_details_description').value == undefined)) {
        // this.setState({
        // errorMessage: "Project Details Description is required", 
        // })
        // }
        // else if (tab === 2 && (document.getElementById('contact_information_description').value == '' || document.getElementById('contact_information_description').value == null
        // || document.getElementById('contact_information_description').value == undefined)) {
        // this.setState({
        // errorMessage: "Contact Information Description is required", 
        // })
        // }
        // else if (tab === 2 && (document.getElementById('project_address').value == '' || document.getElementById('project_address').value == null
        // || document.getElementById('project_address').value == undefined)) {
        // this.setState({
        // errorMessage: "Project Address is required", 
        // })
        // }
        // else if (tab === 2 && (document.getElementById('project_phone_no').value == '' || document.getElementById('project_phone_no').value == null
        // || document.getElementById('project_phone_no').value == undefined)) {
        // this.setState({
        // errorMessage: "Project Phone No is required", 
        // })
        // }
        // else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
        // || document.getElementById('priority').value == undefined)) {
        // this.setState({
        //     errorMessage: "Priority is required", 
        //     })
        // }
        // else {
        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTab: tab
                });
            }
        }
        // }

    }

    SuccessMessage() {
        localStorage.setItem('floorPlanCreate', 'true');
        let id = this.state.FloorPlanList && this.state.FloorPlanList.id;
        this.props.history.push(
            {
                pathname: '/details/floorPlan/'+id,
            }
        )
    }

    handleAddRowNested = () => {
        const item1 = {
            name1: ""
        };
        this.setState({
            rows1: [...this.state.rows1, item1]
        });
    };

    handleRemoveRowNested = (e, idx) => {
        document.getElementById("nested" + idx).style.display = "none";
    };


    removeMessage() {
        this.setState({ errorMessage: '' });
    }
    handlleProjectNameFilter(option, inputValue) {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    }

    handlleProjectName(selectedGroup) {

        this.setState({
            ParentProjectNameList: selectedGroup
        })
        console.log("out", this.state.selectedGroup);

        //   this.state.selectedGroup = option;
    }

    addImageToggle(id) {
        console.log("addImageToggle", id);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.state.change_slider_thumbnail_image = {};
        // this.state.change_slider_large_image = {};
        // this.state.filterNameList_list = filterNameList;
        this.removeBodyCss();
    }
    addBrochureToggle(id) {
        console.log("addBrochureToggle", id);
        this.setState(prevState => ({
            modal_scroll2: !prevState.modal_scroll2,
        }));
        // this.state.change_slider_thumbnail_image = {};
        // this.state.change_slider_large_image = {};
        // this.state.filterNameList_list = filterNameList;
        this.removeBodyCss();
    }
    
    handleCityId(cityId) {

        this.setState({ SpecificCityNameList: cityId });
    }
    handledistrictId(districtId) {

        this.setState({ SpecificDistrictNameList: districtId });
    }
    handlepriceId(priceId) {

        this.setState({ priceId });
    }
    handlesizeId(sizeId) {

        this.setState({ sizeId });
    }
    handlestoriesId(storiesId) {

        this.setState({ storiesId });
    }
    handlebedroomId(bedroomId) {

        this.setState({ bedroomId });
    }
    handlegarageId(garageId) {

        this.setState({ garageId });
    }
    handlebathroomId(bathroomId) {
        this.setState({ bathroomId });
    }

    handleDelete(id) {
        console.log('delete', id);
    }

    handleDelete = (conmpany) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(conmpany);
    };

    onClickDelete = (customer) => {
        // console.log("Delete Modal", customer);
        this.setState({ deleteCustomer: customer });
        this.setState({ deleteModal: true });

    };
    handleDeleteSubmit() {

        const { deleteCustomer } = this.state;

        if (deleteCustomer != undefined) {
            deleteFloorPlanImage(deleteCustomer)
                .then((response) => {
                    if (response.success == true) {
                        //   console.log(response)
                        // alert(response.data);
                        // this.fetchFloorPlan();
                        // window.location.reload();
                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,

                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }
    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;

        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        if (localStorage.getItem('floorPlanCreate') == "true") {
            localStorage.setItem('floorPlanCreate', "false");
            window.location.reload();
        }
        const initialImage = '';
        const { ParentProjectNameList, SpecificDistrictNameList, SpecificCityNameList } = this.state;
        const defaultValues = {
            is_quick_move_in_homes: this.state.FloorPlanList && this.state.FloorPlanList.is_quick_move_in_homes,

            checkItOut: true,
        };
        const { deleteModal, message } = this.state;

        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="Floor Plan View" />
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() => this.SuccessMessage()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.error && this.state.error ?
                            <SweetAlert
                                danger
                                title={this.state.error}
                                timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.success && this.state.success ?
                            <SweetAlert
                                title={this.state.success}
                                timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={() => this.handleDeleteSubmit()}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        {
                                            this.state.FloorPlanList &&
                                            <>
                                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)} model={defaultValues}>

                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Project name : </Label>
                                                            <AvField type="text" name="project_id_value" id="project_id_value" value={ParentProjectNameList && ParentProjectNameList.label} readOnly />
                                                            <AvField type="hidden" name="id" id="id" value={this.state.FloorPlanList && this.state.FloorPlanList.id} required />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Floor Plan Name')} id="floore_plan_name" name="floore_plan_name" value={this.state.FloorPlanList.floore_plans_names} required readOnly />
                                                        </div>

                                                    </Row>
                                                    <Row>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Floor Type')} value={this.state.FloorPlanList && this.state.FloorPlanList.floor_type} id="floor_type" name="floor_type" required readOnly />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Short Description')} id="short_description" name="short_descriptions" value={this.state.FloorPlanList.short_descriptions && this.state.FloorPlanList.short_descriptions} readOnly />
                                                        </div>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <div className="col-md-12">
                                                            <AvField className="form-control" type="text" label={reqLabel('Details Descrptions')} id="details_descrptions" name="details_descrptions" value={this.state.FloorPlanList.details_descrptions} readOnly />
                                                        </div>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">City name : </Label>
                                                            <AvField type="text" name="f_city_info_id" id="f_city_info_id" value={SpecificCityNameList && SpecificCityNameList.label} required readOnly />


                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">District name : </Label>
                                                            <AvField type="text" name="f_district_id" id="f_district_id" value={SpecificDistrictNameList && SpecificDistrictNameList.label} required readOnly />


                                                        </div>

                                                    </Row>

                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Stories name : </Label>
                                                            <AvField type="text" name="f_stories_id" id="f_stories_id" value={this.state.storiesId && this.state.storiesId.label} required readOnly />

                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Stories Quantity')} value={this.state.FloorPlanList && this.state.FloorPlanList.stories_qty} id="stories_qty" name="stories_qty" required readOnly />
                                                        </div>

                                                    </Row>
                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Bedroom name : </Label>

                                                            <AvField type="text" name="f_bedroom_id" id="f_bedroom_id" value={this.state.bedroomId && this.state.bedroomId.label} required readOnly />


                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Beedroom Quantity')} value={this.state.FloorPlanList && this.state.FloorPlanList.bedroom_qty} id="bedroom_qty" name="bedroom_qty" required readOnly />
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Garage name : </Label>
                                                            <AvField type="text" name="f_garage_id" id="f_garage_id" value={this.state.garageId && this.state.garageId.label} required readOnly />


                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Garage Quantity')} value={this.state.FloorPlanList && this.state.FloorPlanList.garage_qty} id="garage_qty" name="garage_qty" required readOnly />
                                                        </div>
                                                    </Row>
                                                    {/* </TabPane>

                                                                    <TabPane tabId={2}> */}

                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Bathroom name : </Label>

                                                            <AvField type="text" name="f_bathroom_id" id="f_bathroom_id" value={this.state.bathroomId && this.state.bathroomId.label} required readOnly />

                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Bathroom Quantity')} value={this.state.FloorPlanList && this.state.FloorPlanList.bathroom_qty} id="bathroom_qty" name="bathroom_qty" required readOnly />
                                                        </div>
                                                    </Row>

                                                    <Row>
                                                        <div className="col-md-12">
                                                            <AvField className="form-control" type="text" label={reqLabel('Bathroom Details')} id="bathroom_details" name="bathroom_details" value={this.state.FilterOptionList && this.state.FilterOptionList.bathroom_details} required readOnly />


                                                        </div>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Size : </Label>


                                                            <AvField type="text" name="f_size_id" id="f_size_id" value={this.state.sizeId && this.state.sizeId.label} required readOnly />


                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Size Details Sqrft')} value={this.state.FloorPlanList && this.state.FloorPlanList.size_details_sq_fit} id="size_details_sq_fit" name="size_details_sq_fit" required readOnly />
                                                        </div>

                                                    </Row>

                                                    <hr />
                                                    <Row>
                                                        <div className="form-group col-md-6">
                                                            <Label htmlFor="formname">Price Id : </Label>
                                                            <AvField type="text" name="f_price_id" id="f_price_id" value={this.state.priceId && this.state.priceId.label} required readOnly />


                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={reqLabel('Price')} value={this.state.FloorPlanList && this.state.FloorPlanList.price} id="price" name="price" required readOnly />
                                                        </div>

                                                    </Row>

                                                    <Row>
                                                        <div className="col-md-6">
                                                            <AvRadioGroup inline name="is_quick_move_in_homes" id="is_quick_move_in_homes" label='Is Quick Move In Home' required readOnly disabled >
                                                                <AvRadio customInput label="Yes" value={1} />
                                                                <AvRadio customInput label="No" value={0} />
                                                            </AvRadioGroup>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField type="date" name="quick_move_in_homes_ready_date" id="quick_move_in_homes_ready_date" value={this.state.FloorPlanList && this.state.FloorPlanList.quick_move_in_homes_ready_date} label='Quick Ready Home Date' readOnly/>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="text" label={'Remarks'} id="remarks" name="remarks" value={this.state.FloorPlanList && this.state.FloorPlanList.remarks} readOnly/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" required readOnly value={this.state.FloorPlanList && this.state.FloorPlanList.priority} />
                                                        </div>
                                                        {/* <AvField type="text" value={this.state.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" /> */}
                                                    </Row>
                                                </AvForm>
                                            </>
                                        }

                                        <div className="row">

                                            {
                                                this.state.FloorPlanImages &&
                                                this.state.FloorPlanImages.map((data, index) =>

                                                    <div className="col-md-6">
                                                        <Label>{reqLabel('Image ' + (index + 1))}</Label><br />

                                                        <div>
                                                            {
                                                                data.slider_large_image != null ?
                                                                    (
                                                                        <img src={imageLink + 'FloorPlan/' + data.f_project_names_id + '/Slider/large/' + data.slider_large_image} alt="" className="w-75 h-50 img-fluid rounded mt-5 mb-2" />
                                                                    ) : null
                                                            }
                                                        </div>

                                                        <button className="btn btn-danger m-2" onClick={() => this.handleDelete(data.id)}><i className="fa fa-trash text-light "></i></button>
                                                        <button className="btn btn-success m-2" onClick={() => this.addImageToggle(this.state.FloorPlanList && this.state.FloorPlanList.id)}><i className="fa fa-plus text-light "></i></button>
                                                    </div>

                                                )

                                            }

                                            {
                                                this.state.FloorPlanBrochure &&
                                                this.state.FloorPlanBrochure.map((data, index) =>
                                                    <div className="col-md-6">
                                                        <Label>{reqLabel('Image ' + (index + 1))}</Label><br />
                                                        <div>
                                                            {
                                                                data.slider_large_image != null ?
                                                                    (
                                                                        <iframe
                                                                            src={imageLink + 'FloorPlanB/brochure/' + data.slider_large_image}
                                                                            frameBorder="0"
                                                                            scrolling="none"
                                                                            height="350px"
                                                                            width="100%"
                                                                        ></iframe>
                                                                    ) : null
                                                            }
                                                        </div>

                                                        <button className="btn btn-danger my-2" onClick={() => this.handleDelete(data.id)}><i className="fa fa-trash text-light fa-2x "></i></button>
                                                        {/* <button className="btn btn-success m-2" onClick={() => this.addBrochureToggle(this.state.FloorPlanList && this.state.FloorPlanList.id)}><i className="fa fa-plus text-light "></i></button> */}

                                                    </div>

                                                )

                                            }

                                        </div>

                                    </CardBody>
                                </Card>
                                <Modal
                                    size="xl"
                                    isOpen={this.state.modal_scroll}
                                    toggle={this.addImageToggle}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">Add More Image</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleEditSubmit(e, v) }} ref={c => (this.form = c)}>
                                        <AvField type="hidden" value={this.state.project_cover_image_2} name="project_cover_image_2" id="project_cover_image_2" required />
                                        <AvField type="hidden" value={this.state.FloorPlanList && this.state.FloorPlanList.id} name="project_id_value" id="project_id_value" required />
                                    </AvForm>
                                    <div className="modal-body">
                                        <div >
                                            <table style={{ width: "100%" }}>
                                                <tbody>
                                                    <tr id="addrMain" key="">
                                                        <td>
                                                            <Row className="mb-2">
                                                                <div className="col-md-3">
                                                                    <Label>{reqLabel('Slider Image ')}</Label><br />
                                                                    <br />
                                                                    < ReactImagePickerEditor
                                                                        id="project_cover_image_2_0"
                                                                        name="project_cover_image_2_0"
                                                                        type="file"
                                                                        config={config2}
                                                                        imageSrcProp={initialImage}
                                                                        imageChanged={(image) => {
                                                                            this.handleLargeImageUpload(0, image)
                                                                        }}
                                                                    />

                                                                </div>

                                                                <div className="col-md-2">
                                                                    <Button
                                                                        disabled
                                                                        color="primary"
                                                                        className="btn-block inner mb-50 "
                                                                        style={{ width: "100%" }}
                                                                    > Delete
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                        </td>
                                                    </tr>


                                                    {this.state.rows1.map((item1, idx) => (
                                                        <tr id={"nested" + idx} key={idx}>
                                                            <td>
                                                                <Row className="mb-2">
                                                                    <div className="col-md-3">
                                                                        <Label>{reqLabel('Slider Image')}</Label><br />
                                                                        <br />
                                                                        < ReactImagePickerEditor
                                                                            id="project_cover_image_2_0"
                                                                            name="project_cover_image_2_0"
                                                                            type="file"
                                                                            config={config2}
                                                                            imageSrcProp={initialImage}
                                                                            imageChanged={(image) => {
                                                                                this.handleLargeImageUpload((idx + 1), image)
                                                                            }}
                                                                        />

                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <Button
                                                                            onClick={e =>
                                                                                this.handleRemoveRowNested(e, idx)
                                                                            }
                                                                            color="primary"
                                                                            className="btn-block inner mb-50"
                                                                            style={{ width: "100%" }}
                                                                        > Delete
                                                                        </Button>
                                                                    </div>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <Button
                                                onClick={this.handleAddRowNested}
                                                color="success"
                                                className="m-1"
                                            >
                                                Add Image
                                            </Button>
                                            <Button type="submit" form='my-form' color="success" className="m-1">Submit</Button>
                                        </div>
                                    </div>
                                </Modal>


                                <Modal
                                    size="xl"
                                    isOpen={this.state.modal_scroll2}
                                    toggle={this.addImageToggle}
                                    scrollable={true}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0">Add Brochure Image</h5>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.setState({ modal_scroll2: false })
                                            }
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div className="modal-body">
                                       
                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.FloorPlan;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createFloorPlan, editFloorPlan,uploadImageFloorPlan  })(FloorPlanView));