import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {CREATE_FloorPlan,CREATE_SubFloorPlan,CREATE_CreateBrochure,EDIT_FloorPlan,EDIT_uploadImageFloorPlan,EDIT_SubFloorPlan,SHOW_FloorPlan_SUCCESS,SHOW_SHOW_FloorPlan,SHOW_FloorPlan_ERROR} from './actionTypes';

import { brandSuccess, AboutUsPageSliderError,brandShow, merchantError, AboutUsPageSliderSuccess } from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* CREATE_createFloorPlans({ payload: { FloorPlan } }) {
    try {
       console.log("SHOW_FloorPlan",FloorPlan);
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'FloorPlan/create',FloorPlan);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
                if(response.success == true){
                    // setTimeout(() => {
                    //     this.props.history.push({
                    //         pathname: '/index/FloorPlan',
                    //     });
                    // }, 2000)
                }
            } 
          }
    } catch (error) {
        yield put(AboutUsPageSliderError("Can not Create !"));
    }
}
function* CREATE_createSubFloorPlans({ payload: { FloorPlan } }) {
       console.log("SHOW_FloorPlan",FloorPlan);
}
function* CREATE_createBrochure({ payload: { UploadBrochure } }) {
     try {
       console.log("CREATE_CreateBrochure",UploadBrochure);
           if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
           {  
              const response = yield call(postJwtData, 'FloorPlan/upload/brochure',UploadBrochure);
              if(response.success == false){
                  alert(response.message['message']);
                 yield put(AboutUsPageSliderError(response.message.message));
             }else if(response.success == true){
                 yield put(AboutUsPageSliderSuccess(response.data.message));
                 if(response.success == true){
                      setTimeout(() => {
                          this.props.history.push({
                              pathname: '/index/FloorPlan',
                          });
                      }, 2000)
                 }
             } 
           }
     } catch (error) {
         yield put(AboutUsPageSliderError("Can not Create !"));
     }
}

function* EDIT_createFloorPlans({ payload: { FloorPlan } }) {
    // console.log("saga ---- ",FloorPlan);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'FloorPlan/'+FloorPlan.id, FloorPlan);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(AboutUsPageSliderError(error));
    }
}
function* EDIT_createSubFloorPlans({ payload: { FloorPlan } }) {
    // console.log("saga ---- ",FloorPlan);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'FloorPlan/sub/'+FloorPlan.id, FloorPlan);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(AboutUsPageSliderError(error));
    }
}

function* EDIT_uploadImageFloor({ payload: { FloorPlan } }) {
    // console.log("saga ---- ",FloorPlan);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'FloorPlan/image/create', FloorPlan);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(AboutUsPageSliderError(response.message.message));
            }else if(response.success == true){
                yield put(AboutUsPageSliderSuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(AboutUsPageSliderError(error));
    }
}


export function* watchFloorPlan() {
    yield takeEvery(EDIT_FloorPlan, EDIT_createFloorPlans)
    yield takeEvery(EDIT_SubFloorPlan, EDIT_createSubFloorPlans)
    yield takeEvery(EDIT_uploadImageFloorPlan,EDIT_uploadImageFloor )
    yield takeEvery(CREATE_SubFloorPlan, CREATE_createSubFloorPlans)
    yield takeEvery(CREATE_CreateBrochure, CREATE_createBrochure)
    yield takeEvery(CREATE_FloorPlan, CREATE_createFloorPlans)
    // yield takeEvery(SHOW_MERCHANT, showMerchant)
}

function* FloorPlan() {
    yield all([
        fork(watchFloorPlan),
    ]);
}

export default FloorPlan;