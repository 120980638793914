import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {CREATE_Gallery,CREATE_SubGallery,EDIT_Gallery,EDIT_SubGallery,SHOW_Gallery_SUCCESS,SHOW_SHOW_Gallery,SHOW_Gallery_ERROR,CLEAR_MESSAGE} from './actionTypes';

import { brandSuccess, GalleryError,brandShow, merchantError, GallerySuccess} from './actions';

//Include Both Helper File with needed methods
import { getJwtData, postJwtData,editJwtData  } from '../../helpers/self_accounts_settings_helper';

function* CREATE_createGallerys({ payload: { Gallery } }) {
    console.log("SHOW_Gallery",Gallery);

    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(postJwtData, 'Gallery/create',Gallery);
             if(response.success == false){
                yield put(GalleryError(response.message.message));
            }else if(response.success == true){
                yield put(GallerySuccess(response.data.message));
                if(response.success == true){

                }
            } 
          }
    } catch (error) {
        yield put(GalleryError("Can not Create !"));
    }
}
function* EDIT_createGallerys({ payload: { Gallery } }) {
    // console.log("saga ---- ",Gallery);
    try {
          if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
          {  
             const response = yield call(editJwtData, 'Gallery/'+Gallery.id, Gallery);
             if(response.success == false){
                // alert(response.message['message']);
                yield put(GalleryError(response.message.message));
            }else if(response.success == true){
                yield put(GallerySuccess(response.data.message));
            } 
          }
          
    } catch (error) {
        yield put(GalleryError(error));
    }
}

function* EDIT_FilterClearMessage({ payload: { message } }) {
    try {
        if (message.valueSuccess) {
            console.log(message.valueSuccess);
            yield put(GallerySuccess(""));
            yield put(GalleryError(""));
        } else {
            yield put(GallerySuccess(""));
            yield put(GalleryError(""));
        }
    } catch (error) {
            yield put(GallerySuccess(""));
            yield put(GalleryError(""));
    }
}
export function* watchGallery() {
    yield takeEvery(EDIT_Gallery, EDIT_createGallerys)
    yield takeEvery(CREATE_Gallery, CREATE_createGallerys)
    yield takeEvery(CLEAR_MESSAGE, EDIT_FilterClearMessage)
}

function* Gallery() {
    yield all([
        fork(watchGallery),
    ]);
}

export default Gallery;