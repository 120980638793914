import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import UserProfile from "../pages/Authentication/UserProfile";
import CreateUserProfile from "../pages/Authentication/CreateUserProfile";
import CreateRole from "../pages/Authentication/CreateRole";
import Roles from "../pages/Authentication/Roles";
import Users from "../pages/Authentication/Users";

import ForgetPwd from "../pages/Authentication/ForgetPassword";

  // Dashboard
import Dashboard from "../pages/Dashboard/index";

//Home Features
import HomeFeatures from "../pages/HomeFeatures/HomeFeatures";
import HomeFeaturesMap from "../pages/HomeFeatures/HomeFeaturesMap";
import HomeFeaturesMapDetails from "../pages/HomeFeatures/HomeFeaturesMapDeatils";
import PrivacyPolicy from "../pages/Settings/PrivacyPolicy/privacyPolicy";

import BasicCompanySettingsCreate from "../pages/BasicCompanySettings/BasicCompanySettingsCreate";
import AboutUsPageSlider from "../pages/AboutUsPageSlider/AboutUsPageSlider";
import AboutUsPageSliderCreate from "../pages/AboutUsPageSlider/AboutUsPageSliderCreate";
import ProjectNameCreate from "../pages/ProjectName/ProjectNameCreate";
import ProjectNameEdit from "../pages/ProjectName/ProjectNameEdit";
import SubProjectNameCreate from "../pages/ProjectName/SubProjectNameCreate";
import SubProjectNameEdit from "../pages/ProjectName/SubProjectNameEdit";
import ProjectName from "../pages/ProjectName/ProjectName";


import Gallery from "../pages/Gallery/Gallery";
import GalleryCreate from "../pages/Gallery/GalleryCreate";
import GalleryEdit from "../pages/Gallery/GalleryEdit";


import ProjectSlider from "../pages/ProjectSlider/ProjectSlider";
import ProjectSliderCreate from "../pages/ProjectSlider/ProjectSliderCreate";
import ProjectSliderEdit from "../pages/ProjectSlider/ProjectSliderEdit";

import ProjectSiteMapEdit from "../pages/ProjectSlider/ProjectSiteMapEdit";
import ProjectSiteMap from "../pages/ProjectSlider/ProjectSiteMap";
import ProjectSiteMapCreate from "../pages/ProjectSlider/ProjectSiteMapCreate";

import ProjectSliderBrochure from "../pages/ProjectSlider/UploadBrochure";
import ProjectBrochure from "../pages/ProjectSlider/ProjectBrochure";



import FloorPlan from "../pages/FloorPlan/FloorPlan";
import FloorPlanCreate from "../pages/FloorPlan/FloorPlanCreate";
import FloorPlanEdit from "../pages/FloorPlan/FloorPlanEdit";
import FloorPlanView from "../pages/FloorPlan/FloorPlanView";
import UploadBrochure from "../pages/FloorPlan/UploadBrochure";
import IndexUploadBrochure from "../pages/FloorPlan/IndexUploadBrochure";


import DashboardSlider from "../pages/DashboardSlider/DashboardSlider";
import DashboardSliderCreate from "../pages/DashboardSlider/DashboardSliderCreate";

import Upcoming from "../pages/Upcoming/Upcoming";
import UpcomingCreate from "../pages/Upcoming/UpcomingCreate";

import FilterNameList from "../pages/FilterName/FilterNameList";

import FilterValueList from "../pages/FilterValue/FilterValueList";
import FilterValueCreate from "../pages/FilterValue/FilterValueCreate";

import DistrictList from "../pages/District/DistrictList";
import DistrictCreate from "../pages/District/DistrictCreate";
import DistrictMultipleCreate from "../pages/District/DistrictMultipleCreate";


import CityList from "../pages/CITY/CityList";
import CityCreate from "../pages/CITY/CityCreate";
import CityMultipleCreate from "../pages/CITY/CityMultipleCreate";


import LotList from "../pages/Lots/LotList";
import LotCreate from "../pages/Lots/LotCreate";
import LotMultipleCreate from "../pages/Lots/LotMultipleCreate";

import BookList from "../pages/Book/BookList";
import BookDownload from "../pages/Book/BookDownload";
import BookEdit from "../pages/Book/BookEdit";
import LotBlukEdit from "../pages/Lots/LotBlukEdit";
import FeatureSettings from "../pages/FeatureSettings/FeatureSettings";





const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	{ path: "/profile", component: UserProfile },
	{ path: "/users/create", component: CreateUserProfile },
	{ path: "/users/:id", component: CreateUserProfile },
	{ path: "/users", component: Users },
	{ path: "/create/role", component: CreateRole },
	{ path: "/roles/:id", component: CreateRole },
	{ path: "/role", component: Roles },

	{ path: "/featuresCreate", component: HomeFeatures },
	{ path: "/featuresMap", component: HomeFeaturesMap },
	{ path: "/features-details/:id", component: HomeFeaturesMapDetails},
	{ path: "/privacy/policy/update/1", component: PrivacyPolicy},


	//aspen
	// { path: "/basic/company/settings", component: BasicCompanySettings },
	{ path: "/basic/company/settings", component: BasicCompanySettingsCreate},

	{ path: "/management/info", component: AboutUsPageSlider },
	{ path: "/featureSettings", component: FeatureSettings },
	{ path: "/create/management/info", component: AboutUsPageSliderCreate},

	{ path: "/index/projectName", component: ProjectName },
	{ path: "/create/projectName", component: ProjectNameCreate},
	{ path: "/edit/projectName/:id", component: ProjectNameEdit},

	{ path: "/create/sub/projectName", component: SubProjectNameCreate},
	{ path: "/edit/sub/projectName/:id", component: SubProjectNameEdit},

	{ path: "/slider/landingPage", component: DashboardSlider },
	{ path: "/create/landingPage/slider", component: DashboardSliderCreate},

	{ path: "/upcoming", component: Upcoming },
	{ path: "/create/upcoming", component: UpcomingCreate},

	{ path: "/index/gallery", component: Gallery },
	{ path: "/create/gallery", component: GalleryCreate},
	{ path: "/edit/gallery/:id", component: GalleryEdit},

	{ path: "/create/projectBrochure", component: ProjectSliderBrochure},
	{ path: "/index/projectBrochure", component: ProjectBrochure },

	{ path: "/index/projectSlider", component: ProjectSlider },
	{ path: "/create/projectSlider", component: ProjectSliderCreate},
	{ path: "/edit/projectSlider/:id", component: ProjectSliderEdit},

	{ path: "/edit/projectSiteMapEdit/:id", component: ProjectSiteMapEdit},
	{ path: "/create/projectSiteMap", component: ProjectSiteMapCreate},
	{ path: "/index/projectSiteMap", component: ProjectSiteMap},

	{ path: "/index/floorPlan", component: FloorPlan },
	{ path: "/create/floorPlan", component: FloorPlanCreate},
	{ path: "/edit/floorPlan/:id", component: FloorPlanEdit},
	{ path: "/details/floorPlan/:id", component: FloorPlanView},
	{ path: "/upload/brochure", component: UploadBrochure},
	{ path: "/index/upload/brochure", component: IndexUploadBrochure},

	
	{ path: "/filter/name/list", component: FilterNameList },

	{ path: "/value/filter/list", component: FilterValueList },
	{ path: "/create/filter/value", component: FilterValueCreate},

	{ path: "/school/district/list", component: DistrictList },
	{ path: "/create/school/district", component: DistrictCreate},
	{ path: "/multiple/create/district", component: DistrictMultipleCreate },


	{ path: "/city/list", component: CityList },
	{ path: "/create/city", component: CityCreate},
	{ path: "/multiple/create/city", component: CityMultipleCreate },


	{ path: "/lot/list", component: LotList },
	{ path: "/lot/edit/list", component: LotBlukEdit },
	{ path: "/create/lot", component: LotCreate},
	{ path: "/multiple/create/lot", component: LotMultipleCreate },
	// { path: "/index/projectSlider", component: ProjectSlider },
	// { path: "/create/projectSlider", component: ProjectSliderCreate},
	// { path: "/edit/projectSlider/:id", component: ProjectSliderEdit},

	{ path: "/book/list", component: BookList },
	{ path: "/book/excel/download", component: BookDownload },
	{ path: "/book/edit/:id", component: BookEdit },





	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },

];

export { authProtectedRoutes, publicRoutes };
