import { AboutUsPageSlider_ERROR, AboutUsPageSlider_SUCCESS, EDIT_FilterValue , CLEAR_MESSAGE} from './actionTypes';

const initialState = {
    error: "",
    success: ""
}

const FilterValue = (state = initialState, action) => {
    switch (action.type) {

        case EDIT_FilterValue:
            state = { ...state }
            break;
        case AboutUsPageSlider_SUCCESS:
            state = { ...state, success: action.payload }
            break;
        case AboutUsPageSlider_ERROR:
            state = { ...state, error: action.payload };
            break;
        // case CLEAR_MESSAGE:
        //     state = { ...state, success: "" };
        //     break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default FilterValue;