import React, { Component } from "react";
// import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { Input, Form, TabContent, TabPane, NavItem, NavLink, CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";

import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { editBook, ShowBook } from '../../store/Book/actions';
// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from "react-select";
import { clearSuccess } from '../../store/FilterValue/actions';

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';

import 'react-image-picker-editor/dist/index.css'
const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class BookEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            activeTab: 1,
            activeTabProgress: 1,
            supplier: {

            },
            status: 0,

            change_site_map_image: {},
            change_project_logo: {},
            change_project_cover_image_2: {},
            change_project_cover_image_1: {},

            brand: {

            },


        };
        // this.tog_scroll = this.tog_scroll.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        // console.log(this.props)
        if (this.props.location.state !== undefined) {
            console.log(this.props.location.state.value.Id)
            this.fetchShowBook(this.props.location.state.value.Id)
        }
        else {
            let id = window.location.href.split('/').pop();
            this.fetchShowBook(id)
        }
    }





    fetchShowBook = (id) => {


        ShowBook(id).then((response) => {
            //   console.log(response);
            if (response.data != []) {
                this.setState({
                    BookNow: response.data[0],
                });
                this.state.BookNowNow = response.data[0];

            } else {
                this.setState({
                    BookNow: {

                    },

                });
            }


        })
            .catch((err) => {
                console.log(err);
            })
    }


    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        console.log("values",values);
        this.props.editBook(values);
        
    }


    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        // this.fetchsupplierList();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }


    handletHumbnailImageUpload = (image) => {
        console.log(image);

        const formData = { file: image, name: "change_project_cover_image_1", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_cover_image_1["change_project_cover_image_1"] = formData;

        // console.log("this.setState.project_cover_image_1[0]", this.state.project_cover_image_1["project_cover_image_1"]);

    }

    handleLargeImageUpload = (image) => {

        const formData = { file: image, name: "change_project_cover_image_2", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_cover_image_2["change_project_cover_image_2"] = formData;
    }

    handleMediumImageUpload = (image) => {

        const formData = { file: image, name: "change_site_map_image", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_site_map_image["change_site_map_image"] = formData;
    }

    handleProjectLogoUpload = (image) => {

        const formData = { file: image, name: "change_project_logo", type: "image/jpeg" };
        //console.log("formData", formData);

        this.state.change_project_logo["change_project_logo"] = formData;
    }

    toggleTab(tab) {
        // if (tab === 2 && (document.getElementById('project_name').value == '' || document.getElementById('project_name').value == null
        //     || document.getElementById('project_name').value == undefined)) {
        //     this.setState({
        //         errorMessage: "Bookname is required",
        //     })
        // }
        // else if (tab === 2 && (document.getElementById('project_title').value == '' || document.getElementById('project_title').value == null
        //     || document.getElementById('project_title').value == undefined)) {
        //     this.setState({
        //         errorMessage: "BookTitle is required",
        //     })
        // }
        // else if (tab === 2 && (document.getElementById('project_short_description').value == '' || document.getElementById('project_short_description').value == null
        //     || document.getElementById('project_short_description').value == undefined)) {
        //     this.setState({
        //         errorMessage: "BookShort Description is required",
        //     })
        // }
        // else if (tab === 2 && (document.getElementById('project_details_description').value == '' || document.getElementById('project_details_description').value == null
        //     || document.getElementById('project_details_description').value == undefined)) {
        //     this.setState({
        //         errorMessage: "BookDetails Description is required",
        //     })
        // }
        // else if (tab === 2 && (document.getElementById('contact_information_description').value == '' || document.getElementById('contact_information_description').value == null
        //     || document.getElementById('contact_information_description').value == undefined)) {
        //     this.setState({
        //         errorMessage: "Contact Information Description is required",
        //     })
        // }
        // else if (tab === 2 && (document.getElementById('project_address').value == '' || document.getElementById('project_address').value == null
        //     || document.getElementById('project_address').value == undefined)) {
        //     this.setState({
        //         errorMessage: "BookAddress is required",
        //     })
        // }
        // else if (tab === 2 && (document.getElementById('project_phone_no').value == '' || document.getElementById('project_phone_no').value == null
        //     || document.getElementById('project_phone_no').value == undefined)) {
        //     this.setState({
        //         errorMessage: "BookPhone No is required",
        //     })
        // }
        // else if (tab === 2 && (document.getElementById('priority').value == '' || document.getElementById('priority').value == null
        //     || document.getElementById('priority').value == undefined)) {
        //     this.setState({
        //         errorMessage: "Priority is required",
        //     })
        // }
        // else {
        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTab: tab
                });
            }
        }
        // }

    }



    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/book/list',
            }
        )
    }
    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
    }


    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;

        // const { phone_1, id, phone_2, phone_3, email_1, email_2, email_3, logo_small, logo_medium, logo_large, favicon, address_1, address_2, address_3, our_vision_title, our_vision_short_description, our_vision_details_description, our_vision_image, about_us, about_us_cover_image_1, about_us_cover_image_2, privacy_policy, privacy_policy_cover_image_1, privacy_policy_cover_image_2, contact_us_map_link, contact_us_cover_image_1, contact_us_cover_image_2, f_about_us_info, copy_right_info_name, copy_right_year, dashboard_youtube_link_1, dashboard_youtube_link_2, dashboard_youtube_link_3, dashboard_youtube_link_4, dashboard_our_foot_print_map, slider_transection_time } = this.state.companySettingListLists;

        // let Book=[];
        // if (this.state.hasOwnProperty('Book')) {
        //     Book = this.state.BookNow;
        // }

        const config2 = {
            borderRadius: '8px',
            language: 'en',
            width: '330px',
            height: '250px',
            objectFit: 'contain',
            // aspectRatio: 4 / 3,
            compressInitial: null,
        };
        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        const initialImage = '';

        // console.log("this.props.success", this.props.success)
        // if (this.props.success != '') {
        //     setTimeout(function () {
        //         window.location.reload();
        //     }, 1000);

        // }
        console.log("this.state.BookNow", this.state.BookNow);
        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="BookCreate" />
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 1 })} onClick={() => { this.toggleTab(1); }} >
                                                        <span className="step-number mr-2">01</span>
                                                        Buyer Details
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => { this.toggleTab(2); }} >
                                                        <span className="step-number mr-2">02</span>
                                                        <span>Lot Details</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink className={classnames({ active: this.state.activeTab === 3 })} onClick={() => { this.toggleTab(3); }} >
                                                        <span className="step-number mr-2">03</span>
                                                        <span>Payment  Details</span>
                                                    </NavLink>
                                                </NavItem>
                                            </ul>
                                            {
                                                this.state.BookNow &&
                                                <>
                                                    <AvForm className="form-horizontal" id='my-form' onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                                        <TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">

                                                            <TabPane tabId={1}>

                                                                <AvField className="form-control" type="hidden" id="Id" value={this.state.BookNow.Id} name="Id"  />
                                                                <AvField className="form-control" type="text" id="f_available_Lots_Aspen_id" value={this.state.BookNow.f_available_Lots_Aspen_id} name="f_available_Lots_Aspen_id"  />
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Code')} id="Code" value={this.state.BookNow.Code} name="Code"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Serial No'} id="Serial_No" name="Serial_No" value={this.state.BookNow.Serial_No} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer First Name')} id="Buyer_First_Name" value={this.state.BookNow.Buyer_First_Name} name="Buyer_First_Name"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer Middle Name')} id="Buyer_Middle_Name" name="Buyer_Middle_Name" value={this.state.BookNow.Buyer_Middle_Name} />
                                                                    </div>

                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer Last Name')} id="Buyer_Last_Name" name="Buyer_Last_Name" value={this.state.BookNow.Buyer_Last_Name} />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer Phone')} id="Buyer_Phone" value={this.state.BookNow.Buyer_Phone} name="Buyer_Phone"  />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer Address 1')} id="Buyer_Address_1" value={this.state.BookNow.Buyer_Address_1} name="Buyer_Address_1"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Buyer Address 2'} id="Buyer_Address_2" name="Buyer_Address_2" value={this.state.BookNow.Buyer_Address_2} />
                                                                    </div>
                                                                </Row>

                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer City')} id="Buyer_City" value={this.state.BookNow.Buyer_City} name="Buyer_City"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer State')} id="Buyer_State" name="Buyer_State" value={this.state.BookNow.Buyer_State}  />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer Zip')} id="Buyer_Zip" value={this.state.BookNow.Buyer_Zip} name="Buyer_Zip"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer Email')} id="Buyer_Email" name="Buyer_Email" value={this.state.BookNow.Buyer_Email}  />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer Rank')} id="Buyer_Rank" value={this.state.BookNow.Buyer_Rank} name="Buyer_Rank"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer Order Preference Number')} id="Buyer_Order_Preference_Number" name="Buyer_Order_Preference_Number" value={this.state.BookNow.Buyer_Order_Preference_Number}  />
                                                                    </div>
                                                                </Row>
                                                                <AvField type="hidden" value={this.state.BookNow.project_cover_image_1} name="project_cover_image_1" id="project_cover_image_1"  />
                                                               
                                                            </TabPane>
                                                            <TabPane tabId={2}>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Block')} id="Block" value={this.state.BookNow.Block} name="Block"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Lot'} id="Lot" name="Lot" value={this.state.BookNow.Lot} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Lot Sqft')} id="Lot_Sqft" value={this.state.BookNow.Lot_Sqft} name="Lot_Sqft"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Model')} id="Model" name="Model" value={this.state.BookNow.Model} />
                                                                    </div>

                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Unit Price Per Lot')} id="Unit_Price_Per_Lot" name="Unit_Price_Per_Lot" value={this.state.BookNow.Unit_Price_Per_Lot} />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Number Of Lots')} id="Number_Of_Lots" value={this.state.BookNow.Number_Of_Lots} name="Number_Of_Lots"  />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Sales_Price')} id="Sales_Price" value={this.state.BookNow.Sales_Price} name="Sales_Price"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Sales Price Sum'} id="Sales_Price_Sum" name="Sales_Price_Sum" value={this.state.BookNow.Sales_Price_Sum} />
                                                                    </div>
                                                                </Row>

                                                            </TabPane>
                                                            <TabPane tabId={3}>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Earnest Money')} id="Earnest_Money" value={this.state.BookNow.Earnest_Money} name="Earnest_Money"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={'Received Money'} id="Received_Money" name="Received_Money" value={this.state.BookNow.Received_Money} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Payment Due')} id="Payment_Due" value={this.state.BookNow.Payment_Due} name="Payment_Due"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Received Through Check')} id="Received_Through_Check" name="Received_Through_Check" value={this.state.BookNow.Received_Through_Check} />
                                                                    </div>

                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Received Through Wire')} id="Received_Through_Wire" name="Received_Through_Wire" value={this.state.BookNow.Received_Through_Wire} />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Received Through Cash')} id="Received_Through_Cash" value={this.state.BookNow.Received_Through_Cash} name="Received_Through_Cash"  />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Form Of Money Received')} id="Form_Of_Money_Received" value={this.state.BookNow.Form_Of_Money_Received} name="Form_Of_Money_Received"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="date" label={reqLabel('LOI Received Date')} id="LOI_Received_Date" name="LOI_Received_Date" value={this.state.BookNow.LOI_Received_Date} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="date" label={reqLabel('Next Payment Date Expected')} id="Next_Payment_Date_Expected" value={this.state.BookNow.Next_Payment_Date_Expected} name="Next_Payment_Date_Expected"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="date" label={reqLabel('Money Received Date')} id="Money_Received_Date" name="Money_Received_Date" value={this.state.BookNow.Money_Received_Date} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Payment Made Receipt No')} id="Payment_Made_Receipt_No" value={this.state.BookNow.Payment_Made_Receipt_No} name="Payment_Made_Receipt_No"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Reference')} id="Reference" name="Reference" value={this.state.BookNow.Reference} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Docu Sign')} id="Docu_Sign" value={this.state.BookNow.Docu_Sign} name="Docu_Sign"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Physical Sign')} id="Physical_Sign" name="Physical_Sign" value={this.state.BookNow.Physical_Sign} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Final Agreement Sent')} id="Final_Agreement_Sent" value={this.state.BookNow.Final_Agreement_Sent} name="Final_Agreement_Sent"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Final Signed')} id="Final_Signed" name="Final_Signed" value={this.state.BookNow.Final_Signed} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Invoice Sent')} id="Invoice_Sent" value={this.state.BookNow.Invoice_Sent} name="Invoice_Sent"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Digital Version Ready')} id="Digital_Version_Ready" name="Digital_Version_Ready" value={this.state.BookNow.Digital_Version_Ready} />
                                                                    </div>
                                                                </Row>
                                                                <Row>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('LOI Payment Proof')} id="LOI_Payment_Proof" value={this.state.BookNow.LOI_Payment_Proof} name="LOI_Payment_Proof"  />
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <AvField className="form-control" type="text" label={reqLabel('Buyer LUID')} id="Buyer_LUID" name="Buyer_LUID" value={this.state.BookNow.Buyer_LUID} />
                                                                    </div>
                                                                </Row>
                                                            </TabPane>
                                                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                                <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab - 1); }}>Previous</Link></li>
                                                                {
                                                                    this.state.activeTab == 3 ?
                                                                        <li className="next">
                                                                            <Button type="submit" form='my-form' color="success">Submit</Button>
                                                                        </li>
                                                                        :
                                                                        <li className={this.state.activeTab === 3 ? "next disabled" : "next"}><Link to="#" onClick={() => { this.toggleTab(this.state.activeTab + 1); }}>Next</Link></li>

                                                                }
                                                            </ul>
                                                        </TabContent>
                                                    </AvForm>

                                                </>
                                            }
                                        </div>

                                        {/* 
                                            <div className=" mt-4">
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>
                                             */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ProjectName;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { editBook, clearSuccess })(BookEdit));