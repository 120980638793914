import React, { Component } from "react";
import { TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, CardSubtitle } from "reactstrap";
import classnames from "classnames";

import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
import { deleteBrochure, editFloorPlan, FloorPlanBrochureList, SubFloorPlanList } from '../../store/FloorPlan/actions';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';
import renderHTML from 'react-render-html';
import DeleteModal from './DeleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class IndexUploadBrochure extends Component {
    constructor(props) {
        var Tabvalue = localStorage.getItem('projectName');
        // console.log(Tabvalue);
        if (Tabvalue == undefined || Tabvalue == null) {
            localStorage.setItem('projectName', "project");
        }
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            activeTab1: Tabvalue,
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            ProjectName_list: {},
            deleteModal: false,
            supplier: {

            },

        };
        // this.tog_scroll = this.tog_scroll.bind(this);
    }

    componentDidMount() {
        this.fetchFloorPlan();
        var Tabvalue = localStorage.getItem('projectName');
        if (Tabvalue == undefined || Tabvalue == null) {
            localStorage.setItem('projectName', "project");
        }

        if (localStorage.getItem('floorPlanCreate') == "true") {
            localStorage.setItem('floorPlanCreate', "false");
            window.location.reload();
        }
        // this.fetchCityList();     
    }

    fetchFloorPlan = () => {
        let options = {
            page: this.state.page,
            per_page: this.state.per_page,
            search: this.state.search
        }
        FloorPlanBrochureList(options).then((response) => {
            //   console.log(response);
            this.setState({
                ProjectNameList: response.data,
            });
            this.state.ProjectNameList = response.data;
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (IndexUploadBrochure) => {

        this.props.history.push({
            pathname: '/edit/floorPlan/' + IndexUploadBrochure.id,
            state: { value: IndexUploadBrochure, }
        });
    }
    view = (IndexUploadBrochure) => {

        this.props.history.push({
            pathname: '/details/floorPlan/' + IndexUploadBrochure.id,
            state: { value: IndexUploadBrochure, }
        });
    }
    //modal
    tog_scroll2 = (IndexUploadBrochure) => {

        this.props.history.push({
            pathname: '/edit/sub/IndexUploadBrochure/' + IndexUploadBrochure.id,
            state: { value: IndexUploadBrochure, }
        });
    }


    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    // handleValidSubmit(event, values) {

    //     this.props.createSupplier(values);
    //     this.form && this.form.reset();
    //     this.fetchFloorPlan();
    //     // alert("Supplier Create Successfully");
    //     // window.location.reload();
    // }
    toggleDeleteModal = (conmpany) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
        this.onClickDelete(conmpany);
    };

    onClickDelete = (customer) => {
        // console.log("Delete Modal", customer);
        this.setState({ deleteCustomer: customer });
        this.setState({ deleteModal: true });

    };
    handleDeleteSubmit() {
        // alert("Are You Want To Delete This?");
        // this.fetchFloorPlan(); 
        const { deleteCustomer } = this.state;
        // console.log("Delete Modal22222222", deleteCustomer);

        if (deleteCustomer != undefined) {
            deleteBrochure(deleteCustomer.id)
                .then((response) => {
                    if (response.success == true) {
                        //   console.log(response)
                        // alert(response.data);
                        this.fetchFloorPlan();
                        // window.location.reload();
                        this.setState({
                            deleteModal: false,
                            success: response.data.message,
                        });
                    }
                    else {
                        this.setState({
                            deleteModal: false,
                            error: response.data.error,

                        })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }


    handleEditSubmit(event, values) {
        this.props.editProjectName(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.fetchFloorPlan();
        // window.location.reload();
    }


    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchFloorPlan();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchFloorPlan();
        });
    }

    toggle1(tab) {
        localStorage.setItem('projectName', tab)
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab
            });

        }
    }

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;

        let ProjectNameListtMap = [];
        let SubProjectNameListtMap = [];
        const { deleteModal, message } = this.state;

        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        let paginator2;
        if (this.state.hasOwnProperty('ProjectNameList')) {
            ProjectNameListtMap = this.state.ProjectNameList.data;
            paginator = {
                'currentPage': this.state.ProjectNameList.current_page,
                'pagesCount': this.state.ProjectNameList.last_page,
                'total': this.state.ProjectNameList.total,
                'to': this.state.ProjectNameList.to,
                'from': this.state.ProjectNameList.from,
            }
        }
        if (this.state.hasOwnProperty('SubProjectNameList')) {
            SubProjectNameListtMap = this.state.SubProjectNameList.data;
            paginator2 = {
                'currentPage': this.state.SubProjectNameList.current_page,
                'pagesCount': this.state.SubProjectNameList.last_page,
                'total': this.state.SubProjectNameList.total,
                'to': this.state.SubProjectNameList.to,
                'from': this.state.SubProjectNameList.from,
            }
        }

        let singlevoucher = this.state.voucher;

        const { code, slider_header_title, id, slider_title, short_description, long_descriptiom, slider_thumbnail_image, slider_large_image, slider_medium_image, remarks, priority } = this.state.ProjectName_list;

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }
        //   console.log("this.props.success", this.props.success)
        if (this.props.success != '') {
            setTimeout(function () {
                window.location.reload();
            }, 1);
        }
        if (this.props.error != '') {
            setTimeout(function () {
                window.location.reload();
            }, 1);
        }

        return (
            <React.Fragment>
                {/* <div style={this.state.displayHide}> 
                        <h6 align="center">Loding...</h6> 
                        <CardImg  className="loader" src="./loader.gif" />
                    </div> */}
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Settings" breadcrumbItem="Floor Brochure  " />
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                        {this.state.error && this.state.error ?
                            <SweetAlert
                                danger
                                title={this.state.error}
                                timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.state.success && this.state.success ?
                            <SweetAlert
                                title={this.state.success}
                                timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                success
                                onConfirm={() => window.location.reload()
                                }
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                onConfirm={() => window.location.reload()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                timeout={2000}
                                showConfirm={true}
                                // showCloseButton={true}
                                success
                                onConfirm={() => window.location.reload()
                                }
                            >
                            </SweetAlert> : ''
                        }
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={() => this.handleDeleteSubmit()}
                            onCloseClick={() => this.setState({ deleteModal: false })}
                        />
                        <Row>

                            <Col lg={12}>
                                <Card>
                                    <CardBody>

                                        <Row>
                                            <Col lg="12" md="12">
                                                <Card>

                                                    <CardBody>

                                                        <CardTitle>
                                                            <div className="row justify-content-between">
                                                                <p> Floor Brochure List </p>
                                                                <div>
                                                                    <Link to={'/upload/brochure'}><Button className="btn btn-success pull-left mx-2">Upload brochure File</Button></Link>
                                                                </div>
                                                            </div>


                                                        </CardTitle>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <DataTabeSearch handleInputChange={this.handleInputChange} />
                                                                <Table responsive>

                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Floor Name</th>
                                                                            <th>Floor Brochure Id</th>
                                                                            <th>Floor Brochure</th>
                                                                            <th>Status</th>

                                                                            <th colspan="2">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ProjectNameListtMap.map((companySetting, index) =>
                                                                            <tr key={companySetting.id.toString()}>
                                                                                <td scope="row">{index + 1}</td>
                                                                                <td>{companySetting.floor_plan && companySetting.floor_plan[0].floore_plans_names}</td>
                                                                                <td>{companySetting.id}</td>
                                                                                {
                                                                                    companySetting.slider_large_image != null ?
                                                                                        (
                                                                                            <iframe src={imageLink + 'FloorPlanB/brochure/' + companySetting.slider_large_image}  ></iframe>
                                                                                        ) : null
                                                                                }
                                                                                <td><Badge color={getBadge(companySetting.status)}>{getStatusValue(companySetting.status)}</Badge></td>

                                                                                <td>
                                                                                    {/* <i type="button"
                                                                                        className="fa fa-eye  text-warning fa-2x my-2 ml-2"
                                                                                        onClick={() => { this.view(companySetting) }}

                                                                                    >
                                                                                    </i> */}

                                                                                    <i
                                                                                        className="fa fa-trash text-danger fa-2x my-2 ml-2"
                                                                                        onClick={() => { this.toggleDeleteModal(companySetting) }}
                                                                                    ></i>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                    {paginator &&
                                                        <CardFooter className="p-6 bg-white">
                                                            <CustomPagination paginator={paginator} handlePageClick={this.handlePageClick} />
                                                        </CardFooter>
                                                    }
                                                </Card>

                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ProjectName;
    return { msg, error, success };
}

// export default withRouter(connect(mapStatetoProps, { createSupplier, editSupplier, supplierError })(VoucherIndex));
export default withRouter(connect(mapStatetoProps, {})(IndexUploadBrochure));