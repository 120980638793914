import React, { Component } from "react";
import { CardImg, Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardFooter, Modal, CardHeader, FormGroup, Label } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { CustomPagination } from "../Settings/Common/CustomPagination";
import { DataTabeSearch } from '../Settings/Common/DataTabeSearch';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Switch from 'react-switch';
// supplierList, editSupplier, supplierError 
import { createCity } from '../../store/CITY/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { clearSuccess } from '../../store/FilterValue/actions';

// import { createPump, pumpList, editPump, thanaList, cityList, districtList } from '../../store/petrolPump/actions';
// import { marchantList } from '../../store/merchantBranch/actions';
// import { voucherImageUpload, supplierList } from "../../store/actions";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditorDescription from '@ckeditor/ckeditor5-build-classic';
import ClassicEditorSpecification from '@ckeditor/ckeditor5-build-classic';

const reqLabel = label => {
    return <>{label}<sup style={{ color: 'red' }}>(*)</sup></>;
}

class CityCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            toggleSwitch: false,
            edittoggleSwitch: false,
            modal_scroll: false,
            supplier: {

            },
            status: 0,
            upcoming_project_image: {},
            brand: {
            },
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }


    //modal
    tog_scroll = (supplier) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.supplier = supplier;
        this.fetchDistrictList(supplier.f_division_id);
        this.fetchThanaList(supplier.f_district_id);

        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.props.createCity(values);

    }
    handleEditSubmit(event, values) {
        this.props.editSupplier(values);
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
    }
    handleInputChange = (e) => {
        if (e.target.name == 'f_division_id') {
            this.fetchDistrictList(e.target.value);
        }
        if (e.target.name == 'f_district_id') {
            this.fetchThanaList(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.fetchsupplierList();
        });
    }

    handlePageClick = (e, index) => {
        e.preventDefault()
        this.setState({
            page: index
        }, () => {
            this.fetchsupplierList();
        });
    }

    handleChange(status) {
        this.setState({ status });
    }

    handletHumbnailImageUpload = (e) => {
        var id = e.target.id;
        let files = e.target.files;

        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            const formData = { file: e.target.result, name: files[0].name, type: files[0].type };
            console.log("formData", formData);

            this.state.upcoming_project_image[id] = formData;
            //  console.log("this.setState.voucharImage[0]",  this.state.voucharImage[id]);
        }
    }
    clear = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.success);
        this.props.history.push(
            {
                pathname: '/city/list',
            }
        )
    }
    clearErr = () => {
        localStorage.setItem('last_action', "create");
        this.props.clearSuccess(this.props.error);
    }

    render() {
        const imageLink = process.env.REACT_APP_IMAGE_URL;
        let supplierListMap = [];
        let cityList = [];
        let districtList = [];
        let thanaList = [];
        let marchantList = [];
        let paginator;
        let filterNameListInfo = [];
        if (this.state.hasOwnProperty('filterNameList')) {
            filterNameListInfo = this.state.filterNameList.data;
        }

        const getBadge = (status) => {
            return status == 1 ? 'success' : 'secondary'
        }

        const getStatusValue = (status) => {
            return status == 1 ? 'Active' : 'In-active'
        }


        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid={true}>

                        <Breadcrumbs title="Settings" breadcrumbItem="City Create" />
                        {/* {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null} */}
                        {this.props.error && this.props.error ?
                            <SweetAlert
                                danger
                                title={this.props.error}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                onCancel={this.hideAlert}
                                onConfirm={() => this.clearErr()}
                            >
                            </SweetAlert> : ''
                        }
                        {this.props.success && this.props.success ?
                            <SweetAlert
                                title={this.props.success}
                                // timeout={2000}
                                showConfirm={true}
                                showCloseButton={true}
                                success
                                onCancel={this.hideAlert}
                                onConfirm={() =>

                                    this.clear()

                                }
                            >
                            </SweetAlert> : ''
                        }
                        <Row>


                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Name')} id="city_name" name="city_name" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Zip Code')} id="zip_code" name="zip_code" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('State Name')} id="state_name" name="state_name" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('State Short Form')} id="state_short_form" name="state_short_form" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Latitude')} id="latitude" name="latitude" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Longitude')} id="longitude" name="longitude" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Land Of Area')} id="land_of_area" name="land_of_area" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label={reqLabel('Address Details')} id="address_details" name="address_details" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="text" label='Remarks' id="remarks" name="remarks" />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField className="form-control" type="number" label={reqLabel('Priority')} id="priority" name="priority" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className="col-md-6">
                                                    <Label>{reqLabel('Status')}</Label><br />
                                                    <AvField type="select" className="form-control" name="Status"   >
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>

                                                    </AvField>
                                                </div>
                                            </Row>







                                            <div className=" mt-4">
                                                <Button type="submit" color="success">Submit</Button>
                                            </div>


                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.AboutUsPageSlider;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createCity, clearSuccess })(CityCreate));